import { IContact } from "../models/Contact";
import {IContactState} from "./ContactState";

export const initNewContact = (): IContact => {
    return {
        contactId: 0,
        createdOn: new Date(),
        avatarId: null,
        firstName: '',
        lastName: '',
        nickName: '',
        patronymic: '',
        chatName: '',
        phone: '',
        messengers: [],
        chatsCount: 0,
        accounts: [],
        contactComment: '',
        isDeleted: false,
        locale: 'ru-RU',
        tags: []
    }
}

export const initState: IContactState = {
    contactList: {
        contacts: [],
        isReceivingContacts: false,
        isContactCreating: false,
        countOfContacts: 0,
        searchString: '',
        isSortingByContactLastName: false,
        contactItemInfo: {
            selectedContact: initNewContact(),
            changedContact: initNewContact(),
            contactToMerge: initNewContact(),
            isReceivingContact: false,
            isRestoringContact: false,
        }
    }
}