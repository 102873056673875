import { AppThunkAction } from "src/~store/models/AppThunkAction";
import { ChangeAnalyticTabAction, ChangeAnalyticSubtabAction, ChangeSelectedBotAction, GetBotsAction, GetChatStepsAction, GetSettingByIdAction, UpdateChartsByFilter, GetReportsSettingByIdAction, ChangeReportsSubtabAction, GetReportByNameAction, SetAnalyticsTagsAction, SaveReportByNameAction } from "./interfaces";
import { getBotsAsync, getChatStepsAsync, getReportByNameAsync, getSettingByIdAsync, saveReportByNameAsync } from "./../../~Api/actions";
import { CHANGE_ANALYTIC_TAB, CHANGE_ANALYTIC_SUBTAB, CHANGE_SELECTED_BOT_SUCCESS, GET_BOTS_SUCCESS, GET_CHAT_STEPS_SUCCESS, GET_SETTING_BY_ID_SUCCESS, UPDATE_CHARTS_BY_FILTER, GET_REPORTS_SETTING_BY_ID_SUCCESS, CHANGE_REPORT_SUBTAB_SUCCESS, GET_REPORT_BY_NAME_SUCCESS, SET_ANALYTICS_TAGS_SUCCESS, SAVE_REPORT_BY_NAME_START, SAVE_REPORT_BY_NAME_SUCCESS, SAVE_REPORT_BY_NAME_ERROR } from "./types";
import { ISetting } from "src/app/setting/~store/models/setting";
import { AnalyticTab } from "../models/enums/AnalyticTab";
import { ISearchingTag } from "src/app/shared/components/tag-searching-input/~types/models/SearchingTag";
import { showErrorToastr } from "src/app/shared/helpers/toastr-helper/ToastrHelper";
import { ReportType } from "../models/enums/ReportType";
import i18n from "src/app/shared/localization/i18n";

export const getChatSteps = (selectedBotId: number, tags: ISearchingTag[]): AppThunkAction<GetChatStepsAction> => async dispatch => {
    const result = await getChatStepsAsync(selectedBotId, tags);

    if(result.isSuccess) {
        const chatSteps = result.value ?? []

        dispatch({type: GET_CHAT_STEPS_SUCCESS, payload: {chatSteps}})
    }
}

export const getBots = (): AppThunkAction<GetBotsAction> => async dispatch => {
    const result = await getBotsAsync();

    if(result.isSuccess) {
        const bots = result.value ?? []

        dispatch({type: GET_BOTS_SUCCESS, payload: {bots}})
    }
}

export const changeSelectedBot = (selectedBotId: number): AppThunkAction<ChangeSelectedBotAction> => async dispatch => {
    dispatch({type: CHANGE_SELECTED_BOT_SUCCESS, payload: {selectedBotId}})
}

export const getChartsSettingById = (settingId: string, selectedSubtab: string, tags: ISearchingTag[]): AppThunkAction<GetSettingByIdAction> => async dispatch => {
    const result = await getSettingByIdAsync(settingId);

    if(result.isSuccess) {
        dispatch({type: GET_SETTING_BY_ID_SUCCESS, payload: {setting: result.value ?? {} as ISetting, selectedSubtab: selectedSubtab, tags: tags}})
    } else {

    }
}

export const getReportsSettingById = (settingId: string): AppThunkAction<GetReportsSettingByIdAction> => async dispatch => {
    const result = await getSettingByIdAsync(settingId);

    if(result.isSuccess && result.value) {
        dispatch({type: GET_REPORTS_SETTING_BY_ID_SUCCESS, payload: {setting: result.value ?? {} as ISetting}})
    } 
    else if(!result.isSuccess){
        showErrorToastr("Error!!!")
    }
}

export const changeReportsSubtab = (subtab: string): AppThunkAction<ChangeReportsSubtabAction> => async dispatch => {
    dispatch({type: CHANGE_REPORT_SUBTAB_SUCCESS, payload: {subtab}})
}

export const getReportByName = (name: string): AppThunkAction<GetReportByNameAction> => async (dispatch, getState) => {
    dispatch({type: GET_REPORT_BY_NAME_SUCCESS, payload: {report: '[]'}})

    const {tags} = getState().analyticsState.analytics;

    const result = await getReportByNameAsync(name, tags);

    if(result.isSuccess && result.value) {
        dispatch({type: GET_REPORT_BY_NAME_SUCCESS, payload: {report: result.value}})
    } 
    else {
        showErrorToastr("Error!!!")
    }
}

export const saveReportByName = (name: string, reportType: ReportType): AppThunkAction<SaveReportByNameAction> => async (dispatch, getState) => {
    dispatch({ type: SAVE_REPORT_BY_NAME_START})
    const {tags} = getState().analyticsState.analytics;

    const result = await saveReportByNameAsync(name, reportType, tags);
    const errorText = JSON?.parse(result.errorText ?? "null");

    if (result.isSuccess) {
        dispatch({ type: SAVE_REPORT_BY_NAME_SUCCESS })
    } else {
        if(errorText.errorCode === "error.reportOutOfRangeException") {
            showErrorToastr(i18n.t('analytics.reportOutOfRangeException'));
        }
        else {
            showErrorToastr(i18n.t('analytics.reportException'));
        }
        dispatch({ type: SAVE_REPORT_BY_NAME_ERROR })
    }
}

export const updateChartsDate = (date: string, channelId: string, nodes: NodeListOf<ChildNode>): AppThunkAction<UpdateChartsByFilter> => async dispatch => {
    dispatch( {type: UPDATE_CHARTS_BY_FILTER, payload: {date, channelId, nodes}} );
}

export const changeAnalyticTab = (tab: AnalyticTab): AppThunkAction<ChangeAnalyticTabAction> => async dispatch => {
    dispatch( {type: CHANGE_ANALYTIC_TAB, payload: {tab}});
}

export const changeAnalyticSubtab = (tab: AnalyticTab, subtab: string, currentSettings: string, tags: ISearchingTag[]): AppThunkAction<ChangeAnalyticSubtabAction> => async dispatch => {
    dispatch( {type: CHANGE_ANALYTIC_SUBTAB, payload: {tab, subtab, currentSettings, tags}});
}

export const setAnalyticsTags = (tags: ISearchingTag[]): AppThunkAction<SetAnalyticsTagsAction> => async dispatch => {
    dispatch( {type: SET_ANALYTICS_TAGS_SUCCESS, payload: {tags}} );
}