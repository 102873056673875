const CHAT_API_ROUTE = `${process.env.REACT_APP_CHAT_API_ROUTE}`;

const CHANNEL_API_ROUTE = `${CHAT_API_ROUTE}/channel`;
export const GET_CHANNELS_API_ROUTE = `${CHANNEL_API_ROUTE}/get-all-channels`;
export const SAVE_CHANNEL_API_ROUTE = `${CHANNEL_API_ROUTE}/save-channel`;
export const DELETE_CHANNEL_API_ROUTE = `${CHANNEL_API_ROUTE}/delete-channel?channelId=`;
export const RESTART_CHANNEL_API_ROUTE = `${CHANNEL_API_ROUTE}/restart-channel?channelId=`;
export const SAVE_CHANNEL_SETTING_API_ROUTE = `${CHANNEL_API_ROUTE}/save-channel-setting`;
export const GET_SETTING_BY_CHANNEL_ID_API_ROUTE = `${CHANNEL_API_ROUTE}/get-setting-by-channel-id?channelId=`;
export const GET_SETTINGS_BY_CHANNELS_ID_API_ROUTE = `${CHANNEL_API_ROUTE}/get-settings-by-channels-id?`;
export const GET_CHANNEL_BY_ID_API_ROUTE = `${CHANNEL_API_ROUTE}/get-channel-by-id?channelId=`;
export const GET_CHANNELS_BY_CHANNELSID_API_ROUTE = `${CHANNEL_API_ROUTE}/get-channels-by-id`;


