import React, { useEffect, useRef, useState } from 'react';
import useInterval from '../../../shared/helpers/custom-hooks/use-interval/useInterval'
import SearchingInput from 'src/app/shared/components/searching-input/SearchingInput';
import { IChatsListProps } from './~types/ChatsListProps';
import { connect } from 'react-redux';
import { 
    getChats, 
    setSearchStringToChatsList, 
    onChatItemClick, 
    updateChats, 
    addNewChatToState,
    onGetChatsModeChanged, 
    changeChatContactChatName,
    searchChats,
    selectChat,
    getSLColorSecondsSettigs,
    toggleAutoUpdateChats} from '../../~store/actions/actions';
import { setChatVisibilityToState, updateChatsInChatPanel } from './../../../chats-panel/~store/actions/actions'

import { IApplicationState } from 'src/~store/models/ApplicationState';
import Loader from 'src/app/shared/components/loader/Loader';
import { Button, Table, ButtonGroup } from 'reactstrap';
import ChatsItem from './chats-item/ChatsItem';
import history from 'src/~store/history';
import { 
    channelsListSelector,
    chatsSelector, 
    isReceivingChatsSelector, 
    countOfChatsSelector, 
    searchStringForChatsListSelector,
    operatorIdSelector,
    isModeChangingSelector,
    tagsSelector,
    SLColorSecondsSettigsSelector,
    autoUpdateChatsSelector,
    isAllChatsLoadedSelector,
} from '../../~store/selector';
import { createMatchSelector } from 'connected-react-router';
import { IChatsListRouteParams } from './~types/ChatsListRouteParams';
import './ChatsList.css';
import { IMessage } from '../../~store/models/Message';
import { MessageType } from '../../~store/models/enums/MessageType';
import { stat } from 'fs';
import { IChat } from '../../~store/models/Chat';
import Switch from "react-switch";
import { GetChatsMode } from '../../~store/models/enums/GetChatsMode';
import { REQUESTED_NUMBER_OF_CHATS, SEARCHING_TAGS, TRIGGER_SCROLL_HEIGHT } from './ChatsListConstants';
import UserContext from 'src/app/shared/components/user-context/UserContext';
import { PermissionType } from 'src/app/shared/components/user-context/models/PermissionType';
import { IContact } from 'src/app/contact/~store/models/Contact';
import { isChatVisibleSelector } from 'src/app/contact/~store/selector';
import TagSearchingInput from 'src/app/shared/components/tag-searching-input/TagSearchingInput';
import { ISearchingTag, TagName, TagType } from 'src/app/shared/components/tag-searching-input/~types/models/SearchingTag';
import { ChannelType } from 'src/app/channel/~store/models/enums/ChannelType';
import { allOperatorsSelector } from '../../../operator/~store/selector'
import { getAllOperators } from 'src/app/operator/~store/actions/actions';
import { contactsSelector } from 'src/app/contact/~store/selector';
import { getContacts } from 'src/app/contact/~store/actions/actions';
import { IChatItem } from 'src/app/chats-panel/~store/models/ChatItem';
import { selectedChatSelector } from 'src/app/chats-panel/~store/selectors';
import { useTranslation } from 'react-i18next';
import { getAllChannels } from 'src/app/channel/~store/actions/actions';
import { getDate, getTimePassedFromDate } from 'src/app/shared/helpers/date-helper/DateHelper';
import { getDateTime } from 'src/app/shared/helpers/date-helper/DateFormats';
import moment from 'moment';
import Checkbox from "@material-ui/core/Checkbox";

type ReduxType = ReturnType<typeof mapStateToProps> & IChatsListProps;

const ChatsList = (props: ReduxType) => {
    const { t } = useTranslation();

    const filter: string = 
        props.matchParams && props.matchParams.filter.length > 0 
        ? props.matchParams.filter 
        : '';

    const [rerenderFlag, setRerenderFlag] = useState<boolean>(false);
    const chatsContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {        
        props.getSLColorSecondsSettigs();
        if(filter.length > 0) {
            props.onGetChatsModeChanged(GetChatsMode.archive);
            _useFilter(filter);
        }

        const hubConnection = props.hubConnection;
        hubConnection.on('BroadcastMessage', onBroadcastSignalRMessage); 
        props.setSearchString(filter);

        document.addEventListener("keydown", _onKeyDown);
        return () => {
            hubConnection.off('BroadcastMessage', onBroadcastSignalRMessage);
            document.removeEventListener("keydown", _onKeyDown);
        }
    }, []);

    useEffect(() => {
        document.removeEventListener("keydown", _onKeyDown);
        document.addEventListener("keydown", _onKeyDown);

        return () => {
            document.removeEventListener("keydown", _onKeyDown);
        }
    }, [props.selectedChatId, props.chats]);

    useInterval(() => {
        if(props.getChatsMode !== GetChatsMode.archive) {
            setRerenderFlag(!rerenderFlag);
        }   
    }, 1000);

    const onBroadcastSignalRMessage = (recievedMessage: any) => {
        switch(recievedMessage.type) {
            case "UpdateChats":
                props.updateChats(recievedMessage.data);
                break;
            case "NewChat_Broadcast":
                props.updateChats([recievedMessage.data]);
                break;
        }
    }

    const _onKeyDown = (event: KeyboardEvent) => {
        if(event.key === "PageUp") {
            props.selectChat(false, props.chats, props.selectedChat);
            event.preventDefault();
        }
        if(event.key === "PageDown") {
            props.selectChat(true, props.chats, props.selectedChat);
            event.preventDefault();
        }
    }

    const _useFilter = (filter: string) => {
        if(filter.includes("contact:")) {
            const contactId = Number.parseInt(filter.split(":")[1]);
            if(contactId !== NaN && props.tags.find(tag => tag.tagName === TagName.Contact && tag.value === contactId.toString()) === undefined) {
                const index = props.tags.findIndex((t) => t.tagName === TagName.Contact && t.tagType === TagType.ClosingTag);
                if(index > -1) {
                    props.tags.splice(index - 2, 3);
                }
                props.getContacts(0, 1, contactId.toString(), true, true);
                const openingContactTag = {...SEARCHING_TAGS.find(tag => tag.tagType === TagType.OpeningTag && tag.tagName === TagName.Contact)} as ISearchingTag;
                const operationTag = {...SEARCHING_TAGS.find(tag => tag.tagType === TagType.OperationTag && tag.value === "=")} as ISearchingTag;
                const closingTag = {...SEARCHING_TAGS.find(tag => tag.tagType === TagType.ClosingTag && tag.tagName === TagName.Contact), value: contactId.toString()} as ISearchingTag;
                _searchChats(props.tags.concat([openingContactTag, operationTag, closingTag]));
                history.push("/chats");
            }
        }
    }

    const onChatsScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if ((e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight) <= TRIGGER_SCROLL_HEIGHT && !props.isReceivingChats && !props.isAllChatsLoaded) {
            props.getChats(props.countOfChats, REQUESTED_NUMBER_OF_CHATS, props.searchString, props.tags, props.getChatsMode);
        }
    }

    const onGetChatsModeChanged = (mode: GetChatsMode) => {
        if(props.isReceivingChats || props.getChatsMode === mode || props.isModeChanging) return;
        props.onGetChatsModeChanged(mode);
        props.getChats(0, REQUESTED_NUMBER_OF_CHATS, props.searchString, props.tags, mode);
    }

    const _searchChats = (tags: ISearchingTag[]) => {
        chatsContainerRef.current?.scrollTo({top: 0});
        props.searchChats(tags);
    }

    const _onClearFilter = () => {
        _searchChats([]);
    }

    const onChatItemClickHandler = (chatId: number) => {
        props.onChatItemClick(chatId);

        setChatVisibilityToAll(true);
    }

    const setChatVisibilityToAll = (value: boolean) => {
        props.setChatVisibility(value);
        props.setChatVisibilityToState(value);
    };


    const GetChatsTable = (): React.ReactElement<{}> => {
        const { t } = useTranslation();
        return (
            <React.Fragment>
                {(props.chats.length > 0) && 
                    <Table className="chats-list-table card-table">
                        <thead>
                            <tr>
                                <th className="chat-item__chat-id-cell"></th>
                                <th className="chat-item__channel-info-cell">
                                    {t('common.contact')}
                                </th>
                                <th style={props.isChatVisible ? {} : { float: "right" }}>{t('chats.info')}</th>
                                {!props.isChatVisible && <th></th>}
                                {props.getChatsMode === GetChatsMode.archive && <th>{t('common.status')}</th>}
                                {!props.isChatVisible && <th>{t('chats.statistics')}</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {props.chats.map((chat) => {
                                return (
                                    <ChatsItem 
                                        chat={chat} 
                                        key={chat.chatId}
                                        operatorName={getOperatorName(chat.lastMessage, props.operatorId)}
                                        onClick={(chatId) => { if(chatId !== props.selectedChatId || !props.isChatVisible) onChatItemClickHandler(chatId)}}
                                        selected={chat.chatId === props.selectedChatId}
                                        isChatvisible={props.isChatVisible}
                                        operatorId={props.operatorId}
                                        changeChatContactChatname={props.changeChatContactChatname}
                                        chatsMode={props.getChatsMode}
                                        firstNotAnsweredMessage={chat.firstNotAnsweredMessage}
                                        pausedTime={chat.lastPausedTime ? getTimePassedFromDate(chat.lastPausedTime) : ''}
                                        SLColorSecondsSettings={props.SLColorSecondsSettigs}
                                    />
                                )
                            })}
                        </tbody>
                    </Table>
                }
            </React.Fragment>
        )
    }

    return (
        <div className="chats-list-container">
            <div className="chats-list__header">
                <div className="chats-list__search-mode">
                    <span className={props.getChatsMode === GetChatsMode.my ? "active" : ""} onClick={() => { onGetChatsModeChanged(GetChatsMode.my) }}>{t('chats.my')}</span>
                    <span className={props.getChatsMode === GetChatsMode.withMe ? "active" : ""} onClick={() => { onGetChatsModeChanged(GetChatsMode.withMe) }}>{t('chats.withMe')}</span>
                    <span className={props.getChatsMode === GetChatsMode.all || props.getChatsMode === GetChatsMode.botsAndQueue ? "active" : ""} onClick={() => { onGetChatsModeChanged(props.permissionList.includes(PermissionType.Chats_ManageOthersChats) ? GetChatsMode.all : GetChatsMode.botsAndQueue) }}>{t('chats.all')}</span>
                    <span className={props.getChatsMode === GetChatsMode.archive ? "active" : ""} onClick={() => { onGetChatsModeChanged(GetChatsMode.archive) }}>{t('chats.archive')}</span>
                </div>
                <TagSearchingInput
                    searchingTags={SEARCHING_TAGS}
                    operators={props.operators}
                    contacts={props.contacts}
                    channels={props.channels}
                    getAllChannels={props.getAllChannels}
                    getAllOperators={props.getAllOperators}
                    getContacts={(searchingString) => props.getContacts(0, 5, searchingString, true, true)}
                    onSearch={(tags) => _searchChats(tags)}
                    currentTags={props.tags}
                    onClearFilter={() => _onClearFilter()} />
            </div>
            <div className='auto-update-chats-container'>
                <Checkbox className='auto-update-chats-check' color="success"  checked={props.autoUpdateChats} onClick={props.toggleAutoUpdateChats}/>
                <span>{t('chats.autoUpdateChat')}</span>
            </div>
            <div 
                ref={chatsContainerRef}
                onScroll={onChatsScroll} 
                className={`chats-list__body ${(props.isChatVisible ? "splittered" : "")}`}>
                {GetChatsTable()}
                {props.isReceivingChats && <Loader/>}
            </div>
        </div>
    )
}



const getOperatorName = (message: IMessage, myId : number): string | null => {
    if(message && message.operatorName) {
        if(message.messageType === MessageType.ClientMessage)
            return null;
        if(message.operatorId === myId) {
            return "Вы";
        }
        return message.operatorName;
    }
    return null;
}

const mapStateToProps = (state: IApplicationState, externalProps: any) => {
    return {
        channels: channelsListSelector(state),
        chats: chatsSelector(state),
        isAllChatsLoaded: isAllChatsLoadedSelector(state),
        isReceivingChats: isReceivingChatsSelector(state),
        countOfChats: countOfChatsSelector(state),
        searchString: searchStringForChatsListSelector(state),
        matchParams: createMatchSelector('/chats/:filter')(state)?.params as IChatsListRouteParams,
        operatorId: operatorIdSelector(state),
        SLColorSecondsSettigs: SLColorSecondsSettigsSelector(state),
        isChatVisible: isChatVisibleSelector(state),
        selectedChatId: state.chatItemsState.selectedChatId,
        hubConnection: state.chatItemsState.hubConnection,
        getChatsMode: state.chatsState.chatsList.getChatsMode,
        isModeChanging: isModeChangingSelector(state),
        operators: allOperatorsSelector(state),
        contacts: contactsSelector(state),
        tags: tagsSelector(state),
        selectedChat: selectedChatSelector(state),
        setChatVisibility: externalProps.setChatVisibility,
        permissionList: state.userContextState.permissions.permissionList,
        autoUpdateChats: autoUpdateChatsSelector(state),
    };
}

const mapDispatchToProps = (dispatch: any): IChatsListProps => {
    return {
        getAllChannels: () => dispatch(getAllChannels()),
        getChats: (
            skip: number, 
            count: number, 
            searchString: string,
            tags: ISearchingTag[],
            getChatsMode: GetChatsMode
        ) => dispatch(getChats(skip, count, searchString, tags, getChatsMode)),
        setSearchString: (
            searchString: string,
        ) => dispatch(setSearchStringToChatsList(searchString)),
        onChatItemClick: (chatId: number) => dispatch( onChatItemClick(chatId)),
        setChatVisibilityToState: (value: boolean) => dispatch(setChatVisibilityToState(value)),
        updateChats: (chats: IChat[]) => dispatch(updateChats(chats)),
        onGetChatsModeChanged: (mode: GetChatsMode) => dispatch(onGetChatsModeChanged(mode)),
        changeChatContactChatname: (
            contact: IContact, 
            chatId: number
        ) => dispatch(changeChatContactChatName(contact, chatId)),
        addNewChatToState: (chat: IChat) => dispatch(addNewChatToState(chat)),
        getAllOperators: () => dispatch(getAllOperators()),
        getContacts: (
            skip: number, 
            count: number, 
            searchString: string, 
            isSortingByContactLastName: boolean
        ) => dispatch(getContacts(skip, count, searchString, isSortingByContactLastName, true)),
        searchChats: (
            tags: ISearchingTag[]
        ) => dispatch(searchChats(tags)),
        selectChat: (
            isNext: boolean,
            chats: IChat[],
            selectedChat: IChatItem | undefined,
        ) => dispatch(selectChat(isNext, chats, selectedChat)),
        toggleAutoUpdateChats: () => dispatch(toggleAutoUpdateChats()),
        getSLColorSecondsSettigs: () => dispatch(getSLColorSecondsSettigs())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatsList);