import React, { useState, useEffect } from 'react';
import {Button} from 'reactstrap';
import SearchingInput from 'src/app/shared/components/searching-input/SearchingInput';
import TransferChatOperatorItem from './transfer-chat-operator-item/TransferChatOperatorItem';
import Loader from 'src/app/shared/components/loader/Loader';
import { ITransferChatProps } from './~types/TransferChatProps';
import { IChannelOperator } from 'src/app/channel/~store/models/ChannelOperator';
import { OperatorStatusType } from 'src/app/chats-panel/~store/state/ChatItemsState';

import './TransferChat.css';
import { OperatorPriorityType } from 'src/app/chats-panel/~store/models/ChannelItem';
import { IOperatorItem } from 'src/app/chats-panel/~store/models/OperatorItem';
import { useTranslation } from 'react-i18next';

const TransferChat = (props: ITransferChatProps) => {
    const [transferChatFilter, setTransferChatFilter] = useState('');
    const [transferChatSelectedOperatorId, setTransferChatSelectedOperatorId] = useState(0);
    const [channelOperators, setChannelOperators] = useState([] as IChannelOperator[]);
    const [transferChatMode, setChatTransferChatMode] = useState<"OPERATOR" | "GROUP" | "BOT">("OPERATOR");
    const [selectedTier, setSelectedTier] = useState<OperatorPriorityType>();
    const [selectedBot, setSelectedBot] = useState<IOperatorItem>();
    const { t } = useTranslation();

    useEffect(() => {
        props.getOnlineOperators();
        props.getAvailableTiers();
        props.getAvailableBotsTransferTo();
    }, []);

    useEffect(() => {
        if(props.onlineOperators.length > 0)
            props.getChannelOperators(props.onlineOperators.map(val => val.operatorId), props.channelId)
                .then((val) => setChannelOperators(val ?? []))
    }, [props.onlineOperators]);

    const _mapOnlineOperators = () => {
        return ( 
            props.onlineOperators.map((op, index) => {
            if((transferChatFilter.length === 0 || op.name.includes(transferChatFilter))) {
                const ind = props.operatorsStatusList.findIndex(o => o.operatorId === op.operatorId);
                return (
                    <TransferChatOperatorItem
                        key={index}
                        operatorStatus={ind > -1 ? props.operatorsStatusList[ind].status : OperatorStatusType.Offline}
                        operator={op}
                        tier={channelOperators.find(val => val.operatorId === op.operatorId)?.priorityType}
                        selected={op.operatorId === transferChatSelectedOperatorId ? true : false}
                        onSelectOperator={setTransferChatSelectedOperatorId}
                        pauseCategories={props.pauseCategories}/>
                )
            }
        }))
    }

    const _mapOperatorTab = () => {
        props.onlineOperators.sort((a, b) => {
            const tierA = channelOperators.find(val => val.operatorId === a.operatorId)?.priorityType ?? 6;
            const tierB = channelOperators.find(val => val.operatorId === b.operatorId)?.priorityType ?? 6;
            const nameA = a.name.toLocaleUpperCase();
            const nameB = b.name.toLocaleUpperCase();
            const tierDifference = tierA - tierB;
            return tierDifference === 0 ? nameA > nameB ? 1 : -1 : tierDifference;
        })
        return (
            <>
                <SearchingInput
                    placeholder={ t('common.search')}
                    value={transferChatFilter}
                    onSearch={(searchString) => {
                        setTransferChatFilter(searchString);
                        setTransferChatSelectedOperatorId(0);
                    }}
                    className="transfer-invite-dialog__search"/>
                
                <div className="transfer-invite-dialog__body">
                    {(props.isReceivingOnlineOperators) && <Loader/>}
                    {!props.isReceivingOnlineOperators && _mapOnlineOperators()}
                </div>
            </>
        )
    }

    const _mapBotTab = () => {
        return ( 
            <>
                {props.availableBotsTransferTo.map((item) => {
                    return (
                        <div>
                            <div className="transfer-tier-row" onClick={() => setSelectedBot(item)}>
                                {item.name} { selectedBot === item &&
                                <i className={"material-icons transfer-tier-selected-row"}>done</i>}
                            </div>
                            
                        </div>
                    )
                })}
            </>
        )
    }

    const _mapTiers = () => {
        return ( 
            <>
                {props.availableTiers.map((item) => {
                    return (
                        <div>
                            <div className="transfer-tier-row" onClick={() => setSelectedTier(item)}>
                                {t('common.tier') + ' ' + item} {selectedTier === item && 
                                <i className={"material-icons transfer-tier-selected-row"}>done</i>}
                            </div>
                            
                        </div>
                    )
                })}
            </>
        )
    }

    const _mapTabBody = () => {
        switch(transferChatMode) {
            case "OPERATOR": {
                return _mapOperatorTab()
            }
            case "BOT": {
                return _mapBotTab()
            }
            case "GROUP": {
                return _mapTiers();
            }
        }
    }

    const _onTransferButtonClick = () => {
        switch(transferChatMode) {
            case "OPERATOR": {
                if(transferChatSelectedOperatorId != 0) {
                    props.onTransferChat(transferChatSelectedOperatorId);
                }
            }
            case "BOT": {
                if(selectedBot) {
                    props.onTransferChat(selectedBot.operatorId);
                }
            }
            case "GROUP": {
                if(selectedTier) {
                    props.onTransferChatToTier(selectedTier);
                }
            }
        }
    }
    
    return (
        <>
            <div className="transfer-operator-tab-container">
                <div className={"transfer-operator_tab " + (transferChatMode === "OPERATOR" && "selected")} onClick={() => setChatTransferChatMode("OPERATOR")}>{t('chat.transferToOperator')}</div>
                <div className={"transfer-operator_tab " + (transferChatMode === "BOT" && "selected")} onClick={() => setChatTransferChatMode("BOT")}>{t('chat.transferToBot')}</div>
                <div className={"transfer-operator_tab " + (transferChatMode === "GROUP" && "selected")} onClick={() => setChatTransferChatMode("GROUP")}>{t('chat.transferToGroup')}</div>
            </div>
            {_mapTabBody()}
            <div className="transfer-invite-dialog__footer">
                <hr/>
                <Button 
                    className="transfer-invite-dialog__action-btn"
                    onClick={() => {
                        props.onCloseDialog();
                        _onTransferButtonClick();
                    }}> 
                    { t('chat.transfer')}
                </Button>
            </div>
        </>
    )
    
}

export default TransferChat;