import React from 'react';
import IUser from 'src/app/authentication/~store/models/User';
import {LicenseExpiresDialogProps} from './~types/LicenseExpiresDialogProps';
import { useTranslation } from 'react-i18next';
import './LicenseExpiresDialog.css';

const LicenseExpiresDialog = (props: LicenseExpiresDialogProps) => {

    const { t } = useTranslation();

    const _onClickHideDialog = () => {        
        props.setIsLicenseDaysLeftVisible(false);
        sessionStorage.setItem('expiresDialogIsClosed', 'true');
    };


    try {
        const expiresDialogIsClosed: boolean = JSON.parse(sessionStorage.getItem('expiresDialogIsClosed') ?? "");
        const user: IUser = JSON.parse(sessionStorage.getItem('user') ?? "");
        if(!expiresDialogIsClosed && user.licenseDaysLeft <= 30) {                        
            return (
                <div className='license-expires-dialog-container' onClick={() => _onClickHideDialog()}>
                    <div className='license-expires-dialog-container__text'>
                        {t('license.licenseExpiresPart', {days: user.licenseDaysLeft})}
                    </div>
                    <div className="close-dialog-button">
                        <img onClick={() => _onClickHideDialog()}></img>
                    </div>
                </div>
            );
        }
        else {
            return(
                <></>
            );
        }
    }
    catch {
        return(
            <></>
        );
    }
}

export default LicenseExpiresDialog;