import React, { useEffect, useState, useRef } from "react";
import { Input, Button } from "reactstrap";
import { ISearchingInputProps } from "./~types/SearchingInputProps";

import './SearchingInput.css';

const SearchingInput = (props: ISearchingInputProps) => {
    const [searchString, setSearchString] = useState(props.value);
    const [isSearching, setIsSearching] = useState(false);
  
    useEffect(() => {
        if(searchString !== "") {
            setIsSearching(true);
        }
    }, []);

    const handleFocus = () => {
        document.getElementById("searching-input-container__input-id")?.focus();
    }

    return (
        <div 
            className={props.className + " searching-input-container"}>
            <Input 
                className="searching-input-container__input"
                id="searching-input-container__input-id"
                type="text" 
                multiple={false}
                value={searchString}
                placeholder={props.placeholder}
                onChange={(event) => setSearchString(event.target.value)}
                onKeyDown={event =>
                    {
                        if(event.key === "Enter") {
                            props.onSearch?.(searchString);
                            setIsSearching(true);
                        }
                }} />
            {isSearching ?
                <Button
                className="searching-input-container__btn-search"
                onClick={!isSearching ? () => {
                    props.onSearch?.(searchString);
                    setIsSearching(true);
                } : () => {
                    props.onSearch?.("");
                    setSearchString("");
                    setIsSearching(false);
                }}>
                {isSearching && searchString ? <i className="material-icons">clear</i> : null}
                </Button>
                :
                null}
        </div>
    )
}

export default SearchingInput;
