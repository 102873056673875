export default function getScriptForImport(
    token: string,
    url: string,
): string {
    const script: string = 
    '<script>\n' +
    '   (function (w, d, s, o, f, js, fjs) {\n' +
        '       w["Chat-Widget"] = o; w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };\n' +
        '       js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];\n' +
        '       js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);\n' +
    '       }(window, document, "script", "mw", "' + url + '/chat/widgetloader/widget-loader.js"));\n\n' +
        '   mw("' + url + '/chat/widget/?token=' + token + '");\n' +
    '</script>';
    return script;
}