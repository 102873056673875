import { PermissionType } from "../models/PermissionType";

export interface IUserContextState {
    permissions: {
        isUpdatingPermissions: boolean,
        isUpdated: boolean,
        permissionList: PermissionType[]
    }
}

export const initUserContext: IUserContextState =  { 
    permissions: {
        isUpdatingPermissions: false,
        isUpdated: false,
        permissionList: []
    }
}