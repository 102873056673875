import { IChannelOperator } from "src/app/channel/~store/models/ChannelOperator";
import { IOperatorItem } from "src/app/chats-panel/~store/models/OperatorItem";
import { IInviteOperatorProps } from "../../invite-operator/~types/InviteOperatorsProps";
import { ITransferChatProps } from "../../transfer-chat/~types/TransferChatProps";

export enum ActionType {
    Transfer,
    Invite,
}

export interface ITransferInviteDialogProps {
    actionType: ActionType;
};

export type TransferInviteDialogProps = 
    ITransferChatProps &
    IInviteOperatorProps &
    ITransferInviteDialogProps;
