import * as React from 'react';
import OperatorsList from './operators-list/OperatorsList';

import './OperatorsMain.css';
import i18n from "src/app/shared/localization/i18n";

export default class OperatorsMain extends React.Component {

    public render() {
        return (
            <div className={"operators"}>
                <div className={"operators__header"}>{i18n.t('common.operators')}</div>
                <OperatorsList />
            </div>
        );
    }
}