import React, { useState } from "react";
import ChatsList from './chats-list/ChatsList';

import "./ChatsMain.css";
import { useTranslation } from 'react-i18next';

const ChatsMain = (props: any) => {
    const { t } = useTranslation();
    return(
        <div className="chats-main">
            <div className="chats-main__header">{t('common.chats')}</div>
            <ChatsList setChatVisibility={props.setChatVisibility}/>
        </div>
    )
}

export default ChatsMain;