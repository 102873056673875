export enum OperatorType {
    Operator = 1,
    Bot = 2,
    Supervisor = 3,
}

export const operatorTypeToString = (operatorType: OperatorType): string => {
    switch(operatorType) {
        case OperatorType.Operator:
            return "Operator";
        case OperatorType.Bot:
            return "Bot";
        case OperatorType.Supervisor:
            return "Supervisor";
    }
}