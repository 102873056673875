import * as React from "react";

import "./AnalyticsMain.css";
import AnalyticsTabs from "./analytics-tabs/AnalyticsTabs";
import { useTranslation } from 'react-i18next';

const AnalyticsMain = () => {
    const { t } = useTranslation();
    return (
        <div className="analytics-main">
            <div className="analytics-main__header">{t('common.analytics')}</div>
            <AnalyticsTabs />
        </div>
    );
}

export default AnalyticsMain;