import React, { useEffect, useState } from "react";
import { ModalWindow } from "src/app/shared/components/modal-window/ModalWindow";
import { IChannelOperatorListConfigurationProps } from "./~types/ChannelOperatorListConfigurationProps";
import { Button, Table, Input, Label } from "reactstrap";
import { IOperatorShort } from "src/app/channel/~store/models/OperatorShort";
import { IChannelOperator } from "src/app/channel/~store/models/ChannelOperator";
import { ChannelOperatorPriority } from "src/app/channel/~store/models/enums/ChannelOperatorPriority";

import "./ChannelOperatorListConfiguration.css";
import { GET_AVATAR_BY_ID_API_ROUTE } from "src/app/operator/~api/apiRoutes";
import { useTranslation } from "react-i18next";
import { Avatar } from '@mui/material';
import OperatorIcon from '@mui/icons-material/SupportAgent'

const ChannelOperatorListConfiguration = (
  props: IChannelOperatorListConfigurationProps
) => {
  const { t } = useTranslation();
  const [channelOperators, setChannelOperators] = useState([
    ...props.channelOperators,
  ]);

  let channelOperatorsIds = props.channelOperators.map(
    (chOp) => chOp.operatorId
  );
  const [operators, setOperators] = useState(
    props.allOperatorsShort.sort((op1, op2) => {
      if (
        channelOperatorsIds.includes(op1.operatorId) &&
        !channelOperatorsIds.includes(op2.operatorId)
      ) {
        return -1;
      }
      if (
        !channelOperatorsIds.includes(op1.operatorId) &&
        channelOperatorsIds.includes(op2.operatorId)
      ) {
        return 1;
      }
      if (op1.name > op2.name) {
        return 1;
      }
      if (op1.name < op2.name) {
        return -1;
      }
      return 0;
    })
  );

  const _getHeader = (): React.ReactElement<{}> => {
    return (
      <div className="channel-operators-config__header">
        <div className="channel-operators-config__header-title">
          {t("channels.operatorsForChannel")}
        </div>
        <Button onClick={_onCloseChannelOperatorsConfigHandler}>
          <i className="material-icons">close</i>
        </Button>
      </div>
    );
  };

  const _getBody = (): React.ReactElement<{}> => {
    return (
      <div className="channel-operators-config__operators">
        <Table>
          <thead>
            <tr className="channel-operators-config__operator-line">
              <th className="channel-operators-config__operator-avatar-cell" />
              <th className="channel-operators-config__operator-name-cell">
                {t("channels.operator")}
              </th>
              <th className="channel-operators-config__operator-enable-cell" />
              <th className="channel-operators-config__operator-priority-cell">
                {t("common.tier")}
              </th>
            </tr>
          </thead>
          <tbody className="channel-operators-config__table-rows">
            {_mapOperatorsToElements()}
          </tbody>
        </Table>
      </div>
    );
  };

  const _getFooter = (): React.ReactElement<{}> => {
    return (
      <Button onClick={_onSaveChannelOperatorsHandler} className="save-button">
        {t("common.save")}
      </Button>
    );
  };

  const _mapOperatorsToElements = (): React.ReactElement<{}> => {
    return (
      <React.Fragment>
        {operators.map((operator, index) => {
          const userInStorage = sessionStorage.getItem("user");
          const customerId = userInStorage === null ? "" : JSON.parse(userInStorage.toString()).customerId;
          const imageUrl: string =
            operator.avatarId === null
              ? ""
              : GET_AVATAR_BY_ID_API_ROUTE +
                operator.avatarId + 
                "&customerId=" + 
                customerId +
                "&cahceBypass=" +
                Math.random();

          const channelOperator = channelOperators.find(
            (channelOp) => channelOp.operatorId === operator.operatorId
          );
          return (
            <tr className="channel-operators-config__operator-line" key={index}>
              <td className="channel-operators-config__operator-avatar-cell">
                <Avatar
                  className="channel-operators-config__operator-avatar"
                  src={imageUrl}
                >
                  <OperatorIcon style={{ color: "#26a69a" }} />
                </Avatar>
                {/* <img
                  className="channel-operators-config__operator-avatar"
                  src={imageUrl}
                  width={"50px"}
                  height={"50px"}
                /> */}
              </td>
              <td className="channel-operators-config__operator-name-cell">
                <div className="channel-operators-config__operator-name">
                  {operator.name}
                </div>
              </td>
              <td className="channel-operators-config__operator-enable-cell">
                <Label check={channelOperator !== undefined}>
                  <Input
                    type="checkbox"
                    checked={channelOperator !== undefined}
                    onChange={(e) => _onOperatorEnableChange(e, operator)}
                  />
                  <span className="channel-operators-config__operator-enable-checkbox">
                    {""}
                  </span>
                </Label>
              </td>
              <td className="channel-operators-config__operator-priority-cell">
                <Input
                  className="channel-operators-config__operator-priority"
                  type="select"
                  disabled={channelOperator === undefined}
                  value={
                    channelOperator === undefined
                      ? ChannelOperatorPriority.Tier1
                      : channelOperator.priorityType
                  }
                  onChange={(e) =>
                    _onChannelOperatorPriorityChangeHandler(e, channelOperator)
                  }
                >
                  <option value={ChannelOperatorPriority.Tier1}>
                    {t("common.tier") + " 1"}
                  </option>
                  <option value={ChannelOperatorPriority.Tier2}>
                    {t("common.tier") + " 2"}
                  </option>
                  <option value={ChannelOperatorPriority.Tier3}>
                    {t("common.tier") + " 3"}
                  </option>
                  <option value={ChannelOperatorPriority.Tier4}>
                    {t("common.tier") + " 4"}
                  </option>
                  <option value={ChannelOperatorPriority.Tier5}>
                    {t("common.tier") + " 5"}
                  </option>
                  <option value={ChannelOperatorPriority.Feedback}>
                    {t("channels.feedback")}
                  </option>
                  <option value={ChannelOperatorPriority.TierVIP}>
                    {t("channels.vip")}
                  </option>
                  <option value={ChannelOperatorPriority.Spam}>
                    {t("channels.spam")}
                  </option>
                </Input>
              </td>
            </tr>
          );
        })}
      </React.Fragment>
    );
  };

  const _onOperatorEnableChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    operator: IOperatorShort
  ) => {
    if (event.currentTarget.checked) {
      channelOperators.push({
        operatorId: operator.operatorId,
        priorityType: 1,
        channelId: props.channelId,
      });
    } else {
      channelOperators.forEach((channelOperator, index) => {
        if (channelOperator.operatorId === operator.operatorId) {
          channelOperators.splice(index, 1);
        }
      });
    }
    setChannelOperators([...channelOperators]);
  };

  const _onChannelOperatorPriorityChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    channelOperator: IChannelOperator | undefined
  ) => {
    const channelPriority: ChannelOperatorPriority = +e.currentTarget.value;
    channelOperators.forEach((channelOp, index) => {
      if (channelOp.operatorId === channelOperator?.operatorId) {
        channelOperators[index].priorityType = channelPriority;
      }
    });

    setChannelOperators([...channelOperators]);
  };

  const _onCloseChannelOperatorsConfigHandler = (): void => {
    props.onCloseChannelOperatorsConfig?.();
  };

  const _onSaveChannelOperatorsHandler = (): void => {
    props.onSaveChannelOperators?.(channelOperators);
    props.onCloseChannelOperatorsConfig?.();
  };
  return (
    <ModalWindow
      isOpen={props.isChannelOperatorsConfigOpen}
      header={_getHeader()}
      body={_getBody()}
      footer={_getFooter()}
      className="channel-operators-config"
    />
  );
};

export default ChannelOperatorListConfiguration;
