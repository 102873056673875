import {ChannelType} from "./../../../channel/~store/models/enums/ChannelType"

export const GetChannelImage = (type: ChannelType): string => {
    switch(type) {
        case ChannelType.Telegram: return(require("src/app/shared/images/Chat/telegram.svg"));
        case ChannelType.Viber: return(require("src/app/shared/images/Chat/viber.svg"));
        case ChannelType.Vk: return(require("src/app/shared/images/Chat/vk.svg"));
        case ChannelType.Widget: return (require("src/app/shared/images/Chat/widget.svg"));
        case ChannelType.Facebook: return (require("src/app/shared/images/Chat/facebook.svg"));
        case ChannelType.WeChat: return (require("src/app/shared/images/Chat/wechat.svg"));
        case ChannelType.Instagram: return (require("src/app/shared/images/Chat/instagram.svg"));
        case ChannelType.UniformWidget: return (require("src/app/shared/images/Chat/uniformwidget.svg"));
        case ChannelType.EdnaWhatsApp: return (require("src/app/shared/images/Chat/whatsapp-logo.svg"));
        case ChannelType.WhatsApp: return (require("src/app/shared/images/Chat/whatsapp-logo.svg"));
        case ChannelType.VoiceChannel: return (require("src/app/shared/images/Chat/voice-channel-logo.svg"));
    }
}