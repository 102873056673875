import {AppThunkAction} from "../../../../../src/~store/models/AppThunkAction";

import { FILE_MAX_SIZE, FILE_MAX_SIZE_MESSAGE } from "./../../../../~store/constants";

import {
    GetOpenedOperatorChatsAction,
    AssignmentChatsToOperatorAction,
    ChangeOperatorStatusAction,
    StopSignalRAction,
    StartSignalRAction,
    GetChatMessagesAction,
    UpdateIncomingChatInStateAction as UpdateIncomingChatInStateAction,
    AddNewMessageToStateAction,
    UpdatePreviewMessageToStateAction,
    ChangeOperatorChatTextAction,
    ChangeOperatorMessageContentTypeAction,
    GetOperatorInfoAction,
    GetChannelInfoAction,
    GetContactInfoAction,
    GetOperatorChannelsAction,
    IncreaseChatUnreadMessagesCountAction,
    OnReadMessagesAction,
    GetOnlineOperatorsAction,
    RemoveChatByChatIdAction,
    GetOnlineOperatorsToInviteAction,
    SetMessageToEditAction,
    ToggleMessageEditingAction,
    EditMessageAction,
    SetChatVisibilityAction,
    AddChatsHistoryAction,
    JoinChatAction,
    RemoveOperatorFromChatAction,
    clearQuickAnswersAction,
    getQuickAnswersAction,
    removeQuickAnswerAction,
    ExportChatToZendesk,
    TransferChatAction,
    UpdateMessageInStateAction,
    updateChatsInChatPanelAction,
    OperatorSendFileMessageAction,
    GetOperatorStatusListAction,
    UpdateOperatorStatusInStateAction,
    ChangeReconnectingStatusAction,
    GetAvailableTiersAction,
    CloseChatAction,
    GetAvailableBotsTransferToAction,
    GetAvailableContactTagsAction,
    UpdateContactTagsAction,
    GetGlobalOperatorSettingsAction,
    GetCloseChatCategoriesSettingsAction,
    GetTopicPairSubjectsByTopicNameAction,
    UpdateIsContactOnline,
    UpdateSpamStatusAction,
    UpdateSendingMessageAction,
} from "./interfaces";

import {
    GET_OPENED_OPERATOR_CHATS_START,
    GET_OPENED_OPERATOR_CHATS_SUCCESS,
    GET_OPENED_OPERATOR_CHATS_ERROR,
    ASSIGNMENT_CHATS_TO_OPERATOR_START,
    ASSIGNMENT_CHATS_TO_OPERATOR_SUCCESS,
    ASSIGNMENT_CHATS_TO_OPERATOR_ERROR,
    CHANGE_OPERTOR_STATUS_SUCCESS,
    CHANGE_RECONNECTING_STATUS_SUCCESS,
    START_SIGNALR_SUCCESS,
    STOP_SIGNALR_SUCCESS,
    UPDATE_INCOMING_CHAT_IN_STATE_SUCCESS,
    GET_CHAT_MESSEGES_START,
    GET_CHAT_MESSEGES_SUCCESS,
    GET_CHAT_MESSEGES_ERROR,
    UPDATE_MESSAGE_PREVIEW_IN_STATE_SUCCESS,
    ADD_NEW_MESSAGE_TO_STATE_SUCCESS,
    CHANGE_OPERATOR_CHAT_TEXT_SUCCESS,
    CHANGE_OPERATOR_MESSAGE_CONTENT_TYPE,
    GET_OPERATOR_INFO_SUCCESS,
    GET_OPERATOR_INFO_ERROR,
    GET_OPERATOR_INFO_START,
    GET_CHANNEL_INFO_START,
    GET_CHANNEL_INFO_SUCCESS,
    GET_CHANNEL_INFO_ERROR,
    GET_CONTACT_INFO_START,
    GET_CONTACT_INFO_SUCCESS,
    GET_CONTACT_INFO_ERROR,
    GET_OPERATOR_CHANNELS_START,
    GET_OPERATOR_CHANNELS_SUCCESS,
    GET_OPERATOR_CHANNELS_ERROR,
    INCREASE_CHAT_UNREAD_MESSAGES_COUNT,
    ON_READ_MESSAGES,
    GET_ONLINE_OPERATORS_START,
    GET_ONLINE_OPERATORS_SUCCESS,
    GET_ONLINE_OPERATORS_ERROR,
    REMOVE_CHAT_BY_CHAT_ID,
    GET_ONLINE_OPERATORS_TO_INVITE_START,
    GET_ONLINE_OPERATORS_TO_INVITE_SUCCESS,
    GET_ONLINE_OPERATORS_TO_INVITE_ERROR,
    SET_MESSAGE_TO_EDIT,
    EDIT_MESSAGE_START,
    EDIT_MESSAGE_ERROR,
    SET_CHAT_VISIBILITY,
    ADD_CHAT_HISTORY_START,
    ADD_CHAT_HISTORY_ERROR,
    ADD_CHAT_HISTORY_SUCCESS,
    JOIN_CHAT_SUCCESS,
    REMOVE_OPERATOR_FROM_CHAT_SUCCESS,
    REMOVE_OPERATOR_FROM_CHAT_START,
    JOIN_CHAT_START,
    CLEAR_QUICK_ANSWERS_SUCCESS,
    GET_QUICK_ANSWERS_SUCCESS,
    GET_QUICK_ANSWERS_START,
    GET_QUICK_ANSWERS_ERROR,
    REMOVE_QUICK_ANSWER_SUCCESS,
    EXPORT_CHAT_TO_ZENDESK_SUCCESS,
    TRANSFER_CHAT_START,
    TRANSFER_CHAT_SUCCESS,
    UPDATE_MESSAGE_IN_STATE_SUCCESS,
    UPDATE_CHATS_IN_RIGHT_PANEL_SUCCESS,
    OPERATOR_SEND_FILE_MESSAGE_START,
    OPERATOR_SEND_FILE_MESSAGE_FINISH,
    GET_OPERATOR_STATUS_LIST_SUCCESS,
    UPDATE_OPERATOR_STATUS_IN_STATE_SUCCESS,
    GET_AVAILABLE_TIERS_START,
    GET_AVAILABLE_TIERS_SUCCESS,
    GET_AVAILABLE_TIERS_ERROR,
    CLOSE_CHAT_START,
    CLOSE_CHAT_SUCCESS,
    CLOSE_CHAT_ERROR,
    GET_AVAILABLE_CONTACT_TAGS_SUCCESS,
    UPDATE_CONTACT_TAGS_START,
    UPDATE_CONTACT_TAGS_ERROR,
    UPDATE_CONTACT_TAGS_SUCCESS,
    GET_AVAILABLE_BOTS_TRANSFER_TO_START,
    GET_AVAILABLE_BOTS_TRANSFER_TO_ERROR,
    GET_AVAILABLE_BOTS_TRANSFER_TO_SUCCESS,
    GET_PAUSE_CATEGORIES_SUCCESS,
    GET_OPERATOR_DISCONNECTION_TIMEOUT_SUCCESS,
    GET_CHAT_CLOSE_CATEGORIES_SUCCESS,
    GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_SUCCESS,
    UPDATE_IS_CONTACT_ONLINE_SUCCESS,
    TOGGLE_SPAM_CHAT_SUCCESS,
    GET_SHOW_HINTS_FROM_ALL_OPERATORS_SUCCESS,
    UPDATE_SENDING_MESSAGE_SUCCESS,
    GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_START,
    GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_ERROR
} from "./~types";

import {
    getChatsAsync, 
    assignmentChatsToOperatorAsync, 
    getChatMessagesAsync,   
    getOperatorInfoAsync, 
    getChannelInfoAsync, 
    closeChatAsync, 
    getContactInfoAsync, 
    getOperatorChannelsAsync,  
    getOnlineOperatorsToTransferAsync,
    transferChatAsync,
    sendChatMessageAsync,
    removeOperatorFromChatAsync,
    getOnlineOperatorsToInviteAsync,
    inviteOperatorsToChatAsync,
    onOperatorSendFileMessageAsync,
    editMessageAsync,
    OpenChatAsync,
    addChatHistoryAsync,
    joinChatAsync,
    getQuickAnswersAsync,
    removeQuickAnswerAsync,
    exportChatToZendeskAsync,
    getChannelOperatorsAsync,
    getOpenedContactChatsAsync,
    getOperatorStatusListAsync,
    getAvailableTiersAsync,
    transferChatToTierAsync,
    getAvailableBotsTransferToAsync,
    getAvailableContactTagsAsync,
    addContactTagsAsync,
    deleteContactTagsAsync,
    getSettingsByIdAsync,
    pauseChatAsync,
    unpauseChatAsync,
    getIsContactOnlineAsync,
    markChatAsSpamAsync,
    isChatSpamAsync,
} from "../../~api/actions";
import { IChatItem } from "../models/ChatItem";
import { IMessageItem, MessageType } from "../models/MessageItem";
import { IOperatorStatusViewModel, OperatorStatusType } from "../state/ChatItemsState";
import { IOperatorItem } from "../models/OperatorItem";
import { FileType } from "src/app/shared/components/choose-file-dialog/ChooseFileDialog";
import { EDIT_MESSAGE_SUCCESS, TOGGLE_MESSAGE_EDITING } from "src/app/chats/~store/actions/types";
import { showSuccessToastr, showErrorToastr, showInfoToastr, showWarningToastr } from "src/app/shared/helpers/toastr-helper/ToastrHelper";
import { selectedChatSelector } from "../selectors";
import { IChat } from "src/app/chats/~store/models/Chat";
import { OperatorPriorityType } from "../models/ChannelItem";
import { PermissionType } from "src/app/shared/components/user-context/models/PermissionType";
import { MAX_OPERATOR_DISCONECTION_TIMEOUT, MAX_PAUSE_CATEGORIES } from "./ChatConstants";
import { historyWithForceUpdate } from "src/~store/history";
import { clearNotifyHistory, notifyConnectionLostError, notifyNewMessage } from "../../../shared/helpers/natification-helper/NotificationHelper";
import { newChatWithMessengerAsync } from "src/app/contact/~api/actions";
import { IMessagePreview } from "../models/MessagePreview";
import { HubConnectionState } from "@microsoft/signalr";
import { DELETED_MESSAGE_MESSAGE } from "../../../chat/message-editor/message-editor-constants/MessageEditorConstants";
import i18n from "src/app/shared/localization/i18n";
import { getTopicPairCategoriesByTopicNameAsync } from "src/app/nlp/~api/actions";
import { TopicPairContentType } from "src/app/nlp/~store/models/enums/TopicPairContentType";
import { PAUSE_CATEGORIES } from "src/app/chat/pause-chat-dialog/~types/PauseChatProps";
import { ChannelType } from "src/app/channel/~store/models/enums/ChannelType";
import moment from "moment";
import { getChatByIdAsync, getLightweightChatByIdAsync as getSelectedChatByIdAsync } from "src/app/chats/~api/actions";

export const changeReconnectingStatus = (status: boolean): AppThunkAction<ChangeReconnectingStatusAction> => async dispatch => {
    await dispatch({ type: CHANGE_RECONNECTING_STATUS_SUCCESS, status});
};

export const getChats = (): AppThunkAction<GetOpenedOperatorChatsAction> => async dispatch => {
    dispatch({type: GET_OPENED_OPERATOR_CHATS_START});

    const result = await getChatsAsync();
    if (result.isSuccess) {
        const chats = result.value ?? [];
        for(const ch of chats) {
            ch.fromHistory = false;
        }
        dispatch({type: GET_OPENED_OPERATOR_CHATS_SUCCESS, payload: {chats}});
    } else {
        dispatch({type: GET_OPENED_OPERATOR_CHATS_ERROR});
    }
};

export const assignmentChatsToOperator = (callback: CallableFunction): AppThunkAction<AssignmentChatsToOperatorAction> => async dispatch => {
    dispatch({type: ASSIGNMENT_CHATS_TO_OPERATOR_START});

    const result = await assignmentChatsToOperatorAsync();
    if (result.isSuccess) {
        await dispatch({type: ASSIGNMENT_CHATS_TO_OPERATOR_SUCCESS});
        callback();
    } else {
        dispatch({type: ASSIGNMENT_CHATS_TO_OPERATOR_ERROR});
    }
};

export const changeOperatorStatus = (status: OperatorStatusType, callback: CallableFunction, isFromUI: boolean = false): AppThunkAction<ChangeOperatorStatusAction> => async (dispatch, getState) => {
    if(status === OperatorStatusType.Offline) {
        getState().chatItemsState.chatItems.forEach(chat => clearNotifyHistory(chat.chatId))
    }
    
    await dispatch({type: CHANGE_OPERTOR_STATUS_SUCCESS, status, isFromUI});
    callback(status);
};

export const startSignalR = (callback: CallableFunction): AppThunkAction<StartSignalRAction> => async dispatch => {
    dispatch({type: START_SIGNALR_SUCCESS, callback: callback});
};

export const stopSignalR = (): AppThunkAction<StopSignalRAction> => async dispatch => {
    dispatch({type: STOP_SIGNALR_SUCCESS});
};

export const UpdateIncomingChatInState = (chat: IChatItem): AppThunkAction<UpdateIncomingChatInStateAction> => async dispatch => {
    dispatch({type: UPDATE_INCOMING_CHAT_IN_STATE_SUCCESS, chat: chat});
};

export const addNewMessageToState = (message: IMessageItem): AppThunkAction<AddNewMessageToStateAction> => async dispatch => {
    dispatch({type: ADD_NEW_MESSAGE_TO_STATE_SUCCESS, message: message});
};

export const updateMessagePreviewToState = (message: IMessagePreview): AppThunkAction<UpdatePreviewMessageToStateAction> => async dispatch => {
    message.lastUpdate = moment(new Date());
    dispatch({type: UPDATE_MESSAGE_PREVIEW_IN_STATE_SUCCESS, message: message});
}

export const updateChatMessage = (message: IMessageItem): AppThunkAction<UpdateMessageInStateAction> => async dispatch => {
    dispatch({type: UPDATE_MESSAGE_IN_STATE_SUCCESS, message: message});
};

export const loadChatToWidget = (chatId: number): AppThunkAction<GetChatMessagesAction|GetContactInfoAction> => async (dispatch, getstate) => {
    clearNotifyHistory(chatId);

    dispatch({ type: GET_CHAT_MESSEGES_START });

    const result = await getSelectedChatByIdAsync(chatId, true, true);
    if (result.isSuccess && result.value) {
        getChannelInfo(result.value?.channelId ?? 0)(dispatch, getstate); 
        const messages = result.value?.chatMessages ?? [];
        dispatch({type: GET_CHAT_MESSEGES_SUCCESS, payload: {messages, chat: result.value, needToClearHistory: true}});

        const contact = result.value?.messenger.contact;
        const nickName = (contact?.nickName === null) ? "" : contact?.nickName;
        const firstName = (contact?.firstName === null) ? "" : contact?.firstName;
        const lastName = (contact?.lastName === null) ? "" : contact?.lastName;
        const patronymic = (contact?.patronymic === null) ? "" : contact?.patronymic;
        const name = (lastName || firstName || patronymic) ? lastName + " " + firstName + " " + patronymic : nickName ?? "";
        const avatarId = (!contact?.avatarId) ? null : contact?.avatarId;
        const tags = contact?.tags?.map(item => item.value) || [];
        dispatch({type: GET_CONTACT_INFO_SUCCESS, payload: {contactName: name, contactAvatarId: avatarId, tags}});
    } 
}

export const changeOperatorChatText = (newText: string): AppThunkAction<ChangeOperatorChatTextAction> => async dispatch => {
    dispatch({type: CHANGE_OPERATOR_CHAT_TEXT_SUCCESS, text: newText});
};

export const changeOperatorMessageContentType = (contentType: TopicPairContentType): AppThunkAction<ChangeOperatorMessageContentTypeAction> => async dispatch => {
    dispatch({type: CHANGE_OPERATOR_MESSAGE_CONTENT_TYPE, messageContentType: contentType});
}

export const sendChatMessage = (chatId: number, text: string, operatorId: number, contentType: TopicPairContentType) : AppThunkAction<AddNewMessageToStateAction|UpdateSendingMessageAction|GetChatMessagesAction> => async (dispatch, getstate) => {
    const sm = {chatId, text, operatorId, contentType} as IMessageItem;
    //dispatch({type: UPDATE_SENDING_MESSAGE_SUCCESS, payload: {sendingMessage: {message: sm, failed: false}}});
    
    const result = await sendChatMessageAsync(chatId, text, operatorId, contentType);
    const errorText = JSON?.parse(result.errorText ?? "null");
    if(result.isSuccess && result.value) {
        dispatch({type: UPDATE_SENDING_MESSAGE_SUCCESS, payload: {sendingMessage: null}});
        addNewMessageToState(result.value)(dispatch, getstate);
    }
    else {
        //dispatch({type: UPDATE_SENDING_MESSAGE_SUCCESS, payload: {sendingMessage: {message: sm, failed: true}}});
        if (errorText.errorCode === "error.notSendMessageNotChatParticipant")
            showErrorToastr(i18n.t(errorText.errorCode, { chatId: errorText.data.chatId, operatorId: errorText.data.operatorId }));
        else if (errorText.errorCode === "error.botBlockedByUser")
            showErrorToastr(i18n.t(errorText.errorCode, { channelId: errorText.data.channelId, chatId: errorText.data.chatId }));
        else if (errorText.errorCode === "error.sendMessageFailed")
            showErrorToastr(i18n.t(errorText.errorCode, { chatId: errorText.data.chatId, operatorId: errorText.data.operatorId }));
        else
            showErrorToastr(i18n.t('chatsPanel.sendMessageError'));
        loadChatToWidget(getstate().chatItemsState.selectedChatId)(dispatch, getstate);
    }
};

export const onOperatorSendFileMessage = (file: File, type: FileType, chatId: number): AppThunkAction<OperatorSendFileMessageAction> => async dispatch => {
    if (file.size > FILE_MAX_SIZE) {
        showInfoToastr(FILE_MAX_SIZE_MESSAGE);
        return;
    }

    dispatch({ type: OPERATOR_SEND_FILE_MESSAGE_START, payload: { selectedChatId: chatId } });

    const result = await onOperatorSendFileMessageAsync(file, type, chatId);
    if (result.isSuccess === false) {
        showErrorToastr(i18n.t('chatsPanel.sendFileMessageError') + " " + file.name)
    }

    dispatch({ type: OPERATOR_SEND_FILE_MESSAGE_FINISH, payload: { selectedChatId: chatId } });

}

export const getOperatorInfo = (): AppThunkAction<GetOperatorInfoAction> => async dispatch => {
    dispatch({type: GET_OPERATOR_INFO_START});

    const result = await getOperatorInfoAsync();
    if (result.isSuccess) {
        const operator: IOperatorItem = result.value ?? {} as IOperatorItem;
        
        const avatarId = (!result.value?.avatarId) ? null : result.value.avatarId;
        const name = (result.value?.name === undefined) ? "" : result.value?.name;
        const operatorId = operator.operatorId;
        dispatch({type: GET_OPERATOR_INFO_SUCCESS, payload: {avatarId: avatarId, name: name, operatorId}});
    } else {
        dispatch({type: GET_OPERATOR_INFO_ERROR});
    }
};

export const getOperatorChannels = (): AppThunkAction<GetOperatorChannelsAction> => async dispatch => {
    dispatch({type: GET_OPERATOR_CHANNELS_START});

    const result = await getOperatorChannelsAsync();
    if (result.isSuccess) {
        const channels = (result.value === undefined) ? [] : result.value;
        dispatch({type: GET_OPERATOR_CHANNELS_SUCCESS, payload: {channels: channels}});
    } else {
        dispatch({type: GET_OPERATOR_CHANNELS_ERROR});
    }
};

export const getOperatorStatusList = (): AppThunkAction<GetOperatorStatusListAction> => async dispatch => {
    const result = await getOperatorStatusListAsync();
    if(result.isSuccess) {
        const operatorsStatusList = result.value ?? [];

        dispatch({type: GET_OPERATOR_STATUS_LIST_SUCCESS, payload: {operatorsStatusList}});
    }
};

export const getAvailableContactTags = (): AppThunkAction<GetAvailableContactTagsAction> => async dispatch => {
    const result = await getAvailableContactTagsAsync();
    if(result.isSuccess) {
        const tags = result.value ?? [];

        dispatch({type: GET_AVAILABLE_CONTACT_TAGS_SUCCESS, payload: {tags}});
    }
};

export const getGlobalOperatorSettings = (): AppThunkAction<GetGlobalOperatorSettingsAction> => async dispatch => {

    //todo unite operator settings into one setting
    let result = await getSettingsByIdAsync("OperatorSettings");
    if(result.isSuccess && result.value) {
        try {
            const setting = JSON.parse(result?.value?.value);
            const pauseCategories = setting?.PauseCategories;
            const showHintsFromAllOperators: boolean = setting?.ShowHintsFromAllOperators == 'true' ?? false;
    
            if(pauseCategories) {
                dispatch({type: GET_PAUSE_CATEGORIES_SUCCESS, payload: {pauseCategories: pauseCategories?.split(',')?.slice(0, MAX_PAUSE_CATEGORIES)}});
            }
            dispatch({type: GET_SHOW_HINTS_FROM_ALL_OPERATORS_SUCCESS, payload: {showHintsFromAllOperators}});
        } catch (error) {
            console.error(error);
        }
    }

    result = await getSettingsByIdAsync("ChatOptions");
    if(result.isSuccess && result.value) {
        const setting = JSON.parse(result.value.value);
        const operatorDisconnectionTimeout = setting.OperatorDisconnectionTimeout ?? MAX_OPERATOR_DISCONECTION_TIMEOUT;        

        if(operatorDisconnectionTimeout > 0) {
            dispatch({type: GET_OPERATOR_DISCONNECTION_TIMEOUT_SUCCESS, payload: {operatorDisconnectionTimeout: operatorDisconnectionTimeout}});
        }
    }
};


export const getCloseChatCategoriesSettings = () : AppThunkAction<GetCloseChatCategoriesSettingsAction> => async dispatch => {
    
    let result = await getSettingsByIdAsync("ChatOptions");

    if(result.isSuccess && result.value) {
        const setting = JSON.parse(result.value.value);

        const closeChatCategoriesRow : string = setting.CloseChatCategories;

        if(closeChatCategoriesRow) {
            dispatch({type: GET_CHAT_CLOSE_CATEGORIES_SUCCESS, payload: {closeChatCategories: closeChatCategoriesRow.split(',').slice().map(category => category.trim()).filter(c => c !== '')}});
        }
    }
}

export const getTopicPairSubjectsByTopicName = (topicName: string): AppThunkAction<GetTopicPairSubjectsByTopicNameAction> => async dispatch => {
    dispatch({ type: GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_START });
    if(topicName) {
        const result = await getTopicPairCategoriesByTopicNameAsync(topicName);

        if (result.isSuccess && result.value) {
            const { categories } = result.value;

            dispatch({ type: GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_SUCCESS, payload: { closeChatSubjects: categories } })
        }
        else {
            dispatch({ type: GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_ERROR });
        }
    }
    else {
        dispatch({type: GET_TOPIC_PAIR_SUBJECTS_BY_TOPIC_NAME_SUCCESS, payload: {closeChatSubjects: []}})
    }
}

export const updateOperatorStatusInState = (operatorStatus: IOperatorStatusViewModel): AppThunkAction<UpdateOperatorStatusInStateAction> => async dispatch => {
    dispatch({type: UPDATE_OPERATOR_STATUS_IN_STATE_SUCCESS, payload: {operatorStatus}});
};

export const getChannelInfo = (channelId: number): AppThunkAction<GetChannelInfoAction> => async dispatch => {
    dispatch({type: GET_CHANNEL_INFO_START});

    const result = await getChannelInfoAsync(channelId);
    if (result.isSuccess) {
        const setting = result.value;
        dispatch({type: GET_CHANNEL_INFO_SUCCESS, payload: {setting}});
    } else {
        dispatch({type: GET_CHANNEL_INFO_ERROR});
    }
};

export const closeChat = (chatId: number, forcibly: boolean): AppThunkAction<CloseChatAction> => async (dispatch, getState) => {
    const {isChatClosing, selectedChatId} = getState().chatItemsState;
    if(selectedChatId === 0) return;

    if(isChatClosing) {
        showInfoToastr(i18n.t('chats.chatAlreadyClosing'));
        return;
    }
    
    if (forcibly || window.confirm(i18n.t('chats.askYouWannaCloseChat'))) {
        dispatch({type: CLOSE_CHAT_START});
        const result = await closeChatAsync(chatId);

        if(result.isSuccess) {
            dispatch({type: CLOSE_CHAT_SUCCESS});
            showSuccessToastr(i18n.t('chats.chatClosedSuccessfully'));
        }
        else {
            dispatch({type: CLOSE_CHAT_ERROR});
            showErrorToastr(i18n.t('chats.chatClosedErrorMessage'));
        }
    }

    
};

export const removeOperatorFromChat = (operatorIdToRemove: number, chatId: number): AppThunkAction<RemoveOperatorFromChatAction> => async dispatch => {
    dispatch({ type: REMOVE_OPERATOR_FROM_CHAT_START });
    const result = await removeOperatorFromChatAsync(operatorIdToRemove, chatId);
    if (result.isSuccess) {
        dispatch({ type: REMOVE_OPERATOR_FROM_CHAT_SUCCESS, payload: {chatId, operatorIdToRemove} });
    }
};

export const onOpenChat = (chat: IChatItem, operatorId: number, operatorStatus: OperatorStatusType): AppThunkAction<GetChatMessagesAction | GetContactInfoAction> => async (dispatch, getState) => {
    if (operatorStatus === OperatorStatusType.Offline) {
        showErrorToastr(i18n.t('chatsPanel.errorReopenChatDueOfflineStatus'));
        return;
    }
    else if (window.confirm(i18n.t('chats.askYouWannaReopenChat')) === true) {
        const result = await newChatWithMessengerAsync(chat.messenger.messengerId);
        
        if (result.isSuccess) {
            const reopenedChat = await getChatByIdAsync(result.value!.chatId, false, true);
            if (reopenedChat.value) {
                getChannelInfo(result.value?.channelId ?? 0)(dispatch, getState);
                const messages = reopenedChat.value?.chatMessages ?? [];
                dispatch({ type: GET_CHAT_MESSEGES_SUCCESS, payload: { messages, chat: reopenedChat.value, needToClearHistory: true } });
            }
        }

        if (!result.isSuccess) {
            const error = JSON.parse(result.errorText ?? "null");
            switch (error.errorCode) {
                case "chat.contactHasOpenedChatException":
                    showErrorToastr(i18n.t('chat.contactHasOpenedChatException').
                        replace('{0}', error.data.chatId.toString()).
                        replace('{1}', error.data.operatorName || i18n.t('chats.operatorNotAssignedYetMessage')));
                    break;
                case "error.enabledChannelNotFoundException":
                    showErrorToastr(i18n.t('chat.enabledChannelNotFoundException'))
                    break;
                default:
                    showErrorToastr(i18n.t('chats.continueChatErrorMessage'))
            }
        }
    }
};

export const increaseChatUnreadMessagesCount = (chatId: number): AppThunkAction<IncreaseChatUnreadMessagesCountAction> => async dispatch => {
    dispatch({type: INCREASE_CHAT_UNREAD_MESSAGES_COUNT, payload: {chatId}});
};

export const onReadMessages = (messagesId: number[]): AppThunkAction<OnReadMessagesAction> => async dispatch => {
    dispatch({type: ON_READ_MESSAGES, payload: {messagesId}});
};

export const markChatAsSpam = (chatId: number): AppThunkAction<UpdateSpamStatusAction> => async dispatch => {
    await markChatAsSpamAsync(chatId);
    var result = await isChatSpamAsync(chatId);
    dispatch({type: TOGGLE_SPAM_CHAT_SUCCESS, payload: { isSpam: result.value ?? false }})
};

export const isChatSpam = (chatId: number): AppThunkAction<UpdateSpamStatusAction> => async dispatch => {
    var result = await isChatSpamAsync(chatId);
    dispatch({type: TOGGLE_SPAM_CHAT_SUCCESS, payload: { isSpam: result.value ?? false }})
};

export const getOnlineOperatorsToTransfer = (chatId: number): AppThunkAction<GetOnlineOperatorsAction> => async dispatch => {
    dispatch({ type: GET_ONLINE_OPERATORS_START });

    const result = await getOnlineOperatorsToTransferAsync(chatId);
    if(result.isSuccess) {
        const onlineOperators = result.value ?? [];
        dispatch({ type: GET_ONLINE_OPERATORS_SUCCESS, payload: { onlineOperators } })
    } else {
        dispatch({ type: GET_ONLINE_OPERATORS_ERROR });
    }
}

export const getAvailableTiers = (channelId: number): AppThunkAction<GetAvailableTiersAction> => async dispatch => {
    dispatch({ type: GET_AVAILABLE_TIERS_START });

    const result = await getAvailableTiersAsync(channelId);
    if(result.isSuccess) {
        const tiers = result.value ?? [];
        dispatch({ type: GET_AVAILABLE_TIERS_SUCCESS, payload: { tiers } })
    } else {
        dispatch({ type: GET_AVAILABLE_TIERS_ERROR });
    }
}

export const getAvailableBotsTransferTo = (channelId: number): AppThunkAction<GetAvailableBotsTransferToAction> => async dispatch => {
    dispatch({ type: GET_AVAILABLE_BOTS_TRANSFER_TO_START });

    const result = await getAvailableBotsTransferToAsync(channelId);
    if(result.isSuccess) {
        const bots = result.value ?? [];
        dispatch({ type: GET_AVAILABLE_BOTS_TRANSFER_TO_SUCCESS, payload: { bots } })
    } else {
        dispatch({ type: GET_AVAILABLE_BOTS_TRANSFER_TO_ERROR });
    }
}

export const getOnlineOperatorsToInvite = (chatId: number): AppThunkAction<GetOnlineOperatorsToInviteAction> => async dispatch => {
    dispatch({ type: GET_ONLINE_OPERATORS_TO_INVITE_START });

    const result = await getOnlineOperatorsToInviteAsync(chatId);
    if(result.isSuccess) {
        const onlineOperatorsToInvite = result.value ?? [];
        dispatch({ type: GET_ONLINE_OPERATORS_TO_INVITE_SUCCESS, payload: { onlineOperatorsToInvite: onlineOperatorsToInvite } })
    } else {
        dispatch({ type: GET_ONLINE_OPERATORS_TO_INVITE_ERROR });
    }
}

export const transferChat = (redirectedBy: number, redirectedTo: number, chatId: number): AppThunkAction<TransferChatAction> => async (dispatch, getState) => {
    dispatch({ type: TRANSFER_CHAT_START });
    const result = await transferChatAsync(redirectedBy, redirectedTo, chatId);
    if(result.isSuccess) {
        dispatch({ type: TRANSFER_CHAT_SUCCESS });
        if(!getState().userContextState.permissions.permissionList.includes(PermissionType.Chats_ManageOthersChats)) {
            setChatVisibilityToState(false);
        }
    }
}

export const transferChatToTier = async (tier: OperatorPriorityType, chatId: number) => {
    const result = await transferChatToTierAsync(tier, chatId);
}

export const inviteOperatorsToChat = async (operators: IOperatorItem[], chatId: number) => {
    await inviteOperatorsToChatAsync(operators, chatId);
}

export const removeChatByChatId = (chatId: number): AppThunkAction<RemoveChatByChatIdAction> => async dispatch => {
    dispatch({ type: REMOVE_CHAT_BY_CHAT_ID, payload: {chatId} });
}

export const setMessageToEdit = (
    messageToEdit: IMessageItem | undefined
): AppThunkAction<SetMessageToEditAction> => async dispatch => {
    dispatch({type: SET_MESSAGE_TO_EDIT, payload: { messageToEdit }})
}

export const toggleMessageEditing = (): AppThunkAction<ToggleMessageEditingAction> => async dispatch => {
    dispatch({ type: TOGGLE_MESSAGE_EDITING });
}

export const editMessage = ( editedMessage: IMessageItem): AppThunkAction<EditMessageAction> => async (dispatch, getState) => {
    dispatch({ type: EDIT_MESSAGE_START });
    const result = await editMessageAsync(editedMessage);

    if(result.isSuccess) {
        dispatch({ type: EDIT_MESSAGE_SUCCESS });
        showSuccessToastr(editedMessage.text !== DELETED_MESSAGE_MESSAGE ? i18n.t('chats.editMessageSuccessMessage') : i18n.t('chats.deleteMessageSuccessMessage'));
    } else {
        dispatch({ type: EDIT_MESSAGE_ERROR });
        showErrorToastr(i18n.t('chats.editMessageErrorMessage'));
    }
}

export const setChatVisibilityToState = (value: boolean): AppThunkAction<SetChatVisibilityAction> => async dispatch => {
    dispatch({type: SET_CHAT_VISIBILITY, payload: { value }})
}

export const addChatHistory = (chatIdStart: number, chatsIdUp: number, chatsIdDown: number, direction: "up" | "down" | "both"): AppThunkAction<AddChatsHistoryAction> => async dispatch => {
    dispatch({ type: ADD_CHAT_HISTORY_START });

    const result = await addChatHistoryAsync(chatIdStart, chatsIdUp, chatsIdDown);
    if(result.isSuccess) {
        const chats = result.value ?? [];
        dispatch({type: ADD_CHAT_HISTORY_SUCCESS, payload: {chats, direction}})
    }
    else
        dispatch({type: ADD_CHAT_HISTORY_ERROR});
}

export const joinChat = (chatId: number): AppThunkAction<JoinChatAction> => async (dispatch, getState) => {
    dispatch({ type: JOIN_CHAT_START });
    const result = await joinChatAsync(chatId);
    const selectedChat = selectedChatSelector(getState());
    if(result.isSuccess) {
        dispatch({ type: JOIN_CHAT_SUCCESS, selectedChat });
    }
}

export const pauseChat = async (chatId: number, pauseCategory: PAUSE_CATEGORIES) => {
    const result = await pauseChatAsync(chatId, pauseCategory);

    if(!result.isSuccess) {
        //todo: use error message from the exception when it will merged from master
        const errorText = JSON?.parse(result.errorText ?? "null");
        if (errorText === null)
            showErrorToastr(i18n.t('error.error'));
        else {
            showErrorToastr(i18n.t(errorText.errorCode));
        }
    }
}

export const unpauseChat = (chatId: number) => {
    unpauseChatAsync(chatId);
}

export const getQuickAnswers = (text: string): AppThunkAction<getQuickAnswersAction> => async (dispatch, state) => {
    dispatch({ type: GET_QUICK_ANSWERS_START });

    const operatorId = state().chatItemsState.showHintsFromAllOperators ? 0 : state().chatItemsState.operatorId;

    const result = await getQuickAnswersAsync(text, operatorId);
    if(result.isSuccess) {
        const quickAnswers = result.value?.answersFromHistory ?? [];
        dispatch({ type: GET_QUICK_ANSWERS_SUCCESS, payload: {quickAnswers} });
    }
    else {
        dispatch({ type: GET_QUICK_ANSWERS_ERROR });
    }
}

export const clearQuickAnswers = (): AppThunkAction<clearQuickAnswersAction> => async (dispatch) => {
    dispatch({ type: CLEAR_QUICK_ANSWERS_SUCCESS });
}

export const removeQuickAnswer = (text: string): AppThunkAction<removeQuickAnswerAction> => async (dispatch) => {
    dispatch({ type: REMOVE_QUICK_ANSWER_SUCCESS, payload: {quickAnswerText: text} });

    await removeQuickAnswerAsync(text);
}

export const exportChatToZendesk = (chatId: number): AppThunkAction<ExportChatToZendesk> => async (dispatch) => {
    const result = await exportChatToZendeskAsync(chatId);

    if(result.isSuccess) {
        dispatch({ type: EXPORT_CHAT_TO_ZENDESK_SUCCESS });
        showSuccessToastr(i18n.t('chat.exportSuccess'));
    }
    else {
        let errorCode;
        try {
            let errorObject = JSON?.parse(result.errorText ?? "null");
            errorCode = errorObject.errorCode;
        }
        catch {
            errorCode = 'chat.exportError';
        }
        showErrorToastr(i18n.t(errorCode));
    }
}

export const getChannelOperators = async (operatorsIds: number[], channelId: number) => {
    const result = await getChannelOperatorsAsync(operatorsIds, channelId);
    if(result.isSuccess) {
        return result.value;
    } else {
        return undefined;
    }
}

export const updateChatsInChatPanel = (chats: IChatItem[]): AppThunkAction<updateChatsInChatPanelAction> => async (dispatch) => {
    dispatch({ type: UPDATE_CHATS_IN_RIGHT_PANEL_SUCCESS, payload: {chats} });
}

export const playNewMessageSound = (soundPlayer: HTMLAudioElement, message: IMessageItem): AppThunkAction<void> => async (dispatch, getState) => {
    const {chatItems, operatorStatus, operatorId, silentChatsId} = getState().chatItemsState;
    if(operatorStatus !== OperatorStatusType.Offline &&
       (chatItems.findIndex(ch => ch.chatId === message.chatId && (ch.operatorId === operatorId || ch.invitedOperatorsId.includes(operatorId))) > -1) &&
       message.operatorId !== operatorId &&
       !silentChatsId.includes(message.chatId)) {
        soundPlayer.play();
    }
}

export const pushNewMessageNotification = (message: IMessageItem): AppThunkAction<void> => async (dispatch, getState) => {
    const { chatItems, operatorStatus, selectedChatId, silentChatsId, operatorId} = getState().chatItemsState;
    if (operatorStatus != OperatorStatusType.Offline &&
        (chatItems.findIndex(ch => ch.chatId === message.chatId && (ch.operatorId === operatorId || ch.invitedOperatorsId.includes(operatorId))) > -1) &&
        (selectedChatId != message.chatId || document.hidden) &&
        message.messageType === MessageType.clientMessage &&
        !silentChatsId.includes(message.chatId)) {
        notifyNewMessage(message);
    }
}

export const onLoggedOut = (logoutBy: string, operatorId: number): AppThunkAction<void> => async (dispatch, getState) =>  {
    const currentOperatorId = getState().chatItemsState.operatorId;
    if(operatorId !== undefined && operatorId === currentOperatorId) {
        let logoutMessage: string = "";
        if(logoutBy !== undefined && logoutBy !== "") {
            logoutMessage = `Administrator ${logoutBy} has logged out you`;
        } else {
            logoutMessage = `Administrator has logged you out`;
        }
        sessionStorage.removeItem('user');
        historyWithForceUpdate.push('/login/' + logoutMessage);
    }
};

export const addContactTags = (tags: string[]): AppThunkAction<UpdateContactTagsAction> => async (dispatch, getState) =>  {
    dispatch({ type: UPDATE_CONTACT_TAGS_START });

    const contactId = getState().chatItemsState.selectedChat?.messenger.contactId || 0;
    const result = await addContactTagsAsync(tags, contactId);
    if(result.isSuccess) {
        const {contactTags} = getState().chatItemsState;
        dispatch({ type: UPDATE_CONTACT_TAGS_SUCCESS, payload: {tags: tags.concat(contactTags)} });
    }
    else {
        dispatch({ type: UPDATE_CONTACT_TAGS_ERROR });
    }
};

export const deleteContactTags = (tags: string[]): AppThunkAction<UpdateContactTagsAction> => async (dispatch, getState) =>  {
    dispatch({ type: UPDATE_CONTACT_TAGS_START });

    const contactId = getState().chatItemsState.selectedChat?.messenger.contactId || 0;
    const result = await deleteContactTagsAsync(tags, contactId);
    if(result.isSuccess) {
        const {contactTags} = getState().chatItemsState;
        dispatch({ type: UPDATE_CONTACT_TAGS_SUCCESS, payload: {tags: contactTags.filter(v => !tags.includes(v))} });
    }
    else {
        dispatch({ type: UPDATE_CONTACT_TAGS_ERROR });
    }
};

export const startOperatorLostConnectionHandler = (): AppThunkAction<void> => async (_, getState) => {
    const operatorDisconnectionTimeout = getState().chatItemsState.operatorDisconnectionTimeout * 1000;
    const signalRHubConnection = getState().chatItemsState.hubConnection;
    const nextCheck = 60000;
    showWarningToastr(i18n.t('notification.connectionAbortText'),true, true);
    const operatorLostConnectionHandler = (timeElapsed: number) => {
        if(timeElapsed >= operatorDisconnectionTimeout) {
            if(signalRHubConnection.state === HubConnectionState.Connected) {
                return;
            }
            notifyConnectionLostError();
            timeElapsed = 0;
        }
        setTimeout(() => {
            operatorLostConnectionHandler(timeElapsed + nextCheck);
        }, nextCheck);
    }
    if(operatorDisconnectionTimeout > 0)
        operatorLostConnectionHandler(0);
}

export const getIsContactOnline = (messengerExternalId: string): AppThunkAction<UpdateIsContactOnline> => async (dispatch, getState) => {
    const channel = getState().chatItemsState.selectedChat?.channel;
    if(channel?.type === ChannelType.Widget && messengerExternalId) {
        const result = await getIsContactOnlineAsync(channel.channelId, messengerExternalId)

        dispatch({ type: UPDATE_IS_CONTACT_ONLINE_SUCCESS, payload: {isContactOnline: result.value}});
    }
    else {
        dispatch({ type: UPDATE_IS_CONTACT_ONLINE_SUCCESS, payload: {isContactOnline: undefined}});
    } 
}

export const updateIsContactOnline = (status: boolean, channelToken: string, messengerExternalId: string): AppThunkAction<UpdateIsContactOnline> => async (dispatch, getState)  => {
    if(getState().chatItemsState.selectedChat?.channel.token == channelToken && getState().chatItemsState.selectedChat?.messenger.externalId == messengerExternalId) {
        dispatch({ type: UPDATE_IS_CONTACT_ONLINE_SUCCESS, payload: {isContactOnline: status}});
    }
}