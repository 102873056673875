import {
    minOperatorCodeLength, 
    phoneNumberLength
} from './PhoneHelperConstants';

export const isPhoneValid = (
    phoneNumber: string, 
    countryCode: string,
): boolean => {
    var minPhoneLength = countryCode.length + minOperatorCodeLength + phoneNumberLength;
    if(
        phoneNumber.startsWith(countryCode) &&
        phoneNumber.length >= minPhoneLength) {
            return true;
    }
    return false;
}