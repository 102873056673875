const moduleName: string = 'SETTINGS/';

export const GET_ALL_SETTINGS_START: string = `${moduleName}GET_ALL_SETTINGS_START`;
export const GET_ALL_SETTINGS_SUCCESS: string = `${moduleName}GET_ALL_SETTINGS_SUCCESS`;
export const GET_ALL_SETTINGS_ERROR: string = `${moduleName}GET_ALL_SETTINGS_ERROR`;

export const UPDATE_SETTING_IN_STATE: string = `${moduleName}UPDATE_SETTING_IN_STATE`;

export const UPDATE_CREATING_SETTING_IN_STATE: string = `${moduleName}UPDATE_CREATING_SETTING_IN_STATE`;

export const DELETE_SETTING_FROM_STATE: string = `${moduleName}DELETE_SETTING_FROM_STATE`; 

export const CHANGE_SETTING_SEARCH_STRING: string = `${moduleName}CHANGE_SETTING_SEARCH_STRING`;
export const CHANGE_SETTING_SEARCH_TYPE: string = `${moduleName}CHANGE_SETTING_SEARCH_TYPE`;