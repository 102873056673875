import { IApplicationState } from "src/~store/models/ApplicationState";
import { createSelector } from "reselect";
import { SettingType } from "./models/setting";

const settingSelector = (state: IApplicationState) => state.settingState;


const settingListSelector = createSelector(
    settingSelector, 
    settingState => settingState.settingList
);

export const settingsSelector = createSelector(
    settingListSelector, 
    (settingList) => settingList.settings
        .filter(s => s.id.toLocaleLowerCase().includes(settingList.searchString.toLocaleLowerCase()) && s.settingTypeModel !== SettingType.User && (settingList.searchType ? settingList.searchType === s.settingTypeModel : true ))
        .sort((a, b) => { return ( a.isCreating ? -1 : a.id.localeCompare(b.id))})
);