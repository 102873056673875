import * as React from "react";

import { ITopicItemProps} from "./~types/TopicItemProps";
import { ITopicItemState } from "./~types/TopicItemState";


import {Button, Input} from "reactstrap";

import "./TopicItem.css";
import { IParametersKeyValuePair } from "../../~store/models/TopicShort";
import { ModalWindow } from "../../../shared/components/modal-window/ModalWindow";
import { selectedTopicSelector } from "../../~store/selectors";
import i18n from "src/app/shared/localization/i18n";

export default class TopicItem extends React.Component<ITopicItemProps, ITopicItemState> {
    public readonly state: Readonly<ITopicItemState> = {
        topic: this.props.topic,
        isEditing: !this.props.topic.topicId,
        innerTopicName: this.props.topic.name,
        isParametersOpen: false,
        isEditParametersJson: false,
        parametersDict: [] as IParametersKeyValuePair[],
        parametersText: this.props.topic.parametersJson as string ?? ""
    };

    constructor(props: ITopicItemProps) {
        super(props);
    }

    public componentDidMount() {
        this._setParameters();
    }

    public render() {
        const {isEditing} = this.state;
        const shouldBeEdit = isEditing || this._checkIsNewTopic();

        return (
            <div className="topic-item">
                <div className="topic-item__content topic-item__content-grow" onClick={this._onSelectTopicHandler}>
                    {shouldBeEdit ? this._getEditControl() : this._getDisplayControl()}
                </div>
                <div className="topic-item__content">{shouldBeEdit ? this._getEditButtons() : this._getDisplayButtons()}</div>
                <ModalWindow
                    className="setting-item-modal"
                    isOpen={this.state.isParametersOpen}
                    header={this._getParamsHeader()}
                    body={this._getParamsBody()}
                    footer={this._getParamsFooter()}
                />
            </div>

        );
    }

    private _setParameters = () => {
        if(this.props.topic.parametersJson !== null && this.props.topic.parametersJson !== undefined && this.props.topic.parametersJson.trim() !== ''){
            try {
                const paramsObj = JSON.parse(this.props.topic.parametersJson as string);
                if (paramsObj) {
                    Object.entries(paramsObj).forEach((entry) => {
                        this.state.parametersDict.push({ key: entry[0], value: entry[1] as string })
                    });
                    this.setState({ parametersDict: this.state.parametersDict });
                    this.setState({ parametersText: JSON.stringify(paramsObj) })
                }
            }
            catch (ex) {
                console.error(`Parsed string: ${this.props.topic.parametersJson}.`, ex)
            }
        }
    };

    private _clearUnsavedParameters = () => {
        try {
            while (this.state.parametersDict.length > 0) {
                this.state.parametersDict.pop();
            }
            this.setState({ parametersText: "" });
            this._setParameters();
        } catch (ex) {
            console.error(ex);
        }
    }

    private _onSelectTopicHandler = (): void => {
        const {isEditing} = this.state;
        if (!isEditing) {
            this.props.onSelectTopic(this.props.topic.topicExternalId);
        }
    };

    private _isTopicNameFilled = (): boolean => {
        return this.state.innerTopicName.trim().length === 0;
    };


    private _onToggleEditHandler = (): void => {
        this._changeEditingState();
    };

    private _onSaveTopicHandler = (): void => {
        const { innerTopicName, topic} = this.state;
        const { onSaveTopic } = this.props;

        onSaveTopic(topic.topicExternalId, innerTopicName, topic.parametersJson);
        this._discardState();
    };

    private _onDeleteHandler = (): void => {
        const {topicExternalId} = this.props.topic;
        this.props.onDeleteTopic(topicExternalId);
    };

    private _onChangeInnerNameHandler = (event: React.FormEvent<HTMLInputElement>): void => {
        this._changeInnerTopicName(event.currentTarget.value);
    };

    private _onDiscardChangeHandler = (): void => {
        this._discardState();
    };

    private _onGetScript = (): void => {
        const topicExternalId: string = this.props.topic.topicExternalId;
        const url: string = window.location.protocol + "//" + window.location.host;
        this.props.onGetScript(topicExternalId, url);
    };

    private _discardState = (): void => {
        const {name} = this.props.topic;
        const {isEditing} = this.state;
        this.setState({innerTopicName: name, isEditing: !isEditing});
    };

    private _changeEditingState = (): void => {
        const {isEditing} = this.state;
        this.setState({isEditing: !isEditing});
    };

    private _changeInnerTopicName = (newInnerTopicName: string) => {
        this.setState({innerTopicName: newInnerTopicName});
    };

    private _checkIsNewTopic = (): boolean => {
        return this.props.topic.topicId === 0;
    };

    private _getDisplayControl = (): React.ReactElement<{}> => {
        const {topic} = this.props;

        return (
            <React.Fragment>
                <span className="topic-item__identifier">{`#${topic.topicId}`}</span>
                <span className="topic-item__name">{topic.name}</span>
            </React.Fragment>
        );
    };

    private _getEditControl = (): React.ReactElement<{}> => {
        const {innerTopicName} = this.state;

        return (
            <React.Fragment>
                <Input value={innerTopicName} onChange={this._onChangeInnerNameHandler} autoFocus={true} />
            </React.Fragment>
        );
    };

    private _getDisplayButtons = (): React.ReactElement<{}> => {
        return (
            <React.Fragment>
                {this.props.canUserManageTopic && <Button className="topic-item__button" onClick={this._onGetScript}>
                    {i18n.t('nlp.getScript')}
                </Button>}
                {this.props.canUserSearchTopic && <Button className="topic-item__button" onClick={() => this.props.onSearchHandler(this.props.topic.name)}>
                    <i className="material-icons">search</i>
                </Button>}
                {this.props.canUserManageTopic && <Button
                    onClick={() => this.setState({ isParametersOpen: true })}>
                    <i className="material-icons">settings</i>
                </Button>}
                {this.props.canUserManageTopic && <Button className="topic-item__button topic-item__button-edit" onClick={this._onToggleEditHandler}>
                    <i className="material-icons">edit</i>
                </Button>}
                {this.props.canUserManageTopic && <Button className="topic-item__button topic-item__button-delete" onClick={this._onDeleteHandler}>
                    <i className="material-icons">delete_forever</i>
                </Button>}
            </React.Fragment>
        );
    };

    private _getEditButtons = (): React.ReactElement<{}> => {
        return (
            <React.Fragment>
                <Button className="topic-item__button topic-item__button-save"
                 onClick={this._onSaveTopicHandler}
                 disabled={this._isTopicNameFilled()}>
                    <i className="material-icons">save</i>
                </Button>
                {!this._checkIsNewTopic() && (
                    <Button className="topic-item__button topic-item__button-discard" onClick={this._onDiscardChangeHandler}>
                        <i className="material-icons">replay</i>
                    </Button>
                )}
            </React.Fragment>
        );
    };

    private _onKeyChanged = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        this.state.parametersDict[index].key = event.currentTarget.value;

        this.setState({ parametersDict: this.state.parametersDict.map(kv => ({ ...kv })) });
    }

    private _onValueChanged = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        this.state.parametersDict[index].value = event.currentTarget.value;

        this.setState({ parametersDict: this.state.parametersDict.map(kv => ({ ...kv })) });
        console.log(this.state);
    }

    private _onDeleteKeyValuePair = (index: number) => {
        if (window.confirm("Подтвердить удаление параметра " + ` ${this.state.parametersDict[index].key}?`)) {
            this.state.parametersDict.splice(index, 1);

            this.setState({ parametersDict: this.state.parametersDict.map(kv => ({ ...kv })) });
        }
    }

    private _onAddKeyValuePair = () => {
        if (this._isEmptyKeyValueExist()) {
            this.state.parametersDict.push({ key: "", value: "" });

            this.setState({ parametersDict: this.state.parametersDict.map(kv => ({ ...kv })) });
        }
    }

    private _isEmptyKeyValueExist = () => {
        return this.state.parametersDict.findIndex(kv => kv.key.length === 0 || kv.value.length === 0) === -1;
    }

    private _onJsonTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ parametersText: event.currentTarget.value });
    }

    private _isParamsTextCorrectJson = () => {
        try {
            JSON.parse(this.state.parametersText);
        }
        catch (e) {
            return false;
        }
        return true;
    }

    private _onSettingsSaveButtonClick = () => {
        this.setState({ isParametersOpen: false });
        const { topic, parametersDict } = this.state;

        if (!this.state.isEditParametersJson) {
            const valueObj = Object.fromEntries(parametersDict.map(item => [item.key, item.value]));
            this.setState({ topic: { ...topic, parametersJson: JSON.stringify(valueObj) ?? "" } },
                () => this._onSaveTopicHandler());
        }
        else {
            this.setState({ topic: { ...topic, parametersJson: this.state.parametersText as string } },
                () => this._onSaveTopicHandler());
        }
    }

    private _getParamsHeader = (): React.ReactElement<{}> => {
        return (
            <>
                <div>
                    {i18n.t("nlp.commonParameters")}
                </div>
                <div className="setting-header__close-button-wrapper">
                    {!this.state.isEditParametersJson && (
                        <Button className="setting-header__edit-as-json"
                            onClick={() => {
                                this.setState({ isEditParametersJson: true })
                            }}>
                            {"Edit as json"}
                        </Button>)}
                    {this.state.isEditParametersJson && (
                        <Button className="setting-header__edit-as-default"
                            onClick={() => {
                                this.setState({ isEditParametersJson: false })
                            }}>
                            {"Edit as default"}
                        </Button>)}
                    <Button
                        className="setting-header__close-button red"
                        onClick={() => { this.setState({ isParametersOpen: false }); this._clearUnsavedParameters() }}>
                        <i className="material-icons">
                            close
                        </i>
                    </Button>
                </div>
            </>
        )
    }

    private _getParamsBody = (): React.ReactElement<{}> => {
        if (!this.state.isEditParametersJson) {
            return (
                <>
                    <div className="setting-body__key-value-pairs-list-wrapper">
                        {this.state.parametersDict.map((item, index) => (
                            <div key={index} className="setting-body__line">
                                <div className="setting-body__key-wrapper">
                                    <Input
                                        type="text"
                                        placeholder={"Key"}
                                        className="setting-body__key"
                                        value={item.key}
                                        onChange={(event) => { this._onKeyChanged(event, index) }}>
                                    </Input>
                                </div>
                                <div className="setting-body__value-wrapper">
                                    <Input
                                        type="textarea"
                                        placeholder={"Value"}
                                        className="setting-body__value"
                                        value={item.value}
                                        onChange={(event) => { this._onValueChanged(event, index) }}>
                                    </Input>
                                </div>
                                <div className="setting-body__delete-key-value-wrapper">
                                    <Button
                                        className="setting-body__delete-key-value"
                                        onClick={() => { this._onDeleteKeyValuePair(index) }}>
                                        <i className="material-icons">
                                            delete_forever
                                        </i>
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="setting-body__add-button-wrapper">
                        <Button
                            disabled={!this._isEmptyKeyValueExist()}
                            onClick={() => { this._onAddKeyValuePair() }}>
                            <i className="material-icons">
                                add
                            </i>
                        </Button>
                    </div>
                </>
            )
        }
        return (
            <div className="setting-body__text-input">
                <Input
                    className="setting-body__textarea"
                    type="textarea"
                    value={this.state.parametersText}
                    onChange={(event) => { this._onJsonTextChanged(event) }} />
            </div>
        )
    }

    private _getParamsFooter = (): React.ReactElement<{}> => {
        return (
            <div className="setting-footer-wrapper">
                <Button
                    disabled={(!this._isEmptyKeyValueExist() && !this.state.isEditParametersJson) || (!this._isParamsTextCorrectJson() && this.state.isEditParametersJson)}
                    onClick={() => this._onSettingsSaveButtonClick()}>
                    {"Save"}
                </Button>
            </div>
        )
    }
}
