export const getBytesFromFile = (file: File): ArrayBuffer => {
    const reader: FileReader = new FileReader();
    let fileBytes: ArrayBuffer | null = null;
    reader.onloadend = (event: ProgressEvent<FileReader>) => {
        fileBytes = event.target?.result as ArrayBuffer;
    }

    return fileBytes ? fileBytes : new ArrayBuffer(0);
}

export const buildFileSelector = (accept: string, onFileSelected: (e: any) => void) => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', accept);
    fileSelector.onchange = onFileSelected;
    return fileSelector;
}