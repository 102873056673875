import { IMailingState } from "./state/MailingState";
import {initState, initMailing} from "./state/InitMailingState";
import { Action, Reducer } from "redux";
import { GET_MAILING_ITEM_INFO_SUCCESS, GET_ENABLED_CHANNELS_SUCCESS, CHANGE_SELECTED_CHANNELS_SUCCESS, CHANGE_NAME_SUCCESS, CHANGE_MESSAGE_SUCCESS, SAVE_MAILING_SUCCESS, GET_MAILINGS_SUCCESS, REMOVE_ALL_MAILINGS_FROM_STATE_SUCCESS, START_MAILINGS_SUCCESS, DELETE_MAILING_SUCCESS, GET_MAILINGS_RECIPIENTS_SUCCESS, DELETE_RECIPIENTS_FROM_STATE_SUCCESS, GET_MAILINGS_START, GET_MAILINGS_ERROR, CHANGE_OPERATOR_ID_SUCCESS } from "./actions/types";
import { GetMailingItemInfoAction, GetEnabledChannelsActionSuccess, ChangeSelectedChannelsActionSuccess, ChangeNameActionSuccess, ChangeMessageActionSuccess, GetMailingsActionSuccess, StartMailingActionSuccess, DeleteMailingActionSuccess, GetRecipientsActionSuccess, ChangeOperatorIdActionSuccess } from "./actions/interfaces";
import { stat } from "fs";

export const mailingReducer: Reducer<IMailingState> = (
    state: IMailingState = initState,
    action: Action
): IMailingState => {


    switch(action.type){
        case GET_MAILING_ITEM_INFO_SUCCESS: {
            const mailing = (action as GetMailingItemInfoAction).payload.mailing;

            return {
                ...state,
                mailingItemInfo: {...state.mailingItemInfo, mailing}
            }
        }

        case GET_ENABLED_CHANNELS_SUCCESS: {
            const channels = (action as GetEnabledChannelsActionSuccess).payload.channels;
            return {
                ...state,
                mailingItemInfo: {...state.mailingItemInfo, enabledChannels: channels}
            }
        }

        case GET_MAILINGS_RECIPIENTS_SUCCESS: {
            const {recipients} = (action as GetRecipientsActionSuccess).payload;

            return {
                ...state,
                mailingItemInfo: {...state.mailingItemInfo, recipients: state.mailingItemInfo.recipients.concat(recipients)}
            }
        }

        case DELETE_RECIPIENTS_FROM_STATE_SUCCESS: {
            return {
                ...state,
                mailingItemInfo: {...state.mailingItemInfo, recipients: []}
            }
        }

        case CHANGE_SELECTED_CHANNELS_SUCCESS: {
            const {type, isOn} = (action as ChangeSelectedChannelsActionSuccess).payload;
            
            const channelTypes = state.mailingItemInfo.mailing.channelTypes.filter(x => x !== type);

            return {
                ...state,
                mailingItemInfo: {
                    ...state.mailingItemInfo, mailing: {
                        ...state.mailingItemInfo.mailing, channelTypes: isOn ? channelTypes.concat([type]) : channelTypes
                    }
                }
            }
        }

        case CHANGE_NAME_SUCCESS: {
            const name = (action as ChangeNameActionSuccess).payload.name;
            const newMailing = state.mailingItemInfo.newMailing;
            if(state.mailingItemInfo.mailing.mailingId === 0) {
                newMailing.name = name;
            }

            return {
                ...state,
                mailingItemInfo: {
                    ...state.mailingItemInfo,
                    newMailing,
                    mailing: {
                        ...state.mailingItemInfo.mailing,
                        name
                    }
                }
            }
        }

        case CHANGE_OPERATOR_ID_SUCCESS: {
            const operatorId = (action as ChangeOperatorIdActionSuccess).payload.operatorId;
            const newMailing = state.mailingItemInfo.newMailing;
            if (state.mailingItemInfo.mailing.mailingId === 0) {
                newMailing.operatorId = operatorId;
            }

            return {
                ...state,
                mailingItemInfo: {
                    ...state.mailingItemInfo,
                    newMailing,
                    mailing: {
                        ...state.mailingItemInfo.mailing,
                        operatorId
                    }
                }
            }
        }

        case CHANGE_MESSAGE_SUCCESS: {
            const text = (action as ChangeMessageActionSuccess).payload.message;
            const newMailing = state.mailingItemInfo.newMailing;
            if(state.mailingItemInfo.mailing.mailingId === 0) {
                newMailing.text = text;
            }

            return {
                ...state,
                mailingItemInfo: {
                    ...state.mailingItemInfo,
                    newMailing,
                    mailing: {
                        ...state.mailingItemInfo.mailing,
                        text
                    }
                }
            }
        }

        case SAVE_MAILING_SUCCESS: {
            return {
                ...state,
                mailingItemInfo: {
                    ...state.mailingItemInfo,
                    newMailing: initMailing()
                }
            }
        }

        case GET_MAILINGS_START:
        case GET_MAILINGS_ERROR: {
            return {
                ...state,
                mailingList: {
                    ...state.mailingList,
                    isReceivingmailings: true
                }
            }
        }

        case GET_MAILINGS_SUCCESS: {
            const mailings = (action as GetMailingsActionSuccess).payload.mailings;

            return {
                ...state,
                mailingList: {
                    ...state.mailingList,
                    isReceivingmailings: false,
                    allMailings: state.mailingList.allMailings.concat(mailings)
                }
            }
        }

        case REMOVE_ALL_MAILINGS_FROM_STATE_SUCCESS: {
            return {
                ...state,
                mailingList: {
                    ...state.mailingList,
                    allMailings: []
                }
            }
        }

        case START_MAILINGS_SUCCESS: {
            const sentMailing = (action as StartMailingActionSuccess).payload.sentMailing;

            const index = state.mailingList.allMailings.findIndex(x => x.mailingId === sentMailing.mailingId);
            const copyAllMailings = state.mailingList.allMailings.slice();
            if(index > -1) {
                copyAllMailings.splice(index, 1, sentMailing);
            }

            return {
                ...state,
                mailingList: {
                    ...state.mailingList,
                    allMailings: copyAllMailings
                }
            }
        }

        case DELETE_MAILING_SUCCESS: {
            const mailingId = (action as DeleteMailingActionSuccess).payload.mailingId;

            return {
                ...state,
                mailingList: {
                    ...state.mailingList,
                    allMailings: state.mailingList.allMailings.filter(x => x.mailingId !== mailingId)
                }
            }
        }
    }

    return state;
    
}
