import {IChannelState} from "./ChannelState";

export const initState: IChannelState = {
    channelList: {
        channels: [],
        operators: [],
        isReceivingChannels: false,
        isReceivingOperators: false,
        isSortingByChannelName: false,
        isSavingChannel: false
    }
}
