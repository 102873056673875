import {AppThunkAction} from "src/~store/models/AppThunkAction";

import {
    GetAllChannelsAction, 
    CreateChannelAction, 
    SaveChannelAction,
    DeleteChannelAction,
    GetAllOperatorsAction,
    SaveChannelSettingAction,
    ToggleChannelSortingAction,
    RestartChannelAction,
} from "./interfaces";

import {
    GET_ALL_CHANNELS_START, 
    GET_ALL_CHANNELS_SUCCESS, 
    GET_ALL_CHANNELS_ERROR, 
    CREATE_CHANNEL,
    DELETE_CHANNEL_START,
    DELETE_CHANNEL_SUCCESS,
    GET_ALL_OPERATORS_START,
    GET_ALL_OPERATORS_SUCCESS,
    GET_ALL_OPERATORS_ERROR,
    SAVE_CHANNEL_SETTING,
    DELETE_NEW_CHANNEL_SUCCESS,
    SAVE_NEW_CHANNEL_SUCCESS,
    TOGGLE_CHANNEL_SORTING_ACTION,
    RESTART_CHANNEL_START,
    RESTART_CHANNEL_SUCCESS
} from "./types";

import {
    getAllChannelsAsync,
    getAllOperatorsAsync,
    saveChannelAsync,
    deleteChannelAsync,
    saveChannelSettingAsync,
    getSettingByChannelIdAsync,
    getSettingsByChannelsIdAsync,
    restartChannelAsync
} from "../../~api/actions";

import {IChannel} from "../models/Channel";

import {
    showSuccessToastr, 
    showErrorToastr 
} from "src/app/shared/helpers/toastr-helper/ToastrHelper";

import { initNewSetting } from "src/app/setting/~store/state/InitSettingsState";
import { CHANNEL_SETTING_PREFIX } from "../../channel-main/channel-list/channel-item/ChannelItemConstants";
import i18n from "src/app/shared/localization/i18n";

// channel list

export const getAllChannels = (): AppThunkAction<GetAllChannelsAction> => async dispatch => {
    dispatch({type: GET_ALL_CHANNELS_START});
    const result = await getAllChannelsAsync();
    if (result.isSuccess) {
        const channels = result.value ?? [];

        const settingsResult = await getSettingsByChannelsIdAsync(channels.map((item) => item.channelId));
        if(settingsResult.isSuccess) {
            channels.forEach(channel => channel.setting = settingsResult.value?.find(s => s.id === CHANNEL_SETTING_PREFIX + channel.channelId) || initNewSetting());
            dispatch({type: GET_ALL_CHANNELS_SUCCESS, payload: {channels}});
            return;
        }  
    }
    dispatch({type: GET_ALL_CHANNELS_ERROR});
};

export const getAllOperators = (): AppThunkAction<GetAllOperatorsAction> => async dispatch => {
    dispatch({type: GET_ALL_OPERATORS_START});

    const result = await getAllOperatorsAsync();

    if(result.isSuccess) {
        const operators = result.value ? result.value : [];
        dispatch({type: GET_ALL_OPERATORS_SUCCESS, payload: {operators}})
    } else {
        dispatch({type: GET_ALL_OPERATORS_ERROR})
    }
}

export const createChannel = (): AppThunkAction<CreateChannelAction> => async dispatch => {
    dispatch({type: CREATE_CHANNEL});
};

export const saveChannel = (channel: IChannel): AppThunkAction<SaveChannelAction> => async (dispatch, getState) => {
    if(getState().channelState.channelList.isSavingChannel) return;
    
    const result = await saveChannelAsync(channel);
    const errorText = JSON?.parse(result.errorText ?? "null");
    if(result.isSuccess) {
        if(channel.channelId === undefined) {
            showSuccessToastr(i18n.t('channels.saveNewChannelSuccessMessage'));
            dispatch({ type: SAVE_NEW_CHANNEL_SUCCESS })
        } else {
            showSuccessToastr(i18n.t('channels.saveChannelSuccessMessage'));
        }
    } else {

        if (errorText === null)
            showErrorToastr(i18n.t('channels.saveChannelErrorMessage'));
        else if(errorText.errorCode == 'channels.uniformWidgetStartSignalRError') {
            showErrorToastr(i18n.t(errorText.errorCode, { url: errorText.data.url}));
        }
        else {
            showErrorToastr(i18n.t(errorText.errorCode, { channelId: errorText.data.channelId, openedChats: errorText.data.openedChats}));
        }
    }

    getAllChannels()(dispatch, getState);
}

export const deleteChannel = (channel: IChannel): AppThunkAction<DeleteChannelAction> => async (dispatch, getState) => {
    dispatch({type: DELETE_CHANNEL_START});

    if(channel.channelId === 0) {
        showSuccessToastr(i18n.t('channels.deleteNewChannelSuccessMessage'));
        dispatch({type: DELETE_NEW_CHANNEL_SUCCESS});
        dispatch({type: DELETE_CHANNEL_SUCCESS});
    } else {
        const result = await deleteChannelAsync(channel);

        if(result.isSuccess) {
            if(result.value != 0) {
                showErrorToastr(i18n.t('channels.deleteNewChannelErrorMessage').replace('{0}', channel.channelId.toString()) + result.value?.toString());
            }
            else {
                showSuccessToastr(i18n.t('channels.deleteChannelSuccessMessage'));
            }
        } else {
            showErrorToastr(i18n.t('channels.deleteChannelErrorMessage'));
        }
    }

    getAllChannels()(dispatch, getState);
}

export const restartChannel = (channel: IChannel): AppThunkAction<RestartChannelAction> => async (dispatch, getState) => {
    dispatch({ type: RESTART_CHANNEL_START });

    const result = await restartChannelAsync(channel);

    if (result.isSuccess) {
        await dispatch({ type: RESTART_CHANNEL_SUCCESS });
        showSuccessToastr(i18n.t('channels.restartChannelSuccessMesage'));
    }

    getAllChannels()(dispatch, getState);
}

export const saveChannelSetting = (
    channel: IChannel, 
    setting: string
): AppThunkAction<SaveChannelSettingAction> => async (dispatch, getState) => {
    const result = await saveChannelSettingAsync(channel.channelId as number, setting);

    if(result.isSuccess) {
        dispatch({type: SAVE_CHANNEL_SETTING});
    }

    getAllChannels()(dispatch, getState);
}

export const toggleChannelSorting = () : AppThunkAction<ToggleChannelSortingAction> => async dispatch => {
    dispatch({type: TOGGLE_CHANNEL_SORTING_ACTION});
}
