import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { IApplicationState } from './../../../../../~store/models/ApplicationState';
import { IMailingItemInfoProps } from "./~types/MailingItemInfoProps";
import {RadioGroup, Radio} from 'react-radio-group';
import {ChannelType} from './../../../../channel/~store/models/enums/ChannelType';

import './MailingItemInfo.css';
import { Table, Button } from "reactstrap";
import { mailingItemInfoSelector, mailingRecipientsSelector, enabledChannelsSelector, selectedChannelTypesSelector } from "src/app/mailing/~store/selector";
import { useLocation } from "react-router-dom";
import { getMailingItemInfo, getEnabledChannels, changeSelectedChannels, changeName, changeMessage, saveMailing, getRecipients, deleteRecipientsFromState, changeOperatorId } from "src/app/mailing/~store/actions/actions";
import { showErrorToastr } from "src/app/shared/helpers/toastr-helper/ToastrHelper";
import { GetContactAvatarLink } from "./../../../../shared/helpers/avatar-helper/ContactAvatarLinkHelper"
import { IMessenger } from "src/app/contact/~store/models/Messenger";
import { GetChannelImage } from "./../../../../shared/helpers/img-helpers/ImgHelper";
import { onChatItemClick } from "src/app/chats/~store/actions/actions";
import { mailingRecipientStatusModelToString } from "./../../../~store/models/mailing-recipient";
import { useTranslation } from 'react-i18next';
import { Avatar } from '@mui/material';
import ContactIcon from '@mui/icons-material/AccountCircle';
import { getAllOperators } from "../../../../operator/~store/actions/actions";
import { allOperatorsSelector } from "../../../../operator/~store/selector";
import { operatorIdSelector } from "../../../../chats-panel/~store/selectors";
import { MailingStatus } from "../../../~store/models/enums/MailingStatus";

const MailingItemInfo = (props: ReturnType<typeof mapStateToProps> & IMailingItemInfoProps) => {
    const { t } = useTranslation();
    const [testRadio, setTestRadio] = useState("all");

    const location = useLocation();

    useEffect(() => {
        props.deleteRecipientsFromState();
        let mailingId = 0;
        if(window.location.pathname.includes('/mailing/edit-mailing/')){
            mailingId = +window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
        }
        props.getMailingItemInfo(mailingId);
        props.getRecipients(mailingId);
        props.getAllOperators();
    }, [location]);

    const _getSelectedChannels = () => {
        return (
            props.selectedChannelTypes?.map((channelType) => {
                return (
                    <img key={channelType} className="mailing-item-info__channel-image" src={GetChannelImage(channelType)}/>
                );
            }));
    }

    const _getRecipientMessengers = (messengers: IMessenger[]) => {
        return (
            messengers.map((messenger) => {
                return (
                    <div className="mailing-item-info__channel-container" key={messenger.messengerId}>
                        <img className="mailing-item-info__channel-image" src={GetChannelImage(messenger.channelType)}/>
                        <span className="mailing-item-info__channel-externalid">{messenger.externalId}</span>
                    </div>
                );
            }));
    }

    const _onNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.currentTarget.value;
        props.changeName(name);
    }

    const _onOperatorIdChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const operatorId = +e.currentTarget.value;
        props.changeOperatorId(operatorId);
    }

    const _onMessageChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const message = e.currentTarget.value;
        props.changeMessage(message);
    }

    const _onSaveButtonClick = () => {
        if(props.mailingItemInfo.text.length < 3) {
            showErrorToastr(t('mailing.notEnoughSymbolsInMessageError'));
        }
        else if (!props.mailingItemInfo.operatorId) {
            showErrorToastr(t('mailing.needChooseOperator'));
        }
        else {
            props.saveMailing();
        }
    }
    
    return(
        <div className="mailing-item-info">
            <div className="mailing-item-info__name-block">
                <div className="mailing-item-info__name-label">{t('common.name')}</div>
                <input className="mailing-item-info__name-input" type="text" value={props.mailingItemInfo.name} onChange={_onNameChanged}/>
            </div>
            <div className="mailing-item-info__text-block">
                <div className="mailing-item-info__text-label">{t('common.message')}</div>
                <textarea className="mailing-item-info__text-input"
                    maxLength={ 1000 }
                    value={props.mailingItemInfo.text}
                    onChange={_onMessageChanged} />
            </div>
            <div className="mailing-item-info-operators">
                <div className="mailing-item-info__name-label">{t('mailing.chooseOperator') }</div>
                <select
                    onChange={_onOperatorIdChanged}
                    value={props.mailingItemInfo.operatorId}
                >
                    <option value={0} disabled> {} </option>
                    {props.operators.map(o => {
                        return (
                            <option key={o.operatorId} value={o.operatorId}>{o.name}</option>
                        )
                    }) }
                </select>
            </div>
            <div className="mailing-item-info__channels-block">
                <div className="mailing-item-info__save-block">
                    <Button onClick={_onSaveButtonClick} disabled={props.mailingItemInfo.status != MailingStatus.pending }>
                            {t('common.save')}
                    </Button>
                </div>
            </div>
            <div className="mailing-item-info__recipients-source-block">
                <RadioGroup name="mailing-source" selectedValue={testRadio} onChange={(e) => {setTestRadio(e)}}>
                    <div className="mailing-item-info__recipients-source-radio"><Radio value="all" />{t('mailing.allContacts')}</div>
                </RadioGroup>
            </div>
            <div 
                onScroll={(e) => {if(e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight) props.getRecipients(props.mailingItemInfo.mailingId);}} 
                className={"mailing-item-info__recipients-list-block " + (props.isChatVisible && "mailing-item-info__recipients-list-block-opened-chat-panel")}>
                <Table className="mailing-table card-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th className="mailing-item-header__contact">{t('common.contact')}</th>
                            <th>{t('common.messengers')}</th>
                            <th>{t('common.status')}</th>
                            <th>{t('common.chat')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.recipients.map((recipient, index) => {
                            return (
                                <tr key={recipient.mailingRecipientId}>
                                    <td className="mailing-item-info__avatar">
                                         <Avatar className="mailing-item-info__recipient-photo" src={GetContactAvatarLink(recipient.contact?.avatarId)}>
                                            <ContactIcon style={{ color: '#26a69a'}} />
                                         </Avatar>
                                    </td>
                                    <td className="mailing-item-info__recipient-name">
                                        <div>{recipient.contact?.firstName} {recipient.contact?.lastName} {recipient.contact?.patronymic}</div>
                                        <div>{recipient.contact?.phone}</div>
                                    </td>
                                    <td className="mailing-item-info__messenger">
                                        {_getRecipientMessengers(recipient.contact?.messengers || [])}
                                    </td>
                                    <td className="mailing-item__mailing-status">
                                        {recipient.chat ? 
                                            <div>
                                                <span className="mailing-item-info__channel-sent-label">{t('mailing.sentVia')} </span>
                                                <img className="mailing-item-info__channel-image" src={GetChannelImage(recipient.chat.channelType)}/>
                                            </div> :
                                            <div>{`${mailingRecipientStatusModelToString(recipient.status)}${recipient.errorMessage != null && recipient.errorMessage.length > 0 ? ` (${recipient.errorMessage})` : ""}`}</div>
                                        }  
                                    </td>
                                    <td className="mailing-item-info__link">
                                        {recipient.chat && 
                                            <a className="mailing-item-info__chat-link" 
                                                onClick={() => {if(props.selectedChatId !== recipient.chat.chatId)  onChatItemClick(recipient.chat.chatId)}}>#{recipient.chat.chatId}
                                            </a>}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        mailingItemInfo: mailingItemInfoSelector(state),
        recipients: mailingRecipientsSelector(state),
        enabledChannels: enabledChannelsSelector(state),
        selectedChannelTypes: selectedChannelTypesSelector(state),
        selectedChatId: state.chatItemsState.selectedChatId,
        isChatVisible: state.chatItemsState.isChatVisible,
        operators: allOperatorsSelector(state),
        operatorId: operatorIdSelector(state),
    };
};

const mapDispatchToProps = (dispatch: any): IMailingItemInfoProps => {
    return {
        getMailingItemInfo: (mailingId: number) => dispatch(getMailingItemInfo(mailingId)),
        changeSelectedChannels: (type: ChannelType, isOn: boolean) => dispatch(changeSelectedChannels(type, isOn)),
        changeName: (name: string) => dispatch(changeName(name)),
        changeOperatorId: (operatorId: number) => dispatch(changeOperatorId(operatorId)),
        changeMessage: (message: string) => dispatch(changeMessage(message)),
        saveMailing: () => dispatch(saveMailing()),
        getRecipients: (mailingId: number) => dispatch(getRecipients(mailingId)),
        onChatItemClick: (chatId: number) => { return onChatItemClick(chatId)},
        deleteRecipientsFromState: () => dispatch(deleteRecipientsFromState()),
        getAllOperators: () => dispatch(getAllOperators())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MailingItemInfo);