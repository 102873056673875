import * as React from "react";
import {IValidatableInputProps} from "./~types/ValidatableInputProps";
import {IValidatableInputState} from "./~types/ValidatableInputState";
import {Input, Tooltip} from "reactstrap";
import {CLIENT_NAME_MAX_LENGTH} from "src/~store/constants"


import "./ValidatableInput.css";

export default class ValidatableInput extends React.Component<IValidatableInputProps, IValidatableInputState> {
    public readonly state: IValidatableInputState = {
        tooltipMessage: "",
        isTooltipOpen: false,
        isValueInvalid: false,
    };

    public render() {
        const {className, value, id} = this.props;
        const {isValueInvalid, tooltipMessage, isTooltipOpen} = this.state;
        return (
            <div className="validatable-input__container">
                <Input 
                    type="text" 
                    maxLength={CLIENT_NAME_MAX_LENGTH}
                    className={className}
                    id={id}
                    value={value}
                    invalid={isValueInvalid}
                    onChange={this._onValueChangeHandler}
                    onMouseEnter={this._onValueInputMouseEnterHandler}
                    onMouseLeave={this._onValueInputMouseLeaveHandler} />
                <Tooltip 
                    placement="bottom-start"
                    isOpen={isTooltipOpen}
                    target={id}>
                        {tooltipMessage}
                </Tooltip>
            </div>
        );
    }

    private _onValueChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.currentTarget.value;
        let isValueInvalid: boolean = false;

        const validationMessage: string | undefined = this.props.validate?.(value);
        if(validationMessage !== undefined && validationMessage !== '') {
            isValueInvalid = true;
            const tooltipMessage: string = validationMessage ? validationMessage : '';
            this.setState({tooltipMessage});
        }

        this.setState({isValueInvalid});

        this.props.onValueChange?.(event);
    }

    private _onValueInputMouseEnterHandler = (): void => {
            if(this.state.isValueInvalid) {
                const isTooltipOpen: boolean = true;
                this.setState({isTooltipOpen});
            }
    }

    private _onValueInputMouseLeaveHandler = (): void => {
            if(this.state.isValueInvalid) {
                const isTooltipOpen: boolean = false;
                this.setState({isTooltipOpen});
            }
    }
}
