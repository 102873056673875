import {
    GetAllChannelsAction, 
    GetAllChannelsSuccessAction, 
    CreateChannelAction,
    GetAllOperatorsSuccessAction,
} from "./actions/interfaces";

import {Action, Reducer} from "redux";
import {IChannelState} from "./state/ChannelState";
import {initState} from "./state/InitChanelState";

import { 
    GET_ALL_CHANNELS_START, 
    GET_ALL_CHANNELS_SUCCESS, 
    GET_ALL_CHANNELS_ERROR, 
    CREATE_CHANNEL, 
    GET_ALL_OPERATORS_START,
    GET_ALL_OPERATORS_SUCCESS,
    GET_ALL_OPERATORS_ERROR,
    SAVE_NEW_CHANNEL_ERROR,
    DELETE_NEW_CHANNEL_SUCCESS,
    SAVE_NEW_CHANNEL_SUCCESS,
    TOGGLE_CHANNEL_SORTING_ACTION,
    SAVE_CHANNEL_START
} from "./actions/types";
import { IChannel } from "./models/Channel";
import { ChannelType } from "./models/enums/ChannelType";
import { initNewSetting } from "src/app/setting/~store/state/InitSettingsState";

export type KnownActions = 
    GetAllChannelsAction |
    CreateChannelAction;

export const channelReducer: Reducer<IChannelState> = (
    state: IChannelState = initState, 
    incommingAction: Action
): IChannelState => {
    const action = incommingAction as KnownActions;

    const stateChannels: IChannel[] = state.channelList.channels; 
    // channel list
    switch(action.type) {
        case GET_ALL_CHANNELS_START:
            return {
                ...state,
                channelList: {
                    ...state.channelList,
                    isReceivingChannels: true,
                }
            };

        case GET_ALL_OPERATORS_START:
            return {
                ...state,
                channelList: {
                    ...state.channelList,
                    isReceivingOperators: true,
                }
            };
        
        case GET_ALL_CHANNELS_SUCCESS:
            const receivedChannels = (action as GetAllChannelsSuccessAction).payload.channels;
            return {
                ...state,
                channelList: {
                    ...state.channelList,
                    isReceivingChannels: false,
                    channels: receivedChannels
                }
            };

        case GET_ALL_OPERATORS_SUCCESS:
            const receivedOperators = (action as GetAllOperatorsSuccessAction).payload.operators;
            return {
                ...state,
                channelList: {
                    ...state.channelList,
                    isReceivingOperators: false,
                    operators: receivedOperators,
                }
            }

        case GET_ALL_CHANNELS_ERROR:
            return {
                ...state,
                channelList: {
                    ...state.channelList,
                    isReceivingChannels: true,
                }
            };

        case GET_ALL_OPERATORS_ERROR:
            return {
                ...state,
                channelList: {
                    ...state.channelList,
                    isReceivingOperators: true,
                }
            };

        case CREATE_CHANNEL:
            const createdChannel: IChannel = {
                channelId: 0,
                name: '',
                enabled: false,
                started: false,
                modifiedOn: new Date(),
                type: ChannelType.Telegram,
                setting: initNewSetting(),
                token: '',
                operators: [],
                hasChats: false
            }
            return {
                ...state,
                channelList: {
                    ...state.channelList,
                    channels: [createdChannel, ...state.channelList.channels],
                }
            }

        case SAVE_NEW_CHANNEL_ERROR:
            stateChannels.forEach((channel, index) => {
                if(channel.channelId === undefined) {
                    stateChannels.splice(index, 1);
                }
            })
            return {
                ...state,
                channelList: {
                    ...state.channelList,
                    channels: stateChannels,
                    isSavingChannel: false
                }
            }

        
        case SAVE_NEW_CHANNEL_SUCCESS:
            return { 
                ...state,
                channelList: {
                    ...state.channelList,
                    isSavingChannel: false
                }
            }

        case SAVE_CHANNEL_START: {
            return {
                ...state,
                channelList: {
                    ...state.channelList,
                    isSavingChannel: true
                }
            }
        }

        case TOGGLE_CHANNEL_SORTING_ACTION: {
            return { 
                ...state,
                channelList: {
                    ...state.channelList,
                    isSortingByChannelName: !state.channelList.isSortingByChannelName
                }
            }
        }
        
        default:
            return state;
    }
};
