import React from "react";

import "./SilentChatButton.css"

interface silentChatButtonProps {
    silentChatsId: number[],
    selectedChatId: number,
    onClick(): void
}

export default function SilentChatButton(props: silentChatButtonProps) {
    
    const isSilent = props.silentChatsId.findIndex(x => x === props.selectedChatId) > -1;
    return (
        <React.Fragment >
            <div onClick={props.onClick}>
                <img src={isSilent ? require("./../../images/silent_chat_on.svg") : require("./../../images/silent_chat_off.svg")} /> 
            </div>
        </React.Fragment>
    )
}