import * as React from "react";
import {connect} from "react-redux";
import {Button, Card, CardTitle, CardHeader, CardBody, CardFooter} from "reactstrap";

import {ITopicWidgetProps} from "./~types/TopicWidgetProps";
import {IApplicationState} from "src/~store/models/ApplicationState";
import {
    isTopicWidgetVisibleSelector,
    messagesSelector,
    selectedTopicSelector
} from "../../~store/selectors";
import {
    toggleTopicWidgetVisibility,
    addQuestion,
    getAnswers,
    clearTopicWidgetMessages,
} from "../../~store/actions/actions";

import {getUserExternalIdFromUrl} from "src/app/shared/helpers/user-auth-helper/UserAuthHelper";

import {MessageType} from "../../~store/models/enums/MessageType";
import {IBaseMessage} from "../../~store/models/messages/BaseMessage";
import {IAnswerMessage} from "../../~store/models/messages/AnswerMessage";
import {IQuestionMessage} from "../../~store/models/messages/QuestionMessage";

import Message from "./message/Message";
import MessageInput from "./message-input/MessageInput";
import AnswerMessageBody from "./answer-message-body/AnswerMessageBody";
import QuestionMessageBody from "./question-message-body/QuestionMessageBody";

import "./TopicWidget.css";
import i18n from "src/app/shared/localization/i18n";

type ReduxType = ReturnType<typeof mapStateToProps> & ITopicWidgetProps;

class TopicWidget extends React.Component<ReduxType> {
    public componentWillUnmount() {
        this.props.clearTopicWidgetMessages();
        if(this.props.isTopicWidgetVisible) {
            this._onWidgetClose();
        }
    }

    public render() {
        const {messages} = this.props;

        return (
            <React.Fragment>
                {this.props.isTopicWidgetVisible && (
                    <div className="topic-widget__container">
                        <Card className="topic-widget">
                            <CardHeader className="topic-widget__header">
                                <CardTitle className="topic-widget__title">{i18n.t('nlp.askYourQuestion')}</CardTitle>
                            </CardHeader>
                            <CardBody className="topic-widget__body">
                                {messages.map((m: IBaseMessage, index: number) => (
                                    <Message key={index}
                                             className={this._getMessageClassName(m.type)}
                                             createdDate={m.createdDate}>
                                        {this._getMessageBody(m)}
                                    </Message>
                                ))}
                            </CardBody>
                            <CardFooter className="topic-widget__footer">{this._getCardFooter()}</CardFooter>
                        </Card>
                        <Button className="topic-widget__btn-close" onClick={this._onWidgetClose}>
                            <i className="material-icons">clear</i>
                        </Button>
                    </div>
                )}
                {!this.props.isTopicWidgetVisible && (
                    <Button className="topic-widget__btn-expand" onClick={this._onWidgetOpen}>
                        <i className="material-icons">add</i>
                    </Button>
                )}
            </React.Fragment>
        );
    }

    private _onWidgetOpen = (): void => {
        this.props.toggleTopicWidgetVisibility();
    };

    private _onWidgetClose = (): void => {
        this.props.toggleTopicWidgetVisibility();
    };

    private _onSendMessageHandler = (questionText: string): void => {
        this.props.addQuestion(questionText);
        this.props.getAnswers(this.props.selectedTopic.name, questionText);
    };

    private _getCardFooter = (): React.ReactElement<{}> => {
        return <MessageInput onSendMessage={this._onSendMessageHandler}/>;
    };

    private _getMessageBody = (message: IBaseMessage): React.ReactElement<{}> => {
        switch (message.type) {
            case MessageType.Question: {
                const questionMessage = message as IQuestionMessage;
                return <QuestionMessageBody questionMessage={questionMessage}/>;
            }
            case MessageType.Answer: {
                const answerMessage = message as IAnswerMessage;
                return (
                    <AnswerMessageBody
                        userExternalId={getUserExternalIdFromUrl()}
                        topicExternalId={this.props.selectedTopic.topicExternalId}
                        topicId={this.props.selectedTopic.topicId}
                        answerMessage={answerMessage}
                    />
                );
            }
            default:
                throw new Error(i18n.t('nlp.unsupportedMessageType'));
        }
    };

    private _getMessageClassName = (messageType: MessageType): string => {
        switch (messageType) {
            case MessageType.Answer:
                return "message__answer";
            case MessageType.Question:
                return "message__question";
            default:
                throw new Error(i18n.t('nlp.unsupportedMessageType'));
        }
    };
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        isTopicWidgetVisible: isTopicWidgetVisibleSelector(state),
        selectedTopic: selectedTopicSelector(state),
        messages: messagesSelector(state),
    };
};

const mapDispatchToProps = (dispatch: any): ITopicWidgetProps => {
    return {
        toggleTopicWidgetVisibility: () => dispatch(toggleTopicWidgetVisibility()),
        addQuestion: (questionText: string) => dispatch(addQuestion(questionText)),
        getAnswers: (
            topicExternalId: string, 
            questionText: string
        ) => dispatch(getAnswers(topicExternalId, questionText)),
        clearTopicWidgetMessages: () => dispatch(clearTopicWidgetMessages()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicWidget);
