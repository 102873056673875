import * as React from "react";
import {connect} from "react-redux";

import {MessageBoxType} from "src/app/nlp/~store/models/enums/MessageBoxType";
import {IMessageBoxProps} from "./~type/IMessageBoxProps";
import {IApplicationState} from "src/~store/models/ApplicationState";
import {messageBoxDataSelector} from "src/app/nlp/~store/selectors";
import {toggleMessageBoxVisibility} from "../../../../nlp/~store/actions/actions";

import {ModalWindow} from "../../modal-window/ModalWindow";
import {Badge, Button, ButtonGroup} from "reactstrap";

import "./MessageBox.css";
import i18n from "src/app/shared/localization/i18n";

type ReduxType = ReturnType<typeof mapStateToProps> & IMessageBoxProps;

class MessageBox extends React.Component<ReduxType> {
    public render() {
        return (
            <ModalWindow
                isOpen={true}
                className={"message-box " + this.props.data.className}
                header={this._getHeader()}
                body={this._getBody()}
                footer={this._getFooter()}
            />
        );
    }

    private _close = (): void => {
        this.props.toggleMessageBoxVisibility();
    };

    private _onCloseHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.props.data.onClose?.();
        this._close();
    };

    private _onConfirmHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.props.data.onConfirm?.();
        this._close();
    };

    private _onRejectHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.props.data.onReject?.();
        this._close();
    };

    private _getHeader = (): React.ReactElement<{}> => {
        return (
            <Badge className="message-box__header">
                <div className="message-box__title">{this.props.data.title}</div>
            </Badge>
        );
    };

    private _getBody = (): React.ReactElement<{}> => {
        return <div className="message-box__body">{this.props.data.message}</div>;
    };

    private _getFooter = (): React.ReactElement<{}> => {
        return <div className="message-box__footer">{this._getFooterByType()}</div>;
    };

    private _getFooterByType = (): React.ReactElement<{}> => {
        switch (this.props.data.type) {
            case MessageBoxType.Confirm:
                return this._getConfirmFooter();
            default:
                return this._getDefaultFooter();
        }
    };

    private _getConfirmFooter = (): React.ReactElement<{}> => {
        return (
            <ButtonGroup className="message-box__confirm-buttons">
                <Button className="message-box__confirm-buttons--confirm" onClick={this._onConfirmHandler}>
                    {i18n.t('common.confirm')}
                </Button>
                <Button className="message-box__confirm-buttons--reject" onClick={this._onRejectHandler}>
                    {i18n.t('common.reject')}
                </Button>
            </ButtonGroup>
        );
    };

    private _getDefaultFooter = (): React.ReactElement<{}> => {
        return (
            <Button onClick={this._onCloseHandler} className="message-box__close">
                {i18n.t('common.close')}
            </Button>
        );
    };
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        data: messageBoxDataSelector(state)
    };
};

const mapDispatchToProps = (dispatch: any): IMessageBoxProps => {
    return {
        toggleMessageBoxVisibility: () => dispatch(toggleMessageBoxVisibility()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageBox);
