import React, { useEffect } from 'react';
import { IApplicationState } from 'src/~store/models/ApplicationState';
import { connect } from "react-redux";
import { ISettingsListProps } from "./~types/SettingsListProps";
import {settingsSelector} from "../../~store/selector";
import { updateCreatingSetting, changeSearchString, changeSearchType, createSetting, deleteSettingById, getAllSettings, saveSetting } from '../../~store/actions/actions';
import SettingItem from "./setting-item/SettingItem";

import "./SettingsList.css";
import { ISetting, SettingType } from '../../~store/models/setting';
import { Button, Input } from 'reactstrap';
import { ISearchingTag, TagName, TagType } from 'src/app/shared/components/tag-searching-input/~types/models/SearchingTag';
import TagSearchingInput from 'src/app/shared/components/tag-searching-input/TagSearchingInput';
import { useState } from 'react';
import i18n from "src/app/shared/localization/i18n";


type ReduxType = ReturnType<typeof mapStateToProps> & ISettingsListProps;

const SettingsList = (props: ReduxType) => {

    const [tags, setTags] = useState<ISearchingTag[]>([]);

    useEffect(() => {
        props.getAllSettings();
        _onClearSearchSetting();
    }, []);

    const _onDeleteButtonClick = (setting: ISetting) => {
        if(window.confirm(i18n.t('settings.confirmDeleteSetting') + ` ${setting.id}?`)) {
            props.deleteSettingById(setting.id);
        }
    }

    const _onCreateSettingClick = () => {
        _onClearSearchSetting();
        props.createSetting();
    }

    const _onSearchSetting = (tags: ISearchingTag[]) => {
        tags.filter(val => val.tagName === TagName.Setting && val.tagType === TagType.ClosingTag).forEach(el => el.externalValue = ConvertFromFilterToType(el.value));
        _onClearSearchSetting();

        setTags(tags);

        const type = tags.find(t => t.id === 3);
        const searchString = tags.find(t => t.id === 4);

        if (type) {
            props.changeSearchType(SettingType[type.externalValue as keyof typeof SettingType]);
        }

        if(searchString)
        {
            props.changeSearchString(searchString.value);
        }
    }

    const _onClearSearchSetting = () => {
        setTags([]);
        props.changeSearchType(undefined);
        props.changeSearchString("");
    }

    const _isCreatingMode = props.settings.findIndex(s => s.isCreating) > -1;

    const _mapSettingsListTable = () => {
        return (
            <div className="setting-list-container">
                <table className='settings-list-table card-table'>
                    <thead>
                        <tr><th>{i18n.t('common.name')}</th><th>{i18n.t('common.type')}</th><th></th></tr>
                    </thead>
                    <tbody>
                        {props.settings.map((item) => (
                            <tr key={item.key}>
                                <td>
                                    <div className="setting-id-container">
                                        {item.isCreating ? 
                                            <Input 
                                                value={item.id}
                                                placeholder={i18n.t('settings.settingId')}
                                                onChange={e => { item.id = e.currentTarget.value; props.updateCreatingSetting(item) }}
                                                autoFocus={true}
                                            /> :
                                            item.id
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div className="setting-type-container">
                                        {item.isCreating ? 
                                            <Input
                                                type="select"
                                                className="setting-body__setting-type"
                                                value={SettingType[item.settingTypeModel]}
                                                onChange={e => {item.settingTypeModel = SettingType[e.currentTarget.value as keyof typeof SettingType]; props.updateCreatingSetting(item); }}
                                                >
                                                <option value={SettingType[SettingType.System]}>{i18n.t('settings.system')}</option>
                                                <option value={SettingType[SettingType.Integration]}>{i18n.t('settings.integration')}</option>
                                            </Input> :
                                            settingTypeLocalize(SettingType[item.settingTypeModel])
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div className="setting-buttons-container">
                                        {!item.isCreating && <SettingItem
                                            setting={item}
                                            onSettingSave={props.saveSetting}
                                        />}
                                        {!item.isCreating && <Button
                                            className="setting-delete-button"
                                            onClick={() => {_onDeleteButtonClick(item)}}>
                                            <i className="material-icons">
                                                delete_forever
                                            </i>    
                                        </Button>}
                                        {item.isCreating && <Button
                                            className="setting-save-button"
                                            onClick={() => {props.saveSetting(item)}}>
                                            <i className="material-icons">
                                                save
                                            </i>    
                                        </Button>}
                                    </div>
                                    </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div className="settings-list">
            <div>
                <Button
                    className="setting-create-button"
                    onClick={() => {_onCreateSettingClick()}}
                    disabled= {props.settings.findIndex(s => s.isCreating) > -1}>
                    {i18n.t('settings.createSettings')}
                </Button>
                {props.settings.findIndex(s => s.isCreating) > -1 && <Button
                    className="setting-cancel-button"
                    onClick={() => {props.updateCreatingSetting(undefined)}}>
                    {i18n.t('common.cancel')}
                </Button>}
                <div className="setting-search-string">

                {!_isCreatingMode && <TagSearchingInput
                            searchingTags={[
                                { 
                                    id: 1,
                                    value: i18n.t('common.type'),
                                    suggestions: ["2", "3"],
                                    tagType: TagType.OpeningTag,
                                },
                                { 
                                    id: 2,
                                    value: "=",
                                    tagType: TagType.OperationTag,
                                },
                                {
                                    id: 3,
                                    tagName: TagName.Setting,
                                    value: "",
                                    suggestions: [
                                        i18n.t('settings.integration'),
                                        i18n.t('settings.system')
                                    ],
                                    tagType: TagType.ClosingTag,
                                },
                            ]}
                            currentTags={tags}
                            onSearch={_onSearchSetting}
                            onClearFilter={_onClearSearchSetting}
                            repeatingTags={false}/>}
                </div>
            </div>
            <div className="settings-list__table">
                {_mapSettingsListTable()}
            </div>
        </div>
    );
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        settings: settingsSelector(state)
    }
}

const mapDispatchToProps = (dispatch: any): ISettingsListProps => {
    return {
        getAllSettings: () => dispatch(getAllSettings()),
        saveSetting: (setting: ISetting) => dispatch(saveSetting(setting)),
        createSetting: () => dispatch(createSetting()),
        updateCreatingSetting: (setting: ISetting | undefined) => dispatch(updateCreatingSetting(setting)),
        deleteSettingById: (id: string) => dispatch(deleteSettingById(id)),
        changeSearchString: (value: string) => dispatch(changeSearchString(value)),
        changeSearchType: (type: SettingType | undefined) => dispatch(changeSearchType(type))
    }
}

const settingTypeLocalize = (type: any): string => {
    let result = "";
    switch (type) {
        case "Integration":
            result = i18n.t('settings.integration')
            break
        case "System":
            result = i18n.t('settings.system')
            break
    }
    return result;
}
const ConvertFromFilterToType = (type: any): any =>{
    let result = "";
    switch (type) {
        case i18n.t('settings.integration'):
            result = "Integration"
            break
        case i18n.t('settings.system'):
            result = "System"
            break
    }
    return result;

}
export default connect(mapStateToProps, mapDispatchToProps)(SettingsList);