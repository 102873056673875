import { IMessage } from "./Message";
import { IContact } from "src/app/contact/~store/models/Contact";
import { IOperator } from "src/app/operator/~store/models/Operator";
import { IChannel } from "src/app/channel/~store/models/Channel";
import { IMessenger } from "src/app/contact/~store/models/Messenger";
import { ChannelType } from "src/app/channel/~store/models/enums/ChannelType";

export interface IChat {
    chatId: number,
    operatorId?: number,
    operator?: IOperator,
    channelId: number,
    channel?: IChannel,
    channelType: ChannelType;
    messengerId: number,
    messenger: IMessenger,
    isClosed: boolean,
    onPause: boolean,
    lastPausedTime: Date,
    isNew: boolean,
    timeCreated: Date,
    timeClosed: Date,
    messages: IMessage[],
    lastMessage: IMessage,
    invitedOperatorsId: number[],
    notAnsweredClientMessagesCount: number,
    firstNotAnsweredMessage: IMessage,
    chatStatsModel?: IChatStatsModel
}

export interface IChatStatsModel {
    status?: ChatStatus
}

export enum ChatStatus {
    Missed = 0,
    Expired = 1,
    Finished = 2
}