import { IApplicationState } from "src/~store/models/ApplicationState";
import { createSelector } from "reselect";
import ContactItemInfo from "../contact-main/contact-list/contact-item/contact-item-info/ContactItemInfo";

const contactSelector = (state: IApplicationState) => state.contactState;
const chatItemsSelector = (state: IApplicationState) => state.chatItemsState;

// contact list
const contactListSelector = createSelector(
    contactSelector, 
    contactState => contactState.contactList
);

export const isReceivingContactsSelector = createSelector(
    contactListSelector, 
    (contactList) => contactList.isReceivingContacts
);

export const contactsSelector = createSelector(
    contactListSelector, 
    (contactList) => contactList.contacts
);

export const isContactCreatingSelector = createSelector(
    contactListSelector, 
    (contactList) => contactList.isContactCreating
);

export const countOfContactsSelector = createSelector(
    contactListSelector, 
    (contactList) => contactList.countOfContacts
);

export const searchStringSelector = createSelector(
    contactListSelector,
    (contactList) => contactList.searchString,
);

export const isSortingByContactLastNameSelector = createSelector(
    contactListSelector,
    contactList => contactList.isSortingByContactLastName,
);

// contact item info
const contactItemInfoSelector = createSelector(
    contactListSelector, 
    (contactList) => contactList.contactItemInfo
);

export const selectedContactSelector = createSelector(
    contactItemInfoSelector, 
    (contactItemInfo) => contactItemInfo.selectedContact
);

export const isReceivingContactSelector = createSelector(
    contactItemInfoSelector, 
    (contactItemInfo) => contactItemInfo.isReceivingContact
);

export const changedContactSelector = createSelector(
    contactItemInfoSelector,
    contactItemInfo => contactItemInfo.changedContact
);

export const contactToMergeSelector = createSelector(
    contactItemInfoSelector,
    contactItemInfo => contactItemInfo.contactToMerge ?? undefined
);

export const isRestoringContactSelector = createSelector(
    contactItemInfoSelector,
    contactItemInfo => contactItemInfo.isRestoringContact,
);

export const isChatVisibleSelector = createSelector(
    chatItemsSelector,
    chatItems => chatItems.isChatVisible,
);