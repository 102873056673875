import * as React from 'react';
import {Input, Button, Tooltip, Label} from 'reactstrap';

import {IOperatorItemProps} from './~types/OperatorItemProps';
import {IOperatorItemState} from './~types/OperatorItemState';

import {IOperator} from 'src/app/operator/~store/models/Operator';
import ChangableImage from 'src/app/shared/components/changable-image/ChangableImage';
import {OperatorType, operatorTypeToString} from 'src/app/operator/~store/models/enums/OperatorType';
import {
    MIN_LOGIN_LENGTH,
    MIN_PASSWORD_LENGTH,
    MAX_LOGIN_LENGTH,
    MAX_PASSWORD_LENGTH,
    MASKED_PASSWORD
} from './OperatorItemConstants';

import {showErrorToastr} from 'src/app/shared/helpers/toastr-helper/ToastrHelper';

import './OperatorItem.css';
import {GET_AVATAR_BY_ID_API_ROUTE} from 'src/app/operator/~api/apiRoutes';
import {DEFAULT_OPERATOR_AVATAR, DEFAULT_OPERATOR_AVATAR_ID} from 'src/app/operator/~store/models/ModelsConstants';
import { OperatorStatusType } from 'src/app/chats-panel/~store/state/ChatItemsState';
import ChooseFileDialog, { FileType } from 'src/app/shared/components/choose-file-dialog/ChooseFileDialog';
import SettingsItem from 'src/app/setting/settings-main/settings-list/setting-item/SettingItem';
import { FIRST_PAUSE_CATEGORY_ID } from 'src/app/chats-panel/~store/actions/ChatConstants';
import i18n from "src/app/shared/localization/i18n";

export class OperatorItem extends React.Component<IOperatorItemProps, IOperatorItemState> {
    
    public readonly state: IOperatorItemState = {
        name: this.props.operator.name,
        displayName: this.props.operator.displayName,
        login: this.props.operator.login,
        password: this.props.operator.password,
        isDeleted: this.props.operator.isDeleted,
        type: this.props.operator.type,
        description: this.props.operator.description,
        enabled: this.props.operator.enabled,
        isOperatorSettingOpen: false,
        filePicker: React.createRef<HTMLInputElement>(),
        isNameTooltipOpen: false,
        nameTooltipMessage: '',
        isNameInvalid: false,
        isPasswordTooltipOpen: false,
        passwordTooltipMessage: '',
        isPasswordInvalid: false,
        isLoginTooltipOpen: false,
        loginTooltipMessage: '',
        isLoginInvalid: false,
        isNew: !this.props.operator.operatorId ? true : false,
        scriptFile: undefined,
        pauseCategories: []
    }
    
    public render() {
        const userInStorage = sessionStorage.getItem("user");
        const customerId = userInStorage === null ? "" : JSON.parse(userInStorage.toString()).customerId;
        const imageUrl = 
            this.props.operator.avatarId !== DEFAULT_OPERATOR_AVATAR_ID ? 
            GET_AVATAR_BY_ID_API_ROUTE + this.props.operator.avatarId + "&customerId=" + customerId : 
            '';
        const canChangeEnabledStatus = (userInStorage !== null && JSON.parse(userInStorage.toString()).login !== this.state.login) ? false : true;

        return(
            <React.Fragment>
                <tr className="operator-item">
                    <td className="operator-item-avatar-status">
                        <ChangableImage 
                            src={imageUrl}
                            className={"operator-item__operator-avatar " + OperatorStatusType[((this.props.status === OperatorStatusType.Online || this.props.status === OperatorStatusType.Offline) ? this.props.status : OperatorStatusType.Pause)].toLowerCase()}
                            onImageChange={this._onImageChangeHandler} 
                        />
                        <div className="operator-item__status">{(this.props.status === OperatorStatusType.Online || this.props.status === OperatorStatusType.Pause || this.props.status === OperatorStatusType.Offline) ? 
                                                                OperatorStatusType[this.props.status] :
                                                                this.props.pauseCategories[this.props.status - FIRST_PAUSE_CATEGORY_ID]}</div>
                    </td>
                    <td className="operator-item__input_cell operator-item__name">
                        <Input
                            id={"name" + this.props.operator.operatorId}
                            className="operator-item__operator-name"
                            placeholder={i18n.t('operators.name')}
                            type={"text"}
                            value={this.state.name}
                            onChange={this._onNameChangeHandler}
                            invalid={this.state.isNameInvalid}
                            onMouseEnter={this._onNameInputMouseEnterHandler}
                            onMouseLeave={this._onNameInputMouseLeaveHandler}
                            autoFocus={this.props.operator.operatorId === 0} />
                        <Tooltip 
                            placement="bottom-start" 
                            isOpen={this.state.isNameTooltipOpen} 
                            target={"name" + this.props.operator.operatorId}>
                                {this.state.nameTooltipMessage}
                        </Tooltip>
                    </td>
                    <td className="operator-item__input_cell operator-item__name">
                        <Input
                            id={"display-name" + this.props.operator.operatorId}
                            className="operator-item__operator-name"
                            placeholder={i18n.t('operators.displayName')}
                            type={"text"} 
                            value={this.state.displayName} 
                            onChange={this._onDisplayNameChangeHandler}/>
                    </td>
                    <td className="operator-item__input_cell operator-item__type">
                        <Input
                            type="select"
                            className="opertor-item__operator-type"
                            value={operatorTypeToString(this.state.type)}
                            onChange={this._onTypeChangeHandler}>
                            <option>{operatorTypeToString(OperatorType.Bot)}</option>
                            <option>{operatorTypeToString(OperatorType.Operator)}</option>
                            <option>{operatorTypeToString(OperatorType.Supervisor)}</option>
                        </Input>
                    </td>
                    <td colSpan={(this.state.type === OperatorType.Bot) ? (2) : (0)}
                        className="operator-item__input_cell operator-item__login">
                        <div className="operator-item__input" style={this.state.type === OperatorType.Bot && this.props.operator.operatorId !== 0 ? {} : { display: 'none' }}>
                            <Label className="operator-item__upload-script-label">
                                <Input id={`operator-item__upload-script--${this.props.operator.operatorId}`} className="operator-item__input-file-button" type="file" accept=".zip" onChange={event => {this._onFileChosen(event.target.files?.item(0) ?? {} as File, FileType.File)}}/>
                                {i18n.t('operators.uploadScript')}
                            </Label>
                            <div className="uploaded-script-name">{this.state.scriptFile?.name ?? this.props.operator.bot?.scriptFileName ?? "file not choosen"}</div>
                        </div>
                        
                        <div style={this.state.type !== OperatorType.Bot ? {} : { display: 'none' }}>
                            <Label className="operator-item__label">{i18n.t('common.login')}</Label>
                            <Input
                                id={"login" + this.props.operator.operatorId}
                                className="operator-item__operator-login"
                                placeholder={i18n.t('common.login')}
                                type={"text"}
                                value={this.state.login}
                                onChange={this._onLoginChangeHandler}
                                invalid={this.state.isLoginInvalid}
                                onMouseEnter={this._onLoginInputMouseEnterHandler}
                                onMouseLeave={this._onLoginInputMouseLeaveHandler} />
                            <Tooltip
                                placement="bottom-start"
                                isOpen={this.state.isLoginTooltipOpen}
                                target={"login" + this.props.operator.operatorId}>
                                {this.state.loginTooltipMessage}
                            </Tooltip>
                        </div>
                    </td>
                    <td style={this.state.type !== OperatorType.Bot ? {} : { display: 'none' }}
                        className="operator-item__input_cell operator-item__password">
                        <Label className="operator-item__label">{i18n.t('common.password')}</Label>
                        <Input
                            id={"password" + this.props.operator.operatorId}
                            className="operator-item__operator-password"
                            placeholder={i18n.t('common.maskedPassword')}
                            readOnly
                            onFocus={() => document.querySelector("#password" + this.props.operator.operatorId)?.removeAttribute('readonly')}
                            type={"password"}
                            defaultValue={this.props.operator.operatorId !== 0 ? MASKED_PASSWORD : ""}
                            value={this.state.password}
                            onChange={this._onPasswordChangeHandler}
                            invalid={this.state.isPasswordInvalid}
                            onMouseEnter={this._onPasswordInputMouseEnterHandler}
                            onMouseLeave={this._onPasswordInputMouseLeaveHandler}/>
                        <Tooltip
                            placement="bottom-start"
                            isOpen={this.state.isPasswordTooltipOpen}
                            target={"password" + this.props.operator.operatorId}>
                            {this.state.passwordTooltipMessage}
                        </Tooltip>
                    </td>
                    <td className="operator-item__input_cell operator-item__enabled">
                        <Label check={this.state.enabled}>
                            <Input
                                className="operator-item__checkbox-is-enabled"
                                type="checkbox"
                                name={"checkbox" + this.props.operator.operatorId}
                                checked={this.state.enabled}
                                onChange={this._onEnabledChangeHandler} 
                                disabled={canChangeEnabledStatus} />
                            <span
                                className="operator-item__operator-is-enabled">
                                {""}
                            </span>
                        </Label>
                    </td>
                    <td className="operator-item__operator-actions">
                        {this.state.isNew ? this._getButtonsForNewOperator() : this._getButtonsForOldOperator()}
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    private _getButtonsForNewOperator = (): React.ReactElement<{}> => {
        return (
            <React.Fragment>
                <Button className="operator-item__btn"
                    id="save-operator-button"
                    onClick={this._onSaveOperatorHandler}>
                    <i className="material-icons">
                        save
                    </i>
                </Button>
                <Button className="operator-item__button operator-item__button-discard" onClick={this._onDiscardCreatingHandler}>
                    <i className="material-icons">replay</i>
                </Button>
            </React.Fragment>
            );
    };

    private readonly _getButtonsForOldOperator = (): React.ReactElement<{}> => {
        return (
            <React.Fragment>
                {(this.props.operator.operatorId !== undefined) && 
                    <SettingsItem 
                        setting={this.props.operator.operatorSetting}
                        onSettingSave={setting => this._onSaveOperatorSettingsHandler(setting.value)}/>}
                <Button
                    className="operator-item__btn"
                    id="save-operator-button"
                    onClick={this._onSaveOperatorHandler}>
                    <i className="material-icons">
                        save
                    </i>
                </Button>
                <Button
                    className="operator-item__btn-delete operator-item__btn"
                    onClick={this._onDeleteOperatorHandler}>
                    <i className="material-icons">
                        delete_forever
                    </i>
                </Button>
            </React.Fragment>
            );
    };

    private _onFileChosen = (file: File, type: FileType): void => {
        this.setState({...this.state, scriptFile: file});
    }

    private _onDiscardCreatingHandler = (): void => {
        window.location.reload();
    }

    private _onSaveOperatorHandler = (): void => {
        if(this.state.password?.length < MIN_PASSWORD_LENGTH && this.state.type !== OperatorType.Bot) {
            showErrorToastr(i18n.t('operators.minLengthPasswordMessage') + MIN_PASSWORD_LENGTH);
            return;
        }
        if (this.state.password?.length > MAX_PASSWORD_LENGTH && this.state.type !== OperatorType.Bot) {
            showErrorToastr(i18n.t('operators.maxLengthPasswordMessage') + MAX_PASSWORD_LENGTH);
            return;
        }
        if(this.state.login?.length < MIN_LOGIN_LENGTH && this.state.type !== OperatorType.Bot) {
            showErrorToastr(i18n.t('operators.minLengthLoginMessage') + MIN_LOGIN_LENGTH);
            return;
        }
        if (this.state.login?.length > MAX_LOGIN_LENGTH && this.state.type !== OperatorType.Bot) {
            showErrorToastr(i18n.t('operators.maxLengthLoginMessage') + MAX_LOGIN_LENGTH);
            return;
        }
        if(this.state.login?.indexOf(' ') !== -1) {
            showErrorToastr(i18n.t('operators.spaceInLoginErrorMessage'));
            return;
        }
        if(this.state.name?.length  === 0) {
            showErrorToastr(i18n.t('operators.emptyNameTooltipMessage'));
            return;
        }
        if (!(/^[A-Za-z0-9][A-Za-z0-9_.-]*$/).test(this.state.login) 
        && !this.state.login?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.[A-Za-z0-9-_.]+)+$/)
        && this.state.type !== OperatorType.Bot) {
            showErrorToastr(i18n.t('operators.incorrectLoginMessage'));
            return;
        }
        if(this.state.login?.length > MAX_LOGIN_LENGTH && this.state.type !== OperatorType.Bot) {
            showErrorToastr(i18n.t('operators.maxLengthLoginMessage') + MAX_LOGIN_LENGTH);
            return;
        }
        if(this.state.password?.length > MAX_PASSWORD_LENGTH && this.state.type !== OperatorType.Bot) {
            showErrorToastr(i18n.t('operators.maxLengthPasswordMessage') + MAX_PASSWORD_LENGTH);
            return;
        }
        const oldOperator: IOperator = this.props.operator;
        const newOperator: IOperator = {
            avatarId: this.props.operator.avatarId,
            operatorId: this.props.operator.operatorId,
            displayName: this.state.displayName,
            name: this.state.name,
            login: this.state.login,
            password: this.state.password,
            isDeleted: this.state.isDeleted,
            type: this.state.type,
            description: this.state.description,
            enabled: this.state.enabled,
            modifiedOn: new Date(),
            operatorSetting: this.props.operator.operatorSetting,
        }
        this.props.onSaveOperator?.(oldOperator, newOperator, this.state.scriptFile);
    }

    private _onDeleteOperatorHandler = (): void => {
        this.props.onDeleteOperator?.(this.props.operator);
    }

    private _onSaveOperatorSettingsHandler = (settings: string): void => {
        if(this.props.operator.operatorId === undefined) {
            showErrorToastr(i18n.t('operators.saveOperatorSettingsNullIdMessage'));
            return;
        }
        this.props.onSaveOperatorSetting?.(this.props.operator, settings);
        this._toggleOperatorSettingsVisibility();
    }

    private _toggleOperatorSettingsVisibility = (): void => {
        const isOperatorSettingsOpen = !this.state.isOperatorSettingOpen;
        this.setState({isOperatorSettingOpen: isOperatorSettingsOpen});
    }

    private _onDisplayNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const displayName = event.currentTarget.value;

        this.setState({displayName});
    }

    private _onNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const name = event.currentTarget.value;
        let isNameInvalid: boolean = false;

        if(name === '') {
            isNameInvalid = true;
            const nameTooltipMessage: string = i18n.t('operators.emptyNameTooltipMessage');
            this.setState({nameTooltipMessage});
        }

        this.setState({name, isNameInvalid});
    }

    private _onLoginChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const login = event.currentTarget.value;
        let isLoginInvalid: boolean = false;
        if(login.length < MIN_LOGIN_LENGTH) {
            isLoginInvalid = true;
            const loginTooltipMessage: string = i18n.t('operators.minLengthLoginMessage') + MIN_LOGIN_LENGTH;
            this.setState({loginTooltipMessage});
        } else if(login.length > MAX_LOGIN_LENGTH && this.state.type !== OperatorType.Bot) {
            isLoginInvalid = true;
            const loginTooltipMessage: string = i18n.t('operators.maxLengthLoginMessage') + MAX_LOGIN_LENGTH;
            this.setState({loginTooltipMessage});
        }
        else if(!(/^[A-Za-z0-9][A-Za-z0-9_.-]*$/).test(login) && !login?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.[A-Za-z0-9-_.]+)+$/)) {
            isLoginInvalid = true;
            const loginTooltipMessage: string = i18n.t('operators.incorrectLoginMessage');
            this.setState({loginTooltipMessage});
        }

        this.setState({login, isLoginInvalid});
    }

    private _onPasswordChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const password = event.currentTarget.value;
        let isPasswordInvalid: boolean = false;
        if(password.length < MIN_PASSWORD_LENGTH) {
            isPasswordInvalid = true;
            const passwordTooltipMessage: string = i18n.t('operators.minLengthPasswordMessage') + MIN_PASSWORD_LENGTH;
            this.setState({passwordTooltipMessage});
        } else if (password.length > MAX_PASSWORD_LENGTH) {
            isPasswordInvalid = true;
            const passwordTooltipMessage: string = i18n.t('operators.maxLengthPasswordMessage') + MAX_PASSWORD_LENGTH;
            this.setState({passwordTooltipMessage});
        }

        this.setState({password, isPasswordInvalid});
    }

    private _onTypeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const opType: string = event.currentTarget.value;
        const type: OperatorType = OperatorType[opType as keyof typeof OperatorType];
        this.setState({type});
    }

    private _onImageChangeHandler = (imageFile: File): void => {
        if(this.props.operator.operatorId === undefined) {
            showErrorToastr(i18n.t('operators.changeAvatarNullIdErrorMessage'));
            return;
        }

        this.props.onChangeOperatorAvatar?.(imageFile, this.props.operator, this._onAvatarChangeSuccessHandler);
    }

    private _onAvatarChangeSuccessHandler = (avatarId: string | null): void => {
        this.props.operator.avatarId = avatarId;
        this.forceUpdate();
    } 

    private _onNameInputMouseEnterHandler = (): void => {
        if(this.state.isNameInvalid) {
            const isNameTooltipOpen: boolean = true;
            this.setState({isNameTooltipOpen});
        }
    }

    private _onNameInputMouseLeaveHandler = (): void => {
            const isNameTooltipOpen: boolean = false;
            this.setState({isNameTooltipOpen});
    }

    private _onLoginInputMouseEnterHandler = (): void => {
        if(this.state.isLoginInvalid) {
            const isLoginTooltipOpen: boolean = true;
            this.setState({isLoginTooltipOpen});
        }
    }
    private _onLoginInputMouseLeaveHandler = (): void => {
            const isLoginTooltipOpen: boolean = false;
            this.setState({isLoginTooltipOpen});
    }

    private _onPasswordInputMouseEnterHandler = (): void => {
        if(this.state.isPasswordInvalid) {
            const isPasswordTooltipOpen: boolean = true;
            this.setState({isPasswordTooltipOpen});
        }
    }
    private _onPasswordInputMouseLeaveHandler = (): void => {
        const isPasswordTooltipOpen: boolean = false;
        this.setState({isPasswordTooltipOpen});
    }

    private _onDescriptionChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const description = event.currentTarget.value;

        this.setState({ description });
    }

    private _onEnabledChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const enabled = event.currentTarget.checked;
        this.setState({ enabled });
    }
}
