import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "src/~store/models/ApplicationState";
import { IPermissionsRolesListProps } from "./~types/PermissionsRoleListProps";

import './PermissionRoleList.css';
import { ModalWindow } from "src/app/shared/components/modal-window/ModalWindow";
import { getAllPermissions, getRolesPermissions, saveRolePermissions } from "../../../~store/actions/actions";
import { allPermissionsSelector, rolesPermissionsSelector } from "../../../~store/selector";
import { IRole } from "../../../~store/models/IRole";
import { Checkbox } from "@mui/material";
import { Button } from "reactstrap";
import IPermissionViewModel from "../../../~store/models/PermissionViewModel";
import { IRolePermissions } from "../../../~store/models/RolePermissions";
import { useTranslation } from 'react-i18next';

type ReduxType = ReturnType<typeof mapStateToProps> & IPermissionsRolesListProps;

const PermissionsRoleList = (props: ReduxType) => {

    const { t } = useTranslation();
    const [isEditorOpened, setIsEditorOpened] = useState<boolean>(false);
    const [editingRolePermissions, setEditingRolePermissions] = useState<IRolePermissions>();

    useEffect(() => {
        props.getRolesPermissions(Object.values(IRole).filter(x => parseInt(x as string) >= 0) as IRole[]);
    }, [])

    const _onBlockClicked = (role: IRole) => {
        const rolePermissionsToEdit = props.rolesPermissions.find(rp => rp.role === role);
        if(rolePermissionsToEdit) {
            setIsEditorOpened(true);
            setEditingRolePermissions({...rolePermissionsToEdit});
        }
    }

    const _onPermissionClicked = (value: boolean, permission: IPermissionViewModel) => {
        let permissions = editingRolePermissions?.permissions?.filter(p => p != permission.name);
        if(editingRolePermissions && permissions) {
            if(value) {
                permissions = permissions.concat([permission.name])
            }

            editingRolePermissions.permissions = permissions;
            setEditingRolePermissions({...editingRolePermissions});
        }
    }

    const _onSavePermissionsClicked = () => {
        if(editingRolePermissions) {
            props.saveRolePermissions(editingRolePermissions);
            setIsEditorOpened(false);
        }
    }

    const _mapPermissionEditor = () => {
        if(!editingRolePermissions) return <></>;
        const editingRole = IRole[editingRolePermissions?.role];
        const header = (
            <div>{t('permission.editPermissionsFor') + editingRole}</div>
        )

        const body = (
            <div>{props.allPermissions.map((item, index) => {
                const checked = editingRolePermissions.permissions.includes(item.name) || false;
                return (
                    <div key={index}>
                        <span><Checkbox onClick={() => _onPermissionClicked(!checked, item)} checked={checked} /></span><span>{item.description}</span></div>
                );
            })}</div>
        )

        const footer = (
            <div className="permission-dialog-footer-container">
                <Button onClick={_onSavePermissionsClicked}>{t('common.save')}</Button>
                <Button className="permission-dialog-cancel" onClick={() => { setIsEditorOpened(false) }}>{t('common.cancel')}</Button>
            </div>
        )
        return (
            <ModalWindow header={header} body={body} footer={footer} isOpen={isEditorOpened}/>
        )
    }

    const _mapRolesPermissions = () => {
        return (
            <>
                {Object.values(IRole).filter(x => !(parseInt(x as string) >= 0)).map((item) => {
                    const role = IRole[item as keyof typeof IRole]
                    return (
                        <div key={item} onClick={() => {_onBlockClicked(role)}} className="permission_block line-clamp">
                            <h6>{item}</h6>
                            <span className="permission-list">{props.rolesPermissions.find(rp => rp.role == role)
                                ?.permissions.map(p => props.allPermissions.find(ap => ap.name == p)?.description + "; ")}</span>
                        </div>
                    )
                })}
            </>
        )
    }

    return (
        <div className="permissions_roles-container">
            {_mapRolesPermissions()}
            {_mapPermissionEditor()}
        </div>
    )
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        allPermissions: allPermissionsSelector(state),
        rolesPermissions: rolesPermissionsSelector(state)
    };
};

const mapDispatchToProps = (dispatch: any): IPermissionsRolesListProps => {
    return {
        getRolesPermissions: (roles: IRole[]) => dispatch(getRolesPermissions(roles)),
        saveRolePermissions: (rolePermissions: IRolePermissions) => dispatch(saveRolePermissions(rolePermissions))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsRoleList);