import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ClickAwayListener } from '@material-ui/core';

import './QuickAnswerDialog.css';
import { IQuickAnswer } from "src/app/chats-panel/~store/models/QuickAnswer";

interface IQuickAnswerDialogProps {
    quickAnswers: IQuickAnswer[],
    onQuickAnswerClick(quickAnswer: string): void,
    clearQuickAnswer(): void,
    removeQuickAnswer(quickAmswer: string): void,
    onBlurInput(): void,
    onFocusInput(): void
}

export interface QuickAnswerDialogRef {
    onUpArrow() : void,
    onDownArrow() : void,
    onChooseQuickAmswer(): void
}

const QuickAnswerDialog = forwardRef<QuickAnswerDialogRef, IQuickAnswerDialogProps>((props, ref)  => {

    const containerRef = useRef<HTMLDivElement>(null);

    const [selectedAnswer, setSelectedAnswer] = useState(props.quickAnswers.length);

    useEffect(() => {
        setSelectedAnswer(props.quickAnswers.length);
    }, [props.quickAnswers.length])

    useImperativeHandle(ref, () => ({
        onUpArrow() {
          if(selectedAnswer > 0) {
            if(selectedAnswer === props.quickAnswers.length) {
                props.onBlurInput();
                containerRef.current?.focus();
            }
            setSelectedAnswer(selectedAnswer - 1);
          }
        },
        onDownArrow() {
            if(selectedAnswer < props.quickAnswers.length) {
                if(selectedAnswer === props.quickAnswers.length - 1) {
                    props.onFocusInput();
                    containerRef.current?.blur();
                }
                setSelectedAnswer(selectedAnswer + 1);
                
            }
        },
        onChooseQuickAmswer() {
            if(props.quickAnswers.length > 0 && selectedAnswer < props.quickAnswers.length) {
                props.onQuickAnswerClick(props.quickAnswers[selectedAnswer].answerText);
            }
        }
      }));
    return (
        <ClickAwayListener onClickAway={() => {props.clearQuickAnswer()}}>
            <div className={props.quickAnswers.length > 0 ? "quick-answer-container" : ""}>
                {props.quickAnswers.map( (item, index) => {
                    return <div className="quick-answer" key={index}>
                        <span className="quick_answer__counter">{item.counter}</span> 
                        <span className={"quick_answer__text " + (index === selectedAnswer ? "selected" : "")} onClick={() => {props.onQuickAnswerClick(item.answerText)}}>{item.answerText}</span>
                        <span className="quick_answer__close" onClick={() => props.removeQuickAnswer(item.answerText)}>x</span>
                        </div>
                })}
            </div>
        </ClickAwayListener>
    )
});

export default QuickAnswerDialog;