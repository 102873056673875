import * as React from 'react';
import SettigsList from './settings-list/SettingsList';

import './SettingsMain.css';
import { useTranslation } from 'react-i18next';

const SettingsMain = () => {
    const { t } = useTranslation();
    return(
        <div className="settins-main">
            <div className="settings-main__header">{t('common.settings')}</div>
            <SettigsList />
        </div>
    )
}

export default SettingsMain;