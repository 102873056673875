import React from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ISuggestionsListProps } from './~types/SuggestionsListProps';
import Suggestion from './suggestion/Suggestion';

import './SuggestionsList.css';
import { TagType } from '../~types/models/SearchingTag';

const SuggestionsList = (props: ISuggestionsListProps) => {
    let searchString: string = "";
    return (
        <Dropdown
            isOpen={props.isSuggestionsListVisible}
            toggle={() => void 0}
            direction="down">
            <DropdownToggle className="suggestions-list__toggle">
            </DropdownToggle>
            {props.isSuggestionsListVisible &&
                <DropdownMenu className={`suggestions-list__menu ${props.suggestions?.[0]?.tagType === TagType.ClosingTag && 'closing-suggestions'}`}>
                    {props.suggestions
                        .filter(suggestion => {
                            switch(suggestion.tagType) {
                                case TagType.OperationTag:
                                case TagType.OpeningTag: {
                                    return suggestion.value.toLowerCase().includes(props.searchString.toLowerCase());
                                }

                                case TagType.ClosingTag:
                                    searchString = props.searchString;
                                default: {
                                    return true;
                                }
                            }
                        })
                        .map((s, i) => {
                        return (
                            <Suggestion
                                operatorClassName="suggestions-list__suggestion-operator"
                                className="suggestions-list__suggestion"
                                suggestion={s}
                                searchString={searchString}
                                searchingTag={props.searchingTag}
                                onSelected={(suggestion) => {
                                    props.onSelected(suggestion);
                                }}
                                key={i}
                                keyValue={i}
                                operators={props.operators}
                                contacts={props.contacts}
                                channels={props.channels}
                            />
                        );
                    })}
                </DropdownMenu>}
        </Dropdown>
    );
}

export default SuggestionsList;