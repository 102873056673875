import * as React from "react";
import {IMessageProps} from "./~types/MessageProps";

import "./Message.css";

export default class Message extends React.Component<IMessageProps> {
    public render() {
        return (
            <div className={this.props.className + " message"}>
                <div className="message__content">
                    {this.props.children}
                    <div className="message__created-date">{this.props.createdDate.toLocaleString()}</div>
                </div>
            </div>
        );
    }
}
