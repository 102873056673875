import * as React from "react";
import MDEditor from "@uiw/react-md-editor";
import {INlpAnswerTextInputProps} from "./~types/INlpAnswerTextInputProps";
import {INlpAnswerTextInputState} from "./~types/INlpAnswerTextInputState";
import "./NlpAnswerTextInput.css";

class NlpAnswerTextInput extends React.Component<INlpAnswerTextInputProps, INlpAnswerTextInputState> {
    public readonly state: Readonly<INlpAnswerTextInputState> = {
        element: this.props.innerRef ?? React.createRef<HTMLInputElement>(),
    };

    public componentDidMount() {
        this.resize();
    }

    public render() {
        const propsClassName = !!this.props.className ? this.props.className : "";
        const propsPlaceholder = !!this.props.placeholder ? this.props.placeholder : "";

        return (
            <MDEditor
                className="nlp-md-editor"
                height={200} 
                value={this.props.value} 
                onChange={this._onChangeHandler}
                previewOptions={{
                    linkTarget: "_blank",
                }}
                highlightEnable={false}
            />
        );
    }

    private _onChangeHandler = (event?: string): void => {
        this.props.onChange(event ?? "");
        this.resize();
    };

    public resize = () => {
        const element: HTMLInputElement | null = this.state.element.current;
        element?.style.setProperty("height", "auto");
        element?.style.setProperty("height", element?.scrollHeight + "px");
    };
}

export default NlpAnswerTextInput;