import {IApplicationState} from "src/~store/models/ApplicationState";
import {createSelector} from "reselect";

const channelSelector = (state: IApplicationState) => state.channelState;

// channel list
const channelListSelector = createSelector(channelSelector, (channelState) => channelState.channelList);

export const isReceivingChannelsSelector = createSelector(
    channelListSelector, 
    (channelList) => channelList.isReceivingChannels
);
export const isReceivingOperatorsSelector = createSelector(
    channelListSelector, 
    (channelList) => channelList.isReceivingOperators
);
export const channelsSelector = createSelector(
    channelListSelector, 
    (channelList) => channelList.channels.map((a) => a).sort((a, b) => {
        if(channelList.isSortingByChannelName) {
            return a.name?.localeCompare(b.name);
        }
        else {
            return new Date(b.modifiedOn).getTime() - new Date(a.modifiedOn).getTime()
        }
    })
);
export const operatorsSelector = createSelector(
    channelListSelector, 
    (channelList) => channelList.operators
);
export const isChannelCreatingSelector = createSelector(
    channelListSelector, 
    (channelList) => channelList.channels.findIndex(c => c.channelId === 0) > -1
);

export const isSortingByChannelNameSelector = createSelector(
    channelListSelector, 
    (channelList) => channelList.isSortingByChannelName
);
