import * as React from "react";
import {connect} from "react-redux";

import {IApplicationState} from "src/~store/models/ApplicationState";
import {ITransferQuestionPopupProps} from "./~types/TransferQuestionPopupProps";
import {transferQuestionPopupDataSelector, editedTopicPairsSelector, selectedTopicSelector, topicDefaultQuestionSelector} from "../../~store/selectors";
import {
    updateTargetTopicPairId,
    transferQuestionToTopicPair,
    clearTransferQuestionPopupData,
    toggleTransferQuestionPopupVisibility,
    getQuestionsByTopicName
} from "../../~store/actions/actions";

import {ModalWindow} from "src/app/shared/components/modal-window/ModalWindow";
import {Button, Input} from "reactstrap";

import "./TransferQuestionPopup.css";
import i18n from "src/app/shared/localization/i18n";
import { DefaultQuestionPairId } from "../../../shared/helpers/default-question-pair-id-helper/DefaultQuestionPairIdHelper";
import { Autocomplete, Popper, TextField } from "@material-ui/core";

type ReduxType = ReturnType<typeof mapStateToProps> & ITransferQuestionPopupProps;

class TransferQuestionPopup extends React.Component<ReduxType> {
    componentDidMount(): void {
        this.props.getQuestionsByTopicName(this.props.selectedTopic.name, "", DefaultQuestionPairId(""));
    }

    public render() {
        return <ModalWindow isOpen={true} className="transfer-question-popup" body={this._getBody()} header={this._getHeader()} footer={this._getFooter()} />;
    }
    private _onApplyHandler = (): void => {
        const {topicExternalId, topicPairId, targetTopicPairId, questionId} = this.props.popupData;

        if (targetTopicPairId) {
            this.props.transferQuestionToTopicPair(topicExternalId, topicPairId, targetTopicPairId, questionId);
            this._closePopup();
        }
    };

    private _onCancelHandler = (): void => {
        this._closePopup();
    };

    private _closePopup = (): void => {
        this.props.clearTransferQuestionPopupData();
        this.props.toggleTransferQuestionPopupVisibility();
    };

    private _getHeader = (): React.ReactElement<{}> => {
        const {popupData, editedTopicPairs} = this.props;

        const currentTopicPair = editedTopicPairs.find(etp => etp.topicPairId === popupData.topicPairId);
        const question = currentTopicPair!.questions.find(q => q.questionId === popupData.questionId);

        return (
            <React.Fragment>
                {" "}
                <div>{i18n.t('nlp.transferQuestion')}</div>
                <div className="transfer-question-popup__header-info">
                    ({i18n.t('nlp.move') + " "}
                    <span className="transfer-question-popup__header-bold">
                        #{popupData.topicPairId} {question!.text}
                    </span>{" " + i18n.t('nlp.to')})
                </div>
            </React.Fragment>
        );
    };

    private _onApply = (id: number) => {
        this.props.popupData.targetTopicPairId = id;
    }

    private _getBody = (): React.ReactElement<{}> => {
        return (
            <Autocomplete
                disablePortal
                id={"select-target-label"}
                options={this.props.topicDefaultQuestions.questions.map(el => {
                        return {
                            id: el.pairId, 
                            label: `#${el.pairId} - ${el.text}`
                        }
                    })
                }
                classes={{
                    input: "select-target-input",
                    option: "select-target-option"
                }}
                onChange={(event, newValue) => this._onApply(newValue?.id ?? 0)}
                renderInput={(params) => 
                    <TextField {...params} 
                        onChange={(searchString) => this._getQuestions(searchString.target.value)} 
                        label={i18n.t('nlp.selectTarget')} 
                    />
                }
            />
        );
    };

    private _getQuestions = (searchString: string) => {
        this.props.getQuestionsByTopicName(this.props.selectedTopic.name, searchString, DefaultQuestionPairId(searchString));
    }

    private _getFooter = (): React.ReactElement<{}> => {
        return (
            <React.Fragment>
                <Button className="transfer-question-popup__button transfer-question-popup__button-apply" onClick={this._onApplyHandler}>
                    {i18n.t('common.apply')}
                </Button>
                <Button className="transfer-question-popup__button transfer-question-popup__button-cancel" onClick={this._onCancelHandler}>
                    {i18n.t('common.cancel')}
                </Button>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        popupData: transferQuestionPopupDataSelector(state),
        editedTopicPairs: editedTopicPairsSelector(state),
        topicDefaultQuestions: topicDefaultQuestionSelector(state),
        selectedTopic: selectedTopicSelector(state)
    };
};

const mapDispatchToProps = (dispatch: any): ITransferQuestionPopupProps => {
    return {
        updateTargetTopicPairId: (targetTopicId: number) => dispatch(updateTargetTopicPairId(targetTopicId)),
        transferQuestionToTopicPair: (
            topicExternalId: string, 
            actualTopicPairId: number, 
            expectedTopicPairId: number,
            questionId: number
        ) => dispatch(transferQuestionToTopicPair(topicExternalId, actualTopicPairId, expectedTopicPairId, questionId)),
        clearTransferQuestionPopupData: () => dispatch(clearTransferQuestionPopupData()),
        toggleTransferQuestionPopupVisibility: () => dispatch(toggleTransferQuestionPopupVisibility()),
        getQuestionsByTopicName: (topicName: string, questionText: string, pairId: string) =>
            dispatch(getQuestionsByTopicName(topicName, questionText, pairId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferQuestionPopup);
