import * as React from "react";

import {ITopicEditorHeaderProps} from "./~types/TopicEditorHeaderProps";

import {Button} from "reactstrap";
import Loader from "src/app/shared/components/loader/Loader";
import {LoaderType} from "src/app/shared/components/loader/~types/LoaderType";

import "./TopicEditorHeader.css";
import i18n from "src/app/shared/localization/i18n";

const TopicEditorHeader = (props: ITopicEditorHeaderProps) => {
    const _onSynonymsHandler = (): void => {
        props.onSynonymsHandler();
    };

    const _onPublishQuestionsHandler = (): void => {
        props.onPublishQuestions();
    };

    const _onExportPairsHandler = (): void => {
        props.onExportPairs();
    };

    const _onImportPairsHandler = (): void => {
        props.onImportPairs();
    }

    return (
        <div className="topic-editor-header">
            <div className="topic-editor-header__content topic-editor-header__content-grow">
                <span className="topic-editor-header__identifier">{`#${props.topicId}`}</span>
                <span className="topic-editor-header__name">{props.topicName}</span>
            </div>

            <div className="topic-editor-header__content">
                <Button className="topic-editor-header__button_synonyms" onClick={_onSynonymsHandler}>
                    {i18n.t('common.synonyms')}
                </Button>
                <Button className="topic-editor-header__button_export_pairs" onClick={_onExportPairsHandler}>
                    {props.isExportingPairs ? <Loader type={LoaderType.Grow} customStyle={"custom-spinner"}/> : <span>{i18n.t('common.export')}</span>}
                </Button>
                <Button className="topic-editor-header__button-import-pairs" onClick={_onImportPairsHandler}>
                    {i18n.t('common.import')}
                </Button>
                <Button className="topic-editor-header__button-publish" onClick={_onPublishQuestionsHandler}>
                    {props.isPublishingQuestions ? <Loader type={LoaderType.Grow} customStyle={"custom-spinner"}/> : <span>{i18n.t('common.publish')}</span>}
                </Button>
            </div>
        </div>
    );
}

export default TopicEditorHeader;
