import { showErrorToastr } from "src/app/shared/helpers/toastr-helper/ToastrHelper";
import { AppThunkAction } from "src/~store/models/AppThunkAction";
import { getAllPermissionsAsync, getAllUsersAsync, getRolesPermissionsAsync, getUsersPermissionsAsync, saveRolePermissionsAsync, saveUserPermissionsAsync } from "../../~api/actions";
import { IRole } from "../models/IRole";
import { IRolePermissions } from "../models/RolePermissions";
import { IUserPermissions } from "../models/UserPermissions";
import { GetAllPermissionsAction, GetAllUsersAction, GetRolesPermissionsAction, GetUserPermissionsAction, SaveRolePermissionsAction, SaveUserPermissionsAction } from "./interfaces";
import { GET_ALL_PERMISSIONS_SUCCESS, GET_ALL_USERS_SUCCESS, GET_ROLES_PERMISSIONS_SUCCESS, GET_USERS_PERMISSIONS_SUCCESS, SAVE_ROLE_PERMISSIONS_SUCCESS, SAVE_USER_PERMISSIONS_SUCCESS } from "./types";

export const getAllPermissions = (): AppThunkAction<GetAllPermissionsAction> => async dispatch => {
    const result = await getAllPermissionsAsync();

    if(result.isSuccess) {
        const allPermissions = result.value ?? [];
        dispatch( {type: GET_ALL_PERMISSIONS_SUCCESS, payload: {allPermissions}} );
    }
};

export const getRolesPermissions = (roles: IRole[]): AppThunkAction<GetRolesPermissionsAction> => async dispatch => {
    const result = await getRolesPermissionsAsync(roles);

    if(result.isSuccess) {
        const rolePermissions = result.value ?? [];
        dispatch( {type: GET_ROLES_PERMISSIONS_SUCCESS, payload: {rolePermissions}} );
    }
};

export const saveRolePermissions = (rolePermissions: IRolePermissions): AppThunkAction<SaveRolePermissionsAction> => async dispatch => {
    const result = await saveRolePermissionsAsync(rolePermissions);

    if(result.isSuccess) {
        dispatch( {type: SAVE_ROLE_PERMISSIONS_SUCCESS, payload: {rolePermissions}} );
    }

    else showErrorToastr("Error!");
};

export const getAllUsers = (): AppThunkAction<GetAllUsersAction> => async dispatch => {
    const result = await getAllUsersAsync();

    if(result.isSuccess) {
        const allUsers = result.value ?? [];
        dispatch( {type: GET_ALL_USERS_SUCCESS, payload: {allUsers}} );
    }

    else showErrorToastr("Error!");
};

export const getUsersPermissions = (): AppThunkAction<GetUserPermissionsAction> => async dispatch => {
    const result = await getUsersPermissionsAsync();

    if(result.isSuccess) {
        const usersPermissions = result.value ?? [];
        dispatch( {type: GET_USERS_PERMISSIONS_SUCCESS, payload: {usersPermissions}} );
    }

    else showErrorToastr("Error!");
};

export const saveUserPermissions = (userPermissions: IUserPermissions): AppThunkAction<SaveUserPermissionsAction> => async dispatch => {
    const result = await saveUserPermissionsAsync(userPermissions);

    if(result.isSuccess) {
        dispatch( {type: SAVE_USER_PERMISSIONS_SUCCESS, payload: {userPermissions}} );
    }

    else showErrorToastr("Error!");
};