import * as React from "react";
import Linkify from 'react-linkify';

import {ITopicWidgetAnswer} from "src/app/nlp/~store/models/TopicWidgetAnswer";
import {IAnswerMessageBodyProps} from "./~types/AnswerMessageBodyProps";
import {buildUrlWithUserSegment} from "src/app/shared/helpers/user-auth-helper/UserAuthHelper";

import AnswerHolder from "./answer-holder/AnswerHolder";

import './AnswerMessageBody.css';
import i18n from "src/app/shared/localization/i18n";

export default class AnswerMessageBody extends React.Component<IAnswerMessageBodyProps> {
    public render() {
        const answers = this.props.answerMessage.answers.sort((a1, a2) => a2.confidence - a1.confidence);
        return answers && answers.length
        ? this._mapAnswerMessagesToTable(answers, this.props.answerMessage.questionText)
        : this._getNotFoundAnswerMessage();
    }

    private _mapAnswerMessagesToTable(answers: ITopicWidgetAnswer[], questionText: string): React.ReactElement<{}> {
        return (
            <div className="answers-holder">
                <div className="question-text-from-server">{i18n.t('nlp.actualSearchString')} <span>{questionText}</span></div>
                {answers.map((answer, index) => this._mapAnswerToTableRow(answer, index))}
            </div>
        );
    }

    private _mapAnswerToTableRow(answer: ITopicWidgetAnswer, index: number): React.ReactElement<{}> {
        return (
            <AnswerHolder key={index} answer={answer} />
        );
    }

    private _getNotFoundAnswerMessage = (): React.ReactElement<{}> => {
        return <div>{this.props.answerMessage.questionText}{i18n.t('nlp.answerNotFound')}</div>;
    };

    private _componentDecorator = (href: string, text: string, key: number) => (
        <a href={href} key={key} target="_blank">
            {text}
        </a>
    );
}
