import React, { useState } from 'react';
import { ActionType, TransferInviteDialogProps } from './~types/TransferInviteDialogProps';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';

import './TransferInviteDialog.css';
import TransferChat from '../transfer-chat/TransferChat';
import InviteOperator from '../invite-operator/InviteOperator';
import { useTranslation } from 'react-i18next';

const TransferInviteDialog = (props: TransferInviteDialogProps) => {
    const [isTransferInviteDialogOpen, setIsTransferInviteDialogOpen] = useState(false);
    const { t } = useTranslation();
    return (
    <Dropdown 
        isOpen={isTransferInviteDialogOpen} 
        toggle={() => setIsTransferInviteDialogOpen(!isTransferInviteDialogOpen)}
        direction="up">
        <DropdownToggle className={"transfer-invite-dialog__toggle"}>
            <span>
                {props.actionType === ActionType.Invite && t('chat.invite')}
                {props.actionType === ActionType.Transfer && t('chat.transfer')}
            </span>
        </DropdownToggle>
        {isTransferInviteDialogOpen && 
        <DropdownMenu className="transfer-invite-dialog__menu">
            <div className="transfer-invite-dialog__actions-selection-container">
                {props.actionType === ActionType.Invite && <div 
                    className={`transfer-invite-dialog__action-type`}>
                    {t('chat.invite')}
                </div>}
            </div>
            {props.actionType === ActionType.Transfer && <TransferChat
                {...props}
                onCloseDialog={() => setIsTransferInviteDialogOpen(false)} />}
            {props.actionType === ActionType.Invite && <InviteOperator 
                {...props} 
                onCloseDialog={() => setIsTransferInviteDialogOpen(false)} />}
        </DropdownMenu>}
    </Dropdown>);
}

export default TransferInviteDialog;