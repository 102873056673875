import React, { useEffect, useState } from "react";
import ChatItem from "./chat-item/ChatItem";
import ProfileDropdown from "./ProfileDropdown/ProfileDropdown";
import { connect } from "react-redux";
import { historyWithForceUpdate } from "src/~store/history";

import { IChatPanelProps } from "./chats-panel-main/chat-item-list/~types/ChatPanelProps";
import "./ChatsPanel.css";
import { IApplicationState } from "src/~store/models/ApplicationState";

import {
  GetOpenedOperatorChatsAction,
  ChangeOperatorStatusAction,
  StartSignalRAction,
  StopSignalRAction,
  GetChatMessagesAction,
  UpdateIncomingChatInStateAction,
  AddNewMessageToStateAction,
  GetOperatorInfoAction,
  GetChannelInfoAction,
  GetContactInfoAction,
  GetOperatorChannelsAction,
  IncreaseChatUnreadMessagesCountAction,
  UpdateMessageInStateAction,
  GetOperatorStatusListAction,
  UpdateOperatorStatusInStateAction,
  ChangeReconnectingStatusAction,
  UpdatePreviewMessageToStateAction,
  GetAvailableContactTagsAction,
  GetGlobalOperatorSettingsAction,
} from "./~store/actions/interfaces";
import { AppThunkAction } from "src/~store/models/AppThunkAction";
import {
  getChats,
  changeOperatorStatus,
  changeReconnectingStatus,
  startSignalR,
  stopSignalR,
  addNewMessageToState,
  getOperatorInfo,
  getChannelInfo,
  getOperatorChannels,
  increaseChatUnreadMessagesCount,
  setChatVisibilityToState,
  updateChatMessage,
  UpdateIncomingChatInState,
  updateChatsInChatPanel,
  getOperatorStatusList,
  updateOperatorStatusInState,
  playNewMessageSound,
  onLoggedOut,
  pushNewMessageNotification,
  getAvailableContactTags,
  updateMessagePreviewToState,
  getGlobalOperatorSettings,
  startOperatorLostConnectionHandler,
  updateIsContactOnline,
  loadChatToWidget,
} from "./~store/actions/actions";
import {
  IOperatorStatusViewModel,
  OperatorStatusType,
} from "./~store/state/ChatItemsState";
import { IChatItem } from "./~store/models/ChatItem";
import { IChat } from '../chats/~store/models/Chat';
import { updateChats } from '../chats/~store/actions/actions';
import {
  DEFAULT_OPERATOR_AVATAR_ID,
  DEFAULT_OPERATOR_AVATAR,
} from "../operator/~store/models/ModelsConstants";
import { GET_PICTURE_BY_ID_API_ROUTE } from "./~api/apiRoutes";
import {
  selectedChatIdSelector,
  chatItemsSelector,
  operatorStatusSelector,
  hubConnectionSelector,
  operatorNameSelector,
  operatorAvatarIdSelector,
  operatorIdSelector,
  operatorChannelsSelector,
  operatorsStatusListSelector,
  reconnectingStatusSelector,
  pauseCategoriesSelector,
} from "./~store/selectors";
import { animateScroll } from "react-scroll";
import { onGetChatsModeChanged } from "../chats/~store/actions/actions";
import { GetChatsMode } from "../chats/~store/models/enums/GetChatsMode";
import { IMessageItem, MessageType } from "./~store/models/MessageItem";
import Loader from "../shared/components/loader/Loader";
import {IMessagePreview} from "./~store/models/MessagePreview";
import { FIRST_PAUSE_CATEGORY_ID } from "./~store/actions/ChatConstants";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

type ReduxType = ReturnType<typeof mapStateToProps> & IChatPanelProps;

let selectedChatId: number = 0;
let newMessageSoundPlayer: HTMLAudioElement;

const ChatsPanel = (props: ReduxType) => {
  const { t } = useTranslation();
  useEffect(() => {
    props.startSignalR(onSignalRMessage);
    props.hubConnection.onreconnecting(() => {
      props.changeReconnectingStatus(true);
      props.startOperatorLostConnectionHandler();
      // props.changeOperatorStatus(OperatorStatusType.Offline, onOperatorStatusChanged);
    });
    props.hubConnection.onreconnected(() => {
      props.changeReconnectingStatus(false);
      props.hubConnection.invoke("CheckOperatorStatus");
    });
    props.getOperatorInfo();
    props.getOperatorChannels();
    props.getOperatorStatusList();
    //props.getAvailableContactTags();
      props.getGlobalOperatorSettings();
      newMessageSoundPlayer = new Audio(process.env.PUBLIC_URL + "/sounds/new-message-sound.mp3")
  }, []);

  useEffect(() => {
    selectedChatId = props.selectedChatId;
  }, [props.selectedChatId]);
  
  const userInStorage = sessionStorage.getItem("user");
  const customerId = userInStorage === null ? "" : JSON.parse(userInStorage.toString()).customerId;
  const operatorAvatarUrl =
    props.operatorAvatarId !== DEFAULT_OPERATOR_AVATAR_ID
      ? GET_PICTURE_BY_ID_API_ROUTE + props.operatorAvatarId + "&customerId=" + customerId
      : "";

  const onOperatorStatusChanged = (status: OperatorStatusType) => {
    switch (status) {
      case OperatorStatusType.Online:
        props.getChats();
        return;

      case OperatorStatusType.Pause:
      case OperatorStatusType.Pause1:
      case OperatorStatusType.Pause2:
      case OperatorStatusType.Pause3:
      case OperatorStatusType.Pause4:
      case OperatorStatusType.Pause5:
      case OperatorStatusType.Pause6:
      case OperatorStatusType.Pause7:
      case OperatorStatusType.Pause8:
      case OperatorStatusType.Pause9:
      case OperatorStatusType.Pause10:
        props.getChats();
        return;

      case OperatorStatusType.Offline:
        setChatVisibilityToAll(false);
        //props.getChats();
        return;
    }
  };

  const onSignalRMessage = (receivedMessage: any) => {
    switch (receivedMessage.type) {
      case "UpdateChat":
        if (
          receivedMessage.additionalData &&
          receivedMessage.additionalData.chatFromHistory === true
        ) {
          receivedMessage.data.fromHistory = true;
        } else {
          receivedMessage.data.fromHistory = false;
        }
        props.UpdateIncomingChatInState(receivedMessage.data);
        return;

      case "UpdateChats":
        props.updateChatsInChatPanel(receivedMessage.data);
        props.updateChats(receivedMessage.data);
        return;

      case "NewChat_Broadcast":
        props.updateChats([receivedMessage.data]);
        return;

      case "NewMessage":
        if (selectedChatId === receivedMessage.data.chatId) {
          props.addNewMessageToState(receivedMessage.data);
          _scrollDown();
        } else {
          if (
            receivedMessage.data.messageType === MessageType.clientMessage &&
            receivedMessage.data.readTimestamp === null
          ) {
            props.increaseChatUnreadMessagesCount(receivedMessage.data.chatId);
          }
        }
        props.playNewMessageSound(
          newMessageSoundPlayer,
          receivedMessage.data as IMessageItem
        );
        props.pushNewMessageNotification(receivedMessage.data as IMessageItem);

        return;

      case "MessagePreview":
        props.updateMessagePreviewToState(
          receivedMessage.data as IMessagePreview
        );
        if (selectedChatId === receivedMessage.data.chatId) {
          _scrollDown();
        }
        return;
      case "OperatorStatus":
        props.changeOperatorStatus(
          +receivedMessage.data,
          onOperatorStatusChanged
        );
        break;
      case "UpdateMessage":
        props.updateChatMessage(receivedMessage.data);
        break;
      case "UpdateOperatorStatus":
        props.updateOperatorStatusInState(receivedMessage.data);
        break;
      case "LogoutOperator":
        props.onLoggedOut(
          receivedMessage?.data?.logoutBy,
          receivedMessage?.data?.operatorId
        );
        break;
      case "ClientWentOffline":
        props.updateIsContactOnline(false, receivedMessage?.data?.channelToken, receivedMessage?.data?.messengerExternalId); 
      break;
      case "ClientWentOnline": 
        props.updateIsContactOnline(true, receivedMessage?.data?.channelToken, receivedMessage?.data?.messengerExternalId)
      break;
    }
  };

  const _scrollDown = (): void => {
    let chatBody = document.getElementById("chat-body");

    // Scroll down if operator scrolled up not more than on quarter of the chat body
    if (
      chatBody != null &&
      chatBody.scrollHeight - chatBody.offsetHeight / 4 <=
        chatBody.scrollTop + chatBody.offsetHeight
    ) {
      chatBody.scrollTo({top: chatBody.scrollHeight})
    }
  };

  const _mapChats = (): React.ReactElement<{}> => {
    return (
      <React.Fragment>
        <div className="chats-panel__chat-list">
          {props.chatItems.map((chat, index) => {
            if (chat.fromHistory === false && chat.isClosed === false)
              return (
                <div
                  id={"chat-item__chatid-" + chat.chatId}
                  key={index}
                  onClick={() => onChatSelected(chat, true)}
                >
                  <ChatItem
                    chatItem={chat}
                    isSelected={chat.chatId === props.selectedChatId}
                    operatorId={props.operatorId}
                  />
                </div>
              );
          })}
        </div>
      </React.Fragment>
    );
  };

  const _mapPauseCategories = () => {
    return (
      <React.Fragment>
        {props.pauseCategories && props.pauseCategories.length > 0 ? (
          <optgroup className={"chat-panel__operator-status-afk"} label="Pause">
            {props.pauseCategories.map((item, index) => {
              return (
                <option
                  key={index}
                  className={"chat-panel__operator-status-afk"}
                  value={FIRST_PAUSE_CATEGORY_ID + index}
                >
                  {item}
                </option>
              );
            })}
          </optgroup>
        ) : (
          <option
            className={"chat-panel__operator-status-afk"}
            value={OperatorStatusType.Pause}
          >
                        {t('common.pause')}
          </option>
        )}
      </React.Fragment>
    );
  };

  const onChatSelected = (chat: IChatItem, needToClearHistory = false) => {
    toggleProfileDropDownVisibility(false);
    if (props.selectedChatId === chat.chatId && props.isChatVisible === true)
      return;
    setChatVisibilityToAll(true);
    props.loadChatToWidget(chat.chatId);
    activateChatsTabIfNeed(chat);
  };

  const activateChatsTabIfNeed = (chat: IChatItem) => {
    if (
      chat.operatorId === props.operatorId &&
      props.getChatsMode !== GetChatsMode.my
    ) {
      props.onGetChatsModeChanged(GetChatsMode.my, true);
    }
    if (
      chat.invitedOperatorsId.includes(props.operatorId) &&
      props.getChatsMode !== GetChatsMode.withMe
    ) {
      props.onGetChatsModeChanged(GetChatsMode.withMe, true);
    }
  };

  const setChatVisibilityToAll = (value: boolean) => {
    props.setChatVisibility(value);
    props.setChatVisibilityToState(value);
  };

  const toggleProfileDropDownVisibility = (value: boolean) => {
    props.toggleProfileDropDownVisibility(value);
  };

  const onLogout = (logoutMessage: string) => {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("expiresDialogIsClosed");
    historyWithForceUpdate.push("/login/" + logoutMessage ? logoutMessage : "");
  };

  return (
    <div className="chats-panel__container">
      <Avatar
        className="chats-panel__logout"
        id="logout"
        src={operatorAvatarUrl}
        onClick={() =>
          toggleProfileDropDownVisibility(!props.isProfileDropdownVisible)
        }
      >
        <PersonIcon style={{ color: "#26a69a" }} />
      </Avatar>
      <div className="chat-panel__operator-name">{props.operatorName}</div>
      {props.operatorId !== 0 && props.operatorId && (
        <div className={"chat-panel__operator-status-container"}>
          <select
            disabled={props.reconnectingStatus}
            onChange={(e) => {
              props.changeOperatorStatus(
                +e.target.value,
                onOperatorStatusChanged,
                true
              );
            }}
            value={props.operatorStatus}
            className={
              "chat-panel__selected-status-" +
              (props.operatorStatus === OperatorStatusType.Online ||
              props.operatorStatus === OperatorStatusType.Offline
                ? props.operatorStatus
                : OperatorStatusType.Pause)
            }
          >
            <option
              className={"chat-panel__operator-status-online"}
              value={OperatorStatusType.Online}
            >
                          {t('common.online')}
            </option>
            {_mapPauseCategories()}
            <option
              className={"chat-panel__operator-status-offline"}
              value={OperatorStatusType.Offline}
            >
                          {t('common.offline')}
            </option>
          </select>
          {props.reconnectingStatus && <Loader />}
        </div>
      )}
      <div className="chat-panel__chat-items-container">{_mapChats()}</div>
      {props.isProfileDropdownVisible && (
        <ProfileDropdown
          onLogout={onLogout}
          operatorChannels={props.operatorChannels}
          operatorsStatusList={props.operatorsStatusList}
          pauseCategories={props.pauseCategories}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: IApplicationState, externalProps: any) => {
  return {
    chatItems: chatItemsSelector(state),
    isChatVisible: externalProps.isChatVisible,
    isProfileDropdownVisible: externalProps.isProfileDropdownVisible,
    toggleProfileDropDownVisibility:
      externalProps.toggleProfileDropDownVisibility,
    operatorStatus: operatorStatusSelector(state),
    pauseCategories: pauseCategoriesSelector(state),
    reconnectingStatus: reconnectingStatusSelector(state),
    hubConnection: hubConnectionSelector(state),
    selectedChatId: selectedChatIdSelector(state),
    operatorName: operatorNameSelector(state),
    operatorAvatarId: operatorAvatarIdSelector(state),
    operatorId: operatorIdSelector(state),
    operatorChannels: operatorChannelsSelector(state),
    setChatVisibility: externalProps.setChatVisibility,
    getChatsMode: state.chatsState.chatsList.getChatsMode,
    operatorsStatusList: operatorsStatusListSelector(state),
  };
};

const mapDispatchToProps = (dispatch: any): IChatPanelProps => {
  return {
    loadChatToWidget: (chatId: number) => dispatch(loadChatToWidget(chatId)),
    getChats: (): AppThunkAction<GetOpenedOperatorChatsAction> => {
      return dispatch(getChats());
    },
    changeOperatorStatus: (
      status: OperatorStatusType,
      callback: CallableFunction,
      isFromUI: boolean = false
    ): AppThunkAction<ChangeOperatorStatusAction> => {
      return dispatch(changeOperatorStatus(status, callback, isFromUI));
    },
    changeReconnectingStatus: (
      status: boolean
    ): AppThunkAction<ChangeReconnectingStatusAction> => {
      return dispatch(changeReconnectingStatus(status));
    },
    startOperatorLostConnectionHandler: (): AppThunkAction<void> => {
      return dispatch(startOperatorLostConnectionHandler());
    },
    startSignalR: (
      callback: CallableFunction
    ): AppThunkAction<StartSignalRAction> => {
      return dispatch(startSignalR(callback));
    },
    stopSignalR: (): AppThunkAction<StopSignalRAction> => {
      return dispatch(stopSignalR());
    },
    UpdateIncomingChatInState: (
      chat
    ): AppThunkAction<UpdateIncomingChatInStateAction> => {
      return dispatch(UpdateIncomingChatInState(chat));
    },
    updateMessagePreviewToState: (message: IMessagePreview) => {
      return dispatch(updateMessagePreviewToState(message));
    },
    addNewMessageToState: (
      message: IMessageItem
    ): AppThunkAction<AddNewMessageToStateAction> => {
      return dispatch(addNewMessageToState(message));
    },
    updateChatMessage: (
      message: IMessageItem
    ): AppThunkAction<UpdateMessageInStateAction> => {
      return dispatch(updateChatMessage(message));
    },
    getOperatorInfo: (): AppThunkAction<GetOperatorInfoAction> => {
      return dispatch(getOperatorInfo());
    },
    getOperatorChannels: (): AppThunkAction<GetOperatorChannelsAction> => {
      return dispatch(getOperatorChannels());
    },
    getOperatorStatusList: (): AppThunkAction<GetOperatorStatusListAction> => {
      return dispatch(getOperatorStatusList());
    },
    getAvailableContactTags:
      (): AppThunkAction<GetAvailableContactTagsAction> => {
        return dispatch(getAvailableContactTags());
      },
    getGlobalOperatorSettings:
      (): AppThunkAction<GetGlobalOperatorSettingsAction> => {
        return dispatch(getGlobalOperatorSettings());
      },
    updateOperatorStatusInState: (
      opeatorStatus: IOperatorStatusViewModel
    ): AppThunkAction<UpdateOperatorStatusInStateAction> => {
      return dispatch(updateOperatorStatusInState(opeatorStatus));
    },
    getChannelInfo: (
      channelId: number
    ): AppThunkAction<GetChannelInfoAction> => {
      return dispatch(getChannelInfo(channelId));
    },
    increaseChatUnreadMessagesCount: (
      chatId: number
    ): AppThunkAction<IncreaseChatUnreadMessagesCountAction> => {
      return dispatch(increaseChatUnreadMessagesCount(chatId));
    },
    setChatVisibilityToState: (value: boolean) => {
      return dispatch(setChatVisibilityToState(value));
    },
    onGetChatsModeChanged: (
      mode: GetChatsMode,
      needGetChats: boolean = false
    ) => {
      return dispatch(onGetChatsModeChanged(mode, needGetChats));
    },
    updateChatsInChatPanel: (chats: IChatItem[]) =>
      dispatch(updateChatsInChatPanel(chats)),
    updateChats: (chats: IChat[]) => dispatch(updateChats(chats)),
    updateIsContactOnline: (status: boolean, channelToken: string, messengerExternalId: string) => 
      dispatch(updateIsContactOnline(status, channelToken, messengerExternalId)),
    playNewMessageSound: (
      soundPlayer: HTMLAudioElement,
      message: IMessageItem
    ) => dispatch(playNewMessageSound(soundPlayer, message)),
    pushNewMessageNotification: (message: IMessageItem) =>
      dispatch(pushNewMessageNotification(message)),
    onLoggedOut: (logoutBy: string, operatorId: number) =>
      dispatch(onLoggedOut(logoutBy, operatorId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatsPanel);
