import React from "react";
import { connect } from "react-redux";
import { IRole } from "src/app/permission/~store/models/IRole";
import { IApplicationState } from "src/~store/models/ApplicationState";
import { AppThunkAction } from "src/~store/models/AppThunkAction";
import { getPermissions } from "./actions/actions";
import { GetPermissionAction, IUserContextProps } from "./actions/interfaces";



const UserContext = (props: any) => {
    if(!props.permissionsUpdated && !props.isUpdatingPermissions) {
        props.getPermissions();
    }
    else if (props.permission != undefined ? props.permissionList.some((r: any) => props.permission.includes(r)) : false)
    {
        return props.children;
    }
    else if(props.isAdminPermission) {
        const role = JSON.parse(sessionStorage.getItem('user') || "")?.role as string;
        return IRole[role as keyof typeof IRole] == IRole.Admin && props.children;
    } 
    return <></>
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        permissionsUpdated: state.userContextState.permissions.isUpdated,
        permissionList: state.userContextState.permissions.permissionList,
        isUpdatingPermissions: state.userContextState.permissions.isUpdatingPermissions
    };
};

const mapDispatchToProps = (dispatch: any) : IUserContextProps => {
    return {
        getPermissions: (): AppThunkAction<GetPermissionAction> => {
            return dispatch(getPermissions()); 
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserContext);