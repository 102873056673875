const moduleName: string = "CHANNEL/";

export const GET_ALL_CHANNELS_START: string = `${moduleName}GET_ALL_CHANNELS_START`;
export const GET_ALL_CHANNELS_SUCCESS: string = `${moduleName}GET_ALL_CHANNELS_SUCCESS`;
export const GET_ALL_CHANNELS_ERROR: string = `${moduleName}GET_ALL_CHANNELS_ERROR`;

export const CREATE_CHANNEL: string = `${moduleName}CREATE_CHANNEL`;

export const SAVE_CHANNEL_START: string = `${moduleName}SAVE_CHANNEL_START`;
export const SAVE_CHANNEL_SUCCESS: string = `${moduleName}SAVE_CHANNEL_SUCCESS`;
export const SAVE_NEW_CHANNEL_SUCCESS: string = `${moduleName}SAVE_NEW_CHANNEL_SUCCESS`;
export const SAVE_NEW_CHANNEL_ERROR: string = `${moduleName}SAVE_NEW_CHANNEL_ERROR`;
export const SAVE_CHANNEL_ERROR: string = `${moduleName}SAVE_NEW_CHANNEL_ERROR`;

export const DELETE_CHANNEL_START: string = `${moduleName}DELETE_CHANNEL_START`;
export const DELETE_CHANNEL_SUCCESS: string = `${moduleName}DELETE_CHANNEL_SUCCESS`;
export const DELETE_NEW_CHANNEL_SUCCESS: string = `${moduleName}DELETE_NEW_CHANNEL_SUCCESS`;
export const DELETE_CHANNEL_ERROR: string = `${moduleName}DELETE_CHANNEL_ERROR`;

export const GET_ALL_OPERATORS_START: string = `${moduleName}GET_ALL_OPERATORS_START`;
export const GET_ALL_OPERATORS_SUCCESS: string = `${moduleName}GET_ALL_OPERATORS_SUCCESS`;
export const GET_ALL_OPERATORS_ERROR: string = `${moduleName}GET_ALL_OPERATORS_ERROR`;

export const SAVE_CHANNEL_SETTING: string = `${moduleName}SAVE_OPERATOR_SETTING`;

export const TOGGLE_CHANNEL_SORTING_ACTION: string = `${moduleName}TOGGLE_CHANNEL_SORTING_ACTION`;

export const RESTART_CHANNEL_START: string = `${moduleName}RESTART_CHANNEL_START`;
export const RESTART_CHANNEL_SUCCESS: string = `${moduleName}RESTART_CHANNEL_SUCCESS`;