import { OperatorStatusType } from "../state/ChatItemsState";

export const HEIGHT_OF_OPERATORITEM_IN_REM: number = 3.5;
export const HEIGHT_OF_NO_OPERATORS_DIV_IN_REM: number = 3;
export const HEIGHT_OF_DROPDOWN_HEADER_IN_REM: number = 3;
export const HEIGHT_OF_LOADER_IN_REM: number = 2;

export const INPUT_LINES_COUNT: number = 3;

export const MAX_PAUSE_CATEGORIES: number = 10;

export const FIRST_PAUSE_CATEGORY_ID: number = OperatorStatusType.Pause1;
export const LAST_PAUSE_CATEGORY_ID: number = OperatorStatusType.Pause10;

export const MAX_OPERATOR_DISCONECTION_TIMEOUT = 30000;