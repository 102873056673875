import { Action, Reducer } from "redux";
import { IChatsState } from './state/ChatsState';
import { initState } from './state/InitChatsState';
import { 
    GetAllChatsAction, 
    GetAllChatsSuccessAction,
    GetContactChatsLazySuccessAction,
    GetContactChatsLazyAction,
    GetChatByIdSuccessAction,
    SetSearchStringToChatsListSuccessAction,
    SetSearchStringToChatsInfoSuccessAction,
    SetFirstElementIdAction,
    SetSelectedMessageAction,
    ToggleMessageEditingAction,
    EditMessageAction,
    UpdateChatsAction,
    AddNewChatToStateAction,
    GetChatsModeChangedAction,
    GetChatsModeChangedSuccessAction,
    UpdateChatsChannelsAction,
    UpdateChatsOperatorsAction,
    ChangeChatContactChatnameSuccessAction,
    SearchChatsSuccessAction,
    GetChatTagsSuccessAction,
    GetChatTagValuesSuccessAction,
    GetSLColorSecondsSettigsSuccess
} from "./actions/interfaces";
import { 
    GET_ALL_CHATS_START, 
    GET_ALL_CHATS_ERROR, 
    GET_ALL_CHATS_SUCCESS,
    GET_CONTACT_CHATS_LAZY_START,
    GET_CONTACT_CHATS_LAZY_SUCCESS,
    GET_CONTACT_CHATS_LAZY_ERROR,
    GET_CHAT_BY_ID_START,
    GET_CHAT_BY_ID_SUCCESS,
    GET_CHAT_BY_ID_ERROR,
    SET_SEARCH_STRING_TO_CHATS_LIST_SUCCESS,
    SET_SEARCH_STRING_TO_CHATS_INFO_SUCCESS,
    OPEN_CHAT_BY_ID_SUCCESS,
    SET_FIRST_ELEMENT_ID,
    SET_SELECTED_MESSAGE,
    TOGGLE_MESSAGE_EDITING,
    UPDATE_CHATS,
    ADD_NEW_CHAT_TO_CHATPANEL_STATE_SUCCESS,
    GET_CHATS_MODE_CHANGE_SUCCESS,
    GET_CHATS_MODE_CHANGE_START,
    UPDATE_CHATS_CHANNELS,
    UPDATE_CHATS_OPERATORS,
    CHANGE_CHAT_CONTACT_CHATNAME_SUCCESS,
    SEARCH_CHATS,
    GET_TAG_NAMES,
    GET_TAG_VALUES,
    GET_SL_COLOR_SECONDS_SETTINGS_SUCCESS,
    TOGGLE_AUTO_UPDATE_CHATS
} from "./actions/types";
import { GetChatsMode } from "./models/enums/GetChatsMode";
import { PermissionType } from "src/app/shared/components/user-context/models/PermissionType";
import { getDateTime } from "src/app/shared/helpers/date-helper/DateFormats";
import { OperatorType } from "src/app/operator/~store/models/enums/OperatorType";
import { MAX_DISPLAY_CHATS } from "../chats-main/chats-list/chats-item/ChatsItemConstants";

export type KnownActions = 
 | GetAllChatsAction
 | GetContactChatsLazyAction
 | EditMessageAction
 | SetSelectedMessageAction 
 | ToggleMessageEditingAction;

export const chatsReducer: Reducer<IChatsState> = (
    state: IChatsState = initState,
    incommingAction: Action
): IChatsState => {
    const action = incommingAction as KnownActions;
    switch(action.type) {
        case GET_ALL_CHATS_START:
            return {
                ...state,
                chatsList: {
                    ...state.chatsList,
                    isReceivingChats: true,
                }
            }
        
        case GET_ALL_CHATS_SUCCESS:
            const receivedChats = (action as GetAllChatsSuccessAction).payload.chats;
            const skipNumber = (action as GetAllChatsSuccessAction).payload.skipNumber;
            const existingChatIds = state.chatsList.chats.map(chat => chat.chatId);
            const filteredChats = receivedChats.filter(receivedChat => !existingChatIds.includes(receivedChat.chatId));
            const chats =
              skipNumber > 0
                ? [...state.chatsList.chats, ...filteredChats]
                    : receivedChats;
            const allChatsLoaded = receivedChats.length > 0 ? false : true

            return {
                ...state,
                chatsList: {
                    ...state.chatsList,
                    isReceivingChats: false,
                    isAllChatsLoaded: allChatsLoaded,
                    chats,
                    countOfChats: chats.length,
                }
            }

        case UPDATE_CHATS_CHANNELS:
            const chatsChannels = (action as UpdateChatsChannelsAction).payload.chatsChannels;
            const chatsToUpdateChannels = [...state.chatsList.chats];

            for(const chat of chatsToUpdateChannels) {
                if(chatsChannels.has(chat.channelId)) {
                    chat.channel = chatsChannels.get(chat.channelId);
                }
            }

            return {
                ...state,
                chatsList: {
                    ...state.chatsList,
                    chats: chatsToUpdateChannels,
                }
            }

        case UPDATE_CHATS_OPERATORS:
            const chatsOperators = (action as UpdateChatsOperatorsAction).payload.chatsOperators;
            const chatsToUpdateOperators = [...state.chatsList.chats];

            for(const chat of chatsToUpdateOperators) {
                if(chat.operatorId !== undefined && chatsOperators.has(chat.operatorId)) {
                    chat.operator = chatsOperators.get(chat.operatorId);
                }
            }

            return {
                ...state,
                chatsList: {
                    ...state.chatsList,
                    chats: chatsToUpdateOperators,
                }
            }

        case GET_ALL_CHATS_ERROR:
            return {
                ...state,
                chatsList: {
                    ...state.chatsList,
                    isReceivingChats: false,
                    chats: [],
                }
            }
        
        case SET_SEARCH_STRING_TO_CHATS_LIST_SUCCESS:
            const searchStringForChatsList = (action as SetSearchStringToChatsListSuccessAction).payload.searchString;
            return {
                ...state,
                chatsList: {
                    ...state.chatsList,
                    searchString: searchStringForChatsList
                }
            }

        case SET_SEARCH_STRING_TO_CHATS_INFO_SUCCESS:
            const searchStringForChatsInfo = (action as SetSearchStringToChatsInfoSuccessAction).payload.searchString;
            return {
                ...state,
                contactChatsInfo: {
                    ...state.contactChatsInfo,
                    searchString: searchStringForChatsInfo,
                }
            };

        case GET_CHAT_BY_ID_START:
        case GET_CONTACT_CHATS_LAZY_START:
            return {
                ...state,
                contactChatsInfo: {
                    ...state.contactChatsInfo,
                    isReceivingInfo: true,
                }
            }
        case GET_CHAT_BY_ID_SUCCESS:
            const { chat } = (action as GetChatByIdSuccessAction).payload;
            return {
                ...state,
                contactChatsInfo: {
                    ...state.contactChatsInfo,
                    contactChats: [chat],
                    isReceivingInfo: false,
                    areChatsBefore: true,
                    selectedChatId: chat.chatId,
                }
            }

        case GET_SL_COLOR_SECONDS_SETTINGS_SUCCESS:
            const chatColorExpirationSettigs = (action as GetSLColorSecondsSettigsSuccess).payload.settings;
            return {
                ...state,
                chatsList: {
                ...state.chatsList,
                SLColorSecondsSettings: chatColorExpirationSettigs
                }
            }
            

        case GET_CONTACT_CHATS_LAZY_SUCCESS:
            const { contactChats, isBefore, isSetSearchString } = (action as GetContactChatsLazySuccessAction)
                .payload;
            let areChatsBefore = state.contactChatsInfo.areChatsBefore;
            const currentChats = state.contactChatsInfo.contactChats;
            if(isBefore) {
                areChatsBefore = contactChats.length === 0 ? false : true;
            }
            return {
                ...state,
                contactChatsInfo: {
                    ...state.contactChatsInfo,
                    contactChats: 
                    isSetSearchString ?
                        contactChats :
                    isBefore ?
                        contactChats.concat(currentChats) :
                        currentChats.concat(contactChats),
                    isReceivingInfo: false,
                    areChatsBefore,
                }
            }

        case GET_CHAT_BY_ID_ERROR:
        case GET_CONTACT_CHATS_LAZY_ERROR:
            return {
                ...state,
                contactChatsInfo: {
                    ...state.contactChatsInfo,
                    isReceivingInfo: false,
                    areChatsBefore: true,
                    afterChatsCount: 0,
                    beforeChatsCount: 0,
                    contactChats: [],
                }
            }

        case UPDATE_CHATS: {
            const updatedChats = state.chatsList.tags.length === 0 ? [...(action as UpdateChatsAction).payload.chats] : [];
            const { operatorId, permissions } = (action as UpdateChatsAction).payload;
            let chats = state.chatsList.chats;
            for(const chat of updatedChats) {
                const index = chats.findIndex(x => x.chatId === chat.chatId);
                if (index > -1) {
                    if ((state.chatsList.getChatsMode !== GetChatsMode.archive && chat.isClosed) ||
                        (state.chatsList.getChatsMode === GetChatsMode.archive && !chat.isClosed) ||
                        (state.chatsList.getChatsMode === GetChatsMode.my && chat.operatorId !== operatorId) ||
                        (state.chatsList.getChatsMode === GetChatsMode.withMe && !chat.invitedOperatorsId.includes(operatorId)) ||
                        (state.chatsList.getChatsMode === GetChatsMode.botsAndQueue && 
                                    (chat.operatorId !== null && chat.operatorId !== operatorId 
                                    && !chat.invitedOperatorsId.includes(operatorId) && chat.operator?.type !== OperatorType.Bot))) {
                            chats.splice(index, 1);
                    } else {
                        chats[index] = chat;
                    }
                } 
                else if(state.chatsList.autoUpdateChats) {
                    if(state.chatsList.getChatsMode === GetChatsMode.my && chat.operatorId === operatorId && !chat.isClosed) {
                        chats = [chat, ...chats];
                    } 
                    else if (state.chatsList.getChatsMode === GetChatsMode.withMe && !chat.isClosed) {
                        if (chat.invitedOperatorsId.includes(operatorId) && chat.operatorId !== operatorId) {
                            chats = [chat, ...chats];
                        }
                    } 
                    else if (state.chatsList.getChatsMode === GetChatsMode.botsAndQueue && !chat.isClosed) {
                        if (chat.operatorId === null || chat.operatorId === operatorId 
                                    || chat.invitedOperatorsId.includes(operatorId) ||chat.operator?.type === OperatorType.Bot) {
                            chats = [chat, ...chats];
                        }
                    }
                    else if (state.chatsList.getChatsMode === GetChatsMode.all && !chat.isClosed) {
                        chats = [chat, ...chats];
                    }
                    else if(state.chatsList.getChatsMode === GetChatsMode.archive && chat.isClosed) {
                        if(permissions.includes(PermissionType.Chats_ManageOthersChats) ||
                           chat.operatorId === operatorId ||
                           chat.invitedOperatorsId.includes(operatorId)) {
                                chats = [chat, ...chats];
                        }
                    } 
                }
            }

            chats = state.chatsList.getChatsMode === GetChatsMode.archive ? chats : chats.slice(0, MAX_DISPLAY_CHATS);

            return{
                ...state,
                chatsList: {
                    ...state.chatsList,
                    chats: [...chats],
                    countOfChats: chats.length,
                }
            }
        }

        case ADD_NEW_CHAT_TO_CHATPANEL_STATE_SUCCESS: {
            const {chat} = (action as AddNewChatToStateAction).payload

            const chats = [chat].concat(state.chatsList.chats);

            return {
                ...state,
                chatsList: {
                    ...state.chatsList,
                    chats 
                }
            }
        }

        case SET_FIRST_ELEMENT_ID: {
            const { id } = (action as SetFirstElementIdAction).payload;
            return {
                ...state,
                contactChatsInfo: {
                    ...state.contactChatsInfo,
                    firstElementId: id,
                }
            }
        }

        case SET_SELECTED_MESSAGE: {
            const selectedMessage = (action as SetSelectedMessageAction).payload.message;
            return {
                ...state,
                contactChatsInfo: {
                    ...state.contactChatsInfo,
                    selectedMessage,
                }
            }
        }

        case TOGGLE_MESSAGE_EDITING: {
            const isMessageEditing = !state.contactChatsInfo.isMessageEditing;
            return {
                ...state,
                contactChatsInfo: {
                    ...state.contactChatsInfo,
                    isMessageEditing
                }
            }
        }

        case GET_CHATS_MODE_CHANGE_START: {
            return {
                ...state,
                chatsList: {
                    ...state.chatsList,
                    isModeChanging: true,
                }
            }
        }

        case GET_CHATS_MODE_CHANGE_SUCCESS: {
            const mode = (action as GetChatsModeChangedSuccessAction).payload.mode;

            return {
                ...state,
                chatsList: {
                    ...state.chatsList,
                    chats: [],
                    getChatsMode: mode,
                    isModeChanging: false,
                }
            }
        }

        case CHANGE_CHAT_CONTACT_CHATNAME_SUCCESS: {
            const {contact, chatId} = (action as ChangeChatContactChatnameSuccessAction).payload;
            const chats = state.chatsList.chats;
            for(const chat of chats) {
                if(chat.messenger.contactId === contact.contactId) {
                    chat.messenger.contact.chatName = contact.chatName;
                }
            }
            return {
                ...state,
                chatsList: {
                    ...state.chatsList,
                    chats: [...chats]
                }
            }
        }

        case SEARCH_CHATS: {
            const { tags } = (action as SearchChatsSuccessAction).payload;

            return {
                ...state,
                chatsList: {
                    ...state.chatsList,
                    tags: [...tags]
                }
            }
        }

        case GET_TAG_NAMES: {
            const { chatTags } = (action as GetChatTagsSuccessAction).payload;

            return {
                ...state,
                chatsList: {
                    ...state.chatsList,
                    chatTags: [...chatTags]
                }
            }
        }

        case GET_TAG_VALUES: {
            const { chatTagValues } = (action as GetChatTagValuesSuccessAction).payload;

            return {
                ...state,
                chatsList: {
                    ...state.chatsList,
                    chatTagValues: [...chatTagValues]
                }
            }
        }

        case TOGGLE_AUTO_UPDATE_CHATS: {
            return {
                ...state,
                chatsList: {
                    ...state.chatsList,
                    autoUpdateChats: !state.chatsList.autoUpdateChats
                }
            }
        }

        default:
            return state;
    }
}