import { t } from 'i18next';
import React, { useState } from 'react'
import { Button, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { IPauseChatProps, PAUSE_CATEGORIES } from './~types/PauseChatProps';

import "./PauseChatDialog.css"

const PauseChatDialog = (props: IPauseChatProps) => { 
    const [selectedCategory, setSelectedCategory] = useState<PAUSE_CATEGORIES>(PAUSE_CATEGORIES.PAUSE_WITHOUT_NOTIFICATION)
    const [isOpened, setIsOpened] = useState(false);
    
    const _onPauseChatButtonClick = () => {
        setIsOpened(!isOpened);
        if(props.selectedChat) {
          if(!props.selectedChat.onPause) {
            props.pauseChat(props.selectedChat.chatId, selectedCategory)
          }
          else {
            props.unpauseChat(props.selectedChat.chatId)
          }
        }
    }

    return (
        <Dropdown 
            isOpen={isOpened} 
            toggle={() => {setIsOpened(!isOpened); setSelectedCategory(PAUSE_CATEGORIES.PAUSE_WITHOUT_NOTIFICATION)}}
            direction="up">
            <DropdownToggle className={"pause-chat-dialog__toggle"}>
                <span>
                    {t(props.selectedChat.onPause ? "chat.unpause" : "chat.pause")}
                </span>
            </DropdownToggle>
            {isOpened && 
            <DropdownMenu className="pause-chat-dialog__menu">
                {t(props.selectedChat.onPause ? "chat.removePauseHeader" : "chat.setPauseHeader")}
                <hr />

                {!props.selectedChat.onPause && 
                    <select className='pause-chat-dialog__select' value={selectedCategory} onChange={(e) => setSelectedCategory(e.currentTarget.value as PAUSE_CATEGORIES)}>
                        <option value={PAUSE_CATEGORIES.PAUSE_WITHOUT_NOTIFICATION}>{t("chat.pauseWithoutNotifacation")}</option>
                        <option value={PAUSE_CATEGORIES.PAUSE_WITH_NOTIFICATION}>{t("chat.pauseWithNotifacation")}</option>
                    </select>
                }
                <div className={"pause-chat-dialog__footer"}>
                    <hr /> 
                    <Button 
                        className="pause-chat-dialog__action-btn" 
                        onClick={_onPauseChatButtonClick}>
                        {t(props.selectedChat.onPause ? "chat.removePause" : "chat.setPause")}
                    </Button>
                </div>
            </DropdownMenu>}
        </Dropdown>
    )
}

export default PauseChatDialog;
