const HOST_API_ROUTE = `${process.env.REACT_APP_CHAT_API_ROUTE}`;

const CONTACT_API_ROUTE = `${HOST_API_ROUTE}/contact`;
const MESSENGER_API_ROUTE = `${HOST_API_ROUTE}/messenger`;
const ACCOUNT_API_ROUTE = `${HOST_API_ROUTE}/account`;
const CHAT_API_ROUTE = `${HOST_API_ROUTE}/chat`;
const CHAT_TAGS_API_ROUTE = `${HOST_API_ROUTE}/tag`;

// contact
export const GET_CONTACTS_API_ROUTE = `${CONTACT_API_ROUTE}/get-contacts-lazy?`;
export const SAVE_CONTACT_API_ROUTE = `${CONTACT_API_ROUTE}/save-contact`;
export const DELETE_CONTACT_API_ROUTE = `${CONTACT_API_ROUTE}/delete-contact-by-id?contactId=`;
export const GET_CONTACT_BY_ID_API_ROUTE = `${CONTACT_API_ROUTE}/get-contact-by-id?contactId=`;
export const FIND_CONTACT_BY_PHONE_API_ROUTE = `${CONTACT_API_ROUTE}/find-contact-by-phone?phone=`;
export const GET_AVATAR_BY_CONTACT_ID_API_ROUTE = `${CONTACT_API_ROUTE}/get-avatar-by-id?avatarId=`;
export const SAVE_CONTACT_AVATAR_API_ROUTE = `${CONTACT_API_ROUTE}/save-avatar`;
export const MERGE_CONTACTS_API_ROUTE = `${CONTACT_API_ROUTE}/merge-contacts`;
export const RESTORE_CONTACT_API_ROUTE = `${CONTACT_API_ROUTE}/restore-contact?contactId=`;

// messenger
export const GET_CONTACT_MESSENGERS_API_ROUTE = `${MESSENGER_API_ROUTE}/get-contact-messengers?contactId=`;
export const DETACH_MESSENGER_API_ROUTE = `${MESSENGER_API_ROUTE}/detach-messenger?messengerId=`;

// account
export const SAVE_ACCOUNT_API_ROUTE = `${ACCOUNT_API_ROUTE}/save-account`;
export const DELETE_ACCOUNT_BY_ID_API_ROUTE = `${ACCOUNT_API_ROUTE}/delete-account-by-id?accountId=`;

// chat
export const NEW_CHAT_WITH_MESSENGER_API_ROUTE = `${CHAT_API_ROUTE}/new-chat-with-messenger`;