import {IApiActionResult} from "src/~api/ApiActionResult";
import {IChannel} from "../~store/models/Channel";
import {fetchGet, getApiActionResult, fetchPost} from "src/~api/ApiHelper";
import {
    GET_CHANNELS_API_ROUTE,
    SAVE_CHANNEL_API_ROUTE,
    DELETE_CHANNEL_API_ROUTE,
    SAVE_CHANNEL_SETTING_API_ROUTE,
    GET_SETTING_BY_CHANNEL_ID_API_ROUTE,
    GET_CHANNEL_BY_ID_API_ROUTE,
    GET_CHANNELS_BY_CHANNELSID_API_ROUTE,
    GET_SETTINGS_BY_CHANNELS_ID_API_ROUTE,
    RESTART_CHANNEL_API_ROUTE
} from "./apiRoutes";
import {IOperatorShort} from "../~store/models/OperatorShort";
import {GET_OPERATORS_API_ROUTE} from "src/app/operator/~api/apiRoutes";
import { ISetting } from "src/app/setting/~store/models/setting";

export const getAllChannelsAsync = async (): Promise<IApiActionResult<IChannel[]>> => {
    const fetchPromise = fetchGet(GET_CHANNELS_API_ROUTE);
    return await getApiActionResult(fetchPromise, true);
};

export const getChannelByIdAsync = async (channelId: number): Promise<IApiActionResult<IChannel>> => {
    const fetchPromise = fetchGet(GET_CHANNEL_BY_ID_API_ROUTE + channelId);
    return await getApiActionResult(fetchPromise, true);
}

export const getAllOperatorsAsync = async (): Promise<IApiActionResult<IOperatorShort[]>> => {
    const fetchPromise = fetchGet(GET_OPERATORS_API_ROUTE);
    return await getApiActionResult(fetchPromise, true);
};

export const saveChannelAsync = async (newChannel: IChannel): Promise<IApiActionResult<IChannel>> => {
    const fetchPromise = fetchPost(SAVE_CHANNEL_API_ROUTE, {
        body: JSON.stringify({data: newChannel})
    });
    return await getApiActionResult(fetchPromise, true);
};

export const deleteChannelAsync = async (channel: IChannel): Promise<IApiActionResult<Number>> => {
    const fetchPromise = fetchPost(DELETE_CHANNEL_API_ROUTE + channel.channelId, {});
    return await getApiActionResult(fetchPromise, true);
};

export const restartChannelAsync = async (channel: IChannel): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(RESTART_CHANNEL_API_ROUTE + channel.channelId, {});
    return await getApiActionResult(fetchPromise, false);
}

export const saveChannelSettingAsync = async (channelId: number, setting: string): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(SAVE_CHANNEL_SETTING_API_ROUTE, {
        body: JSON.stringify({
            channelId,
            data: {
                value: setting
            }
        })
    });
    return await getApiActionResult(fetchPromise, false);
};

export const getSettingByChannelIdAsync = async (channelId: number): Promise<IApiActionResult<ISetting>> => {
    const fetchPromise = fetchGet(GET_SETTING_BY_CHANNEL_ID_API_ROUTE + channelId);
    return await getApiActionResult(fetchPromise, true);
};

export const getSettingsByChannelsIdAsync = async (channelsId: number[]): Promise<IApiActionResult<ISetting[]>> => {
    const fetchPromise = fetchPost(GET_SETTINGS_BY_CHANNELS_ID_API_ROUTE, {
        body: JSON.stringify({channelsId})
    });
    return await getApiActionResult(fetchPromise, true);
};

export const getChannelsByChannelsIdAsync = async (channelsId: number[]): Promise<IApiActionResult<IChannel[]>> => {
    const fetchPromise = fetchPost(GET_CHANNELS_BY_CHANNELSID_API_ROUTE, {
        body: JSON.stringify(
            channelsId
        )
    });
    return await getApiActionResult(fetchPromise, true);
};
