import i18n from "src/app/shared/localization/i18n";

export enum TopicPairContentType {
    Text = 1,
    Markup = 2,
    Video = 3,
    Audio = 4,
    Navigation = 5,
    TextToSpeech = 6
}

export const topicPairContentTypeToString = (contentType: TopicPairContentType): string => {
    switch(contentType) {
        case TopicPairContentType.Text:
            return i18n.t('nlp.topicPairContentType_text');
        case TopicPairContentType.Markup:
            return i18n.t('nlp.topicPairContentType_markup');
        case TopicPairContentType.Video:
            return i18n.t('nlp.topicPairContentType_video');
        case TopicPairContentType.Audio:
            return i18n.t('nlp.topicPairContentType_audio');
        case TopicPairContentType.Navigation:
            return i18n.t('nlp.topicPairContentType_navigation');
        case TopicPairContentType.TextToSpeech:
            return i18n.t('nlp.topicPairContentType_textToSpeech');
        default:
            return i18n.t('nlp.topicPairContentType_markup');
    }
}

export const stringToTopicPairContentType = (contentType: string): TopicPairContentType => {
    switch(contentType) {
        case i18n.t('nlp.topicPairContentType_text'):
            return TopicPairContentType.Text;
        case i18n.t('nlp.topicPairContentType_markup'):
            return TopicPairContentType.Markup;
        case i18n.t('nlp.topicPairContentType_video'):
            return TopicPairContentType.Video;
        case i18n.t('nlp.topicPairContentType_audio'):
            return TopicPairContentType.Audio;
        case i18n.t('nlp.topicPairContentType_navigation'):
            return TopicPairContentType.Navigation;
        case i18n.t('nlp.topicPairContentType_textToSpeech'):
            return TopicPairContentType.TextToSpeech;
        default:
            return TopicPairContentType.Markup;
    }
}

export const stringToDefaultTopicPairContentTypeSetting = (contentType: string): TopicPairContentType => {
    switch(contentType?.toLowerCase()) {
        case 'text':
            return TopicPairContentType.Text;
        case 'markup':
            return TopicPairContentType.Markup;
        case 'video':
            return TopicPairContentType.Video;
        case 'audio':
            return TopicPairContentType.Audio;
        case 'navigation':
            return TopicPairContentType.Navigation;
        case 'texttospeech':
            return TopicPairContentType.TextToSpeech;
        default:
            return TopicPairContentType.Markup;
    }
}