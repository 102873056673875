import { IApiActionResult } from "src/~api/ApiActionResult";
import { IContact } from "../~store/models/Contact";
import { getApiActionResult, fetchGet, fetchPost } from "src/~api/ApiHelper";

import {
    GET_CONTACTS_API_ROUTE, 
    GET_CONTACT_MESSENGERS_API_ROUTE, 
    SAVE_CONTACT_API_ROUTE,
    DELETE_CONTACT_API_ROUTE,
    GET_CONTACT_BY_ID_API_ROUTE,
    FIND_CONTACT_BY_PHONE_API_ROUTE,
    SAVE_CONTACT_AVATAR_API_ROUTE,
    MERGE_CONTACTS_API_ROUTE, SAVE_ACCOUNT_API_ROUTE, DELETE_ACCOUNT_BY_ID_API_ROUTE, RESTORE_CONTACT_API_ROUTE, DETACH_MESSENGER_API_ROUTE, NEW_CHAT_WITH_MESSENGER_API_ROUTE,
} from "./apiRoutes";

import { IMessenger } from "../~store/models/Messenger";
import { IAccount } from "../~store/models/Account";
import { IChatItem } from "src/app/chats-panel/~store/models/ChatItem";
import { GET_OPENED_CONTACT_CHATS_API_ROUTE } from "src/app/chats-panel/~api/apiRoutes";
import { IChat } from "../../chats/~store/models/Chat";

export const getContactsAsync = async (skip: number, count: number, searchString: string, isSortingByContactLastName: boolean, skipEmpty: boolean): Promise<IApiActionResult<IContact[]>> => {
    const fetchPromise = fetchGet(GET_CONTACTS_API_ROUTE + 
        "skip=" + skip + 
        "&count=" + count + 
        "&searchString=" + searchString + 
        "&withDeleted=" + false + 
        "&isSortingByContactLastName=" + isSortingByContactLastName +
        "&skipEmpty=" + skipEmpty);
    return await getApiActionResult(fetchPromise, true);
};

export const getContactMessengersAsync = async (contactId: number): Promise<IApiActionResult<IMessenger[]>> => {
    const fetchPromise = fetchGet(GET_CONTACT_MESSENGERS_API_ROUTE + contactId);
    return await getApiActionResult(fetchPromise, true);
};

export const saveContactAsync = async (newContact: IContact): Promise<IApiActionResult<IContact>> => {
    const fetchPromise = fetchPost(SAVE_CONTACT_API_ROUTE, {
        body: JSON.stringify({data: newContact}),
    });
    return await getApiActionResult(fetchPromise, true);
};

export const deleteContactAsync = async (contactId: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(DELETE_CONTACT_API_ROUTE + contactId, {});
    return await getApiActionResult(fetchPromise, false); 
}

export const getContactByIdAsync = async (contactId: number): Promise<IApiActionResult<IContact>> => {
    const fetchPromise = fetchGet(GET_CONTACT_BY_ID_API_ROUTE + contactId);
    return await getApiActionResult(fetchPromise, true);
}

export const findContactByPhoneAsync = async (phone: string): Promise<IApiActionResult<IContact>> => {
    const fetchPromise = fetchGet(FIND_CONTACT_BY_PHONE_API_ROUTE + phone);
    return await getApiActionResult(fetchPromise, true);
}

export const changeContactAvatarAsync = async (file: File, contact: IContact): Promise<IApiActionResult<any>> => {
    const result = await sendImageAsync(file, contact);
    return await getApiActionResult(result, true);
}

export const mergeContactsAsync = async(destContact: IContact, sourceContactId: number): Promise<IApiActionResult<IContact>> => {
    const fetchPromise = fetchPost(MERGE_CONTACTS_API_ROUTE, {
        body: JSON.stringify({
            destContact,
            sourceContactId,
        })
    });
    return await getApiActionResult(fetchPromise, true);
}

export const saveAccountAsync = async(account: IAccount): Promise<IApiActionResult<IAccount>> => {
    const fetchPromise = fetchPost(SAVE_ACCOUNT_API_ROUTE, {
        body: JSON.stringify({
            data: account
        })
    });

    return await getApiActionResult(fetchPromise, true);
}

export const deleteAccountAsync = async(accountId: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(DELETE_ACCOUNT_BY_ID_API_ROUTE + accountId);

    return await getApiActionResult(fetchPromise, true);
}

export const restoreContactAsync = async(contactId: number): Promise<IApiActionResult<IContact>> => {
    const fetchPromise = fetchPost(RESTORE_CONTACT_API_ROUTE + contactId);

    return await getApiActionResult(fetchPromise, true);
}

export const detachMessengerAsync = async(messengerId: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(DETACH_MESSENGER_API_ROUTE + messengerId);

    return await getApiActionResult(fetchPromise, false);
}

const sendImageAsync = (f: File, contact: IContact) => {
    return new Promise<any>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const fetchPromise: Promise<any> = fetchPost(SAVE_CONTACT_AVATAR_API_ROUTE, 
                {
                    body: JSON.stringify({
                        contactId: contact.contactId as number,
                        data: {
                            image: Array.from(new Uint8Array(e.target?.result as ArrayBuffer)),
                        }
                    })  
            })
            resolve(fetchPromise);
        }
        reader.onerror = reject;
        reader.readAsArrayBuffer(f);
    })
}

export const newChatWithMessengerAsync = async (messengerId: number): Promise<IApiActionResult<IChat>> => {
    const fetchPromise = fetchPost(NEW_CHAT_WITH_MESSENGER_API_ROUTE, {
        body: JSON.stringify({
            "messengerId": messengerId
        })
    });
    return await getApiActionResult(fetchPromise, true);
}