import { createSelector } from 'reselect';
import { IApplicationState } from "./../../../../src/~store/models/ApplicationState";
import { ChannelType } from 'src/app/channel/~store/models/enums/ChannelType';

const mailingSelector = (state: IApplicationState) => state.mailingState;

export const allMailingsSelector = createSelector(
    mailingSelector, 
    mailingState => mailingState.mailingList.allMailings.sort((a, b) => 
        new Date(b.modifiedOn).getTime() - new Date(a.modifiedOn).getTime()
    )
);

export const isReceivingMailingsSelector = createSelector(
    mailingSelector, 
    mailingState => mailingState.mailingList.isReceivingmailings
);


//mailingItemInfo
export const mailingItemInfoSelector = createSelector(
    mailingSelector, 
    mailingState => mailingState.mailingItemInfo.mailing
);

export const mailingRecipientsSelector = createSelector(
    mailingSelector, 
    mailingState => mailingState.mailingItemInfo.recipients
);

export const enabledChannelsSelector = createSelector(
    mailingSelector, 
    mailingState => mailingState.mailingItemInfo.enabledChannels.slice()
);

export const selectedChannelTypesSelector = createSelector(
    mailingSelector, 
    mailingState =>  mailingState.mailingItemInfo.mailing.channelTypes
);



