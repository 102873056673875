const moduleName: string = "KNOWLEDGE_BASE/";

// topic
export const GET_ALL_TOPICS_START: string = `${moduleName}GET_ALL_TOPICS_START`;
export const GET_ALL_TOPICS_SUCCESS: string = `${moduleName}GET_ALL_TOPICS_SUCCESS`;
export const GET_ALL_TOPICS_ERROR: string = `${moduleName}GET_ALL_TOPICS_ERROR`;

export const GET_TOPIC_BY_EXTERNAL_ID_START: string = `${moduleName}GET_TOPIC_BY_EXTERNAL_ID_START`;
export const GET_TOPIC_BY_EXTERNAL_ID_SUCCESS: string = `${moduleName}GET_TOPIC_BY_EXTERNAL_ID_SUCCESS`;
export const GET_TOPIC_BY_EXTERNAL_ID_ERROR: string = `${moduleName}GET_TOPIC_BY_EXTERNAL_ID_ERROR`;

export const SAVE_TOPIC_START: string = `${moduleName}SAVE_TOPIC_START`;
export const SAVE_TOPIC_SUCCESS: string = `${moduleName}SAVE_TOPIC_SUCCESS`;
export const SAVE_TOPIC_ERROR: string = `${moduleName}SAVE_TOPIC_ERROR`;

export const CREATE_TOPIC: string = `${moduleName}CREATE_TOPIC`;
export const CANCEL_CREATE_TOPIC: string = `${moduleName}CANCEL_CREATE_TOPIC`;

export const DELETE_TOPIC_START: string = `${moduleName}DELETE_TOPIC_START`;
export const DELETE_TOPIC_SUCCESS: string = `${moduleName}DELETE_TOPIC_SUCCESS`;
export const DELETE_TOPIC_ERROR: string = `${moduleName}DELETE_TOPIC_ERROR`;

// topic pairs
export const SAVE_TOPIC_PAIR_START: string = `${moduleName}SAVE_TOPIC_PAIR_START`;
export const SAVE_TOPIC_PAIR_SUCCESS: string = `${moduleName}SAVE_TOPIC_PAIR_SUCCESS`;
export const SAVE_TOPIC_PAIR_ERROR: string = `${moduleName}SAVE_TOPIC_PAIR_ERROR`;

export const DELETE_TOPIC_PAIR_START: string = `${moduleName}DELETE_TOPIC_PAIR_START`;
export const DELETE_TOPIC_PAIR_SUCCESS: string = `${moduleName}DELETE_TOPIC_PAIR_SUCCESS`;
export const DELETE_TOPIC_PAIR_ERROR: string = `${moduleName}DELETE_TOPIC_PAIR_ERROR`;

export const CREATE_TOPIC_PAIR: string = `${moduleName}CREATE_TOPIC_PAIR`;
export const CANCEL_CREATE_TOPIC_PAIR: string = `${moduleName}CANCEL_CREATE_TOPIC_PAIR`;

export const CHANGE_TOPIC_PAIR_STATUS_START: string = `${moduleName}CHANGE_TOPIC_PAIR_STATUS_START`;
export const CHANGE_TOPIC_PAIR_STATUS_SUCCESS: string = `${moduleName}CHANGE_TOPIC_PAIR_STATUS`;
export const CHANGE_TOPIC_PAIR_STATUS_ERROR: string = `${moduleName}CHANGE_TOPIC_PAIR_STATUS`;

export const EXPORT_PAIRS_START: string = `${moduleName}EXPORT_PAIRS_START`;
export const EXPORT_PAIRS_SUCCESS: string = `${moduleName}EXPORT_PAIRS_SUCCESS`;
export const EXPORT_PAIRS_ERROR: string = `${moduleName}EXPORT_PAIRS_ERROR`;

export const PUBLISH_QUESTIONS_START: string = `${moduleName}PUBLISH_QUESTIONS_START`;
export const PUBLISH_QUESTIONS_SUCCESS: string = `${moduleName}PUBLISH_QUESTIONS_SUCCESS`;
export const PUBLISH_QUESTIONS_ERROR: string = `${moduleName}PUBLISH_QUESTIONS_ERROR`;

export const CHANGE_SELECTED_TOPIC_PAIR_STATUS: string = `${moduleName}CHANGE_SELECTED_TOPIC_PAIR_STATUS`;

// transfer question popup
export const UPDATE_TARGET_TOPIC_PAIR_ID: string = `${moduleName}UPDATE_TARGET_TOPIC_PAIR_ID`;

export const TRANSFER_QUESTION_TO_TOPIC_PAIR_START: string = `${moduleName}TRANSFER_QUESTION_TO_TOPIC_PAIR_START`;
export const TRANSFER_QUESTION_TO_TOPIC_PAIR_SUCCESS: string = `${moduleName}TRANSFER_QUESTION_TO_TOPIC_PAIR_SUCCESS`;
export const TRANSFER_QUESTION_TO_TOPIC_PAIR_ERROR: string = `${moduleName}TRANSFER_QUESTION_TO_TOPIC_PAIR_ERROR`;

export const SET_TRANSFER_QUESTION_POPUP_DATA: string = `${moduleName}SET_TRANSFER_QUESTION_POPUP_DATA`;
export const CLEAR_TRANSFER_QUESTION_POPUP_DATA: string = `${moduleName}CLEAR_TRANSFER_QUESTION_POPUP_DATA`;
export const TOGGLE_TRANSFER_QUESTION_POPUP_VISIBILITY: string = `${moduleName}TOGGLE_TRANSFER_QUESTION_POPUP_VISIBILITY`;

// message box
export const TOGGLE_MESSAGE_BOX_VISIBILITY: string = `${moduleName}TOGGLE_MESSAGE_BOX_VISIBILITY`;
export const SET_MESSAGE_BOX_DATA: string = `${moduleName}SET_MESSAGE_BOX_DATA`;

// topic widget
export const TOGGLE_TOPIC_WIDGET_VISIBILITY: string = `${moduleName}TOGGLE_TOPIC_WIDGET_VISIBILITY`;
export const ADD_QUESTION: string = `${moduleName}ADD_QUESTION`;
export const GET_ANSWERS_START: string = `${moduleName}GET_ANSWERS_START`;
export const GET_ANSWERS_SUCCESS: string = `${moduleName}GET_ANSWERS_SUCCESS`;
export const GET_ANSWERS_ERROR: string = `${moduleName}GET_ANSWERS_ERROR`;
export const CLEAR_TOPIC_WIDGET_MESSAGES: string = `${moduleName}CLEAR_TOPIC_WIDGET_MESSAGES`;

// import topic pair
export const IMPORT_PAIRS_START: string = `${moduleName}IMPORT_PAIRS_START`;
export const IMPORT_PAIRS_SUCCESS: string = `${moduleName}IMPORT_PAIRS_SUCCESS`;
export const IMPORT_PAIRS_ERROR: string = `${moduleName}IMPORT_PAIRS_ERROR`;


export const GET_TOPIC_PAIRS_BY_TOPIC_NAME_WITH_FILTER_SUCCESS: string = `${moduleName}GET_TOPIC_PAIRS_BY_TOPIC_NAME_WITH_FILTER_SUCCESS`;

export const GET_TOPIC_PAIR_CATEGORIES_SUCCESS: string = `${moduleName}GET_TOPIC_PAIR_CATEGORIES_SUCCESS`;
export const GET_QUESTIONS_BY_TOPIC_NAME_SUCCESS: string = `${moduleName}GET_QUESTIONS_BY_TOPIC_NAME_SUCCESS`;

export const SET_FILTER_TAGS_SUCCESS: string = `${moduleName}SET_FILTER_TAGS_SUCCESS`;

export const TOGGLE_TOPIC_SORTING_BY_ID: string = `${moduleName}TOGGLE_TOPIC_SORTING_BY_ID`;
export const TOGGLE_TOPIC_SORTING_BY_NAME: string = `${moduleName}TOGGLE_TOPIC_SORTING_BY_NAME`;