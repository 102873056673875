import { Action, Reducer } from "redux";
import { GetAllPermissionsActionSuccess, GetAllUsersActionSuccess, GetRolesPermissionsActionSuccess, GetUserPermissionsActionSuccess, SaveRolePermissionsActionSuccess, SaveUserPermissionsActionSuccess } from "./actions/interfaces";
import { GET_ALL_PERMISSIONS_SUCCESS, GET_ALL_USERS_SUCCESS, GET_ROLES_PERMISSIONS_SUCCESS, GET_USERS_PERMISSIONS_SUCCESS, SAVE_ROLE_PERMISSIONS_SUCCESS, SAVE_USER_PERMISSIONS_SUCCESS } from "./actions/types";
import { initPermissionState, IPermissionState } from "./state/PermissionState";

export const permissionReducer: Reducer<IPermissionState> = (
    state: IPermissionState = initPermissionState,
    action: Action
): IPermissionState => {


    switch(action.type){
        
        case GET_ALL_PERMISSIONS_SUCCESS: {
            const {allPermissions} = (action as GetAllPermissionsActionSuccess).payload;


            return {
                ...state,
                allPermissions
            }
        }

        case GET_ROLES_PERMISSIONS_SUCCESS: {
            const {rolePermissions} = (action as GetRolesPermissionsActionSuccess).payload;

            return {
                ...state,
                rolesPermissions: rolePermissions
            }
        }

        case SAVE_ROLE_PERMISSIONS_SUCCESS: {
            const {rolePermissions} = (action as SaveRolePermissionsActionSuccess).payload;
            const rolesPermissions = state.rolesPermissions.filter(rp => rp.role != rolePermissions.role).concat([rolePermissions]);

            return {
                ...state,
                rolesPermissions
            }
        }

        case GET_ALL_USERS_SUCCESS: {
            const {allUsers} = (action as GetAllUsersActionSuccess).payload;
            
            return {
                ...state,
                allUsers
            }
        }

        case GET_USERS_PERMISSIONS_SUCCESS: {
            const {usersPermissions} = (action as GetUserPermissionsActionSuccess).payload;
            
            return {
                ...state,
                usersPermissions
            }
        }

        case SAVE_USER_PERMISSIONS_SUCCESS: {
            const {userPermissions} = (action as SaveUserPermissionsActionSuccess).payload;
            const filteredUsersPermissions = state.usersPermissions.filter(up => up.user.id != userPermissions.user.id);
            
            const usersPermissions = userPermissions.permissions.length > 0 ? filteredUsersPermissions.concat([userPermissions]) : filteredUsersPermissions;

            return {
                ...state,
                usersPermissions
            }
        }
    }

    return state;
    
}