import React, { useState } from 'react';
import {IContactMessengersProps} from './~types/ContactMessengersProps';
import { ChannelType } from 'src/app/contact/~store/models/ChannelType';

import './ContactMessengers.css';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const ContactMessengers = (props: IContactMessengersProps) => {
    const { t } = useTranslation();
    const [hoveredMessengerId, setHoveredMessengerId] = useState(0);

    const _mapMessengersToElement = () => {
        const {messengers} = props;
        if(messengers !== undefined)
            return (
                <React.Fragment> 
                    {messengers.map((messenger, index) => {
                        let icon: any;
                        switch(messenger.channelType) {
                            case ChannelType.Telegram:
                                icon = require("src/app/shared/images/Chat/telegram.svg");
                                break;
                            case ChannelType.Viber:
                                icon = require("src/app/shared/images/Chat/viber.svg");
                                break;
                            case ChannelType.Vk:
                                icon = require("src/app/shared/images/Chat/vk.svg");
                                break;
                            case ChannelType.Widget:
                                icon = require("src/app/shared/images/Chat/widget.svg");
                                break;
                            case ChannelType.Facebook:
                                icon = require("src/app/shared/images/Chat/facebook.svg");
                                break;
                            case ChannelType.WeChat:
                                icon = require("src/app/shared/images/Chat/wechat.svg");
                                break;
                            case ChannelType.Instagram:
                                icon = require("src/app/shared/images/Chat/instagram.svg");
                                break;
                            case ChannelType.EdnaWhatsApp:
                                icon = require("src/app/shared/images/Chat/whatsapp-logo.svg");
                                break;
                            case ChannelType.WhatsApp:
                                icon = require("src/app/shared/images/Chat/whatsapp-logo.svg");
                                break;
                            case ChannelType.VoiceChannel:
                                icon = require("src/app/shared/images/Chat/voice-channel-logo.svg");
                                break;
                        }

                        return ( 
                            <div 
                                key={messenger.messengerId} 
                                className="contact-messenger"
                                onPointerEnter={() => {
                                    setHoveredMessengerId(messenger.messengerId);
                                }}
                                onPointerLeave={() => {
                                    setHoveredMessengerId(0);
                                }}>
                                <img 
                                    className="contact-messenger__icon"
                                    onClick={() => props.newChatWithMessenger?.(messenger)}
                                    src={icon} 
                                    width={20} 
                                    height={20} />
                                <p className="contact-messenger__id">
                                    {messenger.externalId}
                                </p>
                                {(messenger.messengerId === hoveredMessengerId) && props.isNeedDetach && <Button
                                    className="contact-messenger__detach-button"
                                    onClick={() => {props.detach?.(messenger.messengerId)}}>
                                    {t('common.detach')}
                                </Button>}
                            </div>
                        )
                    })}
                </React.Fragment>
            )
        else 
            return (
                <div>

                </div>
            )
    }

    return(
        <React.Fragment>
            {_mapMessengersToElement()}
        </React.Fragment>
    )
}

export default ContactMessengers;