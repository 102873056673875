import * as React from 'react';
import { useEffect } from 'react';
import { Button } from 'reactstrap';
import { showErrorToastr, showSuccessToastr } from 'src/app/shared/helpers/toastr-helper/ToastrHelper';
import { fetchGet, fetchPost } from 'src/~api/ApiHelper';
import { GET_LICENSE_API_ROUTE, UPDATE_LICENSE_API_ROUTE } from '../~api/apiRoutes';

import './LicenseManage.css';
import { useTranslation } from 'react-i18next';

const LicenseManage = () => {
    const { t } = useTranslation();
    const [license, setLicense] = React.useState("");
    const [changedLicense, setChangedLicense] = React.useState("");

    useEffect(() => {
        _getLivenseFromServer();
    }, [])

    const _getLivenseFromServer = () => {
        fetchGet(GET_LICENSE_API_ROUTE)
        .then(function(response) {
            if(response.ok) {
                return response.text();
            }
            showErrorToastr(t('license.licenseErrorMessage'));
            return ""
        })  
        .then(function(text) { 
            setLicense(text);
            setChangedLicense(text);
        })
        .catch(function() {
            showErrorToastr(t('license.licenseErrorMessage'));
        });
    }

    const _onSaveLicense = (e: React.MouseEvent<any, MouseEvent>) => {

        fetchPost(UPDATE_LICENSE_API_ROUTE, {body: JSON.stringify({license: changedLicense})})
        .then(function(response) {
            if(response.ok) {
                showSuccessToastr(t('license.updateLicenseSuccessMessage'))
                setLicense(changedLicense);
            }
            else {
                showErrorToastr(t('license.updateLicenseErrorMessage'));
            }
        })  
        .catch(function() {
            showErrorToastr(t('license.updateLicenseErrorMessage'));
        });
    }

    return(
        <>
            <div className="license-manage-header">{t('license.license')}</div>
            <div className="license-manage-container">
                <div className="license-manage-container_license-lable">{t('license.licenseDetails') + ":"}</div>
                <div>
                    <textarea value={changedLicense} onChange={(e) => {setChangedLicense(e.currentTarget.value)}} className="license-manage-container_license" cols={30} rows={10} style={{resize: "none"}} />
                </div>
                <div>
                    <Button onClick={_onSaveLicense} disabled={license.localeCompare(changedLicense) === 0} className="license-manage-save">{t('common.save')}</Button>
                </div>
            </div>
        </>
    )
}

export default LicenseManage;