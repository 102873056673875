import { AppThunkAction } from '../../../../~store/models/AppThunkAction';
import { LoginAction } from './interfaces';
import { LOGIN_START, LOGIN_ERROR, LOGIN_SUCCESS } from './types';
import { loginAsync } from '../../~api/actions';
import { ILoginResult } from '../models/LoginResult';
import { DEFAULT_ROUTE } from './constants';
import IUser from '../models/User';
import {historyWithForceUpdate} from 'src/~store/history';
import { showErrorToastr } from 'src/app/shared/helpers/toastr-helper/ToastrHelper';
import { MIN_PASSWORD_LENGTH } from 'src/app/operator/operators-main/operators-list/operator-item/OperatorItemConstants';
import Cookies from 'universal-cookie';
import i18n from "src/app/shared/localization/i18n";

export const loginUser = (
    login: string,
    password: string,
    redirectedFrom?: string,
): AppThunkAction<LoginAction> => async dispatch => {
    dispatch({type: LOGIN_START});
    if(password.length < MIN_PASSWORD_LENGTH) {
        showErrorToastr(i18n.t('common.wrongPasswordOrLoginOrLicenseMessage'))
        dispatch({ type: LOGIN_ERROR });
        return;
    }

    const result = await loginAsync(login, password);
    if(result.isSuccess) {
        const loginResult: ILoginResult = result.value ?? {} as ILoginResult;
        
        const user: IUser = {
            login,
            tokenInfo: {
                token: loginResult.token,
                expirationTime: loginResult.expirationDate,
            },
            isAuth: true,
            role: loginResult.role,
            customerId: loginResult.customerId,
            permissions: loginResult.permissions,
            licenseDaysLeft: loginResult.licenseDaysLeft
        }

        sessionStorage.setItem('expiresDialogIsClosed', 'false');
        sessionStorage.setItem('user', JSON.stringify(user));      
        const cookies = new Cookies();
        cookies.set('customerId', user.customerId, { path: '/' });

        console.log(redirectedFrom);
        dispatch({
            type: LOGIN_SUCCESS, 
            payload: {authOperatorId: loginResult.operatorId}})
            historyWithForceUpdate.push(redirectedFrom ?? DEFAULT_ROUTE);
    } else {
        dispatch({ type: LOGIN_ERROR });
        if (result.errorText !== undefined) {
            showErrorToastr(result.errorText);
        }
    }
}