export enum ReportType {
    csv = 1,
    xlsx = 2,
}

export const reportTypeToString = (reportType: ReportType): string => {
    switch (reportType) {
        case ReportType.csv:
            return "csv";
        case ReportType.xlsx:
            return "xlsx";
    }
}