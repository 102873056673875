import { IContactState } from "./state/ContactState";
import { initNewContact, initState } from "./state/InitContactState";
import { Action, Reducer } from "redux";

import { 
    GetAllContactsAction, 
    GetAllContactsSuccessAction, 
    GetContactByIdSuccessAction,
    ChangeSelectedContactAction,
    SaveContactAction,
    DeleteContactAction,
    GetContactByIdAction,
    FindContactByPhoneSuccessAction,
    ChangeContactAvatarSuccessAction,
    ChangeContactAvatarAction,
    SetSearchStringAction,
    RestoreContactSuccessAction,
    DeleteAccountActionSuccess,
    SaveAccountActionSuccess,
    DetachMessengerSuccessAction
} from "./actions/interfaces";

import { 
    GET_ALL_CONTACTS_START, 
    GET_ALL_CONTACTS_SUCCESS, 
    GET_ALL_CONTACTS_ERROR, 
    CREATE_CONTACT,
    DELETE_NEW_CONTACT_SUCCESS,
    SAVE_NEW_CONTACT_SUCCESS,
    GET_CONTACT_BY_ID_START,
    GET_CONTACT_BY_ID_ERROR,
    GET_CONTACT_BY_ID_SUCCESS,
    CHANGE_SELECTED_CONTACT,
    FIND_CONTACT_BY_PHONE_START,
    FIND_CONTACT_BY_PHONE_SUCCESS,
    FIND_CONTACT_BY_PHONE_ERROR,
    CHANGE_CONTACT_AVATAR_SUCCESS,
    SET_SEARCH_STRING,
    SAVE_CONTACT_SUCCESS,
    SAVE_CONTACT_ERROR,
    MERGE_CONTACT,
    RESTORE_CONTACT_START,
    RESTORE_CONTACT_ERROR,
    RESTORE_CONTACT_SUCCESS,
    CLEAR_CONTACT_FOUND_BY_PHONE,
    DELETE_ACCOUNT_SUCCESS,
    SAVE_ACCOUNT_SUCCESS,
    FIND_CONTACT_BY_PHONE_NOT_FOUND,
    TOGGLE_CONTACT_SORTING,
    DETACH_MESSENGER_SUCCESS,
    CLEAR_CONTACT
} from "./actions/types";

import { IContact } from "./models/Contact";
import { stat } from "fs";
import { IMessenger } from "./models/Messenger";

export type KnownActions = 
    GetAllContactsAction |
    SaveContactAction |
    DeleteContactAction |
    GetContactByIdAction |
    ChangeSelectedContactAction |
    ChangeContactAvatarAction;

export const contactReducer: Reducer<IContactState> = (
    state: IContactState = initState, 
    incommingAction: Action
): IContactState => {
    const action = incommingAction as KnownActions;
    
    // contact list
    switch(action.type) {
        case GET_ALL_CONTACTS_START:
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    isReceivingContacts: true,
                }
            };
        
        case GET_ALL_CONTACTS_SUCCESS:
            const receivedContacts = (action as GetAllContactsSuccessAction).payload.contacts;
            const skipNumber = (action as GetAllContactsSuccessAction).payload.skip;
            const countOfContacts = skipNumber > 0 ? 
                state.contactList.countOfContacts + receivedContacts.length :
                receivedContacts.length;
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    isReceivingContacts: false,
                    contacts: skipNumber > 0 ? 
                        state.contactList.contacts.concat(receivedContacts) :
                        receivedContacts,
                    countOfContacts,
                    isContactCreating: false,
                }
            }

        case GET_ALL_CONTACTS_ERROR:
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    isReceivingContacts: false,
                    contacts: [],
                    isContactCreating: false,
                }
            }

        case CREATE_CONTACT:
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    isContactCreating: true,
                    contactItemInfo: {
                        ...state.contactList.contactItemInfo,
                        changedContact: initNewContact(),
                        selectedContact: initNewContact(),
                        contactToMerge: initNewContact(),
                    }
                }
            }

        case DELETE_NEW_CONTACT_SUCCESS:
        case SAVE_NEW_CONTACT_SUCCESS:
        case SAVE_CONTACT_SUCCESS:
            return { 
                ...state,
                contactList: {
                    ...state.contactList,
                    isContactCreating: false,
                }
            }

        case GET_CONTACT_BY_ID_START:
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    contactItemInfo: {
                        ...state.contactList.contactItemInfo,
                        isReceivingContact: true,
                    }
                }
            }

        case GET_CONTACT_BY_ID_ERROR:
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    contactItemInfo: {
                        ...state.contactList.contactItemInfo,
                        isReceivingContact: false,
                    }
                }
            }

        case GET_CONTACT_BY_ID_SUCCESS:
            const contact: IContact = (action as GetContactByIdSuccessAction).payload.contact;
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    contactItemInfo: {
                        ...state.contactList.contactItemInfo,
                        isReceivingContact: false,
                        selectedContact: Object.assign({}, contact),
                        changedContact: Object.assign({}, contact)
                    }
                }
            }

        case CHANGE_SELECTED_CONTACT: 
            const changedContact: IContact = (action as ChangeSelectedContactAction).payload.contact;
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    contactItemInfo: {
                        ...state.contactList.contactItemInfo,
                        changedContact
                    }
                }
            }

        case FIND_CONTACT_BY_PHONE_START:
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    contactItemInfo: {
                        ...state.contactList.contactItemInfo,
                        isReceivingContact: true,
                    }
                }
            }

        case FIND_CONTACT_BY_PHONE_SUCCESS:
            const foundContact = (action as FindContactByPhoneSuccessAction).payload.foundContact;
            if(foundContact?.contactId === state.contactList.contactItemInfo.selectedContact.contactId) {
            return {
                ...state,
                    contactList: {
                        ...state.contactList,
                        contactItemInfo: {
                            ...state.contactList.contactItemInfo,
                            contactToMerge: Object.assign({}, state.contactList.contactItemInfo.selectedContact),
                            isReceivingContact: false,
                        }
                    }
                }
            }

            if(foundContact !== undefined) {
                if(foundContact.messengers !== undefined) {
                    for(let messenger of foundContact.messengers) {
                        messenger.contactId = state.contactList.contactItemInfo.selectedContact.contactId ?? 0;
                    }
                }
                return {
                    ...state,
                    contactList: {
                        ...state.contactList,
                        contactItemInfo: {
                            ...state.contactList.contactItemInfo,
                            contactToMerge: Object.assign({}, foundContact),
                            isReceivingContact: false,
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    contactList: {
                        ...state.contactList,
                        contactItemInfo: {
                            ...state.contactList.contactItemInfo,
                            contactToMerge: initNewContact(),
                            isReceivingContact: false,
                        }
                    }
                }
            }

        case FIND_CONTACT_BY_PHONE_NOT_FOUND: {
            const {phone} = state.contactList.contactItemInfo.changedContact;
            const changedContact = Object.assign({}, state.contactList.contactItemInfo.selectedContact);
            changedContact.phone = phone;


            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    contactItemInfo: {
                        ...state.contactList.contactItemInfo,
                        isReceivingContact: false,
                        contactToMerge: initNewContact(),
                        changedContact
                    }
                }
            }
        }

        case FIND_CONTACT_BY_PHONE_ERROR:
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    contactItemInfo: {
                        ...state.contactList.contactItemInfo,
                        isReceivingContact: false,
                        contactToMerge: initNewContact(),
                    }
                }
            }

        case SET_SEARCH_STRING:
            const searchString = (action as SetSearchStringAction).payload.searchString;
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    searchString
                }
            }

        case CHANGE_CONTACT_AVATAR_SUCCESS:
            const { avatarId } = (action as ChangeContactAvatarSuccessAction).payload;
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    contactItemInfo: {
                        ...state.contactList.contactItemInfo,
                        changedContact: {
                            ...state.contactList.contactItemInfo.changedContact,
                            avatarId
                        }
                    }
                }
            }

        case MERGE_CONTACT:
            const { contactToMerge } = state.contactList.contactItemInfo;
            if(state.contactList.contactItemInfo.selectedContact.contactId !== 0 && state.contactList.contactItemInfo.selectedContact.contactId !== contactToMerge.contactId){
                contactToMerge.accounts = contactToMerge.accounts.concat(state.contactList.contactItemInfo.selectedContact.accounts);
                contactToMerge.messengers = contactToMerge.messengers.concat(state.contactList.contactItemInfo.selectedContact.messengers);
            }
            if(contactToMerge.contactId === undefined || contactToMerge.contactId === 0)
            {
                return {
                    ...state,
                    contactList: {
                        ...state.contactList,
                        contactItemInfo: {
                            ...state.contactList.contactItemInfo,
                            contactToMerge: initNewContact(),
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    contactList: {
                        ...state.contactList,
                        contactItemInfo: {
                            ...state.contactList.contactItemInfo,
                            changedContact: Object.assign({}, contactToMerge),
                            contactToMerge: initNewContact(),
                        }
                    }
                }
            }

        case SAVE_ACCOUNT_SUCCESS: {
            const { account } = (action as SaveAccountActionSuccess).payload;
            const accounts = state.contactList.contactItemInfo.changedContact.accounts.filter(a => a.id != account.id).concat([account]);

            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    contactItemInfo: {
                        ...state.contactList.contactItemInfo,
                        changedContact: {
                            ...state.contactList.contactItemInfo.changedContact,
                            accounts
                        }
                    }
                }
            }
        }

        case DELETE_ACCOUNT_SUCCESS: {
            const { accountId } = (action as DeleteAccountActionSuccess).payload;
            const changedContact = state.contactList.contactItemInfo.changedContact;
            changedContact.accounts = changedContact.accounts.filter(a => a.id != accountId);
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    contactItemInfo: {
                        ...state.contactList.contactItemInfo,
                        changedContact
                    }
                }
            }
        }

        case RESTORE_CONTACT_START:
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    contactItemInfo: {
                        ...state.contactList.contactItemInfo,
                        isRestoringContact: true,
                    }
                }
            }
        
        case RESTORE_CONTACT_ERROR:
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    contactItemInfo: {
                        ...state.contactList.contactItemInfo,
                        isRestoringContact: false,
                    }
                }
            }

        case RESTORE_CONTACT_SUCCESS:
            const { contactId } = (action as RestoreContactSuccessAction).payload;
            const changedContactToRestore = state.contactList.contactItemInfo.changedContact;
            if(contactId === changedContactToRestore.contactId) {
                changedContactToRestore.isDeleted = false;   
            }
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    contactItemInfo: {
                        ...state.contactList.contactItemInfo,
                        isRestoringContact: false,
                        changedContact: changedContactToRestore,
                    }
                }
            }

        case CLEAR_CONTACT_FOUND_BY_PHONE:
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    contactItemInfo: {
                        ...state.contactList.contactItemInfo,
                        contactToMerge: Object.assign({}, {} as IContact),
                    }
                }
            }

        case TOGGLE_CONTACT_SORTING: {
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    isSortingByContactLastName: !state.contactList.isSortingByContactLastName
                }
            }
        }

        case DETACH_MESSENGER_SUCCESS: {
            const { messengerId } = (action as DetachMessengerSuccessAction).payload;
            const changedContact = {...state.contactList.contactItemInfo.changedContact};
            const selectedContact = {...state.contactList.contactItemInfo.selectedContact};
            let messenger = changedContact.messengers.find(msger => msger.messengerId === messengerId);
            let messengerIndex = changedContact.messengers.indexOf(messenger ?? {} as IMessenger);
            changedContact.messengers.splice(messengerIndex, 1);
            
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    contactItemInfo: {
                        ...state.contactList.contactItemInfo,
                        selectedContact,
                        changedContact,
                    }
                }
            }
        }

        case CLEAR_CONTACT:
            return {
                ...state,
                contactList: {
                    ...state.contactList,
                    contactItemInfo: {
                        ...state.contactList.contactItemInfo,
                        changedContact: initNewContact(),
                    }
                }
            }
            

        default:
            return state;
    }
};
