import { JsonHubProtocol } from "@microsoft/signalr";
import React, { useEffect, useState } from "react";
import { Button, Input, NavItem } from "reactstrap";
import { ISetting, SettingType } from "src/app/setting/~store/models/setting";
import { ModalWindow } from "src/app/shared/components/modal-window/ModalWindow";

import "./SettingItem.css";
import { count } from "console";
import { useTranslation } from 'react-i18next';

interface ISettingItemProps {
    setting: ISetting,
    onSettingSave(setting: ISetting): void
}

interface ISettingKeyValuePair {
    key: string,
    value: string
}

const SettingsItem = (props: ISettingItemProps) => {
    const { t } = useTranslation();
    const [setting, setSetting] = useState<ISetting>({...props.setting});
    const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
    const [settingsDict, setSettingsDict] = useState<ISettingKeyValuePair[]>([]);
    const [isEditJson, setEditJson] = useState<boolean>(false);
    const [settingText, setSettingText] = useState<string>(props.setting.value);

    useEffect(() => {
        if(props.setting.value !== null && props.setting.value !== undefined && props.setting.value.trim() !== ''){
            _setSettingDictionaryValue(props.setting.value);
        }     
    }, []);

    const _onSettingLoad = () => {
        _setSettingDictionaryValue(props.setting.value);
    }

    const _setSettingDictionaryValue = (settingValue: string) => {
        if(settingValue) {
            try {
                while (settingsDict.length > 0) {
                    settingsDict.pop()
                }
                setSettingText("");
                const settingObj = JSON.parse(settingValue);
                Object.entries(settingObj).forEach((entry) => {
                    settingsDict.push({ key: entry[0], value: entry[1] as string })
                });
                setSettingsDict(settingsDict);
                setSettingText(JSON.stringify(settingObj));
            }
            catch (ex) {
                console.error(`Parsed string: ${settingValue}.`, ex)
            }
        }
    }

    const _onIdChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setting.id = event.currentTarget.value;
        setSetting({...setting});
    }

    const _onTypeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setting.settingTypeModel = SettingType[event.currentTarget.value as keyof typeof SettingType];
        setSetting({...setting});
    }

    const _onSettingsDictChanged = () => {
        const valueObj = Object.fromEntries(settingsDict.map(item => [item.key, item.value]))

        setSettingText(JSON.stringify(valueObj));
    }

    const _onKeyChanged = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        settingsDict[index].key = event.currentTarget.value;

        setSettingsDict(settingsDict.map(kv => ({...kv})));

        _onSettingsDictChanged();
    }

    const _onValueChanged = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        settingsDict[index].value = event.currentTarget.value;

        setSettingsDict(settingsDict.map(kv => ({...kv})));

        _onSettingsDictChanged();
    }

    const _onJsonTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingText(event.currentTarget.value);

        if(_isSettingTextCorrectJson(event.currentTarget.value)) {
            _setSettingDictionaryValue(event.currentTarget.value);
        }
    }

    const _onDeleteKeyValuePair = (index: number) => {
        if(window.confirm(t('settings.confirmDeleteSetting') + ` ${settingsDict[index].key}?`)) {
            settingsDict.splice(index, 1);

            setSettingsDict(settingsDict.map(kv => ({...kv})));

            _onSettingsDictChanged();
        }
    }

    const _onAddKeyValuePair = () => {
        if(_isEmptyKeyValueExist()) {
            settingsDict.push({key: "", value: ""});

            setSettingsDict(settingsDict.map(kv => ({...kv})));

            _onSettingsDictChanged();
        }
    }

    const _isEmptyKeyValueExist = () => {
        return settingsDict.findIndex(kv => kv.key.length === 0 || kv.value.length === 0) === -1;
    }

    const _isSettingTextCorrectJson = (jsonSettingText: string) => {
        try {
            JSON.parse(jsonSettingText);
        } catch (e) {
            return false;
        }
        return true;
    }

    const _onSaveButtonClick = () => {
        setIsSettingsOpen(false);
        if (!isEditJson) {
            const valueObj = Object.fromEntries(settingsDict.map(item => [item.key, item.value]))

            setting.value = JSON.stringify(valueObj);
        }
        else {
            setting.value = settingText;
        }

        Object.assign(props.setting, {...setting});

        props.onSettingSave(props.setting);
        setSettingText(props.setting.value);
        settingsDict.splice(0, settingsDict.length);
        const settingObj = JSON.parse(props.setting.value);
        Object.entries(settingObj).forEach((entry) => {
            settingsDict.push({ key: entry[0], value: entry[1] as string })
        });
        setSettingsDict(settingsDict);
    }

    const _getSettingsHeader = () => {
        return (
            <>
                <div>
                    {t('common.setting')}
                </div>
                <div className="setting-header__close-button-wrapper">
                    {!isEditJson && (
                        <Button className="setting-header__edit-as-json"
                            onClick={() => {
                                setEditJson(true);
                            }}>
                            {t('common.editAsJson')}
                        </Button>)}
                    {isEditJson && (
                        <Button className="setting-header__edit-as-default"
                            onClick={() => {
                                setEditJson(false);
                            }}>
                            {t('common.editAsTable')}
                        </Button>)}
                    <Button
                        className="setting-header__close-button red"
                        onClick={() => {
                            setIsSettingsOpen(false);
                            _onSettingLoad();
                        }}>
                        <i className="material-icons">
                            close
                        </i>
                    </Button>
                </div>
            </>
        )
    }

    const _getSettingsBody = () => {
        if (!isEditJson) {
            return (
                <>
                    <div className="setting-body__key-value-pairs-list-wrapper">
                        {settingsDict.map((item, index) => (
                            <div key={index} className="setting-body__line">
                                <div className="setting-body__key-wrapper">
                                    <Input
                                        type="text"
                                        placeholder={t('settings.key')}
                                        className="setting-body__key"
                                        value={item.key}
                                        onChange={(event) => { _onKeyChanged(event, index) }}>
                                    </Input>
                                </div>
                                <div className="setting-body__value-wrapper">
                                    <Input
                                        type="textarea"
                                        placeholder={t('settings.value')}
                                        className="setting-body__value"
                                        value={item.value}
                                        onChange={(event) => { _onValueChanged(event, index) }}>
                                    </Input>
                                </div>
                                <div className="setting-body__delete-key-value-wrapper">
                                    <Button
                                        className="setting-body__delete-key-value"
                                        onClick={() => { _onDeleteKeyValuePair(index) }}>
                                        <i className="material-icons">
                                            delete_forever
                                        </i>
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="setting-body__add-button-wrapper">
                        <Button
                            disabled={!_isEmptyKeyValueExist()}
                            onClick={() => { _onAddKeyValuePair() }}>
                            <i className="material-icons">
                                add
                            </i>
                        </Button>
                    </div>
                </>
            )
        }
        return (
            <div className="setting-body__text-input">
                <Input
                    className="setting-body__textarea"
                    type="textarea"
                    value={settingText}
                    onChange={(event) => { _onJsonTextChanged(event) }}/>
            </div>
            )
    }

    const _getSettingsFooter = () => {
        return (
            <div className="setting-footer-wrapper">
                <Button
                    disabled={(!_isEmptyKeyValueExist() && !isEditJson) || (!_isSettingTextCorrectJson(settingText) && isEditJson)}
                    onClick={() => _onSaveButtonClick()}>
                    {t('common.save')}
                </Button>
            </div>
        )
    }

    return (
        <div className="setting-item-container">
            <Button
                className=""
                onClick={() => setIsSettingsOpen(true)}>
                <i className="material-icons">
                    settings
                </i>
            </Button>

            <ModalWindow 
                className="setting-item-modal" 
                isOpen={isSettingsOpen} 
                header={_getSettingsHeader()} 
                body={_getSettingsBody()}  
                footer={_getSettingsFooter()}
            />
        </div>
    );
}

export default SettingsItem;