import React, { useEffect, useState } from "react";
import SearchingInput from "src/app/shared/components/searching-input/SearchingInput";
import {Button, Table} from "reactstrap";
import {IApplicationState} from "src/~store/models/ApplicationState";
import history from "src/~store/history";
import {IContactListProps} from "./~types/ContactListProps";
import Loader from "src/app/shared/components/loader/Loader";
import {ContactItem} from "./contact-item/ContactItem";
import { 
    REQUESTED_NUMBER_OF_CONTACTS, 
    REQUESTED_NUMBER_OF_CONTACTS_FIRST_TIME,
    TRIGGER_SCROLL_HEIGHT
} from "./ContactListConstants";
import {connect} from "react-redux";

import {
    contactsSelector, 
    isReceivingContactsSelector, 
    isContactCreatingSelector,
    countOfContactsSelector,
    searchStringSelector,
    isSortingByContactLastNameSelector,
    isChatVisibleSelector,
} from "../../~store/selector";

import {
    getContacts,
    createContact,
    setSearchString,
    toggleContactSorting,
    clearContact,
} from "src/app/contact/~store/actions/actions";

import "./ContactList.css";
import { useTranslation } from 'react-i18next';

type ReduxType = ReturnType<typeof mapStateToProps> & IContactListProps;

const ContactList = (props: ReduxType) => {
    const { t } = useTranslation();
    useEffect(() => {
        props.getContacts(0, REQUESTED_NUMBER_OF_CONTACTS_FIRST_TIME, props.searchString, props.isSortingByContactLastName, false);
    }, []);
    const [isReceiving, setIsReceiving] = useState<boolean>(false);

    const {isReceivingContacts} = props;

    const _getContactsTable = () => {
        return (
            <React.Fragment>
                {(props.contacts.length > 0) && <Table className="contact-list-table card-table">
                    {_getContactsTableHead()}
                    {_mapContactsToTableBody()}
                </Table>}
                {props.contacts.length === 0 && !props.isReceivingContacts &&
                    <div className="contact-list__no-contacts-text">
                        {t('contact.noContacts')}
                    </div>}
            </React.Fragment>
        );
    }

    const contactSortingHandler = () => {
        if(props.isReceivingContacts) return;

        props.getContacts(0, REQUESTED_NUMBER_OF_CONTACTS, props.searchString, !props.isSortingByContactLastName, false);
        props.toggleContactSorting();
    }

    const _getContactsTableHead = () => {
        return (
            <thead>
                <tr>
                    <th>
                        <span className="channel-list__name" onClick={() => contactSortingHandler()}>{t('common.contact')}</span>
                        <span>{props.isSortingByContactLastName ? "⯆" : "⯈"}</span>
                    </th>
                    <th className="channel-list__messenger-header">{t('common.messengers')}</th>
                    <th>{t('common.created')}</th>
                    <th>{t('common.chats')}</th>
                </tr>
            </thead>
        );
    }

    const _mapContactsToTableBody = () => {
        const {contacts} = props;
        return (
            <tbody>
                {contacts.map((contact, index) => {
                    return (
                        <ContactItem 
                            contact={contact}
                            key={index}
                            onSelectContact={props.clearContact} />
                    )
                })}
            </tbody>
        )
    }

    const _createContactHandler = () => {
        if (!props.isContactCreating) {
            props.createContact();
        }
        history.push("/contacts/edit-contact/0");
    }

    const _onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (!isReceiving && (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight <= TRIGGER_SCROLL_HEIGHT) && !props.isReceivingContacts) {      
            setIsReceiving(true);
            props.getContacts(props.countOfContacts, REQUESTED_NUMBER_OF_CONTACTS, props.searchString, props.isSortingByContactLastName, false);
            setTimeout(() => {
                setIsReceiving(false);
            })
        }
    }

    return (
        <div className="contact-list-container">
                <div className="contact-list__header">
                    <Button 
                        className="contact-list__btn-create"
                        onClick={_createContactHandler}>
                                {t('contact.createContact')}
                    </Button>
                    <SearchingInput
                        value={props.searchString}
                        className="contact-list__search"
                        onSearch={props.setSearchString}
                        placeholder={t('contact.searchingInputPlaceholder')} />
                </div>
                <div 
                    onScroll={_onScroll} 
                    className={`contacts-list__body ${(props.isChatVisible ? "splittered" : "")}`}>
                    {_getContactsTable()}
                    {(isReceivingContacts && !isReceiving && <Loader />)}
                </div>
            </div>
    );
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        contacts: contactsSelector(state),
        isReceivingContacts: isReceivingContactsSelector(state),
        isContactCreating: isContactCreatingSelector(state),
        countOfContacts: countOfContactsSelector(state),
        searchString: searchStringSelector(state),
        isSortingByContactLastName: isSortingByContactLastNameSelector(state),
        isChatVisible: isChatVisibleSelector(state),
    };
};

const mapDispatchToProps = (dispatch: any): IContactListProps => {
    return {
        getContacts: (
            skip: number, 
            count: number,
            searchString: string,
            isSortingByContactLastName: boolean
        ) => dispatch(getContacts(skip, count, searchString, isSortingByContactLastName, false)),
        createContact: () => dispatch(createContact()),
        setSearchString: (searchString: string) => dispatch(setSearchString(searchString)),
        toggleContactSorting: () => dispatch(toggleContactSorting()),
        clearContact: () => dispatch(clearContact())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);
