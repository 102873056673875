import { IApiActionResult } from "../../../../src/~api/ApiActionResult";
import { IChatItem } from "../~store/models/ChatItem";
import {IOperatorItem} from "../~store/models/OperatorItem"
import {IChannelItem, OperatorPriorityType} from "../~store/models/ChannelItem"
import {IContactItem} from "../~store/models/ContactItem"
import { getApiActionResult, fetchGet, fetchPost } from "../../../../src/~api/ApiHelper";

import {
    GET_OPENED_OPERATOR_CHATS_API_ROUTE, 
    ASSIGNMENT_CHATS_TO_OPERATOR_API_ROUTE, 
    GET_CHAT_MESSAGES_API_ROUTE, 
    SEND_CHAT_MESSAGE_API_ROUTE,
    GET_OPERATOR_API_ROUTE,
    GET_SETTING_BY_CHANNEL_ID_API_ROUTE,
    CLOSE_CHAT_BY_ID_API_ROUTE,
    GET_CONTACT_API_ROUTE,
    GET_OPERATOR_CHANNELS_API_ROUTE,
    GET_ONLINE_OPERATORS_API_ROUTE,
    GET_OPENED_CHATS_BY_OPERATOR_ID_API_ROUTE,
    TRANSFER_CHAT_API_ROUTE,
    GET_ONLINE_OPERATORS_TO_INVITE_API_ROUTE,
    INVITE_OPERATORS_TO_CHAT_API_ROUTE,
    REMOVE_OPERATOR_FROM_CHAT_API_ROUTE,
    OPERATOR_SEND_FILE_MESSAGE_API_ROUTE,
    EDIT_MESSAGE_API_ROUTE,
    OPEN_CHAT_TO_OPERATOR_API_ROUTE,
    JOIN_CHAT_API_ROUTE,
    GET_ANSWER_FROM_HISTORY_CHAT_API_ROUTE,
    REMOVE_ANSWER_FROM_HISTORY_CHAT_API_ROUTE,
    EXPORT_CHAT_TO_EXTERNAL_CRM_API_ROUTE,
    GET_ONLINE_OPERATORS_TO_TRANSFER_API_ROUTE,
    GET_CHANNEL_OPERATORS_API_ROUTE,
    GET_OPENED_CONTACT_CHATS_API_ROUTE,
    GET_OPERATORS_STATUS_LIST_API_ROUTE,
    CONTINUE_CHAT_API_ROUTE,
    GET_AVAILIBLE_TIERS_API_ROUTE,
    TRANSFER_CHAT_TO_TIER_API_ROUTE,
    GET_AVAILABLE_CONTACT_TAGS_API_ROUTE,
    ADD_CONTACT_TAGS_API_ROUTE,
    DELETE_CONTACT_TAGS_API_ROUTE,
    GET_AVAILIBLE_BOTS_TRANSFER_TO_API_ROUTE,
    GET_SETTINGS_BY_ID_API_ROUTE,
    PAUSE_CHAT_API_ROUTE,
    UNPAUSE_CHAT_API_ROUTE,
    GET_IS_CONTACT_ONLINE_API_ROUTE,
    MARK_CHAT_AS_SPAM,
    IS_CHAT_MARKED_AS_SPAM,
} from "./apiRoutes";
import { IMessageItem, MessageType } from "../~store/models/MessageItem";
import { FileType } from "src/app/shared/components/choose-file-dialog/ChooseFileDialog";
import { GET_CONTACT_CHATS_LAZY_API_ROUTE } from "src/app/chats/~api/apiRoutes";
import { IAnswersFromHistory, IQuickAnswer } from "../~store/models/QuickAnswer";
import { IChannelOperator } from "src/app/channel/~store/models/ChannelOperator";
import { IOperatorStatusViewModel } from "../~store/state/ChatItemsState";
import { ISetting } from "src/app/setting/~store/models/setting";
import { TopicPairContentType } from "src/app/nlp/~store/models/enums/TopicPairContentType";
import { PAUSE_CATEGORIES } from "src/app/chat/pause-chat-dialog/~types/PauseChatProps";

export const getChatsAsync = async (): Promise<IApiActionResult<IChatItem[]>> => {
    const fetchPromise = fetchGet(GET_OPENED_OPERATOR_CHATS_API_ROUTE);
    return await getApiActionResult(fetchPromise, true);
};

export const getChatMessagesAsync = async (chatId: string): Promise<IApiActionResult<IMessageItem[]>> => {
    const fetchPromise = fetchGet(GET_CHAT_MESSAGES_API_ROUTE + '?chatId=' + chatId);
    return await getApiActionResult(fetchPromise, true);
};

export const sendChatMessageAsync = async (chatId: number, text: string, operatorId: number, contentType: TopicPairContentType): Promise<IApiActionResult<IMessageItem>> => {
    const fetchPromise = fetchPost(SEND_CHAT_MESSAGE_API_ROUTE, {
        body: JSON.stringify({
            "chatId": chatId,
            "text": text,
            "messageType": MessageType.operatorMessage,
            "operatorId": operatorId,
            "contentType": contentType
        })
    });
    return await getApiActionResult(fetchPromise, true);
};

export const onOperatorSendFileMessageAsync = async (file: File, type: FileType, chatId: number): Promise<IApiActionResult<void>> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type.toString());
    formData.append("chatId", chatId.toString());

    const fetchPromise = fetchPost(OPERATOR_SEND_FILE_MESSAGE_API_ROUTE, {body: formData});
    return await getApiActionResult(fetchPromise, false);
};

export const getOperatorInfoAsync = async (): Promise<IApiActionResult<IOperatorItem>> => {
    const fetchPromise = fetchGet(GET_OPERATOR_API_ROUTE);
    return await getApiActionResult(fetchPromise, true);
};

export const getOperatorChannelsAsync = async (): Promise<IApiActionResult<IChannelItem[]>> => {
    const fetchPromise = fetchGet(GET_OPERATOR_CHANNELS_API_ROUTE);
    return await getApiActionResult(fetchPromise, true);
};

export const getOperatorStatusListAsync = async (): Promise<IApiActionResult<IOperatorStatusViewModel[]>> => {
    const fetchPromise = fetchGet(GET_OPERATORS_STATUS_LIST_API_ROUTE);
    return await getApiActionResult(fetchPromise, true);
};

export const getAvailableContactTagsAsync = async (): Promise<IApiActionResult<string[]>> => {
    const fetchPromise = fetchGet(GET_AVAILABLE_CONTACT_TAGS_API_ROUTE);
    return await getApiActionResult(fetchPromise, true);
};

export const getSettingsByIdAsync = async (Id: string): Promise<IApiActionResult<ISetting>> => {
    const fetchPromise = fetchGet(GET_SETTINGS_BY_ID_API_ROUTE + "?settingId=" + Id);
    return await getApiActionResult(fetchPromise, true);
};

export const getChannelInfoAsync = async (channelId: number): Promise<IApiActionResult<ISetting>> => {
    const fetchPromise = fetchGet(GET_SETTING_BY_CHANNEL_ID_API_ROUTE + "?channelId=" + channelId);
    return await getApiActionResult(fetchPromise, true);
};

export const getContactInfoAsync = async (contactId: number): Promise<IApiActionResult<IContactItem>> => {
    const fetchPromise = fetchGet(GET_CONTACT_API_ROUTE + "?contactId=" + contactId);
    return await getApiActionResult(fetchPromise, true);
};

export const closeChatAsync = async (chatId: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(CLOSE_CHAT_BY_ID_API_ROUTE, {
        body: JSON.stringify({
            "chatId": chatId
        })
    });
    return await getApiActionResult(fetchPromise, false);
};

export const removeOperatorFromChatAsync = async (operatorIdToRemove: number, chatId: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(REMOVE_OPERATOR_FROM_CHAT_API_ROUTE + 
        `?chatId=${chatId}&operatorToRemove=${operatorIdToRemove}`)
    return await getApiActionResult(fetchPromise, false);
};

export const OpenChatAsync = async (chatId: number, operatorId: number): Promise<IApiActionResult<boolean>> => {
    const fetchPromise = fetchPost(OPEN_CHAT_TO_OPERATOR_API_ROUTE + 
        `?chatId=${chatId}&operatorId=${operatorId}`)
    return await getApiActionResult(fetchPromise, true);
};

export const getOpenedContactChatsAsync = async (contactId: number): Promise<IApiActionResult<IChatItem[]>> => {
    const fetchPromise = fetchPost(GET_OPENED_CONTACT_CHATS_API_ROUTE + contactId)
    return await getApiActionResult(fetchPromise, true); 
};

export const assignmentChatsToOperatorAsync = async (): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchGet(ASSIGNMENT_CHATS_TO_OPERATOR_API_ROUTE);
    return await getApiActionResult(fetchPromise, false);
};

export const getOnlineOperatorsToTransferAsync = async (chatId: number): Promise<IApiActionResult<IOperatorItem[]>> => {
    const fetchPromise = fetchGet(GET_ONLINE_OPERATORS_TO_TRANSFER_API_ROUTE + "?chatId=" + chatId);
    return await getApiActionResult(fetchPromise, true);
}

export const getAvailableTiersAsync = async (channelId: number): Promise<IApiActionResult<OperatorPriorityType[]>> => {
    const fetchPromise = fetchGet(GET_AVAILIBLE_TIERS_API_ROUTE + "?channelId=" + channelId);
    return await getApiActionResult(fetchPromise, true);
}

export const getAvailableBotsTransferToAsync = async (channelId: number): Promise<IApiActionResult<IOperatorItem[]>> => {
    const fetchPromise = fetchGet(GET_AVAILIBLE_BOTS_TRANSFER_TO_API_ROUTE + "?channelId=" + channelId);
    return await getApiActionResult(fetchPromise, true);
}

export const getAsync = async (channelId: number): Promise<IApiActionResult<OperatorPriorityType[]>> => {
    const fetchPromise = fetchGet(GET_AVAILIBLE_TIERS_API_ROUTE + "?channelId=" + channelId);
    return await getApiActionResult(fetchPromise, true);
}

export const getOnlineOperatorsToInviteAsync = async (chatId: number): Promise<IApiActionResult<IOperatorItem[]>> => {
    const fetchPromise = fetchGet(GET_ONLINE_OPERATORS_TO_INVITE_API_ROUTE + "?chatId=" + chatId);
    return await getApiActionResult(fetchPromise, true);
}

export const getOpenedOperatorChatsByIdAsync = async (operatorId: number): Promise<IApiActionResult<IChatItem[]>> => {
    const fetchPromise = fetchGet(GET_OPENED_CHATS_BY_OPERATOR_ID_API_ROUTE + operatorId);
    return await getApiActionResult(fetchPromise, true);
}

export const transferChatAsync = async (redirectedBy: number, redirectedTo: number, chatId: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(TRANSFER_CHAT_API_ROUTE + redirectedBy + "&redirectedTo=" + redirectedTo + "&chatId=" + chatId);
    return await getApiActionResult(fetchPromise, false);
}

export const markChatAsSpamAsync = async (chatId: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(MARK_CHAT_AS_SPAM, {
        body: JSON.stringify({
            "chatId": chatId
        })
    });
    return await getApiActionResult(fetchPromise, false);
}

export const isChatSpamAsync = async (chatId: number): Promise<IApiActionResult<boolean>> => {
    const fetchPromise = fetchGet(IS_CHAT_MARKED_AS_SPAM + chatId);
    return await getApiActionResult(fetchPromise, true);
}

export const transferChatToTierAsync = async (tier: OperatorPriorityType, chatId: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(TRANSFER_CHAT_TO_TIER_API_ROUTE, {
        body: JSON.stringify({
            "tier": tier,
            "chatId": chatId
        })
    });
    return await getApiActionResult(fetchPromise, false);
}

export const inviteOperatorsToChatAsync = async (operators: IOperatorItem[], chatId: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(INVITE_OPERATORS_TO_CHAT_API_ROUTE, {
        body: JSON.stringify({
            "operators": operators,
            "chatId": chatId
        })
    });
    return await getApiActionResult(fetchPromise, false);
}

export const editMessageAsync = async (message: IMessageItem): Promise<IApiActionResult<IMessageItem>> => {
    const fetchPromise = fetchPost(EDIT_MESSAGE_API_ROUTE, {
        body: JSON.stringify({
            messageId: message.messageId,
            messageText: message.text,
            imageId: message.imageId,
            fileId: message.fileId,
            contentType: message.contentType
        })
    });

    return await getApiActionResult(fetchPromise, true);
}

export const addChatHistoryAsync = async (startChatId: number, beforeCount: number, afterCount: number): Promise<IApiActionResult<IChatItem[]>> => {
    const fetchPromise = fetchGet(
        encodeURI(GET_CONTACT_CHATS_LAZY_API_ROUTE + startChatId + 
        "&beforeCount=" + beforeCount + 
        "&afterCount=" + afterCount));
    return await getApiActionResult(fetchPromise, true);
}

export const joinChatAsync = async (chatId: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(
        JOIN_CHAT_API_ROUTE + chatId
    );
    
    return await getApiActionResult(fetchPromise);
}

export const pauseChatAsync = async (chatId: number, pauseCategory: PAUSE_CATEGORIES): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchGet(PAUSE_CHAT_API_ROUTE + chatId + '&pausecategory=' + pauseCategory);
    
    return await getApiActionResult(fetchPromise);
}

export const unpauseChatAsync = async (chatId: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchGet(UNPAUSE_CHAT_API_ROUTE + chatId);
    
    return await getApiActionResult(fetchPromise);
}

export const getQuickAnswersAsync = async (text: string, operatorId: number): Promise<IApiActionResult<IAnswersFromHistory>> => {
    const fetchPromise = fetchGet(GET_ANSWER_FROM_HISTORY_CHAT_API_ROUTE + text + ((operatorId) ? '&operatorid=' + operatorId : ''));
    return await getApiActionResult(fetchPromise, true);
}

export const removeQuickAnswerAsync = async (text: string): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(REMOVE_ANSWER_FROM_HISTORY_CHAT_API_ROUTE, {
        body: JSON.stringify({
            answerFromHistory: text,
        })
    });
    return await getApiActionResult(fetchPromise);
}

export const exportChatToZendeskAsync = async (chatId: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(
        EXPORT_CHAT_TO_EXTERNAL_CRM_API_ROUTE + chatId
    );

    return await getApiActionResult(fetchPromise, false);
}

export const getChannelOperatorsAsync = async (operatorsIds: number[], channelId: number): Promise<IApiActionResult<IChannelOperator[]>> => {
    let queryString: string = `${GET_CHANNEL_OPERATORS_API_ROUTE}`;
    operatorsIds?.forEach((val, i) => {
        if(i === 0) 
            queryString += `operatorsIds=${val}`;
        else
            queryString += `&operatorsIds=${val}`
    });
    queryString += `&channelId=${channelId}`;
    const fetchPromise = fetchGet(
        queryString
    );

    return await getApiActionResult(fetchPromise, true);
}

export const addContactTagsAsync = async (tags: string[], contactId: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(ADD_CONTACT_TAGS_API_ROUTE, {
        body: JSON.stringify({
            tags, 
            contactId
        })
    });

    return await getApiActionResult(fetchPromise, false);
}

export const deleteContactTagsAsync = async (tags: string[], contactId: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(DELETE_CONTACT_TAGS_API_ROUTE, {
        body: JSON.stringify({
            tags, 
            contactId
        })
    });

    return await getApiActionResult(fetchPromise, false);
}

export const getIsContactOnlineAsync = async (channelId: number, messengerExternalId: string): Promise<IApiActionResult<boolean>> => {
    const fetchPromise = fetchGet(GET_IS_CONTACT_ONLINE_API_ROUTE + '?channelId=' + channelId + '&messengerExternalId=' + messengerExternalId);
    return await getApiActionResult(fetchPromise, true);
}