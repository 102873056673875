const HOST_API_ROUTE = `${process.env.REACT_APP_CHAT_API_ROUTE}`;

const CHANNEL_API_ROUTE = `${HOST_API_ROUTE}/channel`;
const MAILING_API_ROUTE = `${HOST_API_ROUTE}/mailing`;

export const GET_ENABLED_CHANNELS_API_ROUTE = `${CHANNEL_API_ROUTE}/get-enabled-channels`;

export const SAVE_MAILING_API_ROUTE = `${MAILING_API_ROUTE}/save-mailing`;
export const GET_MAILING_RECIPIENT_API_ROUTE = `${MAILING_API_ROUTE}/get-mailing-recipients-by-mailing-id-lazy`;
export const GET_MAILING_BY_ID_API_ROUTE = `${MAILING_API_ROUTE}/get-mailing-by-id?mailingId=`;
export const GET_MAILINGS_LAZY_API_ROUTE = `${MAILING_API_ROUTE}/get-mailings-lazy`;
export const START_MAILING_API_ROUTE = `${MAILING_API_ROUTE}/start-mailing/?mailingId=`;
export const DELETE_MAILING_API_ROUTE = `${MAILING_API_ROUTE}/delete-mailing-by-id/?mailingId=`;



