export enum ChannelOperatorPriority 
{
    Tier1 = 1,
    Tier2 = 2,
    Tier3 = 3,
    Tier4 = 4,
    Tier5 = 5,

    Feedback = 10,
    TierVIP = 11,
    Spam = 12
}