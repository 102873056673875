import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { resources } from "./resources";
import { Language } from "./localization.types";

i18n.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: Language.en,
  resources,
});

export default i18n;
