import * as React from "react";

import {IModalWindowProps} from "./~types/ModalWindowProps";

import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";

import "./ModalWindow.css";

export class ModalWindow extends React.Component<IModalWindowProps> {
    public render() {
        return (
            <Modal isOpen={this.props.isOpen} className={this.props.className}>
                <ModalHeader>{this.props.header}</ModalHeader>
                <ModalBody>{this.props.body}</ModalBody>
                <ModalFooter>{this.props.footer}</ModalFooter>
            </Modal>
        );
    }
}
