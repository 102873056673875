import { ISearchingTag, TagName, TagType } from "./../../../../shared/components/tag-searching-input/~types/models/SearchingTag";
import i18n from "src/app/shared/localization/i18n";

export const getAnalyticsSearchingTags = (): ISearchingTag[] => [
    {
        id: 1,
        tagName: TagName.Date,
        value: i18n.t('common.date'),
        suggestions: ["3", "5"],
        tagType: TagType.OpeningTag
    },
    { 
        id: 3,
        value: "=",
        tagType: TagType.OperationTag
    },
    {
        id: 5,
        tagName: TagName.Date,
        value: "",
        suggestions: [
            i18n.t('common.today'),
            i18n.t('common.yesterday'),
            i18n.t('common.currentWeek'),
            i18n.t('common.currentMonth'),
            i18n.t('common.currentYear'),
            i18n.t('common.period')
        ],
        tagType: TagType.ClosingTag
    },
    {
        id: 6,
        tagName: TagName.ChannelName,
        value: i18n.t('common.channelName'),
        suggestions: ["3", "7"],
        tagType: TagType.OpeningTag
    },
    {
        id: 7,
        tagName: TagName.ChannelName,
        value: "",
        suggestions: [],
        tagType: TagType.ClosingTag
    },
]