import { History } from 'history';
import thunk from 'redux-thunk';
import { IApplicationState } from './models/ApplicationState';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import {
    combineReducers,
    createStore,
    compose,
    applyMiddleware
} from 'redux';
import { reducers } from './reducers';

export default function configureStore(history: History, initialState?: IApplicationState) {
    const middleware = [thunk, routerMiddleware(history)];

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    const enhancers = [];

    const windowIfDefined = typeof window === 'undefined' ?
        null :
        (window as any);

    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__());
    }

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers));
}