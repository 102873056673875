import * as React from 'react';
import IAnswerHolderProps from './~types/AnswerHolderProps';

import './AnswerHolder.css';
import { Link } from 'react-scroll';
import { botTextToComponentWithLinks } from 'src/app/shared/helpers/weblinks-helper/WeblinksHelper';

export default class AnswerHolder extends React.Component<IAnswerHolderProps> {
    public render() {
        const {topicPairId, confidence, text} = this.props.answer;
        return(
            <div className="answer-holder">
                {topicPairId && (
                    <div className="answer-holder__header">
                        <Link 
                            className="answer-holder__link" 
                            smooth={true}
                            to={topicPairId.toString()}>
                                #{topicPairId}
                        </Link>
                        {confidence}%
                    </div>)}
                <div className="answer-holder__body">
                    {botTextToComponentWithLinks(text)}
                </div>
            </div>
        );
    }
}

