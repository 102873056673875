import {IContact} from './../../../contact/~store/models/Contact';
import {IChat} from './../../../chats/~store/models/Chat';

export interface IMailingRecipient {
    mailingRecipientId: number,
    mailingId: number,
    contactId: number,
    contact: IContact,
    chatId: number,
    chat: IChat,
    status: MailingRecipientStatusModel,
    errorMessage: string
}

export enum MailingRecipientStatusModel{
    Created,
    Postponed,
    Sent,
    Failed
}

export const mailingRecipientStatusModelToString = (status: MailingRecipientStatusModel) : string => {
    switch (status) {
        case MailingRecipientStatusModel.Created:
            return "Created";
        case MailingRecipientStatusModel.Failed:
            return "Failed";
        case MailingRecipientStatusModel.Postponed:
            return "Postponed";
        case MailingRecipientStatusModel.Sent:
            return "Sent";
        default:
            return "";
    }
}