import * as React from "react";
import {Input} from "reactstrap";
import {IScalableTextInputProps} from "./~types/IScalableTextInputProps";
import {IScalableTextInputState} from "./~types/IScalableTextInputState";

import "./ScalableTextInput.css";

class ScalableTextInput extends React.Component<IScalableTextInputProps, IScalableTextInputState> {
    public readonly state: Readonly<IScalableTextInputState> = {
        element: this.props.innerRef ?? React.createRef<HTMLInputElement>(),
    };

    public componentDidMount() {
        this.resize();
    }

    public render() {
        const propsClassName = !!this.props.className ? this.props.className : "";
        const propsPlaceholder = !!this.props.placeholder ? this.props.placeholder : "";

        return (
            <Input
                type="textarea"
                value={this.props.value}
                innerRef={this.state.element}
                onChange={this._onChangeHandler}
                className={"scalable-text-input " + propsClassName}
                placeholder={propsPlaceholder}
                onKeyDown={this._onKeyDownHandler}
                autoFocus={this.props.value ? false : true}
            />
        );
    }

    private _onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.props.onChange(event);
        this.resize();
    };

    private _onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        this.props.onKeyDown?.(event);
        if (event.keyCode == 13) {
            event.preventDefault();
        }
    }

    public resize = () => {
        const element: HTMLInputElement | null = this.state.element.current;
        element?.style.setProperty("height", "auto");
        element?.style.setProperty("height", element?.scrollHeight + "px");
    };
}

export default ScalableTextInput;
