import * as React from "react";
import { connect } from "react-redux";

import { ITopicPair } from "../../~store/models/TopicPair";
import { TopicPairStatus } from "../../~store/models/enums/TopicPairStatus";
import { IApplicationState } from "src/~store/models/ApplicationState";
import { ITopicPairsTabsProps } from "./~types/TopicPairsTabsProps";
import { MessageBoxType } from "../../~store/models/enums/MessageBoxType";

import {
  saveTopicPair,
  createTopicPair,
  deleteTopicPair,
  cancelCreateTopicPair,
  changeTopicPairStatus,
  changeSelectedTopicPairStatus,
  setTransferQuestionPopupData,
  toggleTransferQuestionPopupVisibility,
  setMessageBoxData,
  toggleMessageBoxVisibility,
  setFilterTags,
  getTopicByExternalId,
} from "../../~store/actions/actions";
import {
  editedTopicPairsSelector,
  isCreatingTopicPairSelector,
  selectedTopicPairStatusSelector,
  topicPairCategoriesSelector,
  filterTagsSelector,
  topicPairSourcesSelector,
  selectedTopicParametersJsonSelector,
} from "../../~store/selectors";
import TagSearchingInput from "src/app/shared/components/tag-searching-input/TagSearchingInput";
import { getNlpSearchingTags } from "./../../~store/actions/constants";
import { ISearchingTag } from "src/app/shared/components/tag-searching-input/~types/models/SearchingTag";

import { Button } from "reactstrap";
import TopicPairsItem from "../topic-pairs-item/TopicPairsItem";

import "./TopicPairsTabs.css";
import i18n from "src/app/shared/localization/i18n";
import {
  TopicPairContentType,
  stringToDefaultTopicPairContentTypeSetting,
} from "../../~store/models/enums/TopicPairContentType";
import Paging from "src/app/shared/components/paging/Paging";
import { TOPIC_PAIRS_ON_PAGE } from "../../~store/actions/constants";

type ReduxType = ReturnType<typeof mapStateToProps> & ITopicPairsTabsProps;

const TopicPairsTabs = (props: ReduxType) => {
  const {
    selectedTopicPairStatus,
    isCreatingTopicPair,
    topicPairsCount,
    topicExternalId,
    selectedPage,
    setSelectedPage,
    topicPairsRef,
  } = props;

  const isSelectedActiveStatus =
    selectedTopicPairStatus === TopicPairStatus.Active;
  const isSelectedPendingStatus =
    selectedTopicPairStatus === TopicPairStatus.Pending;
  const isSelectedInactiveStatus =
    selectedTopicPairStatus === TopicPairStatus.Inactive;

  const _onCreateTopicPairHandler = (): void => {
    const { selectedTopicPairStatus } = props;
    props.createTopicPair(selectedTopicPairStatus, _getDefaultContentType());
  };

  const _onCancelCreateTopicPairHandler = (): void => {
    props.cancelCreateTopicPair();
  };

  const _onTopicPairCommitChangeHandler = (
    editedTopicPair: ITopicPair
  ): void => {
    props.saveTopicPair(props.topicExternalId, editedTopicPair, selectedPage);
  };

  const _onDeleteTopicPairHandler = (topicPaidId: number): void => {
    props.setMessageBoxData({
      title: i18n.t("nlp.topicRemoving"),
      message: i18n.t("nlp.doWantDeleteTopic"),
      type: MessageBoxType.Confirm,
      onConfirm: () =>
        props.deleteTopicPair(props.topicExternalId, topicPaidId, selectedPage),
    });
    props.toggleMessageBoxVisibility();
  };

  const _onChangeTopicPairStatusHandler = (
    topicPairId: number,
    topicPairstatus: TopicPairStatus
  ): void => {
    props.changeTopicPairStatus(
      props.topicExternalId,
      topicPairId,
      topicPairstatus
    );
    setSelectedPage(1);
  };

  const _onTransferTopicPairQuestionHandler = (
    topicPairId: number,
    questionId: number
  ): void => {
    props.setTransferQuestionPopupData(
      props.topicExternalId,
      topicPairId,
      questionId
    );
    props.toggleTransferQuestionPopupVisibility();
  };

  const _changeActiveStatusTabToActive = (): void => {
    if (props.selectedTopicPairStatus !== TopicPairStatus.Active) {
      _changeActiveStatusTab(TopicPairStatus.Active);
    }
  };

  const _changeActiveStatusTabToInactive = (): void => {
    if (props.selectedTopicPairStatus !== TopicPairStatus.Inactive) {
      _changeActiveStatusTab(TopicPairStatus.Inactive);
    }
  };

  const _changeActiveStatusTabToPending = (): void => {
    if (props.selectedTopicPairStatus !== TopicPairStatus.Pending) {
      _changeActiveStatusTab(TopicPairStatus.Pending);
    }
  };

  const _changeActiveStatusTab = (topicPairStatus: TopicPairStatus): void => {
    setSelectedPage(1);
    props.changeSelectedTopicPairStatus(topicPairStatus);
  };

  const _getDefaultContentType = (): TopicPairContentType => {
    const settings = props.selectedTopicParametersJson;
    const topicPairContentTypeString = JSON.parse(settings)?.DefaultContentType;
    const topicPairContentType = stringToDefaultTopicPairContentTypeSetting(
      topicPairContentTypeString
    );
    return topicPairContentType;
  };

  const _getTopicPairsItemsBySelectedStatus = (): JSX.Element[] => {
    const { selectedTopicPairStatus } = props;
    return props.editedTopicPairs
      .filter((tp) => tp.status === selectedTopicPairStatus)
      .map((topicPair) => (
        <TopicPairsItem
          key={topicPair.topicPairId}
          topicPairCategories={props.topicPairCategories}
          innerTopicPair={topicPair}
          topicPairContentType={_getDefaultContentType()}
          onDeleteTopicPair={_onDeleteTopicPairHandler}
          onChangeTopicPairStatus={_onChangeTopicPairStatusHandler}
          onCommitTopicPairChanges={_onTopicPairCommitChangeHandler}
          onTransferTopicPairQuestion={_onTransferTopicPairQuestionHandler}
        />
      ));
  };
  const _onSearchByTag = (tags: ISearchingTag[]) => {
    setSelectedPage(1);
    props.onSearchByTags(tags);
  }
  return (
    <React.Fragment>
      <section className="topic-pairs-tabs__header">
        <div className="topic-pairs-tabs__actions">
          <span
            className={`topic-pairs-tabs__tab-button${
              isSelectedActiveStatus ? " active" : ""
            }${isCreatingTopicPair ? " disabled" : ""}`}
            onClick={
              !isCreatingTopicPair ? _changeActiveStatusTabToActive : undefined
            }
          >
            {i18n.t("common.active")}
          </span>
          <span
            className={`topic-pairs-tabs__tab-button${
              isSelectedInactiveStatus ? " active" : ""
            }${isCreatingTopicPair ? " disabled" : ""}`}
            onClick={
              !isCreatingTopicPair
                ? _changeActiveStatusTabToInactive
                : undefined
            }
          >
            {i18n.t("common.inactive")}
          </span>
          <span
            className={`topic-pairs-tabs__tab-button${
              isSelectedPendingStatus ? " active" : ""
            }${isCreatingTopicPair ? " disabled" : ""}`}
            onClick={
              !isCreatingTopicPair ? _changeActiveStatusTabToPending : undefined
            }
          >
            {i18n.t("common.pending")}
          </span>
        </div>
        <TagSearchingInput
          searchingTags={getNlpSearchingTags(
            props.topicPairCategories,
            props.topicPairSources
          )}
          onSearch={_onSearchByTag}
          repeatingTags={false}
          currentTags={props.filterTags}
          onClearFilter={() => {
            props.onSearchByTags([]);
          }}
        />
        <div className="topic-pairs-tabs__paging">
          <Paging
            countOfElements={topicPairsCount}
            countOnPages={TOPIC_PAIRS_ON_PAGE}
            onTileClick={(sp) => {
              setSelectedPage(sp);
              props.getTopicByExternalId(topicExternalId, sp);
            }}
            selectedPage={selectedPage}
          />
        </div>
        {/* <div className="topic-pairs-tabs__filter">
        </div> */}
      </section>
      <section
        ref={topicPairsRef}
        id="topic-pair-items"
        className="topic-pairs-tabs__topic-pair-items"
      >
        {!isSelectedPendingStatus && (
          <div className="topic-pairs-tabs__buttons-header">
            <Button
              className="topic-pairs-tabs__button topic-pairs-tabs__button-create"
              onClick={_onCreateTopicPairHandler}
              disabled={isCreatingTopicPair}
            >
              {i18n.t("nlp.createNewPair")}
            </Button>
            {isCreatingTopicPair && (
              <Button
                className="topic-pairs-tabs__button topic-pairs-tabs__button-cancel-create"
                onClick={_onCancelCreateTopicPairHandler}
              >
                {i18n.t("common.cancel")}
              </Button>
            )}
          </div>
        )}
        {_getTopicPairsItemsBySelectedStatus()}
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IApplicationState, ownProps: any) => {
  return {
    editedTopicPairs: editedTopicPairsSelector(state),
    isCreatingTopicPair: isCreatingTopicPairSelector(state),
    selectedTopicPairStatus: selectedTopicPairStatusSelector(state),
    topicExternalId: ownProps.topicExternalId,
    topicPairsCount: ownProps.topicPairsCount,
    selectedPage: ownProps.selectedPage,
    setSelectedPage: ownProps.setSelectedPage,
    topicPairsRef: ownProps.topicPairsRef,
    topicPairCategories: topicPairCategoriesSelector(state),
    topicPairSources: topicPairSourcesSelector(state),
    filterTags: filterTagsSelector(state),
    selectedTopicParametersJson: selectedTopicParametersJsonSelector(state),
  };
};

const mapDispatchToProps = (dispatch: any): ITopicPairsTabsProps => {
  return {
    saveTopicPair: (
      topicExternalId: string,
      topicPair: ITopicPair,
      pageNumber: number
    ) => dispatch(saveTopicPair(topicExternalId, topicPair, pageNumber)),
    createTopicPair: (
      topicPairStatus: TopicPairStatus,
      defaultContentType: TopicPairContentType
    ) => dispatch(createTopicPair(topicPairStatus, defaultContentType)),
    deleteTopicPair: (
      topicExternalId: string,
      topicPairId: number,
      selectedPage: number
    ) => dispatch(deleteTopicPair(topicExternalId, topicPairId, selectedPage)),
    cancelCreateTopicPair: () => dispatch(cancelCreateTopicPair()),
    changeTopicPairStatus: (
      topicExternalId: string,
      topicPairId: number,
      topicPairStatus: TopicPairStatus
    ) =>
      dispatch(
        changeTopicPairStatus(topicExternalId, topicPairId, topicPairStatus)
      ),
    setTransferQuestionPopupData: (
      topicExternalId: string,
      topicPairId: number,
      questionId: number
    ) =>
      dispatch(
        setTransferQuestionPopupData(topicExternalId, topicPairId, questionId)
      ),
    changeSelectedTopicPairStatus: (topicPairStatus: TopicPairStatus) =>
      dispatch(changeSelectedTopicPairStatus(topicPairStatus)),
    toggleTransferQuestionPopupVisibility: () =>
      dispatch(toggleTransferQuestionPopupVisibility()),
    setMessageBoxData: (data: {
      title: string;
      message: string;
      className?: string;
      type?: MessageBoxType;
      onClose?(): void;
      onConfirm?(): void;
      onReject?(): void;
    }) => dispatch(setMessageBoxData(data)),
    toggleMessageBoxVisibility: () => dispatch(toggleMessageBoxVisibility()),
    onSearchByTags: (tags: ISearchingTag[]) => dispatch(setFilterTags(tags)),
    getTopicByExternalId: (topicExternalId: string, selectedPage: number = 1) =>
      dispatch(getTopicByExternalId(topicExternalId, selectedPage)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopicPairsTabs);
