import {ITopic} from "../~store/models/Topic";
import {ITopicPair} from "../~store/models/TopicPair";
import {ISearchStatsDto} from "../~store/models/SearchStats";
import {ITopicShort} from "../~store/models/TopicShort";
import {TopicPairStatus} from "../~store/models/enums/TopicPairStatus";
import {IApiActionResult} from "../../../~api/ApiActionResult";
import {fetchGet, getApiActionResult, fetchPost, fetchGetFile} from "../../../~api/ApiHelper";
import {
    GET_TOPICS_API_ROUTE,
    SAVE_TOPIC_API_ROUTE,
    DELETE_TOPIC_API_ROUTE,
    PUBLISH_QUESTIONS_API_ROUTE,
    EXPORT_PAIRS_API_ROUTE,
    SAVE_TOPIC_PAIR_API_ROUTE,
    DELETE_TOPIC_PAIR_API_ROUTE,
    GET_TOPIC_BY_EXTERNAL_ID_API_ROUTE,
    CHANGE_TOPIC_PAIR_STATUS_API_ROUTE,
    TRANSFER_QUESTION_TO_TOPIC_PAIR_API_ROUTE,
    SEARCH_ANSWER_API_ROUTE,
    IMPORT_PAIRS_API_ROUTE,
    GET_TOPIC_BY_TOPIC_NAME_WITH_FILTER_API_ROUTE,
    GET_TOPIC_PAIR_CATEGORIES_BY_TOPIC_NAME_API_ROUTE,
    SAVE_SYNONYMS,
    GET_SYNONYMS,
    TRY_IMPORT_TOPIC_API_ROUTE,
    LOG_OPERATOR_ANSWER_API_ROUTE,
    GET_QUESTIONS_BY_TOPIC_NAME_API_ROUTE
} from "./apiRoutes";
import {ANSWER_COUNT} from "./apiConstants";
import { IAnswerMessage } from "../~store/models/messages/AnswerMessage";
import { ISearchingTag, TagType } from "src/app/shared/components/tag-searching-input/~types/models/SearchingTag";
import { ITopicPairCategoriesFromDbResult } from "../~store/models/TopicPairCategoriesFromDbResult";
import i18n from "src/app/shared/localization/i18n";
import { ISynonymsFromDbResult } from "../~store/models/SynonymsFromDbResult";
import { IQuestionsFromDbResult } from "../~store/models/QuestionsFromDbResult";

export const getAllTopicsAsync = async (): Promise<IApiActionResult<ITopicShort[]>> => {
    const fetchPromise = fetchGet(GET_TOPICS_API_ROUTE);
    return await getApiActionResult(fetchPromise, true);
};

export const getTopicByExternalIdAsync = async (topicExternalId: string, tags: ISearchingTag[], skip: number, count: number): Promise<IApiActionResult<ITopic>> => {
    tags.filter(val => val.tagType === TagType.OpeningTag).forEach(el => el.externalValue = ConvertFromFilterToOpeningTag(el.value));
    const fetchPromise = fetchPost(GET_TOPIC_BY_EXTERNAL_ID_API_ROUTE, {
        body: JSON.stringify({
            topicExternalId,
            tags,
            skip,
            count
        })
    });
    return await getApiActionResult(fetchPromise, true);
};

const ConvertFromFilterToOpeningTag = (filter: string): any => {
    let status = filter;
    switch (filter) {
        case i18n.t("nlp.subject"):
            status = "Subject";
            break;
        case i18n.t("nlp.source"):
            status = "Source";
            break;
        case i18n.t("nlp.trigger"):
            status = "Trigger";
            break;
        case i18n.t("common.phrase"):
            status = "Phrase";
            break;
    }
    return status;
}

export const deleteTopicAsync = async (topicExternalId: string): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(DELETE_TOPIC_API_ROUTE, {
        body: JSON.stringify({topicExternalId})
    });
    return await getApiActionResult(fetchPromise);
};

export const saveTopicAsync = async (topicExternalId: string, topicName: string, parametersJson: string): Promise<IApiActionResult<ITopic>> => {
    const fetchPromise = fetchPost(SAVE_TOPIC_API_ROUTE, {
        body: JSON.stringify({topicExternalId, topicName, parametersJson})
    });
    return await getApiActionResult(fetchPromise, true);
};

export const tryImportTopicAsync = async (topicExternalId: string): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchGet(`${TRY_IMPORT_TOPIC_API_ROUTE}?topicExternalId=${topicExternalId}`);
    return await getApiActionResult(fetchPromise);
};

export const exportPairsAsync = async (topicExternalId: string): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchGetFile(`${EXPORT_PAIRS_API_ROUTE}?topicExternalId=${encodeURIComponent(topicExternalId)}`, "Export.csv");
    return await getApiActionResult(fetchPromise, false);
};

export const importPairsAsync = async (topicExternalId: string, file: File): Promise<IApiActionResult<ITopicPair[]>> => {
    const formData = new FormData();
    
    formData.append("TopicExternalId", topicExternalId);
    formData.append("TopicPairsCsvFile", file);
    formData.getAll("TopicExternalId").forEach(val => console.log(val));
    const fetchPromise = fetchPost(IMPORT_PAIRS_API_ROUTE, {
        body: formData
    });
    return await getApiActionResult(fetchPromise, true);
}

export const publishQuestionsAsync = async (topicExternalId: string): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(PUBLISH_QUESTIONS_API_ROUTE, {
        body: JSON.stringify({topicExternalId})
    });
    return await getApiActionResult(fetchPromise);
};

// topic pairs
export const saveTopicPairAsync = async (topicExternalId: string, topicPair: ITopicPair): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(SAVE_TOPIC_PAIR_API_ROUTE, {
        body: JSON.stringify({topicExternalId, topicPair})
    });
    return await getApiActionResult(fetchPromise);
};

export const deleteTopicPairAsync = async (topicExternalId: string, topicPairId: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(DELETE_TOPIC_PAIR_API_ROUTE, {
        body: JSON.stringify({topicExternalId, topicPairId})
    });
    return await getApiActionResult(fetchPromise);
};

export const changeTopicPairStatusAsync = async (topicExternalId: string, topicPairId: number, topicPairStatus: TopicPairStatus): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(CHANGE_TOPIC_PAIR_STATUS_API_ROUTE, {
        body: JSON.stringify({topicExternalId, topicPairId, topicPairStatus})
    });
    return await getApiActionResult(fetchPromise);
};

export const transferQuestionToTopicPairAsync = async (
    topicExternalId: string,
    actualTopicPairId: number,
    expectedTopicPairId: number,
    questionId: number
): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(TRANSFER_QUESTION_TO_TOPIC_PAIR_API_ROUTE, {
        body: JSON.stringify({topicExternalId, actualTopicPairId, expectedTopicPairId, questionId})
    });
    return await getApiActionResult(fetchPromise);
};

// topic widget
export const getAnswersAsync = async (topicName: string, questionText: string): Promise<IApiActionResult<IAnswerMessage>> => {
    const fetchPromise = fetchGet(
        `${SEARCH_ANSWER_API_ROUTE}` +
        `?topicName=${encodeURIComponent(topicName)}` +
            `&questionText=${encodeURIComponent(questionText)}` +
            `&answerCount=${encodeURIComponent(ANSWER_COUNT)}` +
            `&useLearning=${encodeURIComponent(false)}` +
            `&includeTopicPairs=${encodeURIComponent(true)}`
    );
    return await getApiActionResult(fetchPromise, true);
};


export const getTopicByTopicNameWithFilterAsync = async (topicName: string, skip: number, take: number, tags: ISearchingTag[]): Promise<IApiActionResult<ITopic>> => {
    tags.filter(val => val.tagType === TagType.OpeningTag).forEach(el => el.externalValue = ConvertFromFilterToOpeningTag(el.value));
    const fetchPromise = fetchPost(`${GET_TOPIC_BY_TOPIC_NAME_WITH_FILTER_API_ROUTE}`, {
        body: JSON.stringify({
            topicName,
            skip,
            take,
            tags
        })
    });
    return await getApiActionResult(fetchPromise, true);
};


export const getTopicPairCategoriesByTopicNameAsync = async (topicName: string): Promise<IApiActionResult<ITopicPairCategoriesFromDbResult>> => {
    const fetchPromise = fetchGet(`${GET_TOPIC_PAIR_CATEGORIES_BY_TOPIC_NAME_API_ROUTE}${topicName}`);
    return await getApiActionResult(fetchPromise, true);
};

export const getQuestionsByTopicNameAsync = async (topicName: string, searchingText: string, pairId: string): Promise<IApiActionResult<IQuestionsFromDbResult[]>> => {
    const fetchPromise = fetchPost(`${GET_QUESTIONS_BY_TOPIC_NAME_API_ROUTE}`, {
        body: JSON.stringify({
            topicName,
            searchingText,
            pairId
        })
    });
    return await getApiActionResult(fetchPromise, true);
};

export const getSynonymsAsync = async (topicExternalId: string): Promise<IApiActionResult<ISynonymsFromDbResult>> => {
    const fetchPromise = fetchGet(`${GET_SYNONYMS}?topicExternalId=${topicExternalId}`);
    return await getApiActionResult(fetchPromise, true);
};


export const saveSynonymsAsync = async (topicExternalId: string, synonymsText: string): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(`${SAVE_SYNONYMS}`, {
        body: JSON.stringify({
            topicExternalId,
            synonymsText
        })
    });
    return await getApiActionResult(fetchPromise, false);
};


export const logOperatorAnswerAsync = async (searchStats: ISearchStatsDto) : Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(LOG_OPERATOR_ANSWER_API_ROUTE, {
        body: JSON.stringify({
            searchStats
        })
    });

    return await getApiActionResult(fetchPromise, false);
}
