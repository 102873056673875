import React, { useState, useEffect } from 'react';

import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { TopicPairContentType, topicPairContentTypeToString } from '../../../nlp/~store/models/enums/TopicPairContentType';

import './ChooseMessageContentType.css';

export interface chooseMessageContentTypeDialogProps {
    operatorMessageContentType: TopicPairContentType;
    onMessageContentTypeChoosen(contentType: TopicPairContentType): void;
}

export default function ChooseMessageContentTypeDialog(props: chooseMessageContentTypeDialogProps) {
    const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
    const [messageContentType, setMessageContentType] = useState<TopicPairContentType>(TopicPairContentType.Text);

    useEffect(() => {
        return setMessageContentType(props.operatorMessageContentType);
    }, [props.operatorMessageContentType])

    const _getMaterialUiIcon = (): string => {
        switch (messageContentType) {
            case TopicPairContentType.Markup:
                return 'code';
            case TopicPairContentType.Text:
                return 'text_format';
            case TopicPairContentType.Audio:
                return 'audiotrack';            
            case TopicPairContentType.Video:
                return 'ondemand_video';
            case TopicPairContentType.Navigation:
                return 'arrow_forward';
            case TopicPairContentType.TextToSpeech:
                return 'graphic_eq';
            default:
                return 'text_format';
        }
    }

    const _getDropdownMenuItems = (): JSX.Element => {
        return (
            <DropdownMenu style={{}} className="choose-message-content-type-dialog__menu">
                <div className="choose-message-content-type__menu-item" onClick={() => _onMenuChoosenHandler(TopicPairContentType.Markup)}>
                    <i className="material-icons choose-message-content-type-dialog__dropdown-image">code</i>
                    {topicPairContentTypeToString(TopicPairContentType.Markup)}
                </div>
                <div className="choose-message-content-type__menu-item" onClick={() => _onMenuChoosenHandler(TopicPairContentType.Text)}>
                    <i className="material-icons choose-message-content-type-dialog__dropdown-image">text_format</i>
                    {topicPairContentTypeToString(TopicPairContentType.Text)}
                </div>
                <div className="choose-message-content-type__menu-item" onClick={() => _onMenuChoosenHandler(TopicPairContentType.Video)}>
                    <i className="material-icons choose-message-content-type-dialog__dropdown-image">ondemand_video</i>
                    {topicPairContentTypeToString(TopicPairContentType.Video)}
                </div>
                <div className="choose-message-content-type__menu-item" onClick={() => _onMenuChoosenHandler(TopicPairContentType.Audio)}>
                    <i className="material-icons choose-message-content-type-dialog__dropdown-image">audiotrack</i>
                    {topicPairContentTypeToString(TopicPairContentType.Audio)}</div>
                <div className="choose-message-content-type__menu-item" onClick={() => _onMenuChoosenHandler(TopicPairContentType.Navigation)}>
                    <i className="material-icons choose-message-content-type-dialog__dropdown-image">arrow_forward</i>
                    {topicPairContentTypeToString(TopicPairContentType.Navigation)}
                </div>
                <div className="choose-message-content-type__menu-item" onClick={() => _onMenuChoosenHandler(TopicPairContentType.TextToSpeech)}>
                    <i className="material-icons choose-message-content-type-dialog__dropdown-image">graphic_eq</i>
                    {topicPairContentTypeToString(TopicPairContentType.TextToSpeech)}
                </div>
            </DropdownMenu>
        )
    }

    const _onMenuChoosenHandler = (contentType: TopicPairContentType): void => {
        setMessageContentType(contentType);
        props.onMessageContentTypeChoosen(contentType);
    }

    return (
        <React.Fragment>
            <Dropdown className="choose-message-content-type-dialog__container" isOpen={isMenuVisible} toggle={() => {setIsMenuVisible(!isMenuVisible)}}>
                <DropdownToggle tag="div"> <i className="material-icons choose-message-content-type-dialog__image">{_getMaterialUiIcon()}</i>
                    {isMenuVisible && _getDropdownMenuItems()}                        
                </DropdownToggle>
            </Dropdown>
        </React.Fragment>
    )
}