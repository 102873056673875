import React, { useState } from 'react';

import { IContactAccountItemProps } from './~types/ContactAccountItemProps';

import './ContactAccountItem.css';
import { Button, Input } from 'reactstrap';
import { AccountType, accountTypeToString } from 'src/app/contact/~store/models/AccountType';

const ContactAccountItem = (props: IContactAccountItemProps) => {
    const [externalId, setId] = useState(props.account.externalId);
    const [login, setLogin] = useState(props.account.login);
    const [type, setType] = useState(props.account.type);
    const [isEditing, setEditing] = useState(false);
    const [isCreating, setCreating] = useState(props.isCreating);

    const getLinkToBilling = (): string => {
        return "https://tdadmin.unet.by/account/" + login;
    }

    const getLinkToAmo = (): string => {
        return "https://unetby.amocrm.ru/leads/detail/" + externalId;
    }
    
    const getLink = () => {
        switch(type) {
            case AccountType.Amo: {
                return getLinkToAmo();
            }
            case AccountType.Billing: {
                return getLinkToBilling();
            }
        }
    }

    return (
        <tr className="contact-account-item__table-row">
            <td>
                {(isEditing || isCreating) &&
                    <Input
                        className="contact-account-item__id-input"
                        value={externalId}
                        onChange={(event) => setId(event.target.value)} />
                }
                {(!isEditing && !isCreating) && 
                    <a
                        className="contact-account-item__id"
                        href={getLink()}
                        target="_blank">
                        {externalId}
                    </a>
                }
            </td>
            <td>
                {(isEditing || isCreating) && 
                    <Input
                        className="contact-account-item__login-input"
                        value={login}
                        onChange={(event) => setLogin(event.target.value)}/>
                }
                {(!isEditing && !isCreating) && 
                    <div className="contact-account-item__login">
                        {login}
                    </div>
                }
            </td>
            <td>
                {(isEditing || isCreating) && 
                    <Input
                        type="select"
                        className="contact-account-item__type-input"
                        value={accountTypeToString(type)}
                        onChange={(event) => setType(AccountType[event.target.value as keyof typeof AccountType])}>
                        <option>{accountTypeToString(AccountType.Amo)}</option>
                        <option>{accountTypeToString(AccountType.Billing)}</option>
                        <option>{accountTypeToString(AccountType.ZenDesk)}</option>
                        <option>{accountTypeToString(AccountType.TelegramGroup)}</option>
                        <option>{accountTypeToString(AccountType.VoiceChannel)}</option>
                    </Input>
                }
                {(!isEditing && !isCreating) && 
                    <div className="contact-account-item__type">
                        {accountTypeToString(type)}
                    </div>
                }
            </td>
            <td className="contact-account-item__actions">
                {(!isEditing && !isCreating) && 
                    <React.Fragment>
                        <Button
                            className="contact-account-item__btn contact-account-item__btn-edit"
                            onClick={() => {
                                setEditing(true);
                            }}>
                                <i className="material-icons">edit</i>
                        </Button>
                        <Button
                            className="contact-account-item__btn contact-account-item__btn-delete"
                            onClick={() => props.deleteAccount(props.account.id)}>
                                <i className="material-icons">delete_forever</i>
                        </Button>
                </React.Fragment>}
                {(isEditing || isCreating) &&
                    <React.Fragment>
                        <Button
                            className="contact-account-item__btn contact-account-item__btn-save"
                            onClick={() => {
                                var isSuccessSave = props.saveAccount({
                                    ...props.account,
                                    externalId,
                                    login,
                                    type,
                                });
                                if (login && externalId && isSuccessSave) {
                                    setEditing(false);
                                    setCreating(false);
                                }                                
                            }}>
                                <i className="material-icons">save</i>
                        </Button>
                        <Button
                            className="contact-account-item__btn contact-account-item__btn-replay"
                            onClick={() => {
                                if (isEditing) {
                                    setId(props.account.externalId);
                                    setLogin(props.account.login);
                                    setType(props.account.type);
                                    setEditing(false);
                                    setCreating(false);
                                } else {
                                    props.deleteAccount(props.account.id);
                                }                                
                            }}>
                                <i className="material-icons">replay</i>
                        </Button>
                    </React.Fragment>
                }
            </td>
        </tr>
    )
}

export default ContactAccountItem;