import { IChatItem } from "./ChatItem";

export interface IOperatorItem {
    operatorId: number,
    avatarId: string,
    name: string,
    description: string,
    enabled: boolean,
    openedChatsCount: number,
    type: OperatorType,
    isDeleted:boolean
}

export enum OperatorType {
    Operator = 1,
    Bot,
    Supervisor
}
     