import { Action, Reducer } from "redux";
import { ChangeSettingSearchStringSuccessAction, ChangeSettingSearchTypeSuccessAction, DeleteSettingSuccessAction, GetAllSettingsSuccessAction, UpdateSettingInStateSuccessAction } from "./actions/interfaces";
import { CHANGE_SETTING_SEARCH_STRING, CHANGE_SETTING_SEARCH_TYPE, DELETE_SETTING_FROM_STATE, GET_ALL_SETTINGS_SUCCESS, UPDATE_CREATING_SETTING_IN_STATE, UPDATE_SETTING_IN_STATE } from "./actions/types";
import { initState } from "./state/InitSettingsState";
import { ISettingState } from "./state/SettingsState";


export type KnownActions = 
GetAllSettingsSuccessAction |
UpdateSettingInStateSuccessAction |
DeleteSettingSuccessAction | 
ChangeSettingSearchStringSuccessAction |
ChangeSettingSearchTypeSuccessAction;

export const settingReducer: Reducer<ISettingState> = (
    state: ISettingState = initState, 
    incommingAction: Action
): ISettingState => {
    const action = incommingAction as KnownActions;
    
    
    switch(action.type) {
        case GET_ALL_SETTINGS_SUCCESS: {
            return {
                ...state,
                settingList: {
                    ...state.settingList,
                    settings: (action as GetAllSettingsSuccessAction).payload.settings
                }
            }
        }

        case UPDATE_SETTING_IN_STATE: {
            const setting = (action as UpdateSettingInStateSuccessAction).payload.setting;

            const settings = state.settingList.settings;
            const index = settings.findIndex(s => s.id === setting.id);
            if(index > -1) {
                settings.splice(index, 1, setting);
            }
            else {
                settings.push(setting);
            }

            return {
                ...state,
                settingList: {
                    ...state.settingList,
                    settings: settings.map(s => s)
                }
            }
        }

        case UPDATE_CREATING_SETTING_IN_STATE: {
            const setting = (action as UpdateSettingInStateSuccessAction).payload.setting;
            let settings = state.settingList.settings.filter(s => !s.isCreating);
            if(setting) {
                settings = settings.concat([setting]);
            }

            return {
                ...state,
                settingList: {
                    ...state.settingList,
                    settings
                }
            }
        }

        case DELETE_SETTING_FROM_STATE: {
            const settingId = (action as DeleteSettingSuccessAction).payload.id;

            return {
                ...state,
                settingList: {
                    ...state.settingList,
                    settings: state.settingList.settings.filter(s => s.id !== settingId)
                }
            }
        }

        case CHANGE_SETTING_SEARCH_STRING: {
            return {
                ...state,
                settingList: {
                    ...state.settingList,
                    searchString: (action as ChangeSettingSearchStringSuccessAction).payload.value
                }
            }
        }

        case CHANGE_SETTING_SEARCH_TYPE: {
            return {
                ...state,
                settingList: {
                    ...state.settingList,
                    searchType: (action as ChangeSettingSearchTypeSuccessAction).payload.type
                }
            }
        }

        default:
            return state;
    }
};