import { MessageType } from "src/app/chats-panel/~store/models/MessageItem";
import { OperatorType } from "src/app/chats-panel/~store/models/OperatorItem";
import { OperatorStatusType } from "../../../chats-panel/~store/state/ChatItemsState";

export class ChatPanelRuleHelper {

    static canWriteMessages = (isClosed: boolean, chatOwnerId: number, operatorId: number, isInviteToChat: boolean, operatorStatus: OperatorStatusType): boolean => {
        return (
            !isClosed && 
            ((chatOwnerId === operatorId) || isInviteToChat) &&
            (operatorStatus !== OperatorStatusType.Offline)
        );
    }

    static canTransferChat = (
        isClosed: boolean,
        chatOwnerId: number,
        operatorId: number,
        isSupervisor: boolean
    ): boolean => {
        return (
            !isClosed  &&
            ((chatOwnerId === operatorId) ||
            isSupervisor));
        }

    static canInviteOperator = (
        isClosed: boolean,
        chatOwnerId: number,
        operatorId: number,
        isSupervisor: boolean,
        chatOperatorType: OperatorType,
    ): boolean => {
        return (
            chatOperatorType != OperatorType.Bot &&
            !isClosed &&
            ((chatOwnerId === operatorId) || isSupervisor)
        );
    }

    static canJoinChat = (
        isClosed: boolean,
        chatOwnerId: number,
        operatorId: number,
        isSupervisor: boolean,
        isInviteToChat: boolean,
        chatOperatorType: OperatorType,
    ): boolean => {
        return (
            chatOperatorType != OperatorType.Bot &&
            !isClosed &&
            chatOwnerId !== operatorId) &&
            isSupervisor &&
            !isInviteToChat;
    }

    static canLeaveChat = (
        chatOwnerId: number,
        operatorId: number,
        isInviteToChat: boolean,
        isClosed: boolean
        ): boolean => {
        return !isClosed &&
         (chatOwnerId !== operatorId) && isInviteToChat;
    }

    static canCloseChat = (
        chatOwnerId: number,
        operatorId: number,
        isClosed: boolean,
        isSupervisor: boolean,
        paused: boolean
    ): boolean => {
        return !isClosed && !paused &&
            ((chatOwnerId === operatorId) || isSupervisor);
    }

    static canOpenChat = (
        isClosed: boolean, 
        ): boolean => {
            return isClosed;
    }

    static canRemoveOperatorFromChat = (
        messageType: MessageType,
        operatorId: number,
        messageOperatorId: number,
        chatOwnerId: number,
        isSupervisor: boolean,
    ): boolean => {
        return (messageType === MessageType.operatorMessage) && 
               (operatorId !== messageOperatorId) &&
               (messageOperatorId !== chatOwnerId) &&
               ((operatorId === chatOwnerId) || isSupervisor);
    }

    static canPauseChat = (
        isClosed: boolean,
        chatOwnerId: number,
        operatorId: number,
        isSupervisor: boolean
    ): boolean => {
        return (
            !isClosed &&
            ((chatOwnerId === operatorId) ||
                isSupervisor));
    }
}