export const DefaultQuestionPairId = (questionText: string) : string =>
{

    const splittedText = questionText.split(" ");
    var pairId = "";
    if (questionText.charAt(0) == '#' || !isNaN(parseInt(splittedText[0]))) {
        if (splittedText[0].charAt(0) == '#') {
            pairId = splittedText[0].substring(1);
        }
        else {
            pairId = splittedText[0];
        }
    }
    return pairId;
}