export interface ISearchingTag {
    id: number;
    tagType: TagType;
    value: string;
    externalValue?: string;
    tagName?: TagName;
    suggestions?: string[];
}

export enum TagType {
    OpeningTag = 0,
    ClosingTag = 1,
    OperationTag = 2,
}

export enum TagName {
    Date = 0,
    Channel = 1,
    Operator = 2,
    Contact = 3,
    Phrase = 4,
    Status = 5,
    ChatNumber = 6,
    ChatTag = 7,
    ChannelName = 8,
    Setting = 9,
    Subject = 10,
    Source = 11,
    
    Custom = 100
}
