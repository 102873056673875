import React, { useEffect, useState } from 'react'
import { IExpireProps } from './ExpireProps';

const Expire = (props: IExpireProps) => {
    const [isVisible, setIsVisible] = useState(true);
    const [children, setChildren] = useState(props.children)

    useEffect(() =>
    {
        setChildren(props.children);
        if(props.delay > 100) {
          setIsVisible(true);
        }
        const timeout = setTimeout(() => setIsVisible(false), props.delay);
        return () => clearTimeout(timeout);
    }, [props.children, props.delay]);

    return (
        isVisible && children
    );
}

export default Expire;