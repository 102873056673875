import React from 'react';
import './InviteOperatorItem.css';
import { OperatorStatusType } from 'src/app/chats-panel/~store/state/ChatItemsState';
import { IOperatorItem, OperatorType } from 'src/app/chats-panel/~store/models/OperatorItem';
import { GET_PICTURE_BY_ID_API_ROUTE } from 'src/app/chats-panel/~api/apiRoutes';
import { ChannelOperatorPriority } from 'src/app/channel/~store/models/enums/ChannelOperatorPriority';
import { useTranslation } from 'react-i18next';
import { FIRST_PAUSE_CATEGORY_ID, LAST_PAUSE_CATEGORY_ID } from 'src/app/chats-panel/~store/actions/ChatConstants';

interface IInviteOperatorItemProps {
    isChecked: boolean,
    operator: IOperatorItem,
    tier: ChannelOperatorPriority | undefined,
    operatorStatus: OperatorStatusType,
    onChecked(operator: IOperatorItem): void,
    pauseCategories: string[]
}

const InviteOperatorItem = (props: IInviteOperatorItemProps) => {
    const { t } = useTranslation();

    const userInStorage = sessionStorage.getItem("user");
    const customerId = userInStorage === null ? "" : JSON.parse(userInStorage.toString()).customerId;

    const operatorAvatar = props.operator.avatarId === null ?
        require('src/app/shared/images/help.png') :
        GET_PICTURE_BY_ID_API_ROUTE + props.operator.avatarId + "&customerId=" + customerId + "&cahceBypass=" + Math.random();

    let operatorStatusText = t('common.offline');
    let operatorStatusValue = 'offline';

    switch (Number.parseInt(props.operatorStatus.toString())) {
        case OperatorStatusType.Offline.valueOf(): {
            operatorStatusText = t('common.offline');
            operatorStatusValue = 'offline';
            break;
        }
        case OperatorStatusType.Online.valueOf(): {
            operatorStatusText = t('common.online');
            operatorStatusValue = 'online';
            break;
        }
        case OperatorStatusType.Pause.valueOf(): {
            operatorStatusText = t('common.pause');
            operatorStatusValue = 'pause';
            break;
        }
        default: {
            if(props.operatorStatus >= FIRST_PAUSE_CATEGORY_ID || props.operatorStatus <= LAST_PAUSE_CATEGORY_ID) {
                operatorStatusText = props.pauseCategories[props.operatorStatus - FIRST_PAUSE_CATEGORY_ID];
                operatorStatusValue = 'pause';
            }
            
        }
    }

    return (
        <div className="invited-operator-item" onClick={() => props.onChecked(props.operator)}>
            <div className="invited-operator-item__status-container">
                <img className={"invited-operator-item__avatar" + ` ${operatorStatusValue}`} src={operatorAvatar}/>
            </div>
            <div className="invited-operator-item__info">
                <p>{props.operator.name}</p>
                <p>{t('common.chats') + ": " + props.operator.openedChatsCount}</p>
                <p className={`invited-operator-item__tier${props.tier ?? '-supervisor'}`}>{props.operator.type !== OperatorType.Supervisor ? `tier ${props.tier}` : 'supervisor'}</p>
                <p>{t('common.status') + ": "}<span className={`invited-operator-item__status-${operatorStatusValue}`}>{operatorStatusText}</span></p>
            </div>
            {props.isChecked && <i className="material-icons invited-operator-item__selected-item">done</i>}
        </div>
    )
}



export default InviteOperatorItem;