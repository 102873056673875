import React, { useEffect, useRef, useState } from 'react';
import { ITagSearchingInputProps } from './~types/TagSearchingInputProps';
import { ISearchingTag, TagName, TagType } from './~types/models/SearchingTag';
import SuggestionsList from './suggestions-list/SuggestionsList';
import TagsList from './tags-list/TagsList';
import { INVALID_DATE } from './TagSearchingInputConstants';

import './TagSearchingInput.css';
import { showErrorToastr } from '../../helpers/toastr-helper/ToastrHelper';
import { useTranslation } from 'react-i18next';

const TagSearchingInput = (props: ITagSearchingInputProps) => {

    const { t } = useTranslation();
    const [tags, setTags] = useState([...props.currentTags]);
    const [suggestions, setSuggestions] = useState([] as ISearchingTag[]);
    const [isSuggestionsListVisible, setSuggestionsListVisibility] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [tagNameOperations, setTagNameOperations] = useState(new Map<TagName, number>());

    const inputRef = useRef<HTMLInputElement>(null);
    
    useEffect(() => {
        const rootElement = document.getElementById("root");
        rootElement?.removeEventListener("click", _onClickRoot);
        rootElement?.addEventListener("click", _onClickRoot);
        document.removeEventListener("visibilitychange", _onBrowserTabChanged);
        document.addEventListener("visibilitychange", _onBrowserTabChanged);
        setSuggestions(props.searchingTags.filter(val => val.tagType === TagType.OpeningTag));
        
        return () => {
            document.removeEventListener("visibilitychange", _onBrowserTabChanged);
            rootElement?.removeEventListener("click", _onClickRoot);
        }
    }, []);

    useEffect(() => {
        setTags([...props.currentTags])
    }, [props.currentTags]);

    useEffect(() => {
        const rootElement = document.getElementById("root");
        rootElement?.removeEventListener("click", _onClickRoot);
        rootElement?.addEventListener("click", _onClickRoot);
        _updateTagNameOperations();
        _filterSuggestions();

        return () => {
            rootElement?.removeEventListener("click", _onClickRoot);
        }
    }, [tags])

    useEffect(() => {
        const lastTag = tags[tags.length - 1];
        const penultTag = tags[tags.length - 2];
        if(lastTag !== undefined && lastTag.tagType === TagType.OperationTag && penultTag.tagName === TagName.Contact) {
            props.getContacts?.(searchString);
        }
    }, [searchString])

    const _setSuggestionsListVisibility = (isVisible: boolean) => {
        if(suggestions.length === 0) {
            setSuggestionsListVisibility(false);
            return;
        }
        setSuggestionsListVisibility(isVisible);
    }

    const _onClickRoot = (event: MouseEvent) => {
        const targetElement = event.target as Element;
        const suggestionElement = document.getElementsByClassName("suggestions-list__suggestion")[0];
        const tagSearchingInput = document.getElementById("tag-searching-input__input");
        if(suggestionElement !== undefined && targetElement !== undefined && tagSearchingInput !== undefined) {
            if(targetElement.id !== tagSearchingInput?.id && targetElement.className !== suggestionElement?.className) {
                _setSuggestionsListVisibility(false);
                const lastTag = tags[tags.length - 1];
                inputRef.current?.blur();
                if(lastTag !== undefined && (lastTag.tagType === TagType.OpeningTag || lastTag.tagType === TagType.OperationTag)) {
                    inputRef.current?.focus();
                }
            }
        }
    }

    const _onBrowserTabChanged = () => {
        inputRef.current?.blur();
    }

    const _filterSuggestions = () => {
        const lastTag = tags[tags.length - 1];
        if(lastTag !== undefined && lastTag.tagType === TagType.ClosingTag) {
            var newSuggestions =  props.searchingTags.filter(val => val.tagType === TagType.OpeningTag);
            if(!props.repeatingTags) {          
                tags.filter(val => val.tagType === TagType.OpeningTag).forEach(tag => {
                    var index = newSuggestions.findIndex(val => val.tagName === tag.tagName);
                    if(index > -1) {
                        newSuggestions.splice(index, 1);
                    }
                });     
            }
            if(suggestions.length === newSuggestions.length && suggestions.every((v, i) => v === newSuggestions[i])) {
                return;
            }
            setSuggestions([...newSuggestions]);
        }   
    }

    const _onSuggestionSelected = (searchingTag: ISearchingTag) => {
        _setSuggestionsListVisibility(false);
        if(searchingTag.tagType === TagType.ClosingTag && searchingTag.tagName === TagName.Date && searchingTag.value === t('common.period')) {
            setSearchString(_getDefaultPeriod());
            return;
        }

        setSearchString("");
        tags.push({...searchingTag});
        setTags([...tags]);

        if(searchingTag.tagType === TagType.OpeningTag){
            switch (searchingTag.tagName) {
                case TagName.Operator:
                    props.getAllOperators?.();
                    break;
                case TagName.ChannelName:
                    props.getAllChannels?.();
                    break;
                case TagName.Contact:
                    props.getContacts?.(searchString);
                    break;
                default:
                    break;
            }
        }
        
        let newSuggestions: ISearchingTag[] = [];
        if(searchingTag.tagType === TagType.OpeningTag) {
            const openingTag = tags[tags.length - 1];
            if(tagNameOperations.has(openingTag.tagName ?? TagName.Phrase)) {
                const operationId = tagNameOperations.get(openingTag.tagName ?? TagName.Phrase);
                newSuggestions = [{...props.searchingTags.find(tag => tag.id === operationId) ?? {} as ISearchingTag}];
            } else {
                newSuggestions = props.searchingTags.filter(val => {
                    switch(searchingTag.tagType) {
                        case TagType.OpeningTag: {
                            return val.tagType === TagType.OperationTag && searchingTag.suggestions?.includes(val.id.toString());
                        }
                    }
                });
            }
        } else {
            newSuggestions = props.searchingTags.filter(val => {
                switch(searchingTag.tagType) {
                    case TagType.OperationTag: {
                        return val.tagType === TagType.ClosingTag && tags[tags.length - 2].suggestions?.includes(val.id.toString());
                    }
                    case TagType.ClosingTag: {
                        if(props.repeatingTags === null || props.repeatingTags === true || val.tagName === TagName.ChatTag) {
                            return val.tagType === TagType.OpeningTag;
                        }
                        else {
                            return val.tagType === TagType.OpeningTag && tags.find(tag => tag.id === val.id) == null;
                        }
                    }
                }
            });
        }
        setSuggestions([...newSuggestions]);
        if (searchingTag.tagType !== TagType.ClosingTag) {
            setTimeout(() => { scrollTagInputToRightSide(); inputRef.current?.focus() }, 10);
        } else {
            setTimeout(() => { scrollTagInputToRightSide(); inputRef.current?.focus(); _setSuggestionsListVisibility(false) }, 10);
        }
    }

    const _onTextSelected = (text: string) => {
        const lastTag = tags[tags.length - 1];
        const penultTag = tags[tags.length - 2];
        if(penultTag !== undefined && 
           lastTag !== undefined &&
           penultTag.tagType === TagType.OpeningTag &&
           penultTag.tagName === TagName.Date) {
            const [leftDate, rightDate] = _parsePeriodToDates(text);
            if(leftDate !== null && rightDate !== null) {
                const closingDateTag: ISearchingTag = {
                    id: Math.max(...props.searchingTags.map(tag => tag.id)) + 1,
                    tagType: TagType.ClosingTag,
                    value: text,
                    externalValue: _getPeriodInUtc(leftDate, rightDate),
                    tagName: TagName.Date,
                };
                _onSuggestionSelected(closingDateTag);
            }
            return;
        }

        if(penultTag !== undefined &&
           lastTag !== undefined &&
           penultTag.tagType === TagType.OpeningTag &&
           penultTag.tagName === TagName.ChatNumber) {
            const chatId = Number.parseInt(text, 10);
            if(chatId !== NaN) {
                const closingChatNumberTag: ISearchingTag = {
                    id: Math.max(...props.searchingTags.map(tag => tag.id)) + 1,
                    tagType: TagType.ClosingTag,
                    value: chatId.toString(),
                    tagName: TagName.ChatNumber,
                };
                _onSuggestionSelected(closingChatNumberTag);
            }
            return;
        }

        if(penultTag !== undefined &&
            lastTag !== undefined &&
            penultTag.tagType === TagType.OpeningTag &&
            penultTag.tagName === TagName.ChannelName) {
             const channelId = Number.parseInt(text, 10);
             if(channelId !== NaN) {
                 const closingChatNumberTag: ISearchingTag = {
                     id: Math.max(...props.searchingTags.map(tag => tag.id)) + 1,
                     tagType: TagType.ClosingTag,
                     value: text,
                     externalValue: `${props.channels?.find(el => {return el.name === text})?.channelId ?? "0"}`,
                     tagName: TagName.ChannelName,
                 };
                 _onSuggestionSelected(closingChatNumberTag);
             }
             return;
        }

        if (penultTag !== undefined &&
            lastTag !== undefined &&
            penultTag.tagType === TagType.OpeningTag &&
            penultTag.tagName === TagName.Operator) {
            if (props.operators?.some(op => op.name === text)) {
                const closingChatNumberTag: ISearchingTag = {
                    id: Math.max(...props.searchingTags.map(tag => tag.id)) + 1,
                    tagType: TagType.ClosingTag,
                    value: text,
                    externalValue: `${props.operators?.find(el => { return el.name === text })?.operatorId ?? "0"}`,
                    tagName: TagName.Operator
                };
                _onSuggestionSelected(closingChatNumberTag);
            }
            return;
        }

        if(penultTag !== undefined &&
            lastTag !== undefined &&
            penultTag.tagType === TagType.OpeningTag &&
            penultTag.tagName === TagName.Custom) {
             
             if(text.length > 0) {
                 const closingCustomTag: ISearchingTag = {
                     id: Math.max(...props.searchingTags.map(tag => tag.id)) + 1,
                     tagType: TagType.ClosingTag,
                     value: text,
                     tagName: TagName.Custom,
                 };
                 _onSuggestionSelected(closingCustomTag);
             }
             return;
         }

        if(lastTag !== undefined && lastTag.tagType !== TagType.ClosingTag) {
            return;
        }

        setSearchString("");

        const openingPhraseTag: ISearchingTag = {
            id: Math.max(...props.searchingTags.map(tag => tag.id)) + 1,
            tagType: TagType.OpeningTag,
            tagName: TagName.Phrase,
            value: t('common.phrase'),
        };
        const operationPhraseTag: ISearchingTag = {...props.searchingTags.find(tag => tag.tagType === TagType.OperationTag && tag.value == "=") ?? {} as ISearchingTag};
        const closingPhraseTag: ISearchingTag = {
            id: openingPhraseTag.id++,
            tagType: TagType.ClosingTag,
            value: text,
            tagName: TagName.Phrase,
        }
        tags.push(...[openingPhraseTag, operationPhraseTag, closingPhraseTag]);

        setSuggestions(props.searchingTags.filter(val => {
            if(props.repeatingTags === null || props.repeatingTags === true) {
                return val.tagType === TagType.OpeningTag;
            }
            else {
                return val.tagType === TagType.OpeningTag && tags.find(tag => tag.id === val.id) === null;
            }
        }));
    }

    const _onRemoveTagSet = (tag: ISearchingTag, key: number) => {
        setSearchString("");
        tags.splice(key - 2, 3);
        setTags([...tags]);
        let suggestions = [] as ISearchingTag[];
        const lastTag = tags[tags.length - 1];
        if(lastTag === undefined) {
            suggestions.push(...props.searchingTags.filter(tag => tag.tagType === TagType.OpeningTag));
            setSuggestions([...suggestions]);
        } else {
            switch(lastTag.tagType) {
                case TagType.ClosingTag: {
                    _filterSuggestions();
                }
            }
        }
    }

    const _onRemoveLastTag = (needText: boolean) => {
        _setSuggestionsListVisibility(false);
        if (tags[tags.length - 1]?.tagName === TagName.Phrase) {
            _onRemoveTagSet(tags[tags.length - 1], tags.length - 1);
            return;
        }
        if(tags.length > 0) {
            let lastTag = tags.splice(tags.length - 1, 1)[0];
            setTags([...tags]);
            if(needText) {
                setSearchString(lastTag.value);
            }

            lastTag = tags[tags.length - 1];

            if(lastTag !== undefined) {
                const newSuggestions = props.searchingTags.filter(val => {
                    switch(lastTag.tagType) {
                        case TagType.OpeningTag: {
                            return val.tagType === TagType.OperationTag && lastTag.suggestions?.includes(val.id.toString());
                        }
                        case TagType.OperationTag: {
                            return val.tagType === TagType.ClosingTag && tags[tags.length - 2].suggestions?.includes(val.id.toString());
                        }
                        case TagType.ClosingTag: {
                            return val.tagType === TagType.OpeningTag;
                        }
                    }
                });
                setSuggestions([...newSuggestions]);
            } else {
                setSuggestions([...props.searchingTags.filter(val => val.tagType === TagType.OpeningTag)]);
            }
        }
        setTimeout(() => _setSuggestionsListVisibility(true), 10);
    }

    const _updateTagNameOperations = () => {
        const updatedTagNameOperations = new Map<TagName, number>();
        tags.filter(tag => tag.tagType === TagType.OpeningTag).forEach((tag) => {
            if(!updatedTagNameOperations.has(tag.tagName ?? TagName.Phrase)) {
                const operationTagIndex = tags.indexOf(tag);
                const operationTag = tags[operationTagIndex + 1];
                if(operationTag !== undefined && operationTag.tagType === TagType.OperationTag) {
                    updatedTagNameOperations.set(tag.tagName ?? TagName.Phrase, operationTag.id);
                }
            }
        });
        setTagNameOperations(updatedTagNameOperations);
    }

    const _parsePeriodToDates = (period: string) => {
        const [leftDateString, rightDateString] = period.split("-");
        const [left, right] = 
        [
            `${leftDateString.split('.')[1]} ${leftDateString.split('.')[0]} ${leftDateString.split('.')[2]}`, 
            `${rightDateString.split('.')[1]} ${rightDateString.split('.')[0]} ${rightDateString.split('.')[2]}`
        ];
        const [leftDate, rightDate] = [new Date(left), new Date(right)];

        if(leftDate.toString() === INVALID_DATE || rightDate.toString() === INVALID_DATE) {
            showErrorToastr(t('error.invalidDateMessage'));
            return [null, null];
        }
        else {
            if(leftDate <= rightDate) {
                return [leftDate, rightDate];
            }
            else {
                showErrorToastr(t('error.leftDateLessMessage'));
                return [null, null];
            }
        }
    }

    const _getDefaultPeriod = () => {
        const today = new Date(Date.now());
        const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
        const todayMonthString = `0${today.getMonth() + 1}`.slice(-2);;
        const yesterdayMonthString = `0${yesterday.getMonth() + 1}`.slice(-2);
        const todayDayString = `0${today.getDate()}`.slice(-2);
        const yesterdayDayString = `0${yesterday.getDate()}`.slice(-2);
        return `${yesterdayDayString}.${yesterdayMonthString}.${yesterday.getFullYear()} 00:00:00-${todayDayString}.${todayMonthString}.${today.getFullYear()} 23:59:59`
    }

    const _getPeriodInUtc = (leftDate: Date, rightDate: Date) => {
        
        const leftDateMonthString = `0${leftDate.getUTCMonth() + 1}`.slice(-2);
        const rightDateMonthString = `0${rightDate.getUTCMonth() + 1}`.slice(-2);
        const leftDateDayString = `0${leftDate.getUTCDate()}`.slice(-2);
        const rightDateDayString = `0${rightDate.getUTCDate()}`.slice(-2);
        const leftDateHoursString = `0${leftDate.getUTCHours()}`.slice(-2);
        const leftDateMinutesString = `0${leftDate.getUTCMinutes()}`.slice(-2);
        const leftDateSecondsString = `0${leftDate.getUTCSeconds()}`.slice(-2);
        const rightDateHoursString = `0${rightDate.getUTCHours()}`.slice(-2);
        const rightDateMinutesString = `0${rightDate.getUTCMinutes()}`.slice(-2);
        const rightDateSecondsString = `0${rightDate.getUTCSeconds()}`.slice(-2);
        return `${leftDateDayString}.${leftDateMonthString}.${leftDate.getFullYear()} ${leftDateHoursString}:${leftDateMinutesString}:${leftDateSecondsString}-${rightDateDayString}.${rightDateMonthString}.${rightDate.getFullYear()} ${rightDateHoursString}:${rightDateMinutesString}:${rightDateSecondsString}`
    }

    const _clearFilter = () => {
        setSearchString("");
        setTags([]);
        setSuggestions(props.searchingTags.filter(val => val.tagType === TagType.OpeningTag));
        _updateTagNameOperations();
        _setSuggestionsListVisibility(false);
        props.onClearFilter();
    }

    const scrollTagInputToRightSide = () => {
        document.getElementById('tag-searching-input__container')?.scrollTo(document.getElementById('tag-searching-input__container')?.scrollWidth ?? 0, 0);
    }

    return (
        setTimeout(() => { scrollTagInputToRightSide(); }, 10) &&
        <div className={`tag-searching-input__container ${props.containerClass ?? ""}`} id={'tag-searching-input__container'}>
            <TagsList
                tags={tags}
                onCloseTag={_onRemoveTagSet}
                operators={props.operators ?? []}
                channels={props.channels ?? []}
                contacts={props.contacts ?? []}
                />
            <SuggestionsList
                isSuggestionsListVisible={isSuggestionsListVisible}
                suggestions={suggestions}
                searchingTag={tags ?? []}
                onSelected={_onSuggestionSelected}
                operators={props.operators ?? []}
                contacts={props.contacts ?? []}
                channels={props.channels ?? []}
                searchString={searchString} />
            <input
                className="tag-searching-input__input"
                placeholder={(searchString.length === 0 && tags.length === 0) ? (t('common.search') + "...") : undefined}
                ref={inputRef}
                autoComplete="off"
                id={"tag-searching-input__input"}
                onKeyDown={(event) => {
                    if(event.key === "Enter") {
                        if(searchString.length === 0) {
                            props.onSearch(tags);
                        } else {
                            _onTextSelected(searchString);
                            setTimeout(() => { scrollTagInputToRightSide(); inputRef.current?.focus() }, 10);
                        }
                        _setSuggestionsListVisibility(false);
                    }
                    if(event.key === "Backspace") {
                        if(searchString.length > 0) {
                            setSearchString(searchString.substring(0, searchString.length));
                        } else {
                            _onRemoveLastTag(true);
                        }
                        _setSuggestionsListVisibility(true);
                    }
                }}
                onFocus={() => _setSuggestionsListVisibility(true)}
                onClick={() => _setSuggestionsListVisibility(true)}
                value={searchString}
                onChange={(event) => {
                    setSearchString(event.currentTarget.value);
                }} />
                {tags.length > 0 && <div 
                    className="clear-filter"
                    onClick={() => {
                        _clearFilter();
                    }}>
                    <i className="material-icons">clear</i>
                </div>}
        </div>
    );
}

export default TagSearchingInput;