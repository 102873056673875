import moment from 'moment';

export const getDate = (date: Date, format: string) => {
    return moment(date).format(format);
}

export const getTimePassedFromDate = (date: Date) => {

    let localTime : number = new Date().getTime();
    let serverTime: number = new Date(date).getTime();
    let timeDifference : number = localTime - serverTime;

    if (timeDifference < 0) {
        return moment.utc(0).format('HH:mm:ss');
    }

    return moment.utc(timeDifference).format('HH:mm:ss');
}

export const getTime = (date: Date) => {
    return moment(date).format('HH:mm');
}