import {connect } from "react-redux";
import React, { useEffect, useState } from 'react';
import {IApplicationState} from "src/~store/models/ApplicationState";
import { ModalWindow } from "src/app/shared/components/modal-window/ModalWindow";
import {ISpecifyChatCloseReasonDialogProps} from './~types/SpecifyChatCloseReasonDialogProps';
import { getCloseChatCategoriesSettings, getTopicPairSubjectsByTopicName } from "../../chats-panel/~store/actions/actions";
import { closeChatCategoriesSelector, isGettingTopicPairSubjectsSelector, topicPairSubjectsSelector } from "../../chats-panel/~store/selectors";
import { saveTags } from "../../chats/~store/actions/actions";
import { ITagModel } from "./~store/models/TagModel";
import { showInfoToastr, showSuccessToastr } from 'src/app/shared/helpers/toastr-helper/ToastrHelper';
import './SpecifyChatCloseReasonDialog.css';
import Select from 'react-select';
import { Button, Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { divider } from "@uiw/react-md-editor";



type ReduxType = ReturnType<typeof mapStateToProps> & ISpecifyChatCloseReasonDialogProps;

const SpecifyChatCloseReasonDialog = (props: ReduxType) => {         

    const { t } = useTranslation();

    const customStyles = {
        control: (provided: any, state: any) => ({
          ...provided,
          border: 'solid',
          borderColor: '#26a69a !important',
          borderBottomWidth: '2px',
          borderTopWidth: '0px',
          borderRightWidth: '0px',
          borderLeftWidth: '0px',
          borderRadius: 'unset',
          minHeight: '0px',
          height: '45px',       
          boxShadow: 'unset !important'
        }),
        option: (provided: any, state: any) => ({
           ...provided,
           color: state.isSelected ? 'white' : 'gray',
           backgroundColor: state.isSelected ? 'gray' : 'white'
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            minHeight: '0px',
            height: '45px'          
        }),
        input: (provided: any, state: any) => ({
            ...provided,
            minHeight: '0px',
            height: 'unset'      
        }),
      }

    const [category, setCategory] = useState<string>('');
    const [subject, setSubject] = useState<string>('');

    useEffect(() => {
        props.getTopicPairSubjectsByTopicName(props.nlpTopicName);
        props.getCloseChatCategoriesSettings();
    }, [props.nlpTopicName]);

    useEffect(() => {
        setCategory('');
        setSubject('');
    }, [props.chatId])


    const onChangeCategory = (e: any) => {
        setCategory(e.value);
    }

    const onChangeSubject = (e: any) => {
        setSubject(e.value);
    }

    const TagsAreChosen = (): boolean => {
        if ((!category && props.closeChatCategories.length > 0) || (!subject && props.topicPairSubjects.length > 0)) {
                return false;
        }
        return true;
    }

    const GetTagModels = () : ITagModel[] => {
        const tags: ITagModel[] = [];

        if(category){
            tags.push({
                chatId: props.chatId,
                name: "category",
                value: category
            } as ITagModel);
        }

        if(subject){
            tags.push({
                chatId: props.chatId,
                name: "subject",
                value: subject
            } as ITagModel);
        }

        return tags;
    }

    const SaveTags = () => {

        if (!TagsAreChosen()) {
            showInfoToastr(t('chat.categoryAndSubjectSavedError'));
            return;
        }

        const tags = GetTagModels();
        props.saveTags(tags);
        props.setIsVisible(false);
        showSuccessToastr(t('chat.categoryAndSubjectSavedSuccessfully'));

        props.closeChat(props.chatId, true);
    }

    const _getHeader = () => {
        return(
            <>  
                <div className='close-reason-container__question'>{props.topicPairSubjects.length > 0 ? t('chat.specifyCategoryAndSubject') : t('chat.specifyCategory')}</div>
                <div className="hide-chat-panel-button close-chat-panel-button">
                    <img onClick={() => {props.setIsVisible(false)}}></img>
                </div>
            </>
        );
    }

    const _getBody = () => {
        
        return(
            <>                
                <Select 
                    id="category"
                    className='close-reason-container__select' 
                    options={props.closeChatCategories}
                    placeholder={t('chat.category')}
                    onChange={onChangeCategory}
                    styles={customStyles}
                    />
                {props.topicPairSubjects.length > 0 && <Select 
                    id="subject"
                    className='close-reason-container__select' 
                    options={props.topicPairSubjects} 
                    placeholder={t('chat.subject')}
                    onChange={onChangeSubject}
                    styles={customStyles}
                />}
                {props.isgettingTopicPairSubjects && <div className="close-reason-container_spinner"><Spinner /></div>}
            </>
        );
    }

    const _getFooter = () => {
        return (
            <Button className='close-reason-container__button' disabled={ props.isgettingTopicPairSubjects} onClick={() => {SaveTags();}}>{t('chat.closeChat')}</Button>
        );
    }    

    return (        
        <ModalWindow 
            className='chat__close-reason-container'
            isOpen={props.isVisible}
            header={_getHeader()}
            body={_getBody()}
            footer={_getFooter()}
        />
    );

}

const mapStateToProps = (state: IApplicationState, props: any) => {
    return {
        topicPairSubjects: topicPairSubjectsSelector(state),
        closeChatCategories: closeChatCategoriesSelector(state),
        isgettingTopicPairSubjects: isGettingTopicPairSubjectsSelector(state),
        isVisible: props.isVisible,
        nlpTopicName: props.nlpTopicName,
        setIsVisible: props.setIsVisible,
        closeChat: props.closeChat,
        chatId: props.chatId
    };
};

const mapDispatchToProps = (dispatch: any): ISpecifyChatCloseReasonDialogProps => {
    return {
        getTopicPairSubjectsByTopicName: (topicName: string) => dispatch(getTopicPairSubjectsByTopicName(topicName)),
        getCloseChatCategoriesSettings: () => dispatch(getCloseChatCategoriesSettings()),
        saveTags: (tags: ITagModel[]) => dispatch(saveTags(tags))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SpecifyChatCloseReasonDialog);