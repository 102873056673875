import Paper from '@mui/material/Paper';
import React from 'react'
import { Spinner } from 'reactstrap';
import { IMessageItem, ISendingMessage } from '../../~store/models/MessageItem';

import './SendingMessage.css';

interface ISendingMessageProps{
  sendingMessage: ISendingMessage;
  onResendClick (message: IMessageItem):void;
}

export default function SendingMessage(props: ISendingMessageProps) {
  const style = props.sendingMessage.failed ? " send-message-failed" : " sending-message-in-proccess";

    return (
        <div className={"chat-container__message-item-container" + style}>
            <div className="message-item operator-message-type">
                <div className="sending-message-text">{props.sendingMessage.message.text}</div>
                {props.sendingMessage.failed ? 
                    <span className="sending-message-resend"><button onClick={() => props.onResendClick(props.sendingMessage.message)}>⟳</button></span> : 
                    <span className="sending-message-spinner"><Spinner style={{ width: '1rem', height: '1rem' }} /></span>
                 }
                
            </div>
        </div>
    );
}
