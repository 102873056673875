import * as React from "react";
import { GET_AVATAR_BY_CONTACT_ID_API_ROUTE } from "src/app/contact/~api/apiRoutes";
import { showErrorToastr } from "src/app/shared/helpers/toastr-helper/ToastrHelper";
import { IContactItemInfoRouteParams } from "./~types/ContactItemInfoRouteParams";
import ContactMessengers from "../contact-messengers/ContactMessengers";
import { IApplicationState } from "src/~store/models/ApplicationState";
import { IContactItemInfoProps } from "./~types/ContactItemInfoProps";
import { IContact } from "src/app/contact/~store/models/Contact";
import { createMatchSelector } from "connected-react-router";
import { Table, Button, Input } from "reactstrap";
import { connect } from "react-redux";
import { getDate } from "src/app/shared/helpers/date-helper/DateHelper";
import { getDateTime } from "src/app/shared/helpers/date-helper/DateFormats";
import {
  selectedContactSelector,
  isReceivingContactSelector,
  countOfContactsSelector,
  contactsSelector,
  changedContactSelector,
  contactToMergeSelector,
  isRestoringContactSelector,
} from "src/app/contact/~store/selector";
import {
  saveContact,
  deleteContact,
  getContactById,
  changeSelectedContact,
  findContactByPhone,
  changeContactAvatar,
  saveAccount,
  deleteAccount,
  mergeContact,
  restoreContact,
  clearContactFoundByPhone,
  newChatWithMessenger,
  detachMessenger,
} from "src/app/contact/~store/actions/actions";
import { isPhoneValid } from "src/app/shared/helpers/phone-helper/PhoneHelper";

import history from "src/~store/history";
import ValidatableInput from "src/app/shared/components/validatable-input/ValidatableInput";
import TelephoneInput from "src/app/shared/components/telephone-input/TelephoneInput";
import ChangableImage from "src/app/shared/components/changable-image/ChangableImage";
import ContactAccountList from "./contact-account-list/ContactAccountList";

import "./ContactItemInfo.css";
import { URL_TO_CHATS_WITH_FILTER } from "./ContactItemInfoConstants";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IAccount } from "src/app/contact/~store/models/Account";
import { initNewContact } from "src/app/contact/~store/state/InitContactState";
import {
  operatorIdSelector,
  operatorStatusSelector,
} from "src/app/chats-panel/~store/selectors";
import { OperatorStatusType } from "src/app/chats-panel/~store/state/ChatItemsState";
import { IMessenger } from "src/app/contact/~store/models/Messenger";
import { CHAT_NAME_MAX_LENGTH, COMMENT_MAX_LENGTH } from "src/~store/constants";
import { useTranslation } from "react-i18next";
import { ModalWindow } from "src/app/shared/components/modal-window/ModalWindow";

type ReduxType = ReturnType<typeof mapStateToProps> & IContactItemInfoProps;

const ContactItemInfo = (props: ReduxType) => {
  const { t } = useTranslation();
  const validation = {
    isFirstNameInvalid: false,
    isPhoneInvalid: false,
    isLastNameInvalid: false,
    isPatronymicInvalid: false,
  };

  const location = useLocation();

  const [isCommentModalWindowOpen, setIsCommentModalWindowOpen] = useState<boolean>(false);

  useEffect(() => {
    let contactId = 0;
    const path = window.location.pathname;
    if (path.includes("/contacts/edit-contact/")) {
      contactId = +path.split("/")[path.split("/").length - 1];
    }
    if (contactId > 0) {
      props.getContactById(contactId);
    } else {
      const contact =
        props.changedContact.contactId === 0
          ? props.changedContact
          : initNewContact();
      props.changeSelectedContact(contact ?? initNewContact());
    }
  }, [location]);

  const {
    messengers,
    createdOn,
    contactId,
    firstName,
    lastName,
    chatName,
    phone,
    patronymic,
    avatarId,
    chatsCount,
    contactComment,
    isDeleted,
    accounts,
    locale,
  } = props.changedContact;

  const _getBody = (): React.ReactElement<{}> => {
    const userInStorage = sessionStorage.getItem("user");
    const customerId = userInStorage === null ? "" : JSON.parse(userInStorage.toString()).customerId;
    const contactAvatar =
      avatarId === null
        ? ""
        : GET_AVATAR_BY_CONTACT_ID_API_ROUTE +
        props.changedContact.avatarId +
        "&customerId=" +
        customerId;
    return (
      <React.Fragment>
        <Table className="contact-item-info__table">
          <tbody className="contact-item-info__table-body">
            <tr>
              <td className="contact-item-info__avatar-container">
                <ChangableImage
                  src={contactAvatar}
                  className="contact-item-info__avatar"
                  onImageChange={_onImageChangeHandler}
                />
              </td>
              <td>
                <ContactMessengers
                  messengers={messengers}
                  isNeedDetach={messengers.length > 1 ? true : false}
                  detach={props.detachMessenger}
                  newChatWithMessenger={_onNewChatWithMessenger}
                />
              </td>
            </tr>
            <tr className="contact-item-info__phone-cell">
              <td>{t("contact.phoneNumber")}</td>
              <td className="contact-item-info__phone-input-cell">
                <TelephoneInput
                  containerClass="contact-item-info__telephone-container"
                  phoneNumber={phone ?? ""}
                  onChange={_onPhoneChangeHandler}
                />
                {_isContactExist(props.contactToMerge) && (
                  <Button
                    className="contact-item-info__merge-button"
                    onClick={props.mergeContact}
                  >
                    {t("common.merge")}
                  </Button>
                )}
              </td>
            </tr>
            <tr>
              <td>{t("contact.firstName")}</td>
              <td>
                <ValidatableInput
                  id={"first-name-input" + contactId}
                  value={firstName ?? "unknown"}
                  onValueChange={_onFirstNameChangeHandler}
                  validate={_firstNameValidation}
                />
              </td>
            </tr>
            <tr>
              <td>{t("contact.lastName")}</td>
              <td>
                <ValidatableInput
                  id={"last-name-input" + contactId}
                  value={lastName ?? "unknown"}
                  onValueChange={_onLastNameChangeHandler}
                  validate={_lastNameValidation}
                />
              </td>
            </tr>
            <tr>
              <td>{t("contact.middleName")}</td>
              <td>
                <ValidatableInput
                  id={"patronymic-input" + contactId}
                  value={patronymic ?? ""}
                  onValueChange={_onPatronymicChangeHandler}
                  validate={_patronymicValidation}
                />
              </td>
            </tr>
            <tr>
              <td>{t("contact.chatName")}</td>
              <td>
                <input
                  value={chatName ?? ""}
                  type="text"
                  maxLength={CHAT_NAME_MAX_LENGTH}
                  onChange={_onChatNameChangeHandler}
                />
              </td>
            </tr>
            <tr>
              <td>{t("contact.comment")}</td>
              <td>
                <Input
                  value={contactComment ?? ""}
                  type="textarea"
                  maxLength={COMMENT_MAX_LENGTH}
                  onChange={_onContactCommentChangeHandler}
                  style={{
                    resize: "none",
                    borderLeftStyle: "hidden",
                    borderTopStyle: "hidden",
                    borderRightStyle: "hidden",
                    outline: "none",
                    height: 67.5,
                  }}
                />
              </td>
              <td>
                <button className="open-comment-in-full-screen" onClick={_commentOpen}>
                    <i className="material-icons">open_in_full</i>
                        </button>
                <ModalWindow 
                    className="setting-item-modal" 
                    isOpen={isCommentModalWindowOpen}
                    header={_getCommentHeader()}
                    body={_getCommentBody()}
                    footer={<></>}
                />
              </td>
            </tr>
            <tr className="contact-item-info__created-time-cell">
              <td>{t("common.created")}</td>
              <td>{getDateTime(createdOn)}</td>
            </tr>
            <tr>
              <td>{t("contact.chatsCount")}</td>
              <td>
                {chatsCount === 0 && chatsCount}
                {chatsCount > 0 && (
                  <Link to={URL_TO_CHATS_WITH_FILTER + "contact:" + contactId}>
                    {chatsCount}
                  </Link>
                )}
              </td>
            </tr>
            <tr className="contact-item-info__localization-cell">
              <td>{t("contact.locale")}</td>
              <td>
                <Input
                  type="select"
                  className="contact-account-item__type-input"
                  value={locale}
                  onChange={_onLocaleChangeHandler}
                >
                  <option>ru-RU</option>
                  <option>uk-UA</option>
                  <option>uz-Latn-UZ</option>
                  <option>kk-KZ</option>
                  <option>en-US</option>
                  <option>az-Latn-AZ</option>
                  <option>ky-KG</option>
                  <option>tg-Cyrl-TJ</option>
                  <option>ro-MD</option>
                  <option>ro-RO</option>
                </Input>
              </td>
            </tr>
          </tbody>
        </Table>
      </React.Fragment>
    );
  };

  const _isContactExist = (contact: IContact): boolean => {
    return !(contact.contactId === undefined || contact.contactId < 1);
  };

  const _commentOpen = (): void => {
    setIsCommentModalWindowOpen(!isCommentModalWindowOpen);
  };

  const _getCommentHeader = (): React.ReactElement<{}> => {
    return (
      <>
      {t("contact.comment")}
      <Button
      className="setting-header__close-button red"
      onClick={_commentOpen}>
      <i className="material-icons">
          close
      </i>
      </Button>
      </>
    );
  };

  const _getCommentBody = (): React.ReactElement<{}> => {
    return (
        <Input
            value={contactComment ?? ""}
            type="textarea"
            maxLength={COMMENT_MAX_LENGTH}
            onChange={_onContactCommentChangeHandler}
            style={{
                resize: "none",
                borderLeftStyle: "hidden",
                borderTopStyle: "hidden",
                borderRightStyle: "hidden",
                outline: "none",
                height: 500,
            }}
        />
    );
    };

  const _getFooter = (): React.ReactElement<{}> => {
    return (
      <React.Fragment>
        {!props.isRestoringContact && (
          <div className="contact-item-info__actions">
            {!isDeleted && (
              <Button
                className="contact-item-info__btn-save"
                onClick={_onSaveContactHandler}
                disabled={!_areAnyChanges()}
              >
                {t("common.save")}
              </Button>
            )}
            <Button
              className="contact-item-info__btn-cancel"
              onClick={_onCancelChangesHandler}
              disabled={!_areAnyChanges()}
            >
              {t("common.cancel")}
            </Button>
            {!isDeleted && (
              <Button
                className="contact-item-info__btn-delete"
                onClick={_onDeleteContactHandler}
                disabled={!_areAnySaved()}
              >
                {t("common.delete")}
              </Button>
            )}
            {isDeleted && (
              <Button
                className="contact-item-info__btn-restore"
                onClick={() =>
                  props.restoreContact(props.changedContact.contactId ?? 0)
                }
              >
                {t("common.restore")}
              </Button>
            )}
          </div>
        )}
        {!props.isReceivingContact && (
          <ContactAccountList
            contactAccounts={accounts}
            contactId={props.changedContact.contactId ?? 0}
            saveAccount={props.saveAccount}
            deleteAccount={props.deleteAccount}
          />
        )}
      </React.Fragment>
    );
  };

  const _onImageChangeHandler = (imageFile: File): void => {
    if (props.changedContact.contactId === undefined) {
      showErrorToastr(t('operators.changeAvatarNullIdErrorMessage'));
      return;
    }

    props.changeContactAvatar(imageFile, props.changedContact);
  };

  const _onSaveContactHandler = (): void => {
    const contact: IContact = {
      ...props.changedContact,
    };

    props.saveContact(contact, props.countOfContacts);
  };

  const _onCancelChangesHandler = (): void => {
    props.changeSelectedContact(Object.assign({}, props.selectedContact));
  };

  const _onDeleteContactHandler = (): void => {
    props.deleteContact(props.selectedContact, props.countOfContacts + 1);
    history.push(`/contacts`);
  };

  const _onPhoneChangeHandler = (
    phoneNumber: string,
    countryData: any
  ): void => {
    if (isPhoneValid(phoneNumber, countryData.dialCode)) {
      props.findContactByPhone(phoneNumber);
    } else if (props.contactToMerge.contactId !== undefined) {
      console.log(true);
      props.clearContactFoundByPhone();
    }
    props.changeSelectedContact({
      ...props.changedContact,
      phone: phoneNumber,
    });
  };

  const _onLocaleChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const newLocale = event.currentTarget.value;
    props.changeSelectedContact({
      ...props.changedContact,
      locale: newLocale,
    });
  };

  const _onFirstNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.currentTarget.value;
    props.changeSelectedContact({
      ...props.changedContact,
      firstName: value,
    });
  };

  const _firstNameValidation = (firstName: string): string => {
    if (firstName.length === 0) {
      validation.isFirstNameInvalid = true;
      return "First name cannot be empty";
    } else {
      validation.isFirstNameInvalid = false;
      return "";
    }
  };

  const _onLastNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.currentTarget.value;
    props.changeSelectedContact({
      ...props.changedContact,
      lastName: value,
    });
  };

  const _lastNameValidation = (lastName: string): string => {
    if (lastName.length === 0) {
      validation.isLastNameInvalid = true;
      return "Last name cannot be empty";
    } else {
      validation.isLastNameInvalid = false;
      return "";
    }
  };

  const _onContactCommentChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.currentTarget.value;
    props.changeSelectedContact({
      ...props.changedContact,
      contactComment: value,
    });
  };

  const _onPatronymicChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.currentTarget.value;
    props.changeSelectedContact({
      ...props.changedContact,
      patronymic: value,
    });
  };

  const _patronymicValidation = (patronymic: string): string => {
    if (patronymic.length === 0) {
      validation.isPatronymicInvalid = true;
      return "Middle name cannot be empty";
    } else {
      validation.isPatronymicInvalid = false;
      return "";
    }
  };

  const _onChatNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.currentTarget.value;
    props.changeSelectedContact({
      ...props.changedContact,
      chatName: value,
    });
  };

  const _areAnyChanges = (): boolean => {
    const { selectedContact, changedContact } = props;
    return (
      selectedContact.firstName !== changedContact.firstName ||
      selectedContact.lastName !== changedContact.lastName ||
      selectedContact.patronymic !== changedContact.patronymic ||
      selectedContact.chatName !== changedContact.chatName ||
      selectedContact.phone !== changedContact.phone ||
      selectedContact.contactComment !== changedContact.contactComment ||
      selectedContact.avatarId !== changedContact.avatarId ||
      selectedContact.locale !== changedContact.locale
    );
  };

  const _areAnySaved = (): boolean => {
    const { selectedContact} = props;
    return (
      selectedContact.firstName?.length !== 0 ||
      selectedContact.lastName?.length !== 0 ||
      selectedContact.patronymic?.length !== 0 ||
      selectedContact.chatName?.length !== 0 ||
      selectedContact.contactComment?.length !== 0 ||
      selectedContact.phone?.length !== 0
    );
  };

  const _onNewChatWithMessenger = (messenger: IMessenger): void => {
    if (props.operatorStatus === OperatorStatusType.Offline) {
      showErrorToastr(t('chatsPanel.errorReopenChatDueOfflineStatus'));
      return;
    }
    
    if (window.confirm("Вы хотите начать новый диалог с клиентом?")) {
      props.newChatWithMessenger(messenger.messengerId, messenger.contactId);
    }
  };

  return (
    <div className="contact-item-info__container">
      {_getBody()}
      {_getFooter()}
    </div>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    selectedContact: selectedContactSelector(state),
    isReceivingContact: isReceivingContactSelector(state),
    countOfContacts: countOfContactsSelector(state),
    contacts: contactsSelector(state),
    changedContact: changedContactSelector(state),
    contactToMerge: contactToMergeSelector(state),
    isRestoringContact: isRestoringContactSelector(state),
    operatorId: operatorIdSelector(state),
    operatorStatus: operatorStatusSelector(state),
    matchParams: createMatchSelector("/contacts/edit-contact/:contactId")(state)
      ?.params as IContactItemInfoRouteParams,
  };
};

const mapDispatchToProps = (dispatch: any): IContactItemInfoProps => {
  return {
    saveContact: (contact: IContact, contactCountToGet: number) =>
      dispatch(saveContact(contact, contactCountToGet)),
    deleteContact: (contact: IContact, contactCountToGet: number) =>
      dispatch(deleteContact(contact, contactCountToGet)),
    getContactById: (contactId: number) => dispatch(getContactById(contactId)),
    changeSelectedContact: (contact: IContact) =>
      dispatch(changeSelectedContact(contact)),
    findContactByPhone: (phone: string) => dispatch(findContactByPhone(phone)),
    changeContactAvatar: (file: File, contact: IContact) =>
      dispatch(changeContactAvatar(file, contact)),
    saveAccount: (account: IAccount) => dispatch(saveAccount(account)),
    deleteAccount: (accountId: number) => dispatch(deleteAccount(accountId)),
    mergeContact: () => dispatch(mergeContact()),
    restoreContact: (contactId: number) => dispatch(restoreContact(contactId)),
    clearContactFoundByPhone: () => dispatch(clearContactFoundByPhone()),
    newChatWithMessenger: (messengerId: number, contactId: number) =>
      newChatWithMessenger(messengerId, contactId),
    detachMessenger: (messengerId: number) =>
      dispatch(detachMessenger(messengerId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactItemInfo);
