import { createSelector } from 'reselect';
import { IApplicationState } from 'src/~store/models/ApplicationState';
import { IChatStep, IFromToStep, IGroupedStep, IGroupKey } from './models/ChatStep';

const analyticsStateSelector = (state: IApplicationState) => state.analyticsState;
const channelsStateSelector = (state: IApplicationState) => state.channelState;

const analyticsSelector = createSelector(
    analyticsStateSelector,
    analyticsState => analyticsState.analytics,
);

const channelsSelector = createSelector(
    channelsStateSelector,
    channelsState => channelsState.channelList
);

const customerJourneySelector = createSelector(
    analyticsSelector,
    analytics => analytics.customerJourney 
);

const chartsSelector = createSelector(
    analyticsSelector,
    analytics => analytics.charts
);

export const selectedTabSelector = createSelector(
    analyticsSelector,
    analytics => analytics.selectedTab
);

export const channelsListSelector = createSelector(
    channelsSelector,
    channels => channels.channels
)

export const analyticsTagsSelector = createSelector(
    analyticsSelector,
    analytics => analytics.tags
)

export const selectedSubtabSelector = createSelector(
    analyticsSelector,
    analytics => analytics.selectedSubtab
);

export const selectedCurrentSettingsSelector = createSelector(
    analyticsSelector,
    analytics => analytics.currentSettings
);

export const selectedTabKeysSelector = createSelector(
    analyticsSelector,
    analytics => analytics.allKeys
);

export const reportsAllKeysSelector = createSelector(
    analyticsSelector,
    analytics => analytics.reports.allKeys
);

export const reportsSelectedSubtabSelector = createSelector(
    analyticsSelector,
    analytics => analytics.reports.selectedSubtab
);

export const selectedReportsCurrentSettingsSelector = createSelector(
    analyticsSelector,
    analytics => analytics.reports.currentSettings
);

export const reportSelector = createSelector(
    analyticsSelector,
    analytics => {
        const data: { keys: string[], data: any[]} = {
            keys: [],
            data: [],
        }
        if(analytics.reports.report?.length > 0 && typeof analytics.reports.report === 'object') {
            data.keys = Object.keys(analytics.reports.report[0]);
            data.data = analytics.reports.report;
        }
        return data;
    }
);

export const isReportLoad = createSelector(
    analyticsSelector,
    analytics => analytics.reports.isLoad
)

const getGroupedChatSteps = (chatSteps: IChatStep[]):IGroupedStep[] => {
    const groupedObj: any = { };

    chatSteps.forEach(function(item) {
        const key = item.current;

        const list = groupedObj[key];

        if(list) {
            list.push(item);
        } 
        else {
            groupedObj[key] = [item];
        }
    });

    var groups: IGroupedStep[] = [];

    Object.entries(groupedObj).forEach((entry) => {
        const froms: IFromToStep[] = [];
        (entry[1] as IChatStep[]).forEach((item) => {
            const index = froms.findIndex(f => f.key === item.from);
            if(index > -1) {
                ++froms[index].count;
                froms[index].procent = (100 / (entry[1] as IChatStep[]).length) * froms[index].count
            }
            else {
                froms.push({key: item.from, count: 1, procent: (100 / (entry[1] as IChatStep[]).length)})
            }
        })

        const tos: IFromToStep[] = [];
        (entry[1] as IChatStep[]).forEach((item) => {
            const index = tos.findIndex(f => f.key === item.to);
            if(index > -1) {
                ++tos[index].count;
                tos[index].procent = (100 / (entry[1] as IChatStep[]).length) * tos[index].count;
            }
            else {
                tos.push({key: item.to, count: 1, procent: (100 / (entry[1] as IChatStep[]).length)})
            }
        })

        let buttonsArray = JSON.parse((entry[1] as Array<IChatStep>)[0].buttons);

        for(let i = 1; i < (entry[1] as Array<IChatStep>).length; ++i) {
            const btns = JSON.parse((entry[1] as Array<IChatStep>)[i].buttons) as Array<string>;
            buttonsArray = btns.filter(b => buttonsArray.includes(b));
            if (buttonsArray.length === 0) break;
        }

        const key: IGroupKey = {current: entry[0], buttons: JSON.stringify(buttonsArray)};
        groups.push({
            key: key,
            count: groupedObj[entry[0]].length,
            procent: (100 / chatSteps.length) * groupedObj[entry[0]].length,
            froms: froms.sort((a, b) => b.count - a.count),
            tos: tos.sort((a, b) => b.count - a.count)
        });
    })

    return groups.sort((a, b) => b.count - a.count);
}

export const chatStepGroupsSelector = createSelector(
    customerJourneySelector,
    customerJourney => { return getGroupedChatSteps(customerJourney.chatSteps) }
);

export const botsSelector = createSelector(
    customerJourneySelector,
    customerJourney => customerJourney.bots
);

export const selectedBotIdSelector = createSelector(
    customerJourneySelector,
    customerJourney => customerJourney.selectedBotId
);

export const allChartsSelector = createSelector(
    chartsSelector,
    charts => charts.allCharts
);