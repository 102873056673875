const HOST_API_ROUTE = `${process.env.REACT_APP_CHAT_API_ROUTE}`;

const CHAT_API_ROUTE = `${HOST_API_ROUTE}/chat`;
const MESSENGER_API_ROUTE = `${HOST_API_ROUTE}/messenger`
const MESSAGE_API_ROUTE = `${HOST_API_ROUTE}/message`;
const CHAT_TAGS_API_ROUTE = `${HOST_API_ROUTE}/tag`;

// contact
export const GET_CHATS_API_ROUTE = `${CHAT_API_ROUTE}/get-chats-lazy-by-tags`;
export const GET_CHATS_BY_CONTACT_ID_API_ROUTE = `${CHAT_API_ROUTE}/get-chats-by-contact-id?contactId=`;
export const GET_CHAT_BY_ID_API_ROUTE = `${CHAT_API_ROUTE}/get-chat-by-id?chatId=`;
export const GET_SELECTED_CHAT_BY_ID_API_ROUTE = `${CHAT_API_ROUTE}/get-selected-chat-by-id?chatId=`;
export const GET_MESSAGES_BY_CHAT_ID_API_ROUTE = `${MESSAGE_API_ROUTE}/get-messages-by-chat-id?chatId=`;
export const GET_CONTACT_CHATS_LAZY_API_ROUTE = `${CHAT_API_ROUTE}/get-contact-chats-lazy?startChatId=`;
export const OPEN_CHAT_BY_ID_API_ROUTE = `${CHAT_API_ROUTE}/open-chat-by-id?chatId=`;
export const PUSH_CHAT_HISTORY_TO_WIDGET_API_ROUTE = `${CHAT_API_ROUTE}/push-chat-history-to-widget`;

// messenger
export const GET_MESSENGER_BY_ID_API_ROUTE = `${MESSENGER_API_ROUTE}/get-messenger-by-id?messengerId=`;

// message
export const EDIT_MESSAGE_API_ROUTE = `${MESSAGE_API_ROUTE}/edit-message`;

// chat tags
export const GET_TAG_NAMES_API_ROUTE = `${CHAT_TAGS_API_ROUTE}/get-tag-names`;
export const GET_TAG_VALUES_BY_TAG_NAME_API_ROUTE = `${CHAT_TAGS_API_ROUTE}/get-tag-values-by-name`;
export const SAVE_TAG = `${CHAT_TAGS_API_ROUTE}/save-tags`;