export interface ISetting {
    id: string,
    settingTypeModel: SettingType,
    value: string,
    isCreating: boolean,
    key: string
}

export enum SettingType {
    User = 0,
    System,
    Integration
}

