import {IMailingState} from "./MailingState";
import { IMailing } from "../models/mailing";
import { MailingStatus } from "../models/enums/MailingStatus";
import { IMailingRecipient } from "../models/mailing-recipient";
import { IContact } from "src/app/contact/~store/models/Contact";
import { IChat } from "src/app/chats/~store/models/Chat";
import { MailingRecipientStatusModel }from "./../models/mailing-recipient";

export const initMailing = () : IMailing  =>  { 
    return{
        mailingId: 0,
        name: "",
        createdOn: new Date(),
        modifiedOn: new Date(),
        status: MailingStatus.pending,
        text: "",
        operatorId: 0, 
        channelTypes: [],
        recipients: []
    }
}

const testRecipient: IMailingRecipient = {
    mailingId: 1,
    contactId: 1,
    contact: {} as IContact,
    chatId: 1,
    chat: {} as IChat,
    mailingRecipientId: 1,
    status: MailingRecipientStatusModel.Created,
    errorMessage: ""
}



export const initState: IMailingState = {
    mailingList: {
        isReceivingmailings: false,
        allMailings: []
    },
    mailingItemInfo: {
        mailing: initMailing(),
        newMailing: initMailing(),
        recipients: [],
        enabledChannels: []
    }
}