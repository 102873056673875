import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart';
import * as unicodeEmoji from 'unicode-emoji';
import { OmitEmojiWhere } from 'unicode-emoji';


import {IEmojiDialogProps} from "./~types/EmojiDialogProps"
import './EmojiDialog.css'

const emojiUnicode = require("emoji-unicode");

const EmojiDialog = (props: IEmojiDialogProps) => {
    
    const [emojiDialogVisibility, setEmojiDialogVisibility] = useState(false);
    
    const omitWhere: OmitEmojiWhere = { 
        versionAbove: '12.0', 
        group: ['people-body','animals-nature','food-drink','travel-places','activities','objects','symbols','flags']
    };

    const emojis = unicodeEmoji.getEmojis(omitWhere);

    const emojisUnicode = emojis.map(emoji => emojiUnicode(emoji.emoji).toString().toUpperCase());    

    return(
        <Dropdown isOpen={emojiDialogVisibility} toggle={() => {setEmojiDialogVisibility(!emojiDialogVisibility)}}>
            <DropdownToggle className="smiles-container-dropdownToggle"><i className="material-icons smiles-button">sentiment_satisfied_alt</i></DropdownToggle>
                {emojiDialogVisibility && <DropdownMenu>
                <div className="smiles-container">
                    <Picker
                    emojisToShowFilter={((clickedEmoji: any) => emojisUnicode.some(emoji => emoji === clickedEmoji.unified))}
                    useButton={false}
                    title=''
                    theme="light"
                    set="google"
                    color="gray"
                    showPreview={false}
                    onSelect={props.onEmojiClick} />
            </div>
            </DropdownMenu>}
        </Dropdown> 
    );
}

export default EmojiDialog;