export enum PermissionType {
    Contacts_Manage = "Contacts_Manage",
    Chats_ManageOwnChats = "Chats_ManageOwnChats",
    Mailing_Manage = "Mailing_Manage",
    Chats_ManageOthersChats = "Chats_ManageOthersChats",
    NLP_Search = "NLP_Search",
    NLP_Manage = "NLP_Manage",
    Operators_Manage = "Operators_Manage",
    Channels_Manage = "Channels_Manage",
    Integrations_Manage = "Integrations_Manage",
    CanManageAnalytics ="CanManageAnalytics",
    License_Manage = "License_Manage"
}