import { IApiActionResult } from '../../../~api/ApiActionResult';
import { fetchPost, getApiActionResult } from '../../../~api/ApiHelper';
import { ILoginResult } from '../~store/models/LoginResult';
import { LOGIN_API_ROUTE } from './apiRoutes';

export const loginAsync = 
    async (login: string, password: string): Promise<IApiActionResult<ILoginResult>> => {
        const fetchPromise = fetchPost(LOGIN_API_ROUTE, {
            body: JSON.stringify({ login, password })
        });
        return await getApiActionResult(fetchPromise, true);
}