import { AppThunkAction } from "src/~store/models/AppThunkAction";
import { 
    GetMailingItemInfoAction,
    GetEnabledChannelsAction, 
    ChangeSelectedChannelsAction, 
    ChangeNameAction, 
    ChangeMessageAction, 
    SaveMessageAction, 
    GetMailingsAction, 
    RemoveAllMailingsFromStateAction, 
    StartMailingAction,
    DeleteMailingAction,
    GetRecipientsAction,
    DeleteRecipientsFromStateAction,
    ChangeOperatorIdAction} from "./interfaces";
import { 
    GET_MAILING_ITEM_INFO_SUCCESS, 
    GET_ENABLED_CHANNELS_SUCCESS, 
    CHANGE_SELECTED_CHANNELS_SUCCESS, 
    CHANGE_NAME_SUCCESS, 
    CHANGE_MESSAGE_SUCCESS, 
    SAVE_MAILING_SUCCESS,
    GET_MAILINGS_START, 
    GET_MAILINGS_SUCCESS, 
    GET_MAILINGS_ERROR,
    REMOVE_ALL_MAILINGS_FROM_STATE_SUCCESS,
    START_MAILINGS_SUCCESS,
    START_MAILINGS_ERROR,
    DELETE_MAILING_START,
    DELETE_MAILING_SUCCESS,
    GET_MAILINGS_RECIPIENTS_SUCCESS,
    DELETE_RECIPIENTS_FROM_STATE_SUCCESS,
    CHANGE_OPERATOR_ID_SUCCESS} from "./types";
import { getEnabledChannelsAsync, saveMailingAsync, getMailingByIdAsync, getMailingsAsync, startMailingAsync, deleteMailingAsync, getRecipientsAsync } from "../../~api/actions";
import { ChannelType } from "src/app/channel/~store/models/enums/ChannelType";
import history from "src/~store/history";
import { showErrorToastr, showSuccessToastr, showInfoToastr } from "src/app/shared/helpers/toastr-helper/ToastrHelper";
import { IMailing } from "../models/mailing";
import { initMailing } from "../state/InitMailingState";
import { getChannelsByChannelsIdAsync } from "src/app/channel/~api/actions";
import {MAILING_REQUEST_COUNT, MAILINGRECIPIENTS_REQUEST_COUNT} from "./../MailingConstants";
import i18n from "src/app/shared/localization/i18n";


export const startMailing = (mailingId: number): AppThunkAction<StartMailingAction> => async dispatch => {
    showInfoToastr(i18n.t('mailing.startMailingStartMessage'));
    const result = await startMailingAsync(mailingId);

    if(result.isSuccess) {
        const sentMailing = result.value || {} as IMailing;
        dispatch({type: START_MAILINGS_SUCCESS, payload: {sentMailing}});
        showSuccessToastr(i18n.t('mailing.startMailingSuccessMessage'));
    }
    else {
        showErrorToastr(i18n.t('mailing.startMailingErrorMessage'));
    }

}

export const getMailingItemInfo = (mailingId: number): AppThunkAction<GetMailingItemInfoAction> => async (dispatch, getState) => {
    let mailing: IMailing = initMailing();
    if(mailingId === 0) {
        mailing = getState().mailingState.mailingItemInfo.newMailing;
    }
    else { 
        const result = await getMailingByIdAsync(mailingId);

        if(result.isSuccess) {
            mailing = result.value || {} as IMailing;
        }
        else {
            showErrorToastr(i18n.t('mailing.getMailingByIdError'));
        }
    }
    dispatch({ type: GET_MAILING_ITEM_INFO_SUCCESS, payload: { mailing } });
}

export const getEnabledChannels = (): AppThunkAction<GetEnabledChannelsAction> => async dispatch => {
    const result = await getEnabledChannelsAsync();

    if(result.isSuccess) {
        const channels = result.value ? result.value : [];

        dispatch({type: GET_ENABLED_CHANNELS_SUCCESS, payload: { channels }});
    }
}

export const changeSelectedChannels = (type: ChannelType, isOn: boolean): AppThunkAction<ChangeSelectedChannelsAction> => async dispatch => {
    dispatch({type: CHANGE_SELECTED_CHANNELS_SUCCESS, payload: { type, isOn }});
}

export const changeName = (name: string): AppThunkAction<ChangeNameAction> => async dispatch => {
    dispatch({type: CHANGE_NAME_SUCCESS, payload: { name }});
}

export const changeOperatorId = (operatorId: number): AppThunkAction<ChangeOperatorIdAction> => async dispatch => {
    dispatch({ type: CHANGE_OPERATOR_ID_SUCCESS, payload: { operatorId } });
}

export const changeMessage = (message: string): AppThunkAction<ChangeMessageAction> => async dispatch => {
    dispatch({type: CHANGE_MESSAGE_SUCCESS, payload: { message }});
}

export const saveMailing = (): AppThunkAction<SaveMessageAction> => async (dispatch, getState) => {
    const result = await saveMailingAsync(getState().mailingState.mailingItemInfo.mailing);
    if(result.isSuccess) {
        if(getState().mailingState.mailingItemInfo.mailing.mailingId === 0)
            dispatch({type: SAVE_MAILING_SUCCESS});
        history.push("/mailing");
    }
    else{
        showErrorToastr(i18n.t('mailing.saveMailingError'));
    }
}

export const getRecipients = (mailingId: number): AppThunkAction<GetRecipientsAction> => async (dispatch, getState) => {
    
    const skip = getState().mailingState.mailingItemInfo.recipients.length;
    const result = await getRecipientsAsync(mailingId, skip, MAILINGRECIPIENTS_REQUEST_COUNT);

    if(result.isSuccess) {
        const recipients = result.value || [];
        dispatch({type: GET_MAILINGS_RECIPIENTS_SUCCESS, payload: {recipients}})
    }
}

export const deleteRecipientsFromState = (): AppThunkAction<DeleteRecipientsFromStateAction> => async dispatch => {
    dispatch({type: DELETE_RECIPIENTS_FROM_STATE_SUCCESS});
}

export const getMailings = (): AppThunkAction<GetMailingsAction> => async (dispatch, getState) => {
    if(getState().mailingState.mailingList.isReceivingmailings) return;

    dispatch({type: GET_MAILINGS_START});

    const skip = getState().mailingState.mailingList.allMailings.length;
    const result = await getMailingsAsync(skip, MAILING_REQUEST_COUNT);

    if(result.isSuccess) {
        const mailings = result.value || [];

        dispatch({type: GET_MAILINGS_SUCCESS, payload: {mailings}})
    }
    else {
        dispatch({type: GET_MAILINGS_ERROR});
    }
}

export const removeAllMailingsFromState = (): AppThunkAction<RemoveAllMailingsFromStateAction> => async dispatch => {
    dispatch({type: REMOVE_ALL_MAILINGS_FROM_STATE_SUCCESS});
}

export const deleteMailing = (mailingId: number): AppThunkAction<DeleteMailingAction> => async dispatch => {
    dispatch({type: DELETE_MAILING_START});
    const result = await deleteMailingAsync(mailingId);

    if(result.isSuccess) {
        dispatch({type: DELETE_MAILING_SUCCESS, payload: {mailingId}});
        showSuccessToastr(i18n.t('mailing.deleteMailingSuccessMessage'));
    }
    else {
        showErrorToastr(i18n.t('mailing.deleteMailingErrorMessage'));
    }
}

