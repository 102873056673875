import React, { useState, useRef, useEffect } from "react";

import "./PreviewFileDialog.css"
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { FileType } from "../choose-file-dialog/ChooseFileDialog";
import { FormatBytes } from "../../helpers/file-helper/FileSizeHelper";
import { useTranslation } from 'react-i18next';
import { showErrorToastr } from "../../helpers/toastr-helper/ToastrHelper";

interface previewFileDialogProps {
    file: File | undefined,
    type: FileType | undefined,
    onFileSend(): void,
    onFileCancel(): void
}

export default function PreviewFileDialog(props: previewFileDialogProps) {
    const { t } = useTranslation();
    const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
    const imageRef = useRef<HTMLImageElement | null>(null);

    useEffect(() => {
        if(props.file != null) {
            setIsMenuVisible(true);
        }
    },[props.file])

    const _mapFileDialog = () => {
        if(props.file === undefined) return;
        return (
            <React.Fragment>
                {_mapFileName()}
                <i>{FormatBytes(props.file.size, 3)} bytes</i>
            </React.Fragment>   
        )
    }

    const _mapImageDialog = () => {
        if (props.file === undefined) return;
        if (!props.file.type.includes("image/") || props.file.type.includes("svg")) {
            setIsMenuVisible(false);
            showErrorToastr(t('common.fileSendLikeImageError'));
            return;
        }
        const reader = new FileReader();
        reader.onload = function(e) {
            if(e.target?.result && typeof e.target.result === "string") {
                imageRef?.current?.setAttribute("src", e.target.result)
            }
                
        }
        reader.readAsDataURL(props?.file);

        return (
            <React.Fragment>
                <div className="previewFileDialog__image">
                    <img ref={imageRef}></img>
                    {_mapFileName()}
                </div>
            </React.Fragment>   
        )
    }

    const _mapFileName = () => {
        let fileName = props.file?.name;
        if(fileName && fileName.length > 35) {
            fileName = fileName.substring(0, 27) + "..." + fileName.substring(fileName.length - 7, fileName.length);
        }
        return (
            <React.Fragment>
                <div title={props.file?.name} className="previewFileDialog__file-name">{fileName}</div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Dropdown className="preview-file-dialog__container" isOpen={isMenuVisible} toggle={() => {setIsMenuVisible(!isMenuVisible)}}>
                {isMenuVisible && 
                    <DropdownMenu style={{}} className={"preview-file-dialog__menu " + (props.type === FileType.File ? "file" : "image")}>
                        {props.type === FileType.File && _mapFileDialog()}
                        {props.type === FileType.Image && _mapImageDialog()}
                        <div className="previewFileDialog__footer">
                            <button className="btn-small green" onClick={() => { setIsMenuVisible(false); props.onFileSend() }}>{t('common.send')}</button>
                            <button className="btn-small red" onClick={() => { setIsMenuVisible(false); props.onFileCancel() }}>{t('common.cancel')}</button>
                        </div>
                    </DropdownMenu>
                }
            </Dropdown>
        </React.Fragment>
    )
}