import * as React from 'react';
import PermissionsRoleList from './permission-role-list/PermissionRoleList';

import './PermissionTabs.css';
import { useTranslation } from 'react-i18next';
import { allPermissionsSelector } from '../../~store/selector';
import { IApplicationState } from 'src/~store/models/ApplicationState';
import { getAllPermissions, getAllUsers } from '../../~store/actions/actions';
import { IPermissionsTabsProps } from './~types/PermissionTabsProps';
import { connect } from 'react-redux';
import PermissionUserList from './permission-user-list/PermissionUserList';

type ReduxType = ReturnType<typeof mapStateToProps> & IPermissionsTabsProps;

const PermissionTabs = (props: ReduxType) => {

    React.useEffect(() => {
        props.getAllPermissions();
        props.getAllUsers();
    }, []);

    const { t } = useTranslation();
    const [activeTab, setActiveTab] = React.useState<'rolesTab' | 'usersTab'>('rolesTab');
    return (
        <div className={"permission-tabs-container"}>
            <div className="permission-tabs_tabs">
                <span className={activeTab === 'rolesTab' ? "active" : ""} onClick={() => { setActiveTab('rolesTab') }}>{t('permission.tabRole')}</span>
                <span className={activeTab === 'usersTab' ? "active" : ""} onClick={() => { setActiveTab('usersTab') }}>{t('permission.tabUsers')}</span>
            </div>
            {activeTab === 'rolesTab' && <PermissionsRoleList />}
            {activeTab === 'usersTab' && <PermissionUserList />}
        </div>
    );
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        allPermissions: allPermissionsSelector(state)
    };
};

const mapDispatchToProps = (dispatch: any): IPermissionsTabsProps => {
    return {
        getAllPermissions: () => dispatch(getAllPermissions()),
        getAllUsers: () => dispatch(getAllUsers())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionTabs);