const HOST_API_ROUTE = `${process.env.REACT_APP_CHAT_API_ROUTE}`;

const CHAT_API_ROUTE = `${HOST_API_ROUTE}/chat`;
const OPERATOR_API_ROUTE = `${HOST_API_ROUTE}/operator`;
const SETTING_API_ROUTE = `${HOST_API_ROUTE}/setting`;
const REPORT_API_ROUTE = `${HOST_API_ROUTE}/report`;

export const GET_CHAT_STEPS_API_ROUTE = `${CHAT_API_ROUTE}/get-chat-steps?selectedBotId=`;
export const GET_BOTS_API_ROUTE = `${OPERATOR_API_ROUTE}/get-all-bots`;
export const GET_SETTING_BY_ID_API_ROUTE = `${SETTING_API_ROUTE}/get-setting-by-id?settingId=`;

export const GET_REPORT_BY_NAME_API_ROUTE = `${REPORT_API_ROUTE}/get-report-by-name`;
export const SAVE_REPORT_BY_NAME_API_ROUTE = `${REPORT_API_ROUTE}/save-report-by-name`;
export const SAVE_REPORT_BY_NAME_EXCEL_API_ROUTE = `${REPORT_API_ROUTE}/save-xlsx-report-by-name`;