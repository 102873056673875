import { IApplicationState } from 'src/~store/models/ApplicationState';
import { createSelector } from 'reselect';
import { GetChatsMode } from './models/enums/GetChatsMode';
import { IChat } from './models/Chat';

const chatSelector = (state: IApplicationState) => state.chatsState;
const chatItemsSelector = (state: IApplicationState) => state.chatItemsState;
const channelsStateSelector = (state: IApplicationState) => state.channelState;

// chats list
const chatsListSelector = createSelector(
    chatSelector,
    chatsState => chatsState.chatsList
);

const channelsSelector = createSelector(
    channelsStateSelector,
    channelsState => channelsState.channelList
);

export const channelsListSelector = createSelector(
    channelsSelector,
    channels => channels.channels
);

export const isReceivingChatsSelector = createSelector(
    chatsListSelector,
    chatsList => chatsList.isReceivingChats
);

export const isAllChatsLoadedSelector = createSelector(
    chatsListSelector,
    chatsList => chatsList.isAllChatsLoaded
)

export const chatsSelector = createSelector(
    chatsListSelector,
    chatsList => (chatsList.getChatsMode === GetChatsMode.archive || chatsList.getChatsMode === GetChatsMode.all) ? chatsList.chats : filterDublicateChatsDuplicate(chatsList.chats.sort((a, b) => b.lastMessage?.messageId - a.lastMessage?.messageId))
    
);

const filterDublicateChatsDuplicate = (chats: IChat[]): IChat[] => {
    const chatsWithoutDuplicates = [];

    for(let i = 0; i < chats.length; ++i) {
        if(!chats[i].lastMessage || chats[i].lastMessage.messageId === Math.max(...chats.filter(ch => ch.chatId == chats[i].chatId).map(ch => ch.lastMessage?.messageId ?? 0))) {
            chatsWithoutDuplicates.push(chats[i])
        }
    }
    return chatsWithoutDuplicates;
}

export const SLColorSecondsSettigsSelector = createSelector(
  chatsListSelector,
  chatsList => chatsList.SLColorSecondsSettings  
);

export const isChatCreatingSelector = createSelector(
    chatsListSelector,
    chatsList => chatsList.isChatCreating
);

export const countOfChatsSelector = createSelector(
    chatsListSelector,
    chatsList => chatsList.countOfChats
);

export const searchStringForChatsListSelector = createSelector(
    chatsListSelector,
    chatsList => chatsList.searchString,
);

export const tagsSelector = createSelector(
    chatsListSelector,
    chatsList => chatsList.tags,
);

// contact chats info
const contactChatsInfoSelector = createSelector(
    chatSelector,
    chatsState => chatsState.contactChatsInfo
);

export const beforeCountSelector = createSelector(
    contactChatsInfoSelector,
    contactChatsInfo => contactChatsInfo.beforeChatsCount
);

export const afterCountSelector = createSelector(
    contactChatsInfoSelector,
    contactChatsInfo => contactChatsInfo.afterChatsCount,
);

export const contactChatsSelector = createSelector(
    contactChatsInfoSelector,
    contactChatsInfo => contactChatsInfo.contactChats,
);

export const isReceivingInfoSelector = createSelector(
    contactChatsInfoSelector,
    contactChatsInfo => contactChatsInfo.isReceivingInfo,
);

export const areChatsBeforeSelector = createSelector(
    contactChatsInfoSelector,
    contactChatsInfo => contactChatsInfo.areChatsBefore
);

export const searchStringForChatsInfoSelector = createSelector(
    contactChatsInfoSelector,
    contactChatsInfo => contactChatsInfo.searchString,
);

export const authOperatorIdSelector = createSelector(
    chatItemsSelector,
    chatItems => chatItems.operatorId,
);

export const firstElementIdSelector = createSelector(
    contactChatsInfoSelector,
    contactChatsInfo => contactChatsInfo.firstElementId,
);

export const selectedMessageSelector = createSelector(
    contactChatsInfoSelector,
    contactChatsInfo => contactChatsInfo.selectedMessage,
);

export const isMessageEditingSelector = createSelector(
    contactChatsInfoSelector,
    contactChatsInfo => contactChatsInfo.isMessageEditing,
)

export const operatorIdSelector = createSelector(
    chatItemsSelector,
    chatItems => chatItems.operatorId,
);

export const isModeChangingSelector = createSelector(
    chatsListSelector,
    chatList => chatList.isModeChanging,
);

export const chatTagNamesSelector = createSelector(
    chatSelector,
    chatsState => chatsState.chatsList.chatTags
);

export const chatTagValuesSelector = createSelector(
    chatSelector,
    chatsState => chatsState.chatsList.chatTagValues
);

export const autoUpdateChatsSelector = createSelector(
    chatSelector,
    chatsState => chatsState.chatsList.autoUpdateChats
);