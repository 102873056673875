import React from 'react';
import { NavLink as ReactNavLink } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import UserContext from './../shared/components/user-context/UserContext';
import {PermissionType} from './../shared/components/user-context/models/PermissionType';

import './NavMenu.css';
import { useTranslation } from 'react-i18next';

const NavMenu = () => {
    const { t } = useTranslation();
    return (
        <ul className="navmenu-container">
            <div className="logo">
                <span className="logo__title"></span>
                <hr/>
            </div>
            <NavItem>
                <UserContext permission={PermissionType.Contacts_Manage}>
                    <NavLink tag={ReactNavLink} to={"/contacts"} className="navmenu__link">
                        <img
                            className="navmenu__link-img"
                            src={require("../shared/images/contacts.svg")} />
                        {t('common.contacts')}
                    </NavLink>
                </UserContext>
                <UserContext permission={[PermissionType.Chats_ManageOwnChats, PermissionType.Chats_ManageOthersChats]}>
                <NavLink tag={ReactNavLink} to={"/chats"} className="navmenu__link">
                    <img
                        className="navmenu__link-img"
                        src={require("../shared/images/chats.svg")} />
                        {t('common.chats')}
                </NavLink>
                </UserContext>
                <UserContext permission={PermissionType.Mailing_Manage}>
                    <NavLink tag={ReactNavLink} to={"/mailing"} className="navmenu__link">
                        <img
                            className="navmenu__link-img"
                            src={require("../shared/images/mailing.svg")} />
                        {t('common.mailing')}
                    </NavLink>
                </UserContext>
                <UserContext permission={[PermissionType.NLP_Manage, PermissionType.NLP_Search]}>
                    <NavLink tag={ReactNavLink} to={"/nlp"} className="navmenu__link">
                        <img
                            className="navmenu__link-img"
                            src={require("../shared/images/nlp.svg")} />
                        NLP
                    </NavLink>
                </UserContext>
                <UserContext permission={PermissionType.Operators_Manage}>
                    <NavLink tag={ReactNavLink} to={"/operators"} className="navmenu__link">
                        <img
                            className="navmenu__link-img"
                            src={require("../shared/images/operators.svg")} />
                        {t('common.operators')}
                    </NavLink>
                </UserContext>
                <UserContext permission={PermissionType.Channels_Manage}>
                    <NavLink tag={ReactNavLink} to={"/channels"} className="navmenu__link">
                        <img
                            className="navmenu__link-img"
                            src={require("../shared/images/channels.svg")} />
                        {t('common.channels')}
                    </NavLink>
                </UserContext>
                <UserContext permission={PermissionType.Integrations_Manage}>
                    <NavLink tag={ReactNavLink} to={"/settings"} className="navmenu__link">
                        <img
                            className="navmenu__link-img"
                            src={require("../shared/images/settings.svg")}/>
                        {t('common.settings')}
                    </NavLink>
                </UserContext>
                <UserContext isAdminPermission>
                    <NavLink tag={ReactNavLink} to={"/permission"} className="navmenu__link">
                        <img
                            className="navmenu__link-img rounded"
                            src={require("../shared/images/permission.svg")}/>
                        {t('common.permissions')}
                    </NavLink>
                </UserContext>
                <UserContext permission={PermissionType.CanManageAnalytics}>
                    <NavLink tag={ReactNavLink} to={"/analytics"} className="navmenu__link">
                        <img
                            className="navmenu__link-img"
                            src={require("../shared/images/analytics.svg")}/>
                        {t('common.analytics')}
                    </NavLink>
                </UserContext>
                <UserContext permission={PermissionType.License_Manage}>
                    <NavLink tag={ReactNavLink} to={"/license"} className="navmenu__link">
                        <img
                            className="navmenu__link-img"
                            src={require("../shared/images/license.svg")}/>
                        {t('license.license')}
                    </NavLink>
                </UserContext>
            </NavItem>
        </ul>
    )
}

export default NavMenu;