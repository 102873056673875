import { ISetting, SettingType } from "../models/setting";
import { ISettingState } from "./SettingsState";

export const initNewSetting = (type: SettingType = SettingType.User): ISetting => {
    return {
        id: "",
        settingTypeModel: type,
        value: "{}",
        isCreating: true,
        key: Math.random().toString()
    }
}

export const initState: ISettingState = {
    settingList: {
        settings: [],
        searchString: "",
        searchType: undefined
    }
}