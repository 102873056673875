export enum AccountType {
    Billing = 1,
    ZenDesk = 2,
    Amo = 3,
    TelegramGroup = 6,
    VoiceChannel = 7
}

export const accountTypeToString = (type: AccountType): string => {
    switch(type) {
        case AccountType.Amo:
            return "Amo";
        case AccountType.Billing:
            return "Billing";
        case AccountType.ZenDesk:
            return "ZenDesk";
        case AccountType.TelegramGroup:
            return "TelegramGroup";
        case AccountType.VoiceChannel:
            return "VoiceChannel";
    }
}