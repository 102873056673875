import { ISearchingTag, TagName, TagType } from "src/app/shared/components/tag-searching-input/~types/models/SearchingTag";
import i18n from "src/app/shared/localization/i18n";

export const REQUESTED_NUMBER_OF_CHATS: number = 15;
export const TRIGGER_SCROLL_HEIGHT: number = 100;
export const SEARCHING_TAGS: ISearchingTag[] = [
    { 
        id: 1,
        tagName: TagName.Date,
        value: i18n.t('common.date'),
        suggestions: ["3", "4", "5"],
        tagType: TagType.OpeningTag,
    },
    { 
        id: 2,
        tagName: TagName.Channel,
        value: i18n.t('common.channelType'),
        suggestions: ["3", "4", "6"],
        tagType: TagType.OpeningTag,
    },
    {
        id: 7,
        tagName: TagName.Operator,
        value: i18n.t('channels.operator'),
        suggestions: ["3", "4", "8"],
        tagType: TagType.OpeningTag,
    },
    {
        id: 9,
        tagName: TagName.Contact,
        value: i18n.t("common.contact"),
        suggestions: ["3", "4", "10"],
        tagType: TagType.OpeningTag,
    },
    { 
        id: 3,
        value: "=",
        tagType: TagType.OperationTag,
    },
    { 
        id: 4,
        value: "!=",
        tagType: TagType.OperationTag,
    },
    {
        id: 5,
        tagName: TagName.Date,
        value: "",
        suggestions: [
            i18n.t('common.today'),
            i18n.t('common.yesterday'),
            i18n.t('common.currentWeek'),
            i18n.t('common.currentMonth'),
            i18n.t('common.currentYear'),
            i18n.t('common.period')
        ],
        tagType: TagType.ClosingTag,
    },
    {
        id: 6,
        tagName: TagName.Channel,
        value: "",
        suggestions: [
            i18n.t('channels.instagram'),
            i18n.t('channels.facebook'),
            i18n.t('channels.telegram'),
            i18n.t('channels.viber'),
            i18n.t('channels.vk'),
            i18n.t('channels.weChat'),
            i18n.t('channels.widget'),
            i18n.t('channels.uniformWidget'),
            i18n.t('channels.whatsApp'),
            i18n.t('channels.ednaWhatsApp'),
            i18n.t('channels.voiceChannel'),
        ],
        tagType: TagType.ClosingTag,
    },
    {
        id: 8,
        tagName: TagName.Operator,
        value: "",
        suggestions: [],
        tagType: TagType.ClosingTag,
    },
    {
        id: 10,
        tagName: TagName.Contact,
        value: "",
        suggestions: [],
        tagType: TagType.ClosingTag,
    },
    {
        id: 11,
        tagName: TagName.Status,
        value: i18n.t('common.status'),
        suggestions: ["3", "4", "12"],
        tagType: TagType.OpeningTag
    },
    {
        id: 12,
        tagName: TagName.Status,
        value: "",
        suggestions: [
            i18n.t('chats.missed'),
            i18n.t('chats.expired'),
            i18n.t('chats.finished'),
        ],
        tagType: TagType.ClosingTag
    },
    {
        id: 13,
        tagName: TagName.ChatNumber,
        value: i18n.t('chats.chatNumber'),
        suggestions: ["3", "18"],
        tagType: TagType.OpeningTag,
    },
    {
        id: 16,
        tagName: TagName.ChannelName,
        value: i18n.t('common.channelName'),
        suggestions: ["3", "17"],
        tagType: TagType.OpeningTag
    },
    {
        id: 17,
        tagName: TagName.ChannelName,
        value: "",
        suggestions: [],
        tagType: TagType.ClosingTag
    },
    {
        id: 14,
        tagName: TagName.ChatTag,
        value: i18n.t('common.chatTag'),
        suggestions: ["3", "4", "15"],
        tagType: TagType.OpeningTag
    },
    {
        id: 15,
        tagName: TagName.ChatTag,
        value: "",
        suggestions: [],
        tagType: TagType.ClosingTag
    },
    {
        id: 18,
        tagName: TagName.ChatNumber,
        value: "",
        suggestions: [],
        tagType: TagType.ClosingTag
    }
]