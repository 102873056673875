import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./~store/configureStore";
import history from "./~store/history";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import "./fonts/Gilroy/stylesheet.css";

const store = configureStore(history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root") as HTMLElement
);

serviceWorker.unregister();
