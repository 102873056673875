import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ADMIN_DEFAULT_ROUTE } from 'src/app/authentication/~store/actions/constants';
import { DEFAULT_ROUTE } from 'src/app/authentication/~store/actions/constants';
import IUser from 'src/app/authentication/~store/models/User';
import {UserRole} from 'src/app/authentication/~store/models/UserRole';
import { PermissionType } from '../user-context/models/PermissionType';

export const GuardedRoute = ({component: Component, roles, permissions, isAdminPermission, ...rest}: any) => (
    <Route {...rest} render={props => {
        try {
            const user: IUser = JSON.parse(sessionStorage.getItem('user') ?? "");

            const adminPass = isAdminPermission != undefined && user.role === UserRole.Admin;

            permissions = permissions || [];
            
            if(user.isAuth &&
               (roles === undefined || roles.includes(user.role))) {
                if(user.permissions.filter(up => permissions.some((p: PermissionType) => up === p)).length > 0 || adminPass) {
                    return <Component {...rest}/>;
                }
                else if(user.role === UserRole.Admin){
                    return <Redirect to={{pathname: ADMIN_DEFAULT_ROUTE}} />
                }
                else {
                    return <Redirect to={{pathname: DEFAULT_ROUTE}} />
                }
            } else {
                sessionStorage.removeItem('user');
                return <Redirect to={{pathname: '/login', state: {from: props.location, redirectedFrom: props.location.pathname}}} />;
            }
        } catch(ex) {
            return <Redirect to={{pathname: '/login', state: {from: props.location, redirectedFrom: props.location.pathname}}}/>;
        }
    }}
    />
)