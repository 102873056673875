import * as React from "react";
import "./ProfileDropDown.css";
import { IChannelItem } from "../~store/models/ChannelItem";
import {
  IOperatorStatusViewModel,
  OperatorStatusType,
} from "../~store/state/ChatItemsState";
import { getDateTimeFromNumber } from "src/app/shared/helpers/date-helper/DateFormats";
import { FIRST_PAUSE_CATEGORY_ID } from "../~store/actions/ChatConstants";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

interface IProfileDropdownProps {
  onLogout: CallableFunction;
  operatorChannels: IChannelItem[];
  operatorsStatusList: IOperatorStatusViewModel[];
  pauseCategories: string[];
}

const ProfileDropDown = (props: IProfileDropdownProps) => {
  const { t } = useTranslation();

  const _mapOperatorStatusList = (
    operatorsStatusList: IOperatorStatusViewModel[]
  ) => {
    if (operatorsStatusList.length > 0)
      return (
        <table className="operator_list_container">
          <thead style={{ borderBottom: "1px solid" }}>
            <td>{t("common.name")}</td>
            <td>{t("common.status")}</td>
            <td>{t("common.setOn")}</td>
          </thead>
          {operatorsStatusList.map((item) => {
            return (
              <tbody>
                <td>{item.name}</td>
                <td>
                  {item.status === OperatorStatusType.Online ||
                  item.status === OperatorStatusType.Pause ||
                  item.status === OperatorStatusType.Offline
                    ? OperatorStatusType[item.status]
                    : props.pauseCategories[
                        item.status - FIRST_PAUSE_CATEGORY_ID
                      ]}
                </td>
                <td>{getDateTimeFromNumber(item.setOn)}</td>
              </tbody>
            );
          })}
        </table>
      );
  };

  const userInStorage = sessionStorage.getItem("user");
  const userRole =
    userInStorage === null ? "" : JSON.parse(userInStorage.toString()).role;
  const login =
    userInStorage === null ? "" : JSON.parse(userInStorage.toString()).login;
  const isSupervisor = userRole === "Supervisor" ? true : false;
  return (
    <div className="profile-container">
      <div className="profile-container__profile-block">
        <div>
          {t("chatsPanel.youAreLoggedInAs")}
          <span style={{ fontWeight: "bold" }}>{login}</span>
        </div>
        <div>
          {t("chatsPanel.toChangeTheUserPress")}
          <Button onClick={() => props.onLogout()}>{t("common.logout")}</Button>
        </div>
      </div>
      <div className="profile-container__profile-block">
        <div>{t("chatsPanel.haveBeenAssignedChannels")}</div>
        {props.operatorChannels.map((item, index) => (
          <div key={index}>
            <span className="profile-container__profile-channel-type-container">
              <img
                className={
                  "channel-type-img profile-container__profile-channel-type-" +
                  item.type
                }
              ></img>
            </span>
            <span className="profile-container__profile-channel-name">
              {item.name}
            </span>
            <span className="profile-container__profile-channel-priority">
              {isSupervisor
                ? ""
                : "Tier " + item.operatorPriorityType.toString()}
            </span>
          </div>
        ))}
      </div>
      {_mapOperatorStatusList(
        props.operatorsStatusList.filter(
          (o) => o.status != OperatorStatusType.Offline
        )
      )}
    </div>
  );
};

export default ProfileDropDown;
