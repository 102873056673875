import { Action, Reducer } from 'redux';
import IAuthenticationState from './state/AuthenticationState';
import { initState } from './state/InitAuthenticationState';
import { LoginAction, LoginSuccessAction } from './actions/interfaces';
import { LOGIN_START, LOGIN_SUCCESS, LOGIN_ERROR } from './actions/types';

export type KnownAction = 
    | LoginAction;

export const authenticationReducer: Reducer<IAuthenticationState> = (
    state: IAuthenticationState = initState,
    incomingAction: Action,
): IAuthenticationState => {
    const action = incomingAction as KnownAction;

    switch(action.type) {
        case LOGIN_START: {
            const isAuthInProgress = true;
            return {
                ...state,
                isAuthInProgress
            }
        }

        case LOGIN_SUCCESS: {
            const isAuthInProgress = false;
            const isAuth = true;
            const {authOperatorId} = (action as LoginSuccessAction).payload;
            return {
                ...state,
                authOperatorId,
                isAuthInProgress,
                isAuth,
            }
        }

        case LOGIN_ERROR: {
            const isAuthInProgress = false;
            return {
                ...state,
                isAuthInProgress,
            }
        }

        default:
            return state;
    }
}