import { IChatItemsState, OperatorStatusType } from "./ChatItemsState";
import { createSignalRConnection } from "src/~signalR/SignalRHub"
import { IMessageItem } from "../models/MessageItem";
import { IChatItem } from "../models/ChatItem";
import { IMessagePreview } from "../models/MessagePreview";
import { TopicPairContentType } from "src/app/nlp/~store/models/enums/TopicPairContentType";

const CHAT_ROUTE = `${process.env.REACT_APP_CHAT_ROUTE}`;

const tryGetSilentChatsFromSessionStorage = function() {
    try{
        return JSON.parse(sessionStorage.getItem("silentChats") || "[]")
    }
    catch{
        return []
    }
}

export const initState: IChatItemsState = {
    chatItems: [],
    silentChatsId: tryGetSilentChatsFromSessionStorage(),
    chatMessagePreview: [],
    selectedChat: undefined,
    isChatVisible: false,
    isReceivingChats: false,
    canSendMessage: false,
    operatorStatus: OperatorStatusType.Offline,
    pauseCategories: [],
    showHintsFromAllOperators: false,
    closeChatCategories: [],
    closeChatSubjects: [],
    isGettingCloseChatSubjects: false,
    reconnectingStatus: false,
    hubConnection: createSignalRConnection(CHAT_ROUTE),
    selectedChatId: 0,
    messages: [],
    sendingMessage: null,
    operatorCurrentChatText: "",
    operatorCurrentMessageContentType: TopicPairContentType.Text,
    operatorName: "",
    operatorAvatarId: null,
    operatorId: 0,
    contactAvatarId: null,
    contactName: "",
    isContactOnline: undefined,
    channelSettings: undefined,
    contactTags: [],
    updatingContactTags: false,
    operatorChannels: [],
    isReceivingOnlineOperators: false,
    onlineOperators: [],
    botsTransferTo: [],
    isReceivingOnlineOperatorsToInvite: false,
    onlineOperatorsToInvite: [],
    isReceivingAvailableTiers: false,
    availableTiers: [],
    availableContactTags: [],
    isMessageEditing: false,
    messageToEdit: {} as IMessageItem,
    isChatClosing: false,
    chatHistory: {
        canGetMoreAfter: true,
        canGetMoreBefore: true,
        gotAfter: 0,
        gotBefore: 0,
        isReceivingHistory: false
    },
    quickAnswersContainer: {
        quickAnswers: [],
        isReceivingQuickAnswers: false
    },
    chatsDrafts: new Map<number, string>(),
    sendingFiles: [],
    operatorsStatusList: [],
    operatorDisconnectionTimeout: 0,
    isReceivingChat: false,
}