import React, { useState } from 'react';
import { ILoginFormProps } from './~types/LoginFormProps';
import { Card, CardBody, Label, Input, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import './LoginForm.css';

const LoginForm = (props: ILoginFormProps) => {
    const { t } = useTranslation();
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [checked, setChecked] = useState(false);

    const passwordCheckHandler = () => {
        setChecked(!checked);
    }

    const _onLogin = () => {
            props.onLoginUser?.(login, password)
    }
    return (
        <Card className="login-form">
                <CardBody className="login-form__body">
                    <div className="login-form__item">
                        <Label className="login-form__field-description">{t('common.login')}</Label>
                        <Input
                            className="login-form__field-input"
                            type="email"
                            placeholder={t('common.login').toLowerCase()}
                            value={login}
                            onChange={event => setLogin(event.currentTarget.value)}
                        />
                    </div>
                    <div className="login-form__item">
                        <Label className="login-form__field-description">{t('common.password')}</Label>
                        <Input 
                            className="login-form__field-input" 
                            type={checked ? "text" : "password"}
                            placeholder={t('common.password').toLowerCase()}
                            value={password} 
                            onChange={event => setPassword(event.currentTarget.value)} 
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    props.onLoginUser?.(login, password)
                                }
                              }}/>
                        <span className={"password-checkbox" + (checked ? " password-checkbox-checked" : "")} onClick={passwordCheckHandler}><img/></span>
                    </div>
                    <div className="login-form__item login-form__item--center">
                        <Button 
                            onClick={() => _onLogin()}>
                            {t('common.login')}
                        </Button>
                    </div>
                </CardBody>
            </Card>
    )
}

export default LoginForm;