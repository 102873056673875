import React, { useState, useEffect, useRef } from 'react';
import Login from './authentication/login/Login';
import NavMenu from './nav-menu/NavMenu';
import ChatsPanel from './chats-panel/ChatsPanel';
import { Route, Switch, useLocation } from 'react-router-dom';
import { GuardedRoute } from './shared/components/guarded-route/GuardedRoute';
import { BASE_URL } from './AppConstants';
import ContactMain from './contact/contact-main/ContactMain';
import ContactItemInfo from './contact/contact-main/contact-list/contact-item/contact-item-info/ContactItemInfo';
import OperatorsMain from './operator/operators-main/OperatorsMain';
import ChannelMain from './channel/channel-main/ChannelMain';
import ChatsMain from './chats/chats-main/ChatsMain';
import SettingsMain from './setting/settings-main/SettingsMain';
import MailingMain from './mailing/mailing-main/MailingMain';
import MailingItemInfo from './mailing/mailing-main/mailing-list/mailing-item-info/MailingItemInfo'
import IUser from './authentication/~store/models/User';
import LicenseActivation from './license/license-activation/LicenseActivation';
import LicenseManage from './license/license-manage/LicenseMange';
import AnalyticsMain from './analytics/analytics-main/AnalyticsMain'
import LicenseExpiresDialog from './chat/license-expires-dialog/LicenseExpiresDialog';

import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import './App.css';
import TopicEditor from './nlp/topic-editor/TopicEditor';
import KnowledgeBaseMain from './nlp/knowledge-base-main/KnowledgeBaseMain';
import { PermissionType } from './shared/components/user-context/models/PermissionType';
import Chat from './chat/Chat';
import TopicItemSearch from './nlp/topic-list/topic-item-search/TopicItemSearch';
import './shared/localization/i18n';
import PermissionMain from './permission/permission-main/PermissionMain';
const App = () => {
    const [isChatVisible, setChatVisibility] = useState(false);
    const [isLicenseDaysLeftVisible, setIsLicenseDaysLeftVisible] = useState(true);
    const [isProfileDropdownVisible, toggleProfileDropDownVisibility] = useState(false);
    const [isChatWideMode, setIsChatWideMode] = useState(false);
    const [isSidebarsVisible, setIsSidebarsVisible] = useState(checkIsAuth());

    const _setChatVisibility = (isChatVisible: boolean) => {
        setChatVisibility(isChatVisible);
    }

    const _getSwitch = () => {
        return (
            <>
            {isLicenseDaysLeftVisible && <LicenseExpiresDialog setIsLicenseDaysLeftVisible={setIsLicenseDaysLeftVisible}></LicenseExpiresDialog>}
            <Switch>
                        <Route path="/login" component={Login} />
                        <Route path="/login/:message" component={Login} />
                        <Route path="/activate-access" component={LicenseActivation} />
                        <GuardedRoute path={`/contacts/edit-contact/:contactId`} component={ContactItemInfo} permissions={[PermissionType.Chats_ManageOwnChats, PermissionType.Chats_ManageOthersChats]} />
                        <GuardedRoute path={`/nlp/edit-topic/:topicExternalId`} component={TopicEditor} permissions={[PermissionType.NLP_Manage]} />
                        <GuardedRoute path={`/mailing/edit-mailing/:mailingId`} component={MailingItemInfo} permissions={[PermissionType.Mailing_Manage]} />
                        <GuardedRoute path={`/mailing`} component={MailingMain} permissions={[PermissionType.Mailing_Manage]} />
                        <GuardedRoute path={`/contacts`} component={ContactMain} permissions={[PermissionType.Contacts_Manage]} />
                        <GuardedRoute path={`/operators`} component={OperatorsMain} permissions={[PermissionType.Operators_Manage]} />
                        <GuardedRoute path={`/channels`} component={ChannelMain} permissions={[PermissionType.Channels_Manage]} />
                        <GuardedRoute path={`/nlp/search/:topicName`} component={TopicItemSearch} permissions={[PermissionType.NLP_Search]} />
                        <GuardedRoute path={`/nlp`} component={KnowledgeBaseMain} permissions={[PermissionType.NLP_Manage, PermissionType.NLP_Search]} />
                        <GuardedRoute path={`/chats/:filter`} component={ChatsMain} setChatVisibility={_setChatVisibility} permissions={[PermissionType.Chats_ManageOwnChats, PermissionType.Chats_ManageOthersChats]} />
                        <GuardedRoute path={`/settings`} component={SettingsMain} permissions={[PermissionType.Integrations_Manage]} />
                        <GuardedRoute path={`/permission`} component={PermissionMain} isAdminPermission={true} />
                        <GuardedRoute path={`/analytics`} component={AnalyticsMain} permissions={[PermissionType.CanManageAnalytics]} />
                        <GuardedRoute path={`/license`} component={LicenseManage} permissions={[PermissionType.License_Manage]} />
                        <GuardedRoute path={`/`} component={ChatsMain} setChatVisibility={_setChatVisibility} permissions={[PermissionType.Chats_ManageOwnChats, PermissionType.Chats_ManageOthersChats]} />
            </Switch>
            </>
        )
    }

    const location = useLocation();
    useEffect(() => {
        (location.pathname.startsWith("/chats")) ? setIsChatWideMode(true) : setIsChatWideMode(false);
        setIsSidebarsVisible(checkIsAuth());
    }, [location.pathname]);

    if(Notification.permission === "default") Notification.requestPermission();

    return (
        <div className="app-container">            
            <div className="app__navmenu">
                {isSidebarsVisible && <NavMenu />}
            </div>
            <div className="app__router">
                <SplitterLayout                  
                    customClassName={`app__router--split-pane ${isChatVisible ? 'pane-chat-visible' : ''}`}
                    primaryIndex={1}
                    percentage={true}
                    primaryMinSize={30}
                    secondaryMinSize={30}>
                    {_getSwitch()}
                    {isChatVisible && <Chat 
                        isWideMode={isChatWideMode}
                        setChatVisibility={setChatVisibility}/>}
                </SplitterLayout>
            </div>
            <div className="app__chats-panel">
                {isSidebarsVisible && <ChatsPanel
                    setChatVisibility={_setChatVisibility}
                    isChatVisible={isChatVisible}
                    toggleProfileDropDownVisibility={toggleProfileDropDownVisibility}
                    isProfileDropdownVisible={isProfileDropdownVisible}
                    isWideMode={isChatWideMode}/>}
            </div>
        </div>
    );
}

const checkIsAuth = (): boolean => {
    try {
        const user: IUser = JSON.parse(sessionStorage.getItem('user') ?? "");
        if (user.isAuth) {
            return true;
        } else {
            return false;
        }
    } catch (ex) {
        return false;
    }
}

export default App;
