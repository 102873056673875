import * as React from 'react';

import './PermissionMain.css';
import { useTranslation } from 'react-i18next';
import PermissionTabs from './permission-tabs/PermissionTabs';

const PermissionMain = () => {
    const { t } = useTranslation();
    return (
        <div className={"operators"}>
            <div className={"operators__header"}>{t('common.permissions')}</div>
            <PermissionTabs />
        </div>
    );
}

export default PermissionMain;