import * as React from "react";

import {ILoaderProps} from "./~types/LoaderProps";

import {Spinner} from "reactstrap";
import {LoaderType} from "./~types/LoaderType";

import "./Loader.css";

export default class Loader extends React.Component<ILoaderProps> {
    public render() {
        return this._getSpinnerControl();
    }

    private _getSpinnerControl = (): React.ReactElement<{}> => {
        switch (this.props.type) {
            case LoaderType.Grow: {
                return (
                    <section className={`spinner-container ${this.props.customStyle}`}>
                        <Spinner type="grow" />
                    </section>
                );
            }
            case LoaderType.Cicle:
            default: {
                return (
                    <section className={`spinner-container ${this.props.customStyle}`}>
                        <Spinner />
                    </section>
                );
            }
        }
    };
}
