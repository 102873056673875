import { Action, Reducer } from "redux";
import { initUserContext, IUserContextState } from "./state/UserContextState";
import { GetPermissionActionSuccess, GET_PERMISSIONS_START, GET_PERMISSIONS_SUCCESS } from "./actions/interfaces";
import { GET_ALL_CHANNELS_ERROR } from "src/app/channel/~store/actions/types";

export const userContextReducer: Reducer<IUserContextState> = (
    state: IUserContextState = initUserContext,
    action: Action
): IUserContextState => {


    switch(action.type){
        case GET_PERMISSIONS_START:
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    isUpdatingPermissions: true
                }
            }
            
        case GET_PERMISSIONS_SUCCESS:
        const {permissions} = (action as GetPermissionActionSuccess).payload;
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    isUpdatingPermissions: false,
                    isUpdated: true,
                    permissionList: permissions
                }
            }

        case GET_ALL_CHANNELS_ERROR:
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    isUpdatingPermissions: false
                }
            }
    }

    return state;
    
}
