import { getApiActionResult, fetchGet, fetchPost } from "src/~api/ApiHelper";
import { IApiActionResult } from "src/~api/ApiActionResult";
import { IChannel } from "src/app/channel/~store/models/Channel";
import { GET_ENABLED_CHANNELS_API_ROUTE, SAVE_MAILING_API_ROUTE, GET_MAILING_BY_ID_API_ROUTE, GET_MAILINGS_LAZY_API_ROUTE, START_MAILING_API_ROUTE, DELETE_MAILING_API_ROUTE, GET_MAILING_RECIPIENT_API_ROUTE } from "./apiRoutes";
import { IMailing } from "../~store/models/mailing";
import { IMailingRecipient } from "../~store/models/mailing-recipient";
import { MailingStatus } from "../~store/models/enums/MailingStatus";

export const getEnabledChannelsAsync = async (): Promise<IApiActionResult<IChannel[]>> => {
    const fetchPromise = fetchGet(GET_ENABLED_CHANNELS_API_ROUTE);
    return await getApiActionResult(fetchPromise, true);
};

export const saveMailingAsync = async (mailing: IMailing): Promise<IApiActionResult<void>> => {
    mailing.status = MailingStatus.pending;
    const fetchPromise = fetchPost(SAVE_MAILING_API_ROUTE, {
        body: JSON.stringify({
            mailingModel: mailing
        })
    });
    return await getApiActionResult(fetchPromise, false);
};

export const getRecipientsAsync = async (mailingId: number, skip: number, count: number): Promise<IApiActionResult<IMailingRecipient[]>> => {
    const fetchPromise = fetchGet(GET_MAILING_RECIPIENT_API_ROUTE + `?mailingId=${mailingId}&skip=${skip}&count=${count}`);
    return await getApiActionResult(fetchPromise, true);
};

export const getMailingByIdAsync = async (mailingId: number): Promise<IApiActionResult<IMailing>> => {
    const fetchPromise = fetchGet(GET_MAILING_BY_ID_API_ROUTE + mailingId);
    return await getApiActionResult(fetchPromise, true);
};

export const getMailingsAsync = async (skip: number, count: number): Promise<IApiActionResult<IMailing[]>> => {
    const fetchPromise = fetchGet(GET_MAILINGS_LAZY_API_ROUTE + `?skip=${skip}&count=${count}`);
    return await getApiActionResult(fetchPromise, true);
};

export const startMailingAsync = async (mailingId: number): Promise<IApiActionResult<IMailing>> => {
    const fetchPromise = fetchGet(START_MAILING_API_ROUTE + mailingId);
    return await getApiActionResult(fetchPromise, true);
};

export const deleteMailingAsync = async (mailingId: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchGet(DELETE_MAILING_API_ROUTE + mailingId);
    return await getApiActionResult(fetchPromise, false);
};