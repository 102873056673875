import React, { useEffect, useRef } from "react";

import "./Reports.css";
import { IApplicationState } from "src/~store/models/ApplicationState";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { IReportsExternalProps, IReportsProps } from "./ReportsProps";
import {
  changeReportsSubtab,
  getReportByName,
  getReportsSettingById,
  saveReportByName,
} from "src/app/analytics/~store/actions/actions";
import {
    isReportLoad,
  reportsAllKeysSelector,
  reportSelector,
  reportsSelectedSubtabSelector,
  selectedReportsCurrentSettingsSelector,
} from "src/app/analytics/~store/selector";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import Loader from "src/app/shared/components/loader/Loader";
import { LoaderType } from "src/app/shared/components/loader/~types/LoaderType";
import { ReportType, reportTypeToString } from "../../../~store/models/enums/ReportType";

type ReduxType = ReturnType<typeof mapStateToProps> & IReportsProps;

const Reports = (props: ReduxType) => {
  const { t } = useTranslation();
    const tableRef = useRef<HTMLDivElement>(null);
    const [getReportType, setReportType] = React.useState(ReportType.csv);
    const [isDropdownVisible, setDropdownVisible] = React.useState(false);

  useEffect(() => {
    if (tableRef.current) {
      const tds = tableRef.current
        .querySelector("tbody")
        ?.querySelectorAll("td");
      tds?.forEach((td) => {
        // Hardcoded value depends on css
        if (td.offsetWidth === 400) {
          td.title = td.innerHTML;
        }
      });
    }
  });

  useEffect(() => {
    props.getReportsSettingById("ReportsList");
  }, []);

  useEffect(() => {
    if (props.selectedSubtab) {
      props.getReportByName(_getSelectedReportName());
    }
  }, [props.selectedSubtab, props.tags]);

  const _getSelectedReportName = (): string => {
    const settings = JSON.parse(props.currentSettings);
    return settings[props.selectedSubtab];
  };

  const _getReportTableHeaderRows = (keys?: string[]) => {
    return (
      <thead>
        <tr>
          {keys?.map((key, index) => (
            <th key={`${key}-${index}`}>
              <div className="header-item">{key}</div>
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const _getReportTableBodyRows = (keys?: string[], data?: any[]) => {
    if (data && data?.length && keys && keys.length) {
      return (
        <tbody>
          {data?.map((item, index) => (
            <tr key={`${index}`}>
              {keys.map((key) => (
                <td key={key}>{item[key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      );
    }
  };

  return (
    <div className="reports-container">
      <div className="analytics-header analytics-subtab-header">
        {props.allKeys.length > 1 && (
          <div className="analytics-tabs-wrapper">
            {props.allKeys.map((element, index) => {
              return (
                <span
                  key={index}
                  className={props.selectedSubtab === element ? "active" : ""}
                  onClick={() => props.changeReportsSubtab(element)}
                >
                  {element}
                </span>
              );
            })}
          </div>
        )}
      </div>

      {props.selectedSubtab && (
              <div className="report-save-button-container">
                  <Dropdown isOpen={isDropdownVisible} toggle={() => setDropdownVisible(!isDropdownVisible)} direction="down">
                      <DropdownToggle caret style={{ width: 150 }} >
                          {props.isReportLoad ? <Loader type={LoaderType.Grow} customStyle={"custom-spinner"} /> : t("analytics.exportReport")}
                      </DropdownToggle>
                      {isDropdownVisible && <DropdownMenu style={{ width: 150, position: "absolute", zIndex: 2 }}>
                          <DropdownItem onClick={() => {
                              props.saveReportByName(_getSelectedReportName(), ReportType.csv);
                          }} style={{ width: 150 }}>{reportTypeToString(ReportType.csv)}</DropdownItem>
                          <DropdownItem onClick={() => {
                              props.saveReportByName(_getSelectedReportName(), ReportType.xlsx);
                          }} style={{ width: 150 }}>{reportTypeToString(ReportType.xlsx)}</DropdownItem>
                      </DropdownMenu>}
                  </Dropdown>
        </div>
      )}

      <div className="report-table" ref={tableRef}>
        <table>
          {_getReportTableHeaderRows(props.report.keys)}
          {_getReportTableBodyRows(props.report?.keys, props.report?.data)}
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (
  state: IApplicationState,
  externalProps: IReportsExternalProps
) => {
  return {
    allKeys: reportsAllKeysSelector(state),
    selectedSubtab: reportsSelectedSubtabSelector(state),
    currentSettings: selectedReportsCurrentSettingsSelector(state),
    report: reportSelector(state),
    tags: externalProps.tags,
    isReportLoad: isReportLoad(state),
  };
};

const mapDispatchToProps = (dispatch: any): IReportsProps => {
  return {
    getReportsSettingById: (settingId: string) =>
      dispatch(getReportsSettingById(settingId)),
    changeReportsSubtab: (subtab: string) =>
      dispatch(changeReportsSubtab(subtab)),
    getReportByName: (name: string) => dispatch(getReportByName(name)),
    saveReportByName: (name: string, reportType: ReportType) => dispatch(saveReportByName(name, reportType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
