import * as React from "react";
import CustomerJourney from "./customer-journey/CustomerJourney";
import Reports from "./reports/Reports";
import { AnalyticTab } from "../../~store/models/enums/AnalyticTab";
import ChartsTab from "../analytics-tabs/charts-tabs/ChartsTab";
import { getAnalyticsSearchingTags } from "./~store/constants";
import { getAllChannels } from "src/app/channel/~store/actions/actions";
import "./AnalyticsTabs.css";
import { useState } from "react";
import TagSearchingInput from "src/app/shared/components/tag-searching-input/TagSearchingInput";
import { ISearchingTag, TagName, TagType } from "src/app/shared/components/tag-searching-input/~types/models/SearchingTag";
import { IApplicationState } from "src/~store/models/ApplicationState";
import { connect } from "react-redux";
import { IAnalyticsTabsProps } from "./AnalyticsTabsProps";
import { changeAnalyticTab, setAnalyticsTags } from "../../~store/actions/actions";
import { selectedTabSelector, channelsListSelector, analyticsTagsSelector } from "../../~store/selector";
import { useTranslation } from 'react-i18next';

type ReduxType = ReturnType<typeof mapStateToProps> & IAnalyticsTabsProps;

const AnalyticsTabs = (props: ReduxType) => {
    const { t } = useTranslation();

    return (
        <div className="analytics-container">
            <div className="analytics-header">
                <div className="analytics-tabs-wrapper">
                    {/* <span 
                        className={props.selectedTab === AnalyticTab.CustomerJorney ? "active" : ""}
                        onClick={() => props.changeAnalyticTab(AnalyticTab.CustomerJorney)}>
                        {t('analytics.customerJourney')}
                    </span> */}
                    <span 
                        className={props.selectedTab === AnalyticTab.Statistic ? "active" : ""}
                        onClick={() => props.changeAnalyticTab(AnalyticTab.Statistic)}>
                        {t('analytics.statistics')}
                    </span>
                    <span 
                        className={props.selectedTab === AnalyticTab.Monitoring ? "active" : ""}
                        onClick={() => props.changeAnalyticTab(AnalyticTab.Monitoring)}>
                        {t('analytics.monitoring')}
                    </span>
                    <span 
                        className={props.selectedTab === AnalyticTab.Reports ? "active" : ""}
                        onClick={() => props.changeAnalyticTab(AnalyticTab.Reports)}>
                        {t('analytics.reports')}
                    </span>
                </div>
                <TagSearchingInput
                    searchingTags={getAnalyticsSearchingTags()}
                    getAllChannels={props.getAllChannels}
                    channels={props.channels}
                    currentTags={props.tags}
                    onSearch={(tags) => {props.setAnalyticsTags(tags)}}
                    onClearFilter={() => {props.setAnalyticsTags([])}}
                    repeatingTags={false}
                    containerClass="analytics-tabs__searching-input-container"/>
            </div>
            {(props.selectedTab == AnalyticTab.Statistic || props.selectedTab == AnalyticTab.Monitoring) && <ChartsTab tags={props.tags} />}
            <div className="analytics-list__table">
                {props.selectedTab === AnalyticTab.CustomerJorney && <CustomerJourney tags={props.tags}/>}
            </div>
            {props.selectedTab == AnalyticTab.Reports && <Reports tags={props.tags} />}
        </div>
    );
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        selectedTab: selectedTabSelector(state),
        channels: channelsListSelector(state),
        tags: analyticsTagsSelector(state)
    };
}

const mapDispatchToProps = (dispatch: any): IAnalyticsTabsProps => {
    return {
        changeAnalyticTab: (tab: AnalyticTab) => dispatch(changeAnalyticTab(tab)),
        getAllChannels: () => dispatch(getAllChannels()),
        setAnalyticsTags: (tags: ISearchingTag[]) => dispatch(setAnalyticsTags(tags))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsTabs);