import { IChatItem } from "src/app/chats-panel/~store/models/ChatItem";

export interface IPauseChatProps {
    pauseChat:(chatId: number, pauseCategory: PAUSE_CATEGORIES) => void;
    unpauseChat:(chatId: number) => void;
    selectedChat: IChatItem;
}

export enum PAUSE_CATEGORIES {
    PAUSE_WITHOUT_NOTIFICATION ='PAUSE_WITHOUT_NOTIFICATION',
    PAUSE_WITH_NOTIFICATION = 'PAUSE_WITH_NOTIFICATION'
}