import moment, { unitOfTime } from "moment";
import i18n from "src/app/shared/localization/i18n";

export const ConvertFromFilterToTimeInterval = (filter: string) => {
    let from: string = "0", to: string | undefined = undefined;
    let now = new Date();
    switch(filter) {
        case i18n.t('common.today'):
            from = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime().toString();
            to = undefined;
            break;
        case i18n.t('common.yesterday'):
            from = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).getTime().toString();
            to = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime().toString();
            break;
        case i18n.t('common.currentWeek'):
            from = moment().startOf('isoweek' as unitOfTime._isoWeek).toDate().getTime().toString();
            break;
        case i18n.t('common.currentMonth'):
            from = new Date(now.getFullYear(), now.getMonth(), 1).getTime().toString();
            break;
        case i18n.t('common.currentYear'):
            from = new Date(now.getFullYear(), 0, 1).getTime().toString();
            break;
        default:
            const [fromDateString, toDateString] = filter.split("-");
            from = new Date(`0${fromDateString.split(".")[1].slice(-2)}/0${fromDateString.split(".")[0].slice(-2)}/${fromDateString.split(".")[2]}`).getTime().toString();
            to = new Date(`0${toDateString.split(".")[1].slice(-2)}/0${toDateString.split(".")[0].slice(-2)}/${toDateString.split(".")[2]}`).getTime().toString();
            
            break;
    }
    return {from, to}
}

export const ConvertFromFilterToUTCTimeInterval = (filter: string) : string => {
    
    let leftDate: Date = new Date(Date.now()), rightDate: Date = new Date(Date.now());
    let now = new Date();
    switch(filter) {
        case i18n.t('common.today'):
            leftDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            break;
        case i18n.t('common.yesterday'):
            leftDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
            rightDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            break;
        case i18n.t('common.currentWeek'):
            leftDate = moment().startOf('isoweek' as unitOfTime._isoWeek).toDate();
            break;
        case i18n.t('common.currentMonth'):
            leftDate = new Date(now.getFullYear(), now.getMonth(), 1);
            break;
        case i18n.t('common.currentYear'):
            leftDate = new Date(now.getFullYear(), 0, 1);
            break;
        default:
            const [fromDateString, toDateString] = filter.split("-");
            leftDate = new Date(`0${fromDateString.split(".")[1].slice(-2)}/0${fromDateString.split(".")[0].slice(-2)}/${fromDateString.split(".")[2]}`);
            rightDate = new Date(`0${toDateString.split(".")[1].slice(-2)}/0${toDateString.split(".")[0].slice(-2)}/${toDateString.split(".")[2]}`);
            
            break;
    }

    const leftDateMonthString = `0${leftDate.getUTCMonth() + 1}`.slice(-2);
    const rightDateMonthString = `0${rightDate.getUTCMonth() + 1}`.slice(-2);
    const leftDateDayString = `0${leftDate.getUTCDate()}`.slice(-2);
    const rightDateDayString = `0${rightDate.getUTCDate()}`.slice(-2);
    const leftDateHoursString = `0${leftDate.getUTCHours()}`.slice(-2);
    const leftDateMinutesString = `0${leftDate.getUTCMinutes()}`.slice(-2);
    const leftDateSecondsString = `0${leftDate.getUTCSeconds()}`.slice(-2);
    const rightDateHoursString = `0${rightDate.getUTCHours()}`.slice(-2);
    const rightDateMinutesString = `0${rightDate.getUTCMinutes()}`.slice(-2);
    const rightDateSecondsString = `0${rightDate.getUTCSeconds()}`.slice(-2);
    return `${leftDateDayString}.${leftDateMonthString}.${leftDate.getUTCFullYear()} ${leftDateHoursString}:${leftDateMinutesString}:${leftDateSecondsString}-${rightDateDayString}.${rightDateMonthString}.${rightDate.getUTCFullYear()} ${rightDateHoursString}:${rightDateMinutesString}:${rightDateSecondsString}`
}