import {AppThunkAction} from "src/~store/models/AppThunkAction";
import history from 'src/~store/history';
import {
    GetAllContactsAction,
    CreateContactAction,
    SaveContactAction,
    DeleteContactAction,
    GetContactByIdAction,
    ChangeSelectedContactAction,
    FindContactByPhoneAction,
    ChangeContactAvatarAction,
    SetSearchString,  
    MergeContactAction, 
    RestoreContactAction,
    ClearContactFoundByPhoneAction,
    DeleteAccountAction,
    SaveAccountAction,
    ToggleContactSortingAction,
    DetachMessengerAction,
    ClearContactAction,
} from "./interfaces";

import {
    GET_ALL_CONTACTS_START, 
    GET_ALL_CONTACTS_SUCCESS, 
    GET_ALL_CONTACTS_ERROR,
    CREATE_CONTACT,
    SAVE_NEW_CONTACT_SUCCESS,
    SAVE_CONTACT_START,
    SAVE_CONTACT_SUCCESS,
    SAVE_NEW_CONTACT_ERROR,
    SAVE_CONTACT_ERROR,
    DELETE_CONTACT_START,
    DELETE_NEW_CONTACT_SUCCESS,
    DELETE_CONTACT_SUCCESS,
    DELETE_CONTACT_ERROR,
    GET_CONTACT_BY_ID_START,
    GET_CONTACT_BY_ID_SUCCESS,
    GET_CONTACT_BY_ID_ERROR,
    CHANGE_SELECTED_CONTACT,
    FIND_CONTACT_BY_PHONE_START,
    FIND_CONTACT_BY_PHONE_ERROR,
    FIND_CONTACT_BY_PHONE_SUCCESS,
    CHANGE_CONTACT_AVATAR_START,
    CHANGE_CONTACT_AVATAR_ERROR,
    CHANGE_CONTACT_AVATAR_SUCCESS,
    SET_SEARCH_STRING,
    MERGE_CONTACT,
    RESTORE_CONTACT_START,
    RESTORE_CONTACT_SUCCESS,
    RESTORE_CONTACT_ERROR,
    CLEAR_CONTACT_FOUND_BY_PHONE,
    DELETE_ACCOUNT_SUCCESS,
    SAVE_ACCOUNT_SUCCESS,
    FIND_CONTACT_BY_PHONE_NOT_FOUND,
    TOGGLE_CONTACT_SORTING,
    DETACH_MESSENGER_START,
    DETACH_MESSENGER_SUCCESS,
    DETACH_MESSENGER_ERROR,
    CLEAR_CONTACT,
} from "./types";

import {
    getContactsAsync, 
    getContactMessengersAsync,
    saveContactAsync,
    deleteContactAsync,
    getContactByIdAsync,
    findContactByPhoneAsync,
    changeContactAvatarAsync,
    mergeContactsAsync, 
    saveAccountAsync, 
    deleteAccountAsync, 
    restoreContactAsync,
    newChatWithMessengerAsync,
    detachMessengerAsync,
} from "../../~api/actions";

import {IContact} from "../models/Contact";
import {showSuccessToastr, showErrorToastr, showWarningToastr, showInfoToastr} from "src/app/shared/helpers/toastr-helper/ToastrHelper";

import { REQUESTED_NUMBER_OF_CONTACTS } from "../../contact-main/contact-list/ContactListConstants";
import { getContactChatsAsync } from "src/app/chats/~api/actions";
import { IApiActionResult } from "src/~api/ApiActionResult";
import { IAccount } from "../models/Account";
import { getDateTime } from "src/app/shared/helpers/date-helper/DateFormats";
import i18n from "src/app/shared/localization/i18n";

export const getContacts = (
    skip: number, 
    count: number,
    searchString: string,
    isSortingByContactLastName: boolean,
    skipEmpty: boolean
): AppThunkAction<GetAllContactsAction> => async dispatch => {
    dispatch({type: GET_ALL_CONTACTS_START});

    const result = await getContactsAsync(skip, count, searchString, isSortingByContactLastName, skipEmpty);
    if (result.isSuccess) {
        const contacts = result.value ?? [];
        for (const contact of contacts) {
            const messengersResult = await getContactMessengersAsync(contact.contactId ?? 0);
            contact.messengers = messengersResult.value ?? [];

            const chatsResult = await getContactChatsAsync(contact.contactId ?? 0);
            contact.chatsCount = chatsResult.value ? chatsResult.value.length : 0;
        }
        dispatch({type: GET_ALL_CONTACTS_SUCCESS, payload: {contacts, skip}});
    } else {
        dispatch({type: GET_ALL_CONTACTS_ERROR});
    }
};

export const createContact = (): AppThunkAction<CreateContactAction> => async dispatch => {
    dispatch({type: CREATE_CONTACT})
}

export const clearContact = (): AppThunkAction<ClearContactAction> => async dispatch => {
    dispatch({type: CLEAR_CONTACT})
}

export const setSearchString = (
    searchString: string
): AppThunkAction<SetSearchString> => async (dispatch, getState) => {
    dispatch({ type: SET_SEARCH_STRING, payload: { searchString } });
    const countOfContacts = searchString != '' ?
        getState().contactState.contactList.countOfContacts :
        REQUESTED_NUMBER_OF_CONTACTS;
    getContacts(0, countOfContacts, searchString, getState().contactState.contactList.isSortingByContactLastName, false)(dispatch, getState);
}

export const saveContact = (
    contact: IContact, 
    contactsCountToGet: number
): AppThunkAction<SaveContactAction> => async (dispatch, getState) => {
    dispatch({type: SAVE_CONTACT_START})
    let result: IApiActionResult<IContact>;

    if(getState().contactState.contactList.contactItemInfo.selectedContact.contactId !== contact.contactId && contact.contactId !== 0) {
        const sourceContactId = contact.contactId;
        contact.contactId = getState().contactState.contactList.contactItemInfo.selectedContact.contactId === 0 ? 
            sourceContactId : 
            getState().contactState.contactList.contactItemInfo.selectedContact.contactId;
        contact.messengers.forEach((m) => {m.contactId = contact.contactId});
        result = await mergeContactsAsync(contact, sourceContactId ?? 0);
    }
    else {
        result = await saveContactAsync(contact);
        console.log(result.value);
    }
    if(result.isSuccess) {
        history.push(`/contacts`);
        if(contact.contactId === 0) {
            showSuccessToastr(i18n.t('contact.saveNewContactSuccessMessage'));
            dispatch({type: SAVE_NEW_CONTACT_SUCCESS});
        } else {
            showSuccessToastr(i18n.t('contact.saveContactSuccessMessage'));
            dispatch({type: SAVE_CONTACT_SUCCESS})
        }
    } else {
        if(contact.contactId === 0) {
            showErrorToastr(i18n.t('contact.saveNewContactErrorMessage'));
            dispatch({type: SAVE_NEW_CONTACT_ERROR});
        } else {
            showErrorToastr(i18n.t('contact.saveContactErrorMessage'));
            dispatch({type: SAVE_CONTACT_ERROR});
        }
    }
    getContacts(
        0, 
        contactsCountToGet, 
        getState().contactState.contactList.searchString, 
        getState().contactState.contactList.isSortingByContactLastName,
        false)(dispatch, getState);
}

export const deleteContact = (
    contact: IContact, 
    contactsCountToGet: number
): AppThunkAction<DeleteContactAction> => async (dispatch, getState) => {
    dispatch({type: DELETE_CONTACT_START});
    if(contact.contactId === 0) {
        showSuccessToastr(i18n.t('contact.deleteNewContactSuccessMessage'));
        dispatch({type: DELETE_NEW_CONTACT_SUCCESS});
        dispatch({type: DELETE_CONTACT_SUCCESS});
    } else {
        const result = await deleteContactAsync(contact.contactId ?? 0);

        if(result.isSuccess) {
            showSuccessToastr(i18n.t('contact.deleteContactSuccessMessage'));
            dispatch({type: DELETE_CONTACT_SUCCESS});
        } else {
            showErrorToastr(i18n.t('contact.deleteContactErrorMessage'));
            dispatch({type: DELETE_CONTACT_ERROR});
        }
    }

    getContacts(
        0, 
        contactsCountToGet, 
        getState().contactState.contactList.searchString, 
        getState().contactState.contactList.isSortingByContactLastName,
        false)(dispatch, getState);
}

export const getContactById = (
    contactId: number
): AppThunkAction<GetContactByIdAction> => async dispatch => {
    dispatch({type: GET_CONTACT_BY_ID_START});

    const result = await getContactByIdAsync(contactId);

    if(result.isSuccess) {
        const contact = result.value ?? ({} as IContact);

        const messengersResult = await getContactMessengersAsync(contactId);
        contact.messengers = messengersResult.value ?? [];

        const chatsResult = await getContactChatsAsync(contact.contactId ?? 0);
        contact.chatsCount = chatsResult.value ? chatsResult.value.length : 0;
        
        dispatch({type: GET_CONTACT_BY_ID_SUCCESS, payload: {contact}});
    } else {
        dispatch({type: GET_CONTACT_BY_ID_ERROR});
    }
}

export const changeSelectedContact = (
    contact: IContact
): AppThunkAction<ChangeSelectedContactAction> => async dispatch => {
    dispatch({type: CHANGE_SELECTED_CONTACT, payload: {contact}});
}

export const clearContactFoundByPhone = (): AppThunkAction<ClearContactFoundByPhoneAction> => async dispatch => {
    dispatch({ type: CLEAR_CONTACT_FOUND_BY_PHONE });
}

export const findContactByPhone = (
    phone: string
): AppThunkAction<FindContactByPhoneAction> => async dispatch => {
    dispatch({type: FIND_CONTACT_BY_PHONE_START});
    const result = await findContactByPhoneAsync(phone);
    if(result.isSuccess) {
        let foundContact: IContact | undefined = 
            result.value?.contactId === undefined ?
            undefined :
            result.value;
        
        if(foundContact !== undefined && result.statusCode !== 204) {
            showSuccessToastr(i18n.t('contact.contactFoundMessage'));
        } 
        else {
            showInfoToastr(i18n.t('contact.contactNotFoundMessage'));
            dispatch({type: FIND_CONTACT_BY_PHONE_NOT_FOUND });
        }
            
        dispatch({type: FIND_CONTACT_BY_PHONE_SUCCESS, payload: {foundContact}});
    } else {
        dispatch({type: FIND_CONTACT_BY_PHONE_ERROR});
        showErrorToastr(i18n.t('contact.contactNotFoundMessage'));
    }
}

export const mergeContact = (): AppThunkAction<MergeContactAction> => async dispatch => {
    dispatch({ type: MERGE_CONTACT });
}

export const changeContactAvatar = (
    file: File, 
    contact: IContact,
): AppThunkAction<ChangeContactAvatarAction> => async dispatch => {
    dispatch({type: CHANGE_CONTACT_AVATAR_START});

    const result = await changeContactAvatarAsync(file, contact);
    if(result.isSuccess) {
        const avatarId = result.value.avatarId ?? null;

        dispatch({type: CHANGE_CONTACT_AVATAR_SUCCESS, payload: { avatarId }});
    } else {
        dispatch({type: CHANGE_CONTACT_AVATAR_ERROR});
        if(result.statusCode === 401) {
            history.push('/login');
        }
    }
}

export const saveAccount = (account: IAccount): AppThunkAction<SaveAccountAction> => async dispatch => {
    const result = await saveAccountAsync(account);

    if(result.isSuccess) {
        dispatch({type: SAVE_ACCOUNT_SUCCESS, payload: { account: result.value ?? account }});
    }
}

export const deleteAccount = (accountId: number): AppThunkAction<DeleteAccountAction> => async dispatch => {
    const result = await deleteAccountAsync(accountId);

    if(result.isSuccess) {
        dispatch({type: DELETE_ACCOUNT_SUCCESS, payload: { accountId }});
    }
}

export const restoreContact = (
    contactId: number
): AppThunkAction<RestoreContactAction> => async dispatch => {
    dispatch({ type: RESTORE_CONTACT_START });

    const result = await restoreContactAsync(contactId);

    if(result.isSuccess) {
        dispatch({ type: RESTORE_CONTACT_SUCCESS, payload: { contactId } });
    } else {
        dispatch({ type: RESTORE_CONTACT_ERROR });
    }
}

export const toggleContactSorting = (): AppThunkAction<ToggleContactSortingAction> => async dispatch => {
    dispatch({type: TOGGLE_CONTACT_SORTING});
}

export const detachMessenger = (messengerId: number): AppThunkAction<DetachMessengerAction> => async dispatch => {
    dispatch({type: DETACH_MESSENGER_START});

    const result = await detachMessengerAsync(messengerId);
    if(result.isSuccess) {
        dispatch({type: DETACH_MESSENGER_SUCCESS, payload: { messengerId }});
    } else {
        dispatch({type: DETACH_MESSENGER_ERROR});
    }
}

export const newChatWithMessenger = async (messengerId: number, contactId: number) => {
    const result = await newChatWithMessengerAsync(messengerId);
    if (!result.isSuccess) {
        const error = JSON.parse(result.errorText ?? "null");
        switch (error.errorCode) {
            case "chat.contactHasOpenedChatException":
                showErrorToastr(i18n.t('chat.contactHasOpenedChatException').
                    replace('{0}', error.data.chatId.toString()).
                    replace('{1}', error.data.operatorName || i18n.t('chats.operatorNotAssignedYetMessage')));
                break;
            case "error.enabledChannelNotFoundException":
                showErrorToastr(i18n.t('chat.enabledChannelNotFoundException'))
                break;
            default:
                showErrorToastr(i18n.t('chats.continueChatErrorMessage'))
        }
    }
 }
