import * as React from "react";
import {IChannelListProps} from "./~types/ChannelListProps";
import {IApplicationState} from "src/~store/models/ApplicationState";
import {
    channelsSelector, 
    isReceivingChannelsSelector, 
    isChannelCreatingSelector,
    operatorsSelector,
    isReceivingOperatorsSelector,
    isSortingByChannelNameSelector
} from "src/app/channel/~store/selector";

import {
    getAllChannels,
    getAllOperators, 
    createChannel,
    saveChannel,
    deleteChannel,
    saveChannelSetting,
    toggleChannelSorting,
    restartChannel,
} from "src/app/channel/~store/actions/actions";

import {connect} from "react-redux";

import "./ChannelList.css";
import {Button, Table} from "reactstrap";
import Loader from "src/app/shared/components/loader/Loader";
import ChannelItem from "./channel-item/ChannelItem";
import {showErrorToastr} from "src/app/shared/helpers/toastr-helper/ToastrHelper";
import {IChannel} from "../../~store/models/Channel";
import { spawn } from "child_process";
import i18n from "src/app/shared/localization/i18n";

type ReduxType = ReturnType<typeof mapStateToProps> & IChannelListProps;

class ChannelList extends React.Component<ReduxType> {
    public componentDidMount() {
        this.props.getAllChannels();
        this.props.getAllOperators();
    }

    public render() {
        const {isReceivingChannels, isReceivingOperators} = this.props;

        return (
            <div className="channel-list">
                <div className="channel-list__header">
                <Button
                    className="channel-list__btn-create-operator" 
                    onClick={this._createChannelHandler}>{i18n.t('channels.createChannel')}</Button>
                </div>
                {(isReceivingChannels || isReceivingOperators) ? <Loader /> : this._getChannelsTable()}
            </div>
        );
    }

    private _getChannelsTable = (): React.ReactElement<{}> => {
        return (
            <div className="channel-list-table card-table">
                {this._getChannelsTableHead()}
                {this._mapChannelsToTableBody()}
            </div>
        );
    };

    private _getChannelsTableHead = (): React.ReactElement<{}> => {
        return (
        <div className="channel-table-header">
            <div>
                <span className="channel-list__name" onClick={() => this.props.toggleChannelSorting()}>{i18n.t('common.name')}</span>
                <span>{this.props.isSortingByChannelName ? "⯆" : "⯈"}</span>
            </div>
            <div>{i18n.t('common.type')}</div>
            <div>{i18n.t('common.token')}</div>
            <div className="channel-list__enabled-cell">{i18n.t('common.enabled')}</div>
            <div className="channel-list__buttons-cell" />
        </div>
        );
    };

    private _mapChannelsToTableBody = (): React.ReactElement<{}> => {
        const {channels, operators} = this.props;
        return (
            <div>
                {channels.map((channel) => {
                    return <ChannelItem
                                key={channel.channelId ?? 0} 
                                channel={channel} 
                                allOperatorsShort={operators}
                                onSaveChannel={this._onSaveChannelHandler}
                                onDeleteChannel={this._onDeleteChannelHandler}
                                onSaveChannelSetting={this._onSaveChannelSettingHandler}
                                onRestartChannel={this._onRestartChannelHandler}/>;
                })}
            </div>
        );
    };

    private _onSaveChannelHandler = (channel: IChannel): void => {
        this.props.saveChannel(channel);
        this.forceUpdate();
    }

    private _onDeleteChannelHandler = (channel: IChannel): void => {
        if (window.confirm(i18n.t('channels.sureDeleteChannel'))) {
            this.props.deleteChannel(channel);
        }
    }

    private _onRestartChannelHandler = (channel: IChannel): void => {
        if (window.confirm(i18n.t('channels.restartChannelQuestion'))) {
            this.props.restartChannel(channel);
        }
    }

    private _createChannelHandler = (): void => {
        if (this.props.isChannelCreating) {
            showErrorToastr(i18n.t('channels.channelCreatingErrorMessage'));
            return;
        } else {
            this.props.createChannel();
        }
    };

    private _onSaveChannelSettingHandler = (channel: IChannel, setting: string): void => {
        this.props.saveChannelSetting(channel, setting);
    }
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        channels: channelsSelector(state),
        operators: operatorsSelector(state),
        isReceivingChannels: isReceivingChannelsSelector(state),
        isReceivingOperators: isReceivingOperatorsSelector(state),
        isChannelCreating: isChannelCreatingSelector(state),
        isSortingByChannelName: isSortingByChannelNameSelector(state)
    };
};

const mapDispatchToProps = (dispatch: any): IChannelListProps => {
    return {
        getAllChannels: () => dispatch(getAllChannels()),
        getAllOperators: () => dispatch(getAllOperators()),
        createChannel: () => dispatch(createChannel()),
        saveChannel: (channel: IChannel) => dispatch(saveChannel(channel)),
        deleteChannel: (channel: IChannel) => dispatch(deleteChannel(channel)),
        restartChannel: (channel: IChannel) => dispatch(restartChannel(channel)),
        toggleChannelSorting: () => dispatch(toggleChannelSorting()),
        saveChannelSetting: (
            channel: IChannel, 
            setting: string
        ) => dispatch(saveChannelSetting(channel, setting)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelList);
