import * as React from "react";

import "./MailingList.css";
import { IApplicationState } from "src/~store/models/ApplicationState";
import { IMailingListProps } from "./~types/MailingListProps";
import { connect } from "react-redux";
import { Button, Table } from "reactstrap";
import Loader from "src/app/shared/components/loader/Loader";
import {
  allMailingsSelector,
  isReceivingMailingsSelector,
} from "../../~store/selector";
import history from "src/~store/history";
import { useEffect } from "react";
import {
  getMailings,
  removeAllMailingsFromState,
  startMailing,
  deleteMailing,
} from "../../~store/actions/actions";
import { MailingStatus } from "../../~store/models/enums/MailingStatus";
import { getDateTime } from "src/app/shared/helpers/date-helper/DateFormats";
import { useTranslation } from "react-i18next";

const MailingList = (
  props: ReturnType<typeof mapStateToProps> & IMailingListProps
) => {
  const { t } = useTranslation();
  const mailingListRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    props.getMailings();
    if (mailingListRef.current) {
      mailingListRef.current.addEventListener("scroll", _onListScroll);
      return () => {
        mailingListRef?.current?.removeEventListener("scroll", _onListScroll);
        props.removeAllMailingsFromState();
      };
    }
  }, []);

  const _onListScroll = (e: any) => {
    if (
      e.currentTarget.scrollHeight -
        e.currentTarget.scrollTop -
        e.currentTarget.clientHeight <=
      100
    ) {
      props.getMailings();
    }
  };

  const _getTable = () => {
    return (
      <Table className="mailing-list__table card-table">
        <thead>
          <tr>
            <th className="mailing-list__mailing-id-block"></th>
            <th>{t("common.name")}</th>
            <th>{t("common.created")}</th>
            <th>{t("common.status")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.allMailings.map((mailing) => {
            return (
              <tr key={mailing.mailingId}>
                <td className="mailing-list_id">
                  <span>#{mailing.mailingId}</span>
                </td>
                <td>
                  <span>{mailing.name}</span>
                </td>
                <td className="mailing-list__created-date">
                  {getDateTime(mailing.createdOn)}
                </td>
                <td>
                  {mailing.status === MailingStatus.pending && (
                    <span>{t("mailing.pending")}</span>
                  )}
                  {mailing.status === MailingStatus.inProgress && (
                    <span>{t("mailing.inProgress")}</span>
                  )}
                  {mailing.status === MailingStatus.sent && (
                    <span>{t("mailing.sent")}</span>
                  )}
                  {mailing.status === MailingStatus.canceled && (
                    <span>{t("mailing.canceled")}</span>
                  )}
                </td>
                <td>
                  <div className="mailing-list__buttons-block">
                    <Button
                      className="mailing-list__mailing_start_button"
                      onClick={() => {
                        props.startMailing(mailing.mailingId);
                      }}
                      disabled={
                        mailing.status === MailingStatus.inProgress ||
                        mailing.status === MailingStatus.sent
                      }
                    >
                      {t("common.start")}
                    </Button>
                    <Button
                      className="mailing-list__mailing_edit_button"
                      onClick={() => {
                        history.push(
                          "/mailing/edit-mailing/" + mailing.mailingId
                        );
                      }}
                    >
                      <i className="material-icons">edit</i>
                    </Button>
                    <Button
                      className="mailing-list__mailing_delete_button"
                      onClick={() => {
                        props.deleteMailing(mailing.mailingId);
                      }}
                    >
                      <i className="material-icons">delete_forever</i>
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <div ref={mailingListRef} className="mailing-list">
      <div className="mailing-list__header">
        <Button
          className="mailing-list__btn-create"
          onClick={() => {
            history.push("/mailing/edit-mailing/0");
          }}
        >
          {t("mailing.createMailing")}
        </Button>
      </div>
      {_getTable()}
      {props.isReceivingMailings && <Loader />}
    </div>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    isReceivingMailings: isReceivingMailingsSelector(state),
    allMailings: allMailingsSelector(state),
  };
};

const mapDispatchToProps = (dispatch: any): IMailingListProps => {
  return {
    startMailing: (mailingId) => dispatch(startMailing(mailingId)),
    getMailings: () => dispatch(getMailings()),
    removeAllMailingsFromState: () => dispatch(removeAllMailingsFromState()),
    deleteMailing: (mailingId: number) => dispatch(deleteMailing(mailingId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailingList);
