import * as toastr from "toastr";
import "./ToastsHelper.css";
import "toastr/build/toastr.min.css";

const DEFAULT_TITLE = "";
const toastrOptions = {
    positionClass: "toast-top-right",
    hideDuration: 1000,
    timeOut: 5000,
    extendedTimeOut: 1000,
    closeButton: false
};

export const showErrorToastr = (message: string, withCloseButton: boolean = false, infinity: boolean = false): void => {
    toastr.clear();
    editToastrOptions(withCloseButton, infinity);
    toastr.error(message, DEFAULT_TITLE, toastrOptions);
};

export const showSuccessToastr = (message: string, withCloseButton: boolean = false, infinity: boolean = false): void => {
    toastr.clear();
    editToastrOptions(withCloseButton, infinity);
    toastr.success(message, DEFAULT_TITLE, toastrOptions);
};

export const showWarningToastr = (message: string, withCloseButton: boolean = false, infinity: boolean = false): void => {
    toastr.clear();
    editToastrOptions(withCloseButton, infinity);
    toastr.warning(message, DEFAULT_TITLE, toastrOptions);
};

export const showInfoToastr = (message: string, withCloseButton: boolean = false, infinity: boolean = false): void => {
    toastr.clear();
    editToastrOptions(withCloseButton, infinity);
    toastr.info(message, DEFAULT_TITLE, toastrOptions);
}

const editToastrOptions = (withCloseButton: boolean, infinity: boolean): void => {
    if (infinity) {
        toastrOptions.timeOut = 0;
        toastrOptions.extendedTimeOut = 0;
    }
    else {

        toastrOptions.timeOut = 5000;
        toastrOptions.extendedTimeOut = 1000;
    }
    toastrOptions.closeButton = withCloseButton;
}
