import { createSelector } from 'reselect';
import { IApplicationState } from "src/~store/models/ApplicationState";

const operatorsSelector = (state: IApplicationState) => state.operatorState;

// operatorsList
const operatorsListSelector = createSelector(
    operatorsSelector, 
    operators => operators.operatorsList,
);

export const isOperatorCreatingSelector = createSelector(
    operatorsListSelector, 
    operatorsList => operatorsList.allOperators.findIndex(o => o.operatorId === 0) > -1,
);

export const isReceivingOperatorsSelector = createSelector(
    operatorsListSelector,
    operatorsList => operatorsList.isReceivingOperators
);

export const allOperatorsSelector = createSelector(
    operatorsListSelector,
    operatorsList => operatorsList.allOperators.map((a) => a).sort((a, b) => {
        if(operatorsList.isSortingBeOperatorName) {
            return a.name?.localeCompare(b.name);
        }
        else {
            return new Date(b.modifiedOn).getTime() - new Date(a.modifiedOn).getTime()
        }
    })
);

export const isSortingByOperatorNameSelector = createSelector(
    operatorsListSelector,
    operatorsList => operatorsList.isSortingBeOperatorName
);
