import { IChat } from "../~store/models/Chat";
import { IApiActionResult } from "src/~api/ApiActionResult";
import { fetchGet, getApiActionResult, fetchPost } from "src/~api/ApiHelper";
import { 
    GET_CHATS_API_ROUTE, 
    GET_CHATS_BY_CONTACT_ID_API_ROUTE,
    GET_SELECTED_CHAT_BY_ID_API_ROUTE,
    GET_CHAT_BY_ID_API_ROUTE,
    GET_MESSAGES_BY_CHAT_ID_API_ROUTE,
    GET_CONTACT_CHATS_LAZY_API_ROUTE,
    GET_MESSENGER_BY_ID_API_ROUTE,
    OPEN_CHAT_BY_ID_API_ROUTE,
    EDIT_MESSAGE_API_ROUTE,
    PUSH_CHAT_HISTORY_TO_WIDGET_API_ROUTE,
    GET_TAG_NAMES_API_ROUTE,
    GET_TAG_VALUES_BY_TAG_NAME_API_ROUTE,
    SAVE_TAG
} from "./apiRoutes";
import { IMessage } from "../~store/models/Message";
import { IMessenger } from "src/app/contact/~store/models/Messenger";
import { GetChatsMode } from "../~store/models/enums/GetChatsMode";
import { ISearchingTag, TagName, TagType } from "src/app/shared/components/tag-searching-input/~types/models/SearchingTag";
import { ConvertFromFilterToUTCTimeInterval } from "src/app/shared/helpers/date-helper/FromFilterToDateHelper";
import { ITagModel } from "src/app/chat/specify-chat-close-reason-dialog/~store/models/TagModel";
import i18n from "src/app/shared/localization/i18n";
import { IChatItem } from "src/app/chats-panel/~store/models/ChatItem";


export const getChatsAsync = async (skip: number, count: number, searchString: string, tags: ISearchingTag[], getChatsMode: GetChatsMode): Promise<IApiActionResult<IChat[]>> => {
    const nowDate = new Date(Date.now());
    tags.filter(val => val.tagName === TagName.Status && val.tagType === TagType.ClosingTag).forEach(el => el.externalValue = ConvertFromFilterToStatus(el.value));
    tags.filter(val => val.tagName === TagName.Channel && val.tagType === TagType.ClosingTag).forEach(el => el.externalValue = ConvertFromFilterToChannelType(el.value));
    tags.filter(val => val.tagName === TagName.Date && val.tagType === TagType.ClosingTag && val.value !== i18n.t('common.period')).forEach(el => el.externalValue = ConvertFromFilterToUTCTimeInterval(el.value));
    const fetchPromise = fetchPost(GET_CHATS_API_ROUTE,
    {
        body: JSON.stringify({
            skip,
            count,
            tags,
            getChatsMode,
            hourDifference: nowDate.getTimezoneOffset()/-60,
        })
    });
    return await getApiActionResult(fetchPromise, true);
};

const ConvertFromFilterToStatus = (filter: string) : any => {
    let status = "";
    switch (filter) {
        case i18n.t('chats.missed'):
            status = "Missed";
            break;
        case i18n.t('chats.expired'):
            status = "Expired";
            break;
        case i18n.t('chats.finished'):
            status = "Finished";
            break;
    }
    return status;
}

const ConvertFromFilterToChannelType = (filter: string): any => {
    let status = "";
    switch (filter) {
        case i18n.t('channels.instagram'):
            status = "Instagram";
            break;
        case i18n.t('channels.facebook'):
            status = "Facebook";
            break;
        case i18n.t('channels.telegram'):
            status = "Telegram";
            break;
        case i18n.t('channels.viber'):
            status = "Viber";
            break;
        case i18n.t('channels.vk'):
            status = "VK";
            break;
        case i18n.t('channels.weChat'):
            status = "WeChat";
            break;
        case i18n.t('channels.widget'):
            status = "Widget";
            break;
        case i18n.t('channels.uniformWidget'):
            status = "UniformWidget";
            break;
        case i18n.t('channels.whatsApp'):
            status = "WhatsApp";
            break;
        case i18n.t('channels.ednaWhatsApp'):
            status = "EdnaWhatsApp";
            break;
        case i18n.t('channels.voiceChannel'):
            status = "VoiceChannel";
            break;
    }
    return status;
}

export const getContactChatsAsync = async (contactId: number): Promise<IApiActionResult<IChat[]>> => {
    const fetchPromise = fetchGet(GET_CHATS_BY_CONTACT_ID_API_ROUTE + contactId);
    return await getApiActionResult(fetchPromise, true);
}

export const getChatByIdAsync = async (chatId: number, includeMessages: boolean = false, includeContact: boolean): Promise<IApiActionResult<IChatItem>> => {
    const fetchPromise = fetchGet(GET_CHAT_BY_ID_API_ROUTE + chatId + '&includeMessages=' + includeMessages + '&includeContact=' + includeContact);
    return await getApiActionResult(fetchPromise, true);
}

export const getLightweightChatByIdAsync = async (chatId: number, includeMessages: boolean = false, includeContact: boolean): Promise<IApiActionResult<IChatItem>> => {
    const fetchPromise = fetchGet(GET_SELECTED_CHAT_BY_ID_API_ROUTE + chatId + '&includeMessages=' + includeMessages + '&includeContact=' + includeContact);
    return await getApiActionResult(fetchPromise, true);
}

export const getMessagesByChatIdAsync = async (chatId: number): Promise<IApiActionResult<IMessage[]>> => {
    const fetchPromise = fetchGet(GET_MESSAGES_BY_CHAT_ID_API_ROUTE + chatId);
    return await getApiActionResult(fetchPromise, true);
}

export const getMessengerByIdAsync = async (messengerId: number): Promise<IApiActionResult<IMessenger>> => {
    const fetchPromise = fetchGet(GET_MESSENGER_BY_ID_API_ROUTE + messengerId);
    return await getApiActionResult(fetchPromise, true);
}

export const getContactChatsLazyAsync = async (
    startChatId: number, 
    beforeCount: number, 
    afterCount: number,
    searchString: string,
): Promise<IApiActionResult<IChat[]>> => {
    const fetchPromise = fetchGet(
        encodeURI(GET_CONTACT_CHATS_LAZY_API_ROUTE + startChatId + 
        "&beforeCount=" + beforeCount + 
        "&afterCount=" + afterCount +
        "&searchString=" + searchString));
    return await getApiActionResult(fetchPromise, true);
}

export const editMessageAsync = async (
    messageId: number,
    messageText: string,
): Promise<IApiActionResult<IMessage>> => {
    const fetchPromise = fetchPost(EDIT_MESSAGE_API_ROUTE, {
        body: JSON.stringify({
            messageId,
            messageText,
        })
    });

    return await getApiActionResult(fetchPromise, true);
}

export const onChatItemClickAsync = async (chatId: number): Promise<void> => {
    const fetchPromise = fetchPost(PUSH_CHAT_HISTORY_TO_WIDGET_API_ROUTE  + "?chatId=" + chatId);
}

export const getTagNames = async (): Promise<IApiActionResult<string[]>> => {
    const fetchPromise = fetchGet(GET_TAG_NAMES_API_ROUTE);
    return await getApiActionResult(fetchPromise, true);
}

export const getTagValuesByTagName = async (name: string): Promise<IApiActionResult<string[]>> => {
    const fetchPromise = fetchGet(GET_TAG_VALUES_BY_TAG_NAME_API_ROUTE + "?name=" + name);
    return await getApiActionResult(fetchPromise, true);
}

export const saveTagsAsync = async (tags: ITagModel[]): Promise<IApiActionResult<ITagModel[]>> => {
    const fetchPromise = fetchPost(SAVE_TAG, {
        body: JSON.stringify(tags)});
    return await getApiActionResult(fetchPromise, true);
}