import IWriterUser from "../models/IWriterUser";
import IPermissionViewModel from "../models/PermissionViewModel";
import { IRolePermissions } from "../models/RolePermissions";
import { IUserPermissions } from "../models/UserPermissions";

export interface IPermissionState {
    allPermissions: IPermissionViewModel[],
    rolesPermissions: IRolePermissions[],
    allUsers: IWriterUser[],
    usersPermissions: IUserPermissions[]
}

export const initPermissionState: IPermissionState = {
    allPermissions: [],
    rolesPermissions: [],
    allUsers: [],
    usersPermissions: [] 
}