import { IMessageItem } from "../../../chats-panel/~store/models/MessageItem";
import { IChatItem } from "./../../../chats-panel/~store/models/ChatItem";
import i18n from "src/app/shared/localization/i18n";

let connectionNotification: Notification;

var notifyCollection: any = [];
const MAX_ROWS = 4;
const MAX_ROW_LENGTH = 41;

const onNotificationClickHandler = (chatId: number) => {
    var chatItem = document.getElementById("chat-item__chatid-" + chatId);
    if(chatItem) chatItem.click();
    window.focus();
}

export const notifyNewChat = (chat: IChatItem, selectedChat: IChatItem | undefined, operatorId: number) => {
    if(!chat.isClosed && !chat.fromHistory && !chat.fromHistoryAndActive && selectedChat?.chatId !== chat.chatId) {

        push(chat.chatId, i18n.t("notification.newChatRecieved"), i18n.t("notification.chatId") + chat.chatId.toString());
    }
}

export const notifyNewMessage = (message: IMessageItem) => { 
    let text = message.text;
    if(text.length === 0) {
        if (message.fileId?.length > 0) {
            text = message.file?.name || i18n.t("notification.documentText");
        }
        else if (message.imageId?.length > 0) {
            text = i18n.t("notification.photoText");
        }
        else if (message.location) {
            text = i18n.t("notification.locationText");
        }
    }


    push(message.chatId, i18n.t("notification.newMessageInChat") + message.chatId, text);
}

export const clearNotifyHistory = (chatId: number) => {
    const index = notifyCollection.findIndex((x: { id: number; }) => x.id == chatId);
    if (index > -1) {
        notifyCollection[index].notification.close();
        notifyCollection.splice(index, 1);
    }
}

export const notifyConnectionLostError = () => {
    if(connectionNotification) connectionNotification.close();
        connectionNotification = new Notification(i18n.t("notification.connectionError"), {
                body: i18n.t("notification.connectionErrorText")
            });
}

const push = (id: number, header: string, text: string) => {
    var body = "";
    const index = notifyCollection.findIndex((x: { id: number; }) => x.id == id);
    if (index > -1) {
        body = notifyCollection[index].notification.body + "\n";
        notifyCollection[index].notification.close();
    }

    var rows = (body + text).split("\n");
    body = "";

    for (let i = rows.length - 1; i >= 0 && i >= rows.length - MAX_ROWS; --i) {
        body = (rows[i].length > MAX_ROW_LENGTH ? rows[i].substring(0, MAX_ROW_LENGTH - 3) + "..." : rows[i]) + "\n" + body;
    }

    var notification = new Notification( header, {
        body: body.trim()
    });

    console.log(`Notification: chatId ${id}, ${header} ${body}`)

    notification.onclick = () => {
        onNotificationClickHandler(id);
    }

    if (index > -1) {
        notifyCollection[index].notification = notification;
        return;
    }

    notifyCollection.push({id, notification});
}