import React, { useEffect, useState } from "react";
import { getDateTime } from "src/app/shared/helpers/date-helper/DateFormats";
import { ChannelType } from "src/app/contact/~store/models/ChannelType";
import { IChatsItemProps } from "./~types/ChatsItemProps";

import "./ChatsItem.css";
import { Button, Input } from "reactstrap";
import { GetChatsMode } from "src/app/chats/~store/models/enums/GetChatsMode";
import { ChatStatus } from "src/app/chats/~store/models/Chat";
import { CHAT_NAME_MAX_LENGTH } from "src/~store/constants";
import i18n from "src/app/shared/localization/i18n";
import { GET_AVATAR_BY_ID_API_ROUTE } from "../../../../operator/~api/apiRoutes";
import { GET_AVATAR_BY_CONTACT_ID_API_ROUTE } from "../../../../contact/~api/apiRoutes";
import { OperatorType } from "../../../../operator/~store/models/enums/OperatorType";
import { getTime } from "../../../../shared/helpers/date-helper/DateHelper";

const ChatsItem = (props: IChatsItemProps) => {
  const [isChatNameEditing, setChatNameEditing] = useState(false);
  const [editedName, setEditedName] = useState(
    props.chat.messenger.contact.chatName?.length > 0
      ? props.chat.messenger.contact.chatName
      : ""
  );

  useEffect(() => {
    setEditedName(
      props.chat.messenger.contact.chatName?.length > 0
        ? props.chat.messenger.contact.chatName
        : ""
    );
  }, [props.chat.chatId]);

  const { chat } = props;
  const channelLogo = getChatsItemMessengerLogo(
    chat.messenger?.channelType ?? ChannelType.Vk
  );
  const contactAvatar = getContactAvatar(
    chat.messenger.contact?.avatarId ?? null
  );
  const operatorAvatar = getOperatorAvatar(chat.operator?.avatarId ?? null);

  const _getUnreadMessageClassName = (): string => {
    if (chat.isClosed) return "unread-messages-count-closed-chat";
    if (chat.operatorId === props.operatorId)
      return "unread-messages-count-my-chat";
    if (chat.invitedOperatorsId.indexOf(props.operatorId) > -1)
      return "unread-messages-count-withme-chat";
    return "unread-messages-count-withoutme-chat";
  };

  const _setFontWeight = (): string => {
    if (!chat.isNew && chat.isClosed === false)
      return "chat-item-without-message-from-operator";
    return "chat-item-with-message-from-operator";
  };

  const _cancelEditing = () => {
    setChatNameEditing(false);
    setEditedName(
      props.chat.messenger.contact.chatName?.length > 0
        ? props.chat.messenger.contact.chatName
        : ""
    );
  };

  const _saveEditedChatName = () => {
    setChatNameEditing(false);
    props.changeChatContactChatname(
      { ...props.chat.messenger.contact, chatName: editedName },
      props.chat.chatId
    );
  };

  const setFontWeight = _setFontWeight();
  const unreadMessageClassName = _getUnreadMessageClassName();

  const _calculateBackground = () => {
    if (
        !props.chat.firstNotAnsweredMessage ||
        props.chatsMode === GetChatsMode.archive ||
        props.chat.operator?.type === OperatorType.Bot
    )
        return "";

    const secondsClientWaiting =
      (new Date().getTime() -
        new Date(props.chat.firstNotAnsweredMessage.timeSent).getTime()) /
      1000;

      if (secondsClientWaiting < props.SLColorSecondsSettings.SLNoColorSeconds) return "";
      if (secondsClientWaiting > props.SLColorSecondsSettings.SLRedSeconds) return "#ff000080";

        if(secondsClientWaiting <= props.SLColorSecondsSettings.SLYellowSeconds)
        {
            return "#ffffab" + (+(255 - ((secondsClientWaiting - props.SLColorSecondsSettings.SLNoColorSeconds) * (255 / props.SLColorSecondsSettings.SLYellowSeconds))).toFixed()).toString(16);
        }
        
        return "#ff" + (+(255 - ((secondsClientWaiting - props.SLColorSecondsSettings.SLYellowSeconds) * (255 / (props.SLColorSecondsSettings.SLRedSeconds - props.SLColorSecondsSettings.SLYellowSeconds)))).toFixed()).toString(16) + "0080";
    }
    
    return (
        <tr style={{ boxShadow: !_calculateBackground() ? _calculateBackground() : "0 0 4px 4px" + _calculateBackground() }}
            onClick={() => props.onClick(props.chat.chatId)}
            className={"chat-item__row" + (props.selected ? " chat-item__row-selected" : "")}>
            <td className="chat-item__contact-info-cell" colSpan={3}>
                <table>
                    <tbody>
                        <tr>
                            <td className="chat-item__chat-info" colSpan={4}>
                                <span className={"chat-item__chat-id " + setFontWeight}>#{props.chat.chatId}</span>
                                <span className={"chat-item__unread-messages-count " + unreadMessageClassName}>{chat.notAnsweredClientMessagesCount <= 99 ? chat.notAnsweredClientMessagesCount : "99+"}</span>
                                <span className={"chat-item__chat-created-time " + setFontWeight}>{getDateTime(chat.timeCreated)}</span>
                                {props.chat.onPause && props.chatsMode != GetChatsMode.archive && <span className="chat-item__chat-paused">{i18n.t('chats.pause')} {props.pausedTime}</span>}
                            </td>
                        </tr>
                        <tr>
                            <td className="chat-item__contact-info_avatar" rowSpan={2}>
                                <img className="chat-item__contact-avatar" src={contactAvatar}/>
                            </td>
                            <td>
                                {!isChatNameEditing && <div 
                                    className={"chat-item__contact-info_name "+ setFontWeight} 
                                    onClick={() => {
                                        if(props.selected)
                                            setChatNameEditing(true);
                                    }}>
                                        {(chat.messenger.contact.chatName?.length > 0) ? 
                                            (chat.messenger.contact.chatName) :
                                            (chat.messenger.contact.lastName?.length > 0 || chat.messenger.contact.firstName?.length > 0) ?
                                            (chat.messenger.contact.lastName + " " + chat.messenger.contact.firstName) :
                                            (chat.messenger.contact.nickName)
                                        }
                                </div>}
                                {isChatNameEditing && <div 
                                    className="chat-item__contact-info_name-editor"
                                    onBlur={() => _cancelEditing()}>
                                    <Input
                                        className="chat-item__contact-info_name-editor-input"
                                        autoFocus={true}
                                        maxLength={CHAT_NAME_MAX_LENGTH}
                                        placeholder={"enter chat name..."}
                                        onKeyDown={(event) => {
                                            if(event.key === "Escape") {
                                                _cancelEditing();
                                            }
                                            if(event.key === "Enter") {
                                                _saveEditedChatName();
                                            }
                                        }}
                                        onChange={(event) => setEditedName(event.target.value)}
                                        value={editedName}/>
                                    <Button
                                        className="chat-item__contact-info_change-name-btn"
                                        onMouseDown={() => {
                                            _saveEditedChatName()
                                        }}>
                                        ok
                                    </Button>
                                </div>}
                            </td>
                            <td>
                                <div className={"chat-item__operator-info " + setFontWeight}>{!chat.operator?.isDeleted ? chat.operator?.name : "[Removed]"}</div>
                            </td>
                            <td className="chat-item__operator-info_avatar" rowSpan={2}>
                                <img className="chat-item__operator-avatar" src={operatorAvatar}/>
                            </td>
                        </tr>
                        <tr>
                            <td className="chat-item__contact-message-column" colSpan={2}>
                                <div className="chat-item__contact-info_message line-clamp">
                                    {(chat.lastMessage?.text) ?
                                        <span className={setFontWeight}>{props.chatsMode != GetChatsMode.archive && <span className="chat-item-with-message-from-operator-message-time">{getTime(chat.lastMessage.timeSent)}</span>} {(props.operatorName ? (props.operatorName + ": ") : "") + chat.lastMessage.text}</span> 
                                        : ""}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
            {!props.isChatvisible && <td className="chat-item__messenger-info-cell">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <div>
                                    <img
                                        className="chat-item__messenger-img" 
                                        src={channelLogo}/>
                                    <div className="chat-item__channel-name">
                                        <div>{chat.channel?.name}</div>
                                    </div>
                                </div>
                                <div className="chat-item__is-closed-text">
                                    {chat.isClosed ? "Closed" : "Active"}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>}
            {props.chatsMode === GetChatsMode.archive && <td>{getChatStatusString(chat.chatStatsModel?.status)}</td>}
            {!props.isChatvisible && <td>    
            </td>}
        </tr>
    )
}

const getChatStatusString = (status: ChatStatus | undefined) => {
  switch (status) {
    case ChatStatus.Expired:
      return i18n.t("chats.expired");
    case ChatStatus.Finished:
      return i18n.t("chats.finished");
    case ChatStatus.Missed:
      return i18n.t("chats.missed");
    default:
      return "";
  }
};

const getChatsItemMessengerLogo = (channelType: ChannelType) => {
  switch (channelType) {
    case ChannelType.Telegram:
      return require("src/app/shared/images/Chat/telegram.svg");
    case ChannelType.Viber:
      return require("src/app/shared/images/Chat/viber.svg");
    case ChannelType.Vk:
      return require("src/app/shared/images/Chat/vk.svg");
    case ChannelType.Widget:
      return require("src/app/shared/images/Chat/widget.svg");
    case ChannelType.Facebook:
      return require("src/app/shared/images/Chat/facebook.svg");
    case ChannelType.WeChat:
      return require("src/app/shared/images/Chat/wechat.svg");
    case ChannelType.Instagram:
      return require("src/app/shared/images/Chat/instagram.svg");
    case ChannelType.UniformWidget:
      return require("src/app/shared/images/Chat/uniformwidget.svg");
    case ChannelType.EdnaWhatsApp:
      return require("src/app/shared/images/Chat/whatsapp-logo.svg");
    case ChannelType.WhatsApp:
      return require("src/app/shared/images/Chat/whatsapp-logo.svg");
    case ChannelType.VoiceChannel:
      return require("src/app/shared/images/Chat/voice-channel-logo.svg");
  }
};

const userInStorage = sessionStorage.getItem("user");
const customerId = userInStorage === null ? "" : JSON.parse(userInStorage.toString()).customerId;

const getContactAvatar = (avatarId: string | null) => {
  return avatarId === null
      ? require("src/app/shared/images/contact-logo.png")
      : GET_AVATAR_BY_CONTACT_ID_API_ROUTE + avatarId + "&customerId=" + customerId;
};

const getOperatorAvatar = (avatarId: string | null) => {
  return avatarId !== null
      ? GET_AVATAR_BY_ID_API_ROUTE + avatarId + "&customerId=" + customerId
      : require("src/app/shared/images/support.png");
};

export default ChatsItem;
