import {USER_URL_SEGMENT} from "./UserAuthConstants";

export const getUserExternalIdFromUrl = (): string => {
    const pathNames = window.location.pathname.split("/");
    for (let index = 0; index < pathNames.length; ++index) {
        if (pathNames[index] === USER_URL_SEGMENT) {
            return pathNames[index + 1];
        }
    }

    return "";
};

export const buildUrlWithUserSegment = (userExternalId?: string): string => {
    const innerUserExternalId = userExternalId ?? getUserExternalIdFromUrl();
    return `/kb/web/${USER_URL_SEGMENT}/${innerUserExternalId}`;
};
