import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "src/~store/models/ApplicationState";
import { IPermissionsUserListProps } from "./~types/PermissionsUserListProps";

import './PermissionUserList.css';
import { ModalWindow } from "src/app/shared/components/modal-window/ModalWindow";
import { getUsersPermissions, saveUserPermissions,  } from "../../../~store/actions/actions";
import { allPermissionsSelector, allUsersSelector, usersPermissionsSelector,  } from "../../../~store/selector";
import { Checkbox } from "@mui/material";
import { Button } from "reactstrap";
import IPermissionViewModel from "../../../~store/models/PermissionViewModel";
import { useTranslation } from 'react-i18next';
import { IUserPermissions } from "src/app/permission/~store/models/UserPermissions";
import IWriterUser from "src/app/permission/~store/models/IWriterUser";

type ReduxType = ReturnType<typeof mapStateToProps> & IPermissionsUserListProps;

const PermissionUserList = (props: ReduxType) => {

    const { t } = useTranslation();
    const [isEditorOpened, setIsEditorOpened] = useState<boolean>(false);
    const [editingUserPermissions, setEditingUserPermissions] = useState<IUserPermissions>();

    useEffect(() => {
        props.getUsersPermissions();
    }, [])

    const _onBlockClicked = (user: IWriterUser) => {
        const userPermissionsToEdit = props.usersPermissions.find(up => up.user.id === user.id);
        if(userPermissionsToEdit) {
            setIsEditorOpened(true);
            setEditingUserPermissions({...userPermissionsToEdit});
        }
    }

    const _mapUserPermissions = () => {
        return (
            <>
                {props.usersPermissions.map((item) => {
                    return (
                        <div key={item.user.id} onClick={() => {_onBlockClicked(item.user)}} className="permission_block line-clamp">
                            <h6>{item.user.name}</h6>
                            <span className="permission-list">{props.usersPermissions.find(up => up.user.id == item.user.id)
                                ?.permissions.map(p => props.allPermissions.find(ap => ap.name == p)?.description + "; ")}</span>
                        </div>
                    )
                })}
            </>
        )
    }

    const _onPermissionClicked = (value: boolean, permission: IPermissionViewModel) => {
        let permissions = editingUserPermissions?.permissions?.filter(p => p != permission.name);
        if(editingUserPermissions && permissions) {
            if(value) {
                permissions = permissions.concat([permission.name])
            }

            editingUserPermissions.permissions = permissions;
            setEditingUserPermissions({...editingUserPermissions});
        }
    }

    const _onSavePermissionsClicked = () => {
        if(editingUserPermissions) {
            props.saveUserPermissions(editingUserPermissions);
            setIsEditorOpened(false);
        }
    }

    const _mapPermissionEditor = () => {
        if(!editingUserPermissions) return <></>;

        const header = (
            <div>{t('permission.editPermissionsFor') + editingUserPermissions.user.name}</div>
        )

        const body = (
            <div>{props.allPermissions.map((item, index) => {
                const checked = editingUserPermissions.permissions.includes(item.name) || false;
                return (
                    <div key={index}>
                        <span><Checkbox onClick={() => _onPermissionClicked(!checked, item)} checked={checked} /></span><span>{item.description}</span></div>
                );
            })}</div>
        )

        const footer = (
            <div className="permission-dialog-footer-container">
                <Button onClick={_onSavePermissionsClicked}>{t('common.save')}</Button>
                <Button className="permission-dialog-cancel" onClick={() => { setIsEditorOpened(false) }}>{t('common.cancel')}</Button>
            </div>
        )

        return (
            <ModalWindow header={header} body={body} footer={footer} isOpen={isEditorOpened}/>
        )
    }

    const _onUserSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const user = props.allUsers.find(u => u.id === e.currentTarget.value);
        if(user) {
            setEditingUserPermissions({user, permissions: []});
            setIsEditorOpened(true);
        }
    }

    const _mapSelectUsers = (users: IWriterUser[]) => {
        if(users.length > 0) {
            return (
                <select className="permission_user-select" value="" onChange={_onUserSelected}> 
                    <option value="" disabled hidden>{t('permission.selectUser')}</option>
                    {users.map(user => {
                        return (
                            <option key={user.id} value={user.id}>{user.name}</option>
                        )})
                    }
                </select>
            )
        }
        return <></>
    }

    return (
        <div className="permissions_user-container">
            {_mapSelectUsers(props.allUsers.filter(u => !props.usersPermissions.map(i => i.user.id).includes(u.id) ))}
            {_mapUserPermissions()}
            {_mapPermissionEditor()}
        </div>
    )
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        allPermissions: allPermissionsSelector(state),
        allUsers: allUsersSelector(state),
        usersPermissions: usersPermissionsSelector(state)
    };
};

const mapDispatchToProps = (dispatch: any): IPermissionsUserListProps => {
    return {
        getUsersPermissions: () => dispatch(getUsersPermissions()),
        saveUserPermissions: (userPermissions: IUserPermissions) => dispatch(saveUserPermissions(userPermissions))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionUserList);