import { IOperator } from "src/app/operator/~store/models/Operator";
import { ISetting } from "src/app/setting/~store/models/setting";
import { ISearchingTag } from "src/app/shared/components/tag-searching-input/~types/models/SearchingTag";
import { IApiActionResult } from "src/~api/ApiActionResult";
import { fetchGet, fetchGetFile, fetchPost, getApiActionResult } from "src/~api/ApiHelper";
import { IChatStep } from "../~store/models/ChatStep";
import { 
        GET_BOTS_API_ROUTE, 
        GET_CHAT_STEPS_API_ROUTE, 
        GET_REPORT_BY_NAME_API_ROUTE, 
        GET_SETTING_BY_ID_API_ROUTE, 
        SAVE_REPORT_BY_NAME_API_ROUTE,
        SAVE_REPORT_BY_NAME_EXCEL_API_ROUTE
} from "./../~Api/apiRoutes";
import { TagName, TagType } from "src/app/shared/components/tag-searching-input/~types/models/SearchingTag";
import { ConvertFromFilterToUTCTimeInterval } from "src/app/shared/helpers/date-helper/FromFilterToDateHelper";
import i18n from "src/app/shared/localization/i18n";
import { ReportType, reportTypeToString } from "../~store/models/enums/ReportType";


export const getChatStepsAsync = async (selectedBotId: number, tags: ISearchingTag[]): Promise<IApiActionResult<IChatStep[]>> => {
    tags.filter(val => val.tagName === TagName.Date && val.tagType === TagType.ClosingTag && val.value !== i18n.t('common.period')).forEach(el => el.externalValue = ConvertFromFilterToUTCTimeInterval(el.value));
        const fetchPromise = fetchPost(GET_CHAT_STEPS_API_ROUTE, {
                body: JSON.stringify({ 
                        selectedBotId,
                        tags
                })
        });
        
        return await getApiActionResult(fetchPromise, true);
}

export const getBotsAsync = async (): Promise<IApiActionResult<IOperator[]>> => {
        const fetchPromise = fetchGet(GET_BOTS_API_ROUTE);
        
        return await getApiActionResult(fetchPromise, true);
}

export const getSettingByIdAsync = async (settingId: string): Promise<IApiActionResult<ISetting>> => {
        const fetchPromise = fetchGet(GET_SETTING_BY_ID_API_ROUTE + settingId);

        return await getApiActionResult(fetchPromise, true);
}

export const getReportByNameAsync = async (name: string, tags: ISearchingTag[]): Promise<IApiActionResult<string>> => {
        const nowDate = new Date(Date.now());

        tags.filter(val => val.tagName === TagName.Date && val.tagType === TagType.ClosingTag && val.value !== i18n.t('common.period')).forEach(el => el.externalValue = ConvertFromFilterToUTCTimeInterval(el.value));

        const fetchPromise = fetchPost(GET_REPORT_BY_NAME_API_ROUTE, {
                body: JSON.stringify({ 
                        name,
                        tags,
                        count: 100,
                        hourDifference: nowDate.getTimezoneOffset()/-60
                })
        });

        return await getApiActionResult(fetchPromise, true);
}

export const saveReportByNameAsync = async (name: string, reportType: ReportType, tags: ISearchingTag[]): Promise<IApiActionResult<void>> => {
        const nowDate = new Date(Date.now());
        const reportUrl = reportType === ReportType.csv ? SAVE_REPORT_BY_NAME_API_ROUTE : SAVE_REPORT_BY_NAME_EXCEL_API_ROUTE;

        tags.filter(val => val.tagName === TagName.Date && val.tagType === TagType.ClosingTag && val.value !== i18n.t('common.period')).forEach(el => el.externalValue = ConvertFromFilterToUTCTimeInterval(el.value));

        const fetchPromise = fetchGetFile(reportUrl, name + "_" + nowDate.toLocaleString() + "." + reportTypeToString(reportType), {
                body: JSON.stringify({ 
                        name,
                        tags,
                        hourDifference: nowDate.getTimezoneOffset()/-60
                })
        });
        return await getApiActionResult(fetchPromise, false);
    };