import React, { useEffect, useState } from 'react';
import { Button, Table } from 'reactstrap';
import { IAccount } from 'src/app/contact/~store/models/Account';
import { AccountType } from 'src/app/contact/~store/models/AccountType';
import Loader from 'src/app/shared/components/loader/Loader';
import ContactAccountItem from './contact-account-item/ContactAccountItem';
import { showErrorToastr } from 'src/app/shared/helpers/toastr-helper/ToastrHelper';


import './ContactAccountList.css';
import { IContactAccountListProps } from './~types/ContactAccountListProps';
import { useTranslation } from 'react-i18next';

const ContactAccountList = (props: IContactAccountListProps) => {
    const { t } = useTranslation();
    const [isCreating, setCreating] = useState(false);
    const [accounts, setAccounts] = useState(props.contactAccounts);

    useEffect(() =>  {setAccounts(props.contactAccounts)}, [props.contactAccounts.length, props.contactId])

    const _onSaveAccountHandler = (account: IAccount): boolean => {
        if (!account.login || !account.externalId || !account.contactId) {
            showErrorToastr(t('contact.errorSavingAccountWithoutData'));
            setCreating(true);
            return false;
        } else if (/^\s*$/.test(account.externalId) || /^\s*$/.test(account.login)) {
            showErrorToastr(t('contact.errorSavingAccountOnlyWithSpaces'));
            setCreating(true);
            return false;
        } else if (account.externalId.length > 50 || account.login.length > 50) {
            showErrorToastr(t('contact.errorSavingAccountCharactersEqualOrMore50'));
            setCreating(true);
            return false;
        } else if (/\s/.test(account.externalId)) {
            showErrorToastr(t('contact.errorSavingAccountIdWithSpaces'));
            setCreating(true);
            return false;
        }
        else {
            if(account.id === 0) {
                setCreating(false);
            }
            props.saveAccount(account);
            return true;
        }        
    }

    const _onDeleteAccountHandler = (accountId: number): void => {
        if(accountId === 0) {
            let accIndex = accounts.findIndex(a => a.id === accountId);
            accounts.splice(accIndex, 1)
            setAccounts(accounts);
            setCreating(false);
        } else {
            if (window.confirm(t('contact.sureDeleteAccount'))) {
                props.deleteAccount(accountId);
            }            
        }
    }

    return (
        <div className="contact-accounts__container">
            <div className="contact-accounts__header">
                <div className="contact-accounts__header-title">
                    {t('common.accounts')}
                </div>
            </div>
            <Table className="contact-accounts__table">
                <thead
                    className="contact-accounts__btn-header">
                    <tr>
                        <th>                
                            <Button disabled={props.contactId === 0}
                                onClick={() => {
                                    if(!isCreating) {
                                        setAccounts([{id: 0, contactId: props.contactId, type: AccountType.Amo, isSelected: false} as IAccount, ...accounts]);
                                        setCreating(true);
                                    }
                                }}>
                                {t('contact.createNewAccounts')}
                            </Button>
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                {accounts.length > 0 &&     
                    <React.Fragment>
                    <thead>
                        <tr>
                            <th>{t('common.id')}</th>
                            <th>{t('common.login')}</th>
                            <th>{t('common.type')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {accounts.map(val => {

                            return (
                                <ContactAccountItem 
                                    key={val.id} 
                                    isCreating={isCreating}
                                    account={val}
                                    saveAccount={_onSaveAccountHandler}
                                    deleteAccount={_onDeleteAccountHandler} /> 
                            );
                        })}
                    </tbody>
                    </React.Fragment>}
                {accounts.length <= 0 && <tr className="contact-accounts__no-records"><td>{t('contact.noRecords')}</td><td></td><td></td><td></td></tr>}
            </Table>
        </div>
    )
}

export default ContactAccountList;