import React, { useState } from 'react'

import "./Paging.css";

interface IPagingProps {
    selectedPage: number;
    countOfElements: number;
    countOnPages: number;
    onTileClick: (num: number) => void;
}


const Paging = (props: IPagingProps) => {
    const getTiles = () => {
        const tileSkipCount = 2;

        const tilesCount = Math.ceil(props.countOfElements / props.countOnPages) ;

        const leftSkipExists = props.selectedPage > (tileSkipCount + 2);
        const rightSkipExists =  props.selectedPage < (tilesCount - tileSkipCount - 2) ;

        const tilesArray: number[] = [];
        tilesArray.push(1);

        if(leftSkipExists) {
            tilesArray.push(0);
            for(let i = props.selectedPage - tileSkipCount; i < props.selectedPage; ++i){
                tilesArray.push(i);
            }
        }
        else {
            for(let i = 2; i < props.selectedPage; ++i) {
                tilesArray.push(i);
            }
        }

        if(!tilesArray.includes(props.selectedPage)) tilesArray.push(props.selectedPage);

        if(rightSkipExists) {
            for(let i = props.selectedPage + 1; i < props.selectedPage + tileSkipCount + 1; ++i){
                tilesArray.push(i);
            }
            tilesArray.push(0);
        }
        else {
            for(let i = props.selectedPage + 1; i < tilesCount; ++i) {
                tilesArray.push(i);
            }
        }

        if(!tilesArray.includes(tilesCount)) tilesArray.push(tilesCount);


        
        return tilesArray.map((num, inx) => 
            <span 
                className={'paging-tile' + (num === props.selectedPage ? ' selected-tile' : '')} 
                key={inx}
                onClick={() => {if(num > 0) props.onTileClick(num)}}
            >
                {(num > 0) ? num : '...'}
            </span>);
    }

    if(props.countOfElements > props.countOnPages) {
        return (
            <div className='paging-container'>{getTiles()}</div>
        )
    }
    return <></>
}

export default Paging
