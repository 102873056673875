import {Action, Reducer} from "redux";
import {IOperatorsState} from "./state/OperatorsState";
import {initState} from "./state/InitOperatorsState";

import { 
    GET_ALL_OPERATORS_START, 
    GET_ALL_OPERATORS_ERROR, 
    GET_ALL_OPERATORS_SUCCESS,
    CREATE_OPERATOR,
    SAVE_NEW_OPERATOR_SUCCESS,
    DELETE_NEW_OPERATOR_SUCCESS,
    SAVE_OPERATOR_SETTING,
    SAVE_OPERATOR_ERROR,
    TOGGLE_OPERATOR_SORTING
} from "./actions/types";

import {
    GetAllOperatorsSuccessAction, 
    GetAllOperators,
    SaveOperator,
    DeleteOperator,
    CreateOperatorAction,
    ChangeOperatorAvatar,
    SaveOperatorSettingAction,
} from "./actions/interfaces";

import { IOperator } from "./models/Operator";
import { DEFAULT_OPERATOR_AVATAR_ID } from "./models/ModelsConstants";
import { OperatorType } from "./models/enums/OperatorType";
import { SAVE_NEW_CHANNEL_ERROR } from "src/app/channel/~store/actions/types";
import { initNewSetting } from "src/app/setting/~store/state/InitSettingsState";

export type KnownAction = 
    GetAllOperators |
    SaveOperator |
    DeleteOperator |
    CreateOperatorAction |
    ChangeOperatorAvatar | 
    SaveOperatorSettingAction;

export const operatorsReducer: Reducer<IOperatorsState> = (
    state: IOperatorsState = initState,
    incomingAction: Action
): IOperatorsState => {
    const action = incomingAction as KnownAction;

    switch(action.type) {
        case GET_ALL_OPERATORS_START:
            return {
                ...state,
                operatorsList: {
                    ...state.operatorsList,
                    isReceivingOperators: true,
                }
            };

        case GET_ALL_OPERATORS_ERROR:
            return {
                ...state,
                operatorsList: {
                    ...state.operatorsList,
                    isReceivingOperators: false,
                }
            };
        
        case GET_ALL_OPERATORS_SUCCESS:
            const operators: IOperator[] = (action as GetAllOperatorsSuccessAction).payload.operators;
            return {
                ...state,
                operatorsList: {
                    ...state.operatorsList,
                    isReceivingOperators: false,
                    allOperators: operators,
                }
            }

        case SAVE_NEW_OPERATOR_SUCCESS:
        case DELETE_NEW_OPERATOR_SUCCESS:
        case SAVE_OPERATOR_ERROR:
        case SAVE_NEW_CHANNEL_ERROR:
            return {
                ...state,
                operatorsList: {
                    ...state.operatorsList,
                }
            }

        case CREATE_OPERATOR:
            const newOperator: IOperator = {
                operatorId: 0,
                avatarId: DEFAULT_OPERATOR_AVATAR_ID,
                displayName: '',
                name: '',
                login: '',
                isDeleted: false,
                password: '',
                type: OperatorType.Operator,
                description: '',
                enabled: false,
                modifiedOn: new Date(),
                operatorSetting: initNewSetting(),
            };

            return {
                ...state,
                operatorsList: {
                    ...state.operatorsList,
                    allOperators: [newOperator, ...state.operatorsList.allOperators]
                }
            }

        case SAVE_OPERATOR_SETTING:
            const {setting, operator} = (action as SaveOperatorSettingAction).payload;
            const {allOperators} = state.operatorsList;
            allOperators.forEach((op, index) => {
                if(op.operatorId === operator.operatorId) {
                    allOperators[index].operatorSetting.value = setting;
                }
            });
            return {
                ...state,
                operatorsList: {
                    ...state.operatorsList,
                    allOperators
                }
            }

            case TOGGLE_OPERATOR_SORTING: {
                return {
                    ...state,
                operatorsList: {
                    ...state.operatorsList,
                    isSortingBeOperatorName: !state.operatorsList.isSortingBeOperatorName
                    }
                }
            }

        default:
            return state;
    }
}
