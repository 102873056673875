import * as React from "react";

import "./ChannelMain.css";
import ChannelList from "./channel-list/ChannelList";
import i18n from "src/app/shared/localization/i18n";

export default class ChannelMain extends React.Component<{}> {
    public render() {
        return (
            <div className="channel-main">
                <div className="channel-main__header">{i18n.t('common.channels')}</div>
                <ChannelList />
            </div>
        );
    }
}
