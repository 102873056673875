import * as React from "react";
import { showErrorToastr } from "src/app/shared/helpers/toastr-helper/ToastrHelper";
import { MIN_LOGIN_LENGHT, MIN_PASSWORD_LENGHT } from "./constants";
import { ACTIVATE_ACCESS_API_ROUTE } from "./../~api/apiRoutes";
import history from "src/~store/history";

import "./LicenseActivation.css";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

const LicenseActivation = () => {
  const { t } = useTranslation();
  const _formHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const _validatForm = (form: EventTarget & HTMLFormElement): boolean => {
      if (
        (form.querySelector("#password") as HTMLInputElement).value !==
        (form.querySelector("#confirm-password") as HTMLInputElement).value
      ) {
        showErrorToastr(t("license.confirmPasswordNotMatchMessage"));
        return false;
      }
      return true;
    };

    if (_validatForm(e.currentTarget) === true) {
      const data = new FormData(e.currentTarget);

      fetch(ACTIVATE_ACCESS_API_ROUTE, { method: "POST", body: data })
        .then(function (response) {
          if (response.status === 200) return response.text();
          else return t("license.unknownErrorMessage").toString();
        })
        .then(function (text) {
          if (text === "Saved") {
            history.push(`/login/Liсense saved`);
          } else {
            showErrorToastr(text);
          }
        })
        .catch(function () {
          showErrorToastr(t("license.unknownErrorMessage"));
        });
    }
  };

  return (
    <div className="activation-container card">
      <h5>{t("license.title")}</h5>
      <form
        className="activation-container__form"
        onSubmit={_formHandler}
        onInvalid={() => {}}
      >
        <div className="activation-container__form-item">
          <label htmlFor="login">{t("license.loginLabel")}</label>
          <input
            type="text"
            name="login"
            id="login"
            placeholder={t("license.loginPlaceholder")}
            required
            minLength={MIN_LOGIN_LENGHT}
          />
        </div>
        <div className="activation-container__form-item">
          <label htmlFor="password">{t("license.passwordLabel")}</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder={t("license.passwordPlaceholder")}
            required
            minLength={MIN_PASSWORD_LENGHT}
          />
        </div>
        <div className="activation-container__form-item">
          <label htmlFor="confirm-password">
            {t("license.confirmPasswordLabel")}
          </label>
          <input
            type="password"
            id="confirm-password"
            placeholder={t("license.confirmPasswordLabel")}
            required
          />
        </div>
        <div className="activation-container__form-item">
          <label htmlFor="license">{t("license.licenseTitle")}</label>
          <input type="text" disabled style={{ opacity: 0 }} />
        </div>
        <div className="activation-container__form-item">
          <textarea
            name="license"
            id="license"
            cols={30}
            rows={10}
            style={{ resize: "none" }}
            required
          ></textarea>
        </div>
        <div className="activation-container__form-item">
          <Button type="submit">{t('license.submitButton')}</Button>
        </div>
      </form>
    </div>
  );
};

export default LicenseActivation;
