import {AppThunkAction} from "src/~store/models/AppThunkAction";
import { 
    GetAllChatsAction,
    GetContactChatsLazyAction, 
    GetChatByIdAction,
    SetSearchStringToChatsListAction,
    SetSearchStringToChatsInfoAction,
    SetFirstElementIdAction,
    SetSelectedMessageAction,
    ToggleMessageEditingAction,
    EditMessageAction,
    UpdateChatsAction,
    AddNewChatToStateAction,
    GetChatsModeChangedAction,
    ChangeChatContactChatnameAction,
    SearchChatsAction,
    GetChatTagsAction,
    GetChatTagValuesAction,
    GetSLColorSecondsSettigsAction,
    ToggleAutoUpdateChatsAction
} from "./interfaces";
import { 
    GET_ALL_CHATS_START, 
    GET_ALL_CHATS_SUCCESS, 
    GET_ALL_CHATS_ERROR,
    GET_CONTACT_CHATS_LAZY_START,
    GET_CONTACT_CHATS_LAZY_ERROR,
    GET_CONTACT_CHATS_LAZY_SUCCESS,
    GET_CHAT_BY_ID_START,
    GET_CHAT_BY_ID_SUCCESS,
    GET_CHAT_BY_ID_ERROR,
    SET_SEARCH_STRING_TO_CHATS_LIST_SUCCESS,
    SET_SEARCH_STRING_TO_CHATS_INFO_SUCCESS,
    OPEN_CHAT_BY_ID_START,
    OPEN_CHAT_BY_ID_ERROR,
    OPEN_CHAT_BY_ID_SUCCESS,
    SET_FIRST_ELEMENT_ID,
    SET_SELECTED_MESSAGE,
    TOGGLE_MESSAGE_EDITING,
    EDIT_MESSAGE_START,
    EDIT_MESSAGE_SUCCESS,
    EDIT_MESSAGE_ERROR,
    UPDATE_CHATS,
    ADD_NEW_CHAT_TO_CHATPANEL_STATE_SUCCESS,
    GET_CHATS_MODE_CHANGE_SUCCESS,
    GET_CHATS_MODE_CHANGE_START,
    UPDATE_CHATS_CHANNELS,
    UPDATE_CHATS_OPERATORS,
    CHANGE_CHAT_CONTACT_CHATNAME_SUCCESS,
    SEARCH_CHATS,
    GET_TAG_NAMES,
    GET_TAG_VALUES,
    TOGGLE_AUTO_UPDATE_CHATS,
    GET_SL_COLOR_SECONDS_SETTINGS_SUCCESS
} from "./types";
import { 
    getChatsAsync, 
    getMessagesByChatIdAsync, 
    getContactChatsLazyAsync, 
    getChatByIdAsync,
    getMessengerByIdAsync,
    editMessageAsync,
    onChatItemClickAsync,
    getTagNames,
    getTagValuesByTagName,
    saveTagsAsync
} from "../../~api/actions";
import history from "src/~store/history";
import { getChannelByIdAsync } from "src/app/channel/~api/actions";
import { getOperatorByIdAsync } from "src/app/operator/~api/actions";
import { IChat } from "../models/Chat";
import { IMessenger } from "src/app/contact/~store/models/Messenger";
import { IOperator } from "src/app/operator/~store/models/Operator";
import { showSuccessToastr, showErrorToastr, showWarningToastr } from "src/app/shared/helpers/toastr-helper/ToastrHelper";
import { IMessage } from "../models/Message";
import { ISLColorSecondsSettigs } from "../models/SLColorSecondsSettings";
import { GetChatsMode } from "../models/enums/GetChatsMode";
import { IChannel } from "src/app/channel/~store/models/Channel";
import { getDateTime } from "src/app/shared/helpers/date-helper/DateFormats";
import { IContact } from "src/app/contact/~store/models/Contact";
import { saveContactAsync } from "src/app/contact/~api/actions";
import { REQUESTED_NUMBER_OF_CHATS } from "../../chats-main/chats-list/ChatsListConstants";
import { ISearchingTag } from "src/app/shared/components/tag-searching-input/~types/models/SearchingTag";
import { IChatItem } from "src/app/chats-panel/~store/models/ChatItem";
import i18n from "src/app/shared/localization/i18n";
import { ITagModel } from "src/app/chat/specify-chat-close-reason-dialog/~store/models/TagModel";
import { getSettingsByIdAsync } from "src/app/chats-panel/~api/actions";
import { GetChatMessagesAction } from "src/app/chats-panel/~store/actions/interfaces";
import { loadChatToWidget } from "src/app/chats-panel/~store/actions/actions";

export const getChats = (
    skip: number, 
    count: number,
    searchString: string,
    tags: ISearchingTag[],
    getChatsMode: GetChatsMode
): AppThunkAction<GetAllChatsAction> => async dispatch => {
    dispatch({type: GET_ALL_CHATS_START});

    const result = await getChatsAsync(skip, count, searchString, tags, getChatsMode);
    if (result.isSuccess) {

        const chats = result.value ?? [];
        dispatch({type: GET_ALL_CHATS_SUCCESS, payload: {chats, skipNumber: skip}});
           
    } else {
        dispatch({type: GET_ALL_CHATS_ERROR});
        if(result.statusCode === 401)
            history.push('/login');
    }
};

export const getContactChatsLazy = (
    startChatId: number, 
    beforeCount: number, 
    afterCount: number,
    searchString: string,
    isLoadFromStart?: boolean,
): AppThunkAction<GetContactChatsLazyAction> => async dispatch => {
    dispatch({type: GET_CONTACT_CHATS_LAZY_START});
    const result = await getContactChatsLazyAsync(startChatId, beforeCount, afterCount, searchString);
    
    if(result.isSuccess) {
        const chats = result.value ?? [];
        for(const ch of chats) {
            const messagesResult = await getMessagesByChatIdAsync(ch.chatId);
            ch.messages = messagesResult.isSuccess ? messagesResult.value ?? [] : [];
            for(const msg of ch.messages) {
                msg.timeSent = new Date(getDateTime(msg.timeSent));
                msg.editTimestamp = msg.editTimestamp ? new Date(getDateTime(msg.editTimestamp)) : undefined;
            }
            
            const getMessengerResult = await getMessengerByIdAsync(ch.messengerId);
            ch.messenger = getMessengerResult.isSuccess ? 
                getMessengerResult.value ?? {} as IMessenger : 
                {} as IMessenger;
        }
        dispatch({
            type: GET_CONTACT_CHATS_LAZY_SUCCESS, 
            payload: {
                contactChats: chats,
                isBefore: beforeCount > 0 ? true : false,
                isSetSearchString: 
                    (isLoadFromStart === undefined || isLoadFromStart === false) ?
                    false :
                    true}});
    } else {
        dispatch({type: GET_CONTACT_CHATS_LAZY_ERROR});
        if(result.statusCode === 401)
            history.push("/login");
    }
}


export const getSLColorSecondsSettigs = (): AppThunkAction<GetSLColorSecondsSettigsAction> => async dispatch => {
    const result = await getSettingsByIdAsync("ChatOptions");

    if(result.isSuccess && result.value) {
        const settings: ISLColorSecondsSettigs = JSON.parse(result.value.value);

        dispatch({type: GET_SL_COLOR_SECONDS_SETTINGS_SUCCESS, payload: {settings: settings}});
    }
}


export const setSearchStringToChatsList = (
    searchString: string
): AppThunkAction<SetSearchStringToChatsListAction> => async (dispatch, getState) => {
    dispatch({ type: SET_SEARCH_STRING_TO_CHATS_LIST_SUCCESS, payload: {searchString} });
    getChats(0, REQUESTED_NUMBER_OF_CHATS, searchString, getState().chatsState.chatsList.tags, getState().chatsState.chatsList.getChatsMode)(dispatch, getState);
}

export const searchChats = (
    tags: ISearchingTag[],
): AppThunkAction<SearchChatsAction> => async (dispatch, getState) => {
    dispatch({ type: SEARCH_CHATS, payload: {tags} });
    getChats(0, REQUESTED_NUMBER_OF_CHATS, getState().contactState.contactList.searchString, tags, getState().chatsState.chatsList.getChatsMode)(dispatch, getState);
}

export const setSearchStringToChatsInfo = (
    searchString: string,
    chatId: number,
): AppThunkAction<SetSearchStringToChatsInfoAction> => async (dispatch, getState) => {
    dispatch({ type: SET_SEARCH_STRING_TO_CHATS_INFO_SUCCESS, payload: { searchString } });

    const countOfChats = searchString != '' ?
        getState().chatsState.contactChatsInfo.contactChats.length :
        1;
    const startChatId = getState().chatsState.contactChatsInfo.contactChats[0] !== undefined ?
        getState().chatsState.contactChatsInfo.contactChats[0].chatId :
        chatId;
    getContactChatsLazy(
        startChatId,
        0,
        countOfChats,
        searchString,
        true)
        (dispatch, getState);
}

export const setFirstElementId = (
    id: string
): AppThunkAction<SetFirstElementIdAction> => async dispatch => {
    dispatch({type: SET_FIRST_ELEMENT_ID, payload: { id } });
}

export const setSelectedMessage = (
    message: IMessage | undefined
): AppThunkAction<SetSelectedMessageAction> => async dispatch => {
    dispatch({type: SET_SELECTED_MESSAGE, payload: { message }})
}

export const toggleMessageEditing = (): AppThunkAction<ToggleMessageEditingAction> => async dispatch => {
    dispatch({ type: TOGGLE_MESSAGE_EDITING });
}

export const editMessage = (
    editedMessage: IMessage
): AppThunkAction<EditMessageAction> => async (dispatch, getState) => {
    dispatch({ type: EDIT_MESSAGE_START });
    const result = await editMessageAsync(editedMessage.messageId, editedMessage.text);

    if(result.isSuccess) {
        dispatch({ type: EDIT_MESSAGE_SUCCESS });
        showSuccessToastr(i18n.t('chats.editMessageSuccessMessage'));
    } else {
        dispatch({ type: EDIT_MESSAGE_ERROR });
        showErrorToastr(i18n.t('chats.editMessageErrorMessage'));
    }

    getContactChatsLazy(
        getState().chatsState.contactChatsInfo.contactChats[0].chatId,
        0,
        getState().chatsState.contactChatsInfo.contactChats.length,
        getState().chatsState.contactChatsInfo.searchString,
        true)
        (dispatch, getState);
}

export const onChatItemClick = (chatId: number) : AppThunkAction<GetChatMessagesAction> => async (dispatch, getState) => {
    loadChatToWidget(chatId)(dispatch, getState);
}

export const updateChats = (chats: IChat[]): AppThunkAction<UpdateChatsAction> => async (dispatch, getState) => {

    dispatch({type: UPDATE_CHATS, payload: {
        chats, 
        operatorId: getState().chatItemsState.operatorId, 
        permissions: getState().userContextState.permissions.permissionList 
    }});
}

export const addNewChatToState = (chat: IChat): AppThunkAction<AddNewChatToStateAction> => async (dispatch, getState) => {
    const operatorId = getState().chatItemsState.operatorId;
    if(getState().chatsState.chatsList.getChatsMode === GetChatsMode.my && operatorId !== chat.operatorId) return;
    if (getState().chatsState.chatsList.getChatsMode === GetChatsMode.withMe && chat.invitedOperatorsId.indexOf(operatorId) === -1) return;
    if (getState().chatsState.chatsList.getChatsMode === GetChatsMode.archive) return;
    
    dispatch({type: ADD_NEW_CHAT_TO_CHATPANEL_STATE_SUCCESS, payload: {chat}})
}

export const onGetChatsModeChanged = (mode: GetChatsMode, needGetChats: boolean = false): AppThunkAction<GetChatsModeChangedAction> => async (dispatch, getState) => {
    dispatch({type: GET_CHATS_MODE_CHANGE_START});
    if(needGetChats) {
        getChats(0, REQUESTED_NUMBER_OF_CHATS, "", [], mode)(dispatch, getState);
    }
    dispatch({type: GET_CHATS_MODE_CHANGE_SUCCESS, payload: {mode}});  
}

export const changeChatContactChatName = (contact: IContact, chatId: number): AppThunkAction<ChangeChatContactChatnameAction> => async (dispatch) => {
    const result = await saveContactAsync(contact);

    if(result.isSuccess) {
        dispatch({ type: CHANGE_CHAT_CONTACT_CHATNAME_SUCCESS, payload: { contact, chatId } });
    }
}

export const selectChat = (isNext: boolean, chats: IChat[], selectedChatItem: IChatItem | undefined): AppThunkAction<GetChatMessagesAction> => async (dispatch, getState) => {
    if(selectedChatItem === undefined) {
        return;
    }

    const selectedChat = chats.find(chat => chat.chatId === selectedChatItem.chatId);
    if(selectedChat === undefined) {
        const firstChat = chats[0];
        if(firstChat !== undefined) {
            onChatItemClick(firstChat.chatId)(dispatch, getState);
        } else {
            return;
        }
    } else {
        const selectedChatIndex = chats.indexOf(selectedChat);
        const nextChatIndex = isNext ? selectedChatIndex + 1 : selectedChatIndex - 1;
        if(nextChatIndex < 0 || nextChatIndex > chats.length) {
            return;
        }
        
        const nextChat = chats[nextChatIndex];
        if(nextChat === undefined) {
            const firstChat = chats[0];
            if(firstChat !== undefined) {
                onChatItemClick(firstChat.chatId)(dispatch, getState);
            }
            return;
        }

        onChatItemClick(nextChat.chatId)(dispatch, getState);
    }
}

export const getExistsTagNames = () : AppThunkAction<GetChatTagsAction> => async (dispatch) => {
    const result = await getTagNames();

    if(result.isSuccess) {
        const chatTags = result.value ?? [];
        dispatch({ type: GET_TAG_NAMES, payload: {chatTags}});
    }
}

export const getExistsTagValuesByTagName = (name: string) : AppThunkAction<GetChatTagValuesAction> =>  async (dispatch) => {
    const result = await getTagValuesByTagName(name);

    if(result.isSuccess) {
        const chatTagValues = result.value ?? [];
        dispatch({ type: GET_TAG_VALUES, payload: {chatTagValues}});
    }
}

export const saveTags = (tags: ITagModel[]) : AppThunkAction<void> => async () => {
    const result = await saveTagsAsync(tags);
}

export const toggleAutoUpdateChats = () : AppThunkAction<ToggleAutoUpdateChatsAction> =>  async (dispatch) => {
    dispatch({type: TOGGLE_AUTO_UPDATE_CHATS});
}