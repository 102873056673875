import * as React from "react";
import { IChangableImageProps } from "./~types/ChangableImageProps";
import { IChangableImageState } from "./~types/ChangableImageState";
import { Avatar } from "@mui/material";
import ContactIcon from "@mui/icons-material/AccountCircle";

import "./ChangableImage.css";

export default class ChangableImage extends React.Component<
  IChangableImageProps,
  IChangableImageState
> {
  public readonly state: IChangableImageState = {
    filePicker: React.createRef<HTMLInputElement>(),
  };

  public render() {
    return (
      <React.Fragment>
        <input
          accept="image/*"
          ref={this.state.filePicker}
          multiple={false}
          className="invisible-file-input"
          type="file"
          onChange={this._onImagePickerChangeHandler}
        />
        <Avatar
          className={this.props.className}
          src={this.props.src}
          onClick={this._changeImage}
        >
          <ContactIcon style={{ color: "#26a69a" }} />
        </Avatar>
      </React.Fragment>
    );
  }
  private _changeImage = (): void => {
    this.state.filePicker.current?.click();
  };

  private _onImagePickerChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files: FileList | null = event.currentTarget.files;
    if (files != null) {
      if (files.length < 1) {
        return;
      }

      const file: File = files.item(0) as File;
      this.props.onImageChange?.(file);
    }
    return;
  };
}
