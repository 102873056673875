import React, {useEffect} from "react";
import {ICustomerJourneyExternalProps, ICustomerJourneyProps} from "./CustomerJourneyProps";

import "./CustomerJourney.css";
import { IApplicationState } from "src/~store/models/ApplicationState";
import { connect } from "react-redux";
import { changeSelectedBot, getBots, getChatSteps } from "src/app/analytics/~store/actions/actions";
import { botsSelector, chatStepGroupsSelector, selectedBotIdSelector } from "../../../~store/selector";
import { IGroupedStep } from "src/app/analytics/~store/models/ChatStep";
import { Input } from "reactstrap";
import { ISearchingTag } from "src/app/shared/components/tag-searching-input/~types/models/SearchingTag";
import { useTranslation } from 'react-i18next';


type ReduxType = ReturnType<typeof mapStateToProps> & ICustomerJourneyProps;

const CustomerJourney = (props: ReduxType) => {
    const { t } = useTranslation();
    useEffect(() => {
        if(props.selectedBotId) {
            props.getChatSteps(props.selectedBotId, props.tags);
        }
        else {
            props.getBots();
        }
    }, []);

    useEffect(() => {
        if(props.selectedBotId) {
            props.getChatSteps(props.selectedBotId, props.tags);
        }
    }, [props.selectedBotId, props.tags])

    const _onFromToStepClick = (key: string) => {
        document.querySelectorAll(".chat-step-line__key").forEach((value) => {
            value.classList.remove("highlight");
            if(value.innerHTML === key) {
                value.scrollIntoView({block: "center", behavior: "smooth"});
                value.classList.add("highlight");
            }
        });
    }

    const _mapChatStepLine = (chatStepLine: IGroupedStep) => {
        return (
            <tr key={JSON.stringify(chatStepLine.key)}>
                <td>{chatStepLine.froms.map((item, index) => (
                    <div key={index} className="chat-step-line__subline" onClick={() => _onFromToStepClick(item.key)}>
                        <div className="chat-step-line__procentage">[{item.count}]/[{item.procent.toFixed(2)}%]</div>
                        <div key={item.key}>{item.key || "[START]"} </div>
                    </div>
                    
                ))}</td>
                <td><div className="chat-step-line__procentage">[{chatStepLine.count}]/[{chatStepLine.procent.toFixed(2)}%]</div>
                    <div className="chat-step-line__key">{chatStepLine.key.current}</div> 
                    <div>{JSON.parse(chatStepLine.key.buttons)?.map((button: any, index: number) => (
                        <div key={index} className="chat-step-line__key-button">{button}</div>
                    ))}</div>
                </td>
                <td>{chatStepLine.tos.map((item, index) => (
                    <div key={index} className="chat-step-line__subline" onClick={() => _onFromToStepClick(item.key)}>
                        <div className="chat-step-line__procentage">[{item.count}]/[{item.procent.toFixed(2)}%]</div>
                        <div key={item.key}>{item.key || "[FINISH]"}</div>
                    </div>
                    
                ))}</td>
            </tr>
        )
    }

    const _mapBotSwitch = () => {
        return (
            <div className="bot-switch-wrapper">
                <Input
                    type="select"
                    className="bot-switch-select"
                    value={props.selectedBotId}
                    onChange={(e) => {props.changeSelectedBot(+e.currentTarget.value)}}
                    >
                        {props.bots.map(item => (
                            <option key={item.operatorId} value={item.operatorId}>{item.name}</option>
                        ))}
                </Input>
            </div>
        );
    }

    return (
        <div className="customer-journey-container">
            {props.selectedBotId && _mapBotSwitch()}
            <table className="customer-journey-table card-table">
                <thead>
                    <tr>
                        <th>{t("analytics.comeFrom")}</th><th>{t("analytics.dialog")}</th><th>{t("analytics.wentTo")}</th>
                    </tr>
                </thead>
                <tbody>
                {props.chatStepGroups.map((item) => (
                    _mapChatStepLine(item)
                ))}
                </tbody>
            </table>
        </div>
        
    );
}

const mapStateToProps = (state: IApplicationState, externalProps: ICustomerJourneyExternalProps) => {
    return {
        chatStepGroups: chatStepGroupsSelector(state),
        bots: botsSelector(state),
        selectedBotId: selectedBotIdSelector(state),
        tags: externalProps.tags
    };
}

const mapDispatchToProps = (dispatch: any): ICustomerJourneyProps => {
    return {
        getChatSteps: (selectedBotId: number, tags: ISearchingTag[]) => dispatch(getChatSteps(selectedBotId, tags)),
        getBots: () => dispatch(getBots()),
        changeSelectedBot: (selectedBotId: number) => dispatch(changeSelectedBot(selectedBotId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerJourney);