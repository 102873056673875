import React, { useEffect, useState } from 'react';
import { ITransferChatOperatorItemProps } from "./~types/TransferChatOperatorItemProps";

import './TransferChatOperatorItem.css';
import { OperatorPriorityType } from 'src/app/chats-panel/~store/models/ChannelItem';
import { OperatorType } from 'src/app/chats-panel/~store/models/OperatorItem';
import { OperatorStatusType } from 'src/app/chats-panel/~store/state/ChatItemsState';
import { GET_PICTURE_BY_ID_API_ROUTE } from 'src/app/chats-panel/~api/apiRoutes';
import { useTranslation } from 'react-i18next';
import { FIRST_PAUSE_CATEGORY_ID, LAST_PAUSE_CATEGORY_ID } from 'src/app/chats-panel/~store/actions/ChatConstants';

const TransferChatOperatorItem = (props: ITransferChatOperatorItemProps) => {
    const { t } = useTranslation();

    const userInStorage = sessionStorage.getItem("user");
    const customerId = userInStorage === null ? "" : JSON.parse(userInStorage.toString()).customerId;
    
    const operatorAvatar = props.operator.avatarId === null ?
        require('src/app/shared/images/help.png') :
        GET_PICTURE_BY_ID_API_ROUTE + props.operator.avatarId + "&customerId=" + customerId + "&cahceBypass=" + Math.random();;

    let operatorStatusText = t('common.offline');
    let operatorStatusValue = 'offline';

    let operatorStatusType = Number.parseInt(props.operatorStatus.toString());

    switch(operatorStatusType) {
        case OperatorStatusType.Offline.valueOf(): {
            operatorStatusText = t('common.offline');
            operatorStatusValue = 'offline';
            break;
        }
        case OperatorStatusType.Online.valueOf(): {
            operatorStatusText = t('common.online');
            operatorStatusValue = 'online';
            break;
        }
        case OperatorStatusType.Pause.valueOf(): {
            operatorStatusText = t('common.pause');
            operatorStatusValue = 'pause';
            break;
        }
        default: {
            if(props.operatorStatus >= FIRST_PAUSE_CATEGORY_ID || props.operatorStatus <= LAST_PAUSE_CATEGORY_ID) {
                operatorStatusText = props.pauseCategories[props.operatorStatus - FIRST_PAUSE_CATEGORY_ID];
                operatorStatusValue = 'pause';
            }
            
        }
    }
    
    return (
        <div className="transfer-chat__operator-item"
            onClick={() => props.onSelectOperator(props.operator.operatorId)}>
            <div className="transfer-chat__operator-item--avatar-container">
                <img
                    className={"operator-item__operator-avatar" + `  ${operatorStatusValue}`}
                    src={operatorAvatar}/>
            </div>
            <div className="operator-item__operator-info">
                <p>{props.operator.name}</p>
                <p>{t('common.chats') + ': ' + props.operator.openedChatsCount}</p>
                <p className={`operator-item__tier${props.tier ?? '-supervisor'}`}>
                    {props.operator.type !== OperatorType.Supervisor ? `tier ${props.tier}` : 'supervisor'}
                </p>
                <p>{t('common.status') + ": "}<span className={`operator-item__status-${operatorStatusValue}`}>{operatorStatusText}</span></p>
            </div>
            {props.selected && <i className={"material-icons " + "operator-item__selected-icon"}>done</i>}
        </div>
    )
}

export default TransferChatOperatorItem;
