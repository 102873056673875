import * as React from "react";

import "./ContactMain.css";
import ContactList from "./contact-list/ContactList";
import { useTranslation } from 'react-i18next';

const ContactMain = () => {
    const { t } = useTranslation();
    return (
        <div className="contact-main">
            <div className="contact-main__header">{t('common.contacts')}</div>
            <ContactList />
        </div>
    );
}

export default ContactMain;
