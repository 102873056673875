import { PermissionType } from "../models/PermissionType";
import { GetPermissionAction, GET_PERMISSIONS_ERROR, GET_PERMISSIONS_START, GET_PERMISSIONS_SUCCESS } from "./interfaces";
import { AppThunkAction } from "./../../../../../~store/models/AppThunkAction";
import { IApiActionResult } from "src/~api/ApiActionResult";
import { GET_OPERATOR_PERMISSIONS_API_ROUTE } from "src/app/operator/~api/apiRoutes";
import { fetchGet, getApiActionResult } from "src/~api/ApiHelper";

export const getPermissions = (): AppThunkAction<GetPermissionAction> => async dispatch => {
    dispatch({type: GET_PERMISSIONS_START});
    const result = await getPermissionsAsync();

    if(result.isSuccess) {
        const permissions = result.value ? result.value : [];
        console.log(permissions);
        dispatch({type: GET_PERMISSIONS_SUCCESS, payload: { permissions }});
    }
    else {
        dispatch({type: GET_PERMISSIONS_ERROR});
    }
}

const getPermissionsAsync = async (): Promise<IApiActionResult<PermissionType[]>> => {
    const fetchPromise = fetchGet(GET_OPERATOR_PERMISSIONS_API_ROUTE);

    return await getApiActionResult(fetchPromise, true);
}