import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import Loader from 'src/app/shared/components/loader/Loader';
import InviteOperatorItem from './invite-operator-item/InviteOperatorItem';
import SearchingInput from 'src/app/shared/components/searching-input/SearchingInput';
import { IInviteOperatorProps } from './~types/InviteOperatorsProps';
import { IChannelOperator } from 'src/app/channel/~store/models/ChannelOperator';
import { IOperatorItem } from 'src/app/chats-panel/~store/models/OperatorItem';
import { OperatorStatusType } from 'src/app/chats-panel/~store/state/ChatItemsState';
import { useTranslation } from 'react-i18next';

const InviteOperator = (props: IInviteOperatorProps) => {
    const { t } = useTranslation();
    const [checkedOperators, setCheckedOperators] = useState<IOperatorItem[]>([]);
    const [needUpdate, setNeedUpdate] = useState(false);
    const [inviteOperatorFilter, setInviteOperatorFilter] = useState('');
    const [channelOperators, setChannelOperators] = useState([] as IChannelOperator[]);
    useEffect(() => {
        props.getOnlineOperatorsToInvite();
        setCheckedOperators([]);
    }, []);

    useEffect(() => {}, [needUpdate]);

    useEffect(() => {
        if(props.onInviteButtonClick.length > 0)
            props.getChannelOperators(props.onlineOperatorsToInvite?.map(val => val.operatorId), props.channelId)
                .then((val) => setChannelOperators(val ?? []))
    }, [props.onlineOperatorsToInvite]);
    props.onlineOperatorsToInvite?.sort((a, b) => {
        const tierA = channelOperators.find(val => val.operatorId === a.operatorId)?.priorityType ?? 6;
        const tierB = channelOperators.find(val => val.operatorId === b.operatorId)?.priorityType ?? 6;
        const nameA = a.name.toLocaleUpperCase();
        const nameB = b.name.toLocaleUpperCase();
        const tierDifference = tierA - tierB;
        return tierDifference === 0 ? nameA > nameB ? 1 : -1 : tierDifference;
    })

    const onChecked = (operator: IOperatorItem) => {
        if(checkedOperators.includes(operator)) {
            checkedOperators.splice(checkedOperators.indexOf(operator), 1);
        }
        else {
            checkedOperators.push(operator);
        }
        setCheckedOperators(checkedOperators);
        setNeedUpdate(!needUpdate);
    }

    const _mapOnlineOperators = () => {
        return (
            props.onlineOperatorsToInvite?.map((operator, index) => {
                if(operator.name.includes(inviteOperatorFilter)) {
                    var index = props.operatorsStatusList.findIndex(o => o.operatorId === operator.operatorId);
                    return (
                        <InviteOperatorItem 
                            key={index} 
                            isChecked={checkedOperators.includes(operator)} 
                            operator={operator}
                            tier={channelOperators.find(chOp => operator.operatorId === chOp.operatorId)?.priorityType}
                            operatorStatus={(index > -1 ? props.operatorsStatusList[index].status : OperatorStatusType.Offline)}
                            onChecked={onChecked} 
                            pauseCategories={props.pauseCategories}/>
                    )
                }
            })
        )
    }

    const onInviteButtonClick = () => {
        props.onCloseDialog()
        if(checkedOperators.length > 0)
            props.onInviteButtonClick(checkedOperators);
    }

    return (
        <>
            <SearchingInput
                placeholder={ t('common.search')}
                value={inviteOperatorFilter}
                onSearch={(searchString) => {
                setInviteOperatorFilter(searchString);
                }}
                className="transfer-invite-dialog__search"/>
            
            <div className={"transfer-invite-dialog__body"}>
                {props.isReceivingOnlineOperatorsToInvite && <Loader/>}
                {!props.isReceivingOnlineOperatorsToInvite && _mapOnlineOperators()}
            </div>
            <div className={"transfer-invite-dialog__footer"}>
                <hr /> 
                <Button 
                    className="transfer-invite-dialog__action-btn" 
                    onClick={() => {
                        onInviteButtonClick()}
                }>
                    { t('chat.invite')}
                </Button>
            </div>
        </>
    )
}



export default InviteOperator;