import { IApiActionResult } from "src/~api/ApiActionResult";
import { getApiActionResult, fetchGet, fetchPost } from "src/~api/ApiHelper";
import { ISetting } from "../~store/models/setting";
import { DELETE_SETTING_API_ROUTE, GET_SETTINGS_API_ROUTE, SAVE_SETTING_API_ROUTE } from "./apiRoutes"

export const getAllSettingsAsync = async (): Promise<IApiActionResult<ISetting[]>> => {
    const fetchPromise = fetchGet(GET_SETTINGS_API_ROUTE);
    return await getApiActionResult(fetchPromise, true);
};

export const saveSettingAsync = async (setting: ISetting): Promise<IApiActionResult<ISetting>> => {
    const fetchPromise = fetchPost(SAVE_SETTING_API_ROUTE, {
        body: JSON.stringify({
            setting
        }),
    });
    return await getApiActionResult(fetchPromise, false);
};

export const deleteSettingsAsync = async (settingId: string): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(DELETE_SETTING_API_ROUTE + settingId);
    return await getApiActionResult(fetchPromise, false);
};