import { TopicPairContentType } from 'src/app/nlp/~store/models/enums/TopicPairContentType';
import { IFileItem } from './FileItem'
import { IOperatorItem } from './OperatorItem';

export interface ILocation {
    longitude: number,
    latitude: number
}

export interface IMessageItem {
    messageId: number,
    chatId: number,
    text: string,
    receivedTimestamp: Date, 
    readTimestamp: Date, 
    messageType: MessageType,
    timeSent: string,
    imageId: string,
    operatorName: string,
    operatorId?: number,
    operatorModel: IOperatorItem,
    location?: ILocation,
    isEdited: boolean,
    fileId: string,
    file: IFileItem,
    contentType: TopicPairContentType
}

export enum MessageType {
    operatorMessage = 1,
    clientMessage = 2,
    systemMessage = 3,
    clientSystemMessage = 4,
    previewMessage = 5,
    chatInfoMessage = -1
}

export interface ISendingMessage {
    message: IMessageItem,
    failed: boolean
}
  