import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { ModalWindow } from "src/app/shared/components/modal-window/ModalWindow";
import i18n from "src/app/shared/localization/i18n";

import "./SynonymsDialog.css";

export interface ISynonymsDialogProps {
  onClose: () => void;
  saveSynonyms: (synonymsText: string, onSaved: () => void) => void;
  getSynonyms: (onGotSynonyms: (synonymsText: string) => void) => void;
}

const SynonymsDialog = (props: ISynonymsDialogProps) => {
  const [editingSynonyms, setEditingSynonyms] = useState<string>("");

  useEffect(() => {
    props.getSynonyms(setEditingSynonyms);
  }, []);

  const _mapHeader = () => {
    return (
      <div>
        <h3>{i18n.t("nlp.synonyms")}</h3>
        <button
          className="topic-editor-synonyms-close red btn btn-secondary"
          onClick={props.onClose}
        >
          <i className="material-icons">close</i>
        </button>
      </div>
    );
  };

  const _onSynonymsTextChanged = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    setEditingSynonyms(event.currentTarget.value);
  };

  const _onSaveSynonyms = () => {
    if (_validateSynonyms()) {
      props.saveSynonyms(editingSynonyms, props.onClose);
    }
  };

  const _validateSynonyms = (): boolean => {
    const rows = editingSynonyms.trim().split(/\r?\n/);

        for(let i = 0; i < rows.length; ++i) {
            if(rows[i].length === 0) continue;
            if(/[^0-9a-zA-Zа-яА-ЯёЁ|: ]/.test(rows[i])) {
                alert(i18n.t("nlp.synonyms_wrongCharacter") + rows[i]);
                return false;
            }
            if(rows[i].indexOf(':') === -1) {
                alert(i18n.t('nlp.synonyms_needColon') + rows[i]);
                return false;
            }
            if(rows[i].split(':').length > 2) {
                alert(i18n.t('nlp.synonyms_fewColons') + rows[i]);
                return false;
            }
        }
        return true;
    }

  const _mapBody = () => {
    const placeholder = i18n.t("nlp.synonyms_placeholder");
    return (
      <div>
        <h6 className="topic-editor-synonyms_description">
          {i18n.t("nlp.synonyms_fill")}
        </h6>
        <textarea
          placeholder={placeholder}
          className="topic-editor-synonyms_input"
          value={editingSynonyms}
          onChange={_onSynonymsTextChanged}
        />
      </div>
    );
  };

  const _mapFooter = () => {
    return (
      <div>
        <Button onClick={_onSaveSynonyms}>{i18n.t("common.save")}</Button>
      </div>
    );
  };

  return (
    <div className="topic-editor-synonyms-container">
      <ModalWindow
        header={_mapHeader()}
        body={_mapBody()}
        footer={_mapFooter()}
        isOpen={true}
      />
    </div>
  );
};

export default SynonymsDialog;
