import { PermissionType } from "../models/PermissionType";
import { getPermissions } from "./actions";

export interface IUserContextProps {
    getPermissions: typeof getPermissions;
}

export type GetPermissionAction = GetPermissionActionSuccess | GetPermissionActionStart | GetPermissionActionError;
export interface GetPermissionActionStart {
    type: typeof GET_PERMISSIONS_START,
}
export interface GetPermissionActionSuccess {
    type: typeof GET_PERMISSIONS_SUCCESS,
    payload: {permissions: PermissionType[]}
} 
export interface GetPermissionActionError {
    type: typeof GET_PERMISSIONS_ERROR,
}

export const GET_PERMISSIONS_START: string = `GET_PERMISSIONS_START`;
export const GET_PERMISSIONS_SUCCESS: string = `GET_PERMISSIONS_SUCCESS`;
export const GET_PERMISSIONS_ERROR: string = `GET_PERMISSIONS_ERROR`;