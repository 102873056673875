import * as React from 'react';
import {Button, Table} from 'reactstrap';
import {connect} from 'react-redux';
import {IApplicationState} from 'src/~store/models/ApplicationState';

import {
    getAllOperators, 
    saveOperator,
    deleteOperator,
    createOperator,
    changeOperatorAvatar,
    saveOperatorSetting,
    toggleOperatorSorting
} from '../../~store/actions/actions';

import {IOperatorsListProps} from './~types/OperatorsListProps';

import {IOperator} from '../../~store/models/Operator';

import {
    isOperatorCreatingSelector, 
    isReceivingOperatorsSelector, 
    allOperatorsSelector,
    isSortingByOperatorNameSelector,
} from '../../~store/selector';

import {OperatorItem} from './operator-item/OperatorItem';
import Loader from 'src/app/shared/components/loader/Loader';

import {showErrorToastr} from 'src/app/shared/helpers/toastr-helper/ToastrHelper';

import './OperatorsList.css';
import { operatorIdSelector, operatorsStatusListSelector, pauseCategoriesSelector } from 'src/app/chats-panel/~store/selectors';
import { OperatorStatusType } from 'src/app/chats-panel/~store/state/ChatItemsState';
import i18n from "src/app/shared/localization/i18n";

type ReduxType = ReturnType<typeof mapStateToProps> & IOperatorsListProps;

class OperatorsList extends React.Component<ReduxType> {

    public componentDidMount() {
        this.props.getAllOperators();
    }

    public render() {
        return (
            <div className="operators-list">
                <Button 
                    className="operators-list__btn-create" 
                    onClick={this._onCreateOperatorHandler}>
                    {i18n.t('operators.createOperator')}
                </Button>
                {!this.props.isReceivingOperators && this._getTable()}
                {this.props.isReceivingOperators && <Loader />}
            </div>
        );
    }

    private _mapOperatorsToTableRows = (): React.ReactElement<{}> => {
        const {operators} = this.props;

        return(
            <tbody>
                {operators.map((operator) => {
                    const index = this.props.operatorsStatusList.findIndex(o => o.operatorId === operator.operatorId);
                    return(
                        <OperatorItem 
                            operator={operator} 
                            key={operator.operatorId ?? 0}
                            onSaveOperator={this._onSaveOperatorHandler}
                            onDeleteOperator={this._onDeleteOperatorHandler}
                            onChangeOperatorAvatar={this._onChangeOperatorAvatarHandler}
                            onSaveOperatorSetting={this._onSaveOperatorSettingHandler}
                            status={(index > -1) ? this.props.operatorsStatusList[index].status : OperatorStatusType.Offline}
                            pauseCategories = {this.props.pauseCategories}
                        />
                    );
                })}
            </tbody>
        );
    }

    private _onSaveOperatorHandler = (oldOperator: IOperator, newOperator: IOperator, scriptFile?: File) => {
        this.props.saveOperator(oldOperator, newOperator, scriptFile);
    }

    private _onSaveOperatorSettingHandler = (operator: IOperator, settings: string) => {
        this.props.saveOperatorSetting(operator, settings);
    }

    private _onDeleteOperatorHandler = (operator: IOperator) => {
        if (window.confirm(i18n.t('operators.sureRemoveOperator'))) {
            this.props.deleteOperator(operator);
        }
    }

    private _onCreateOperatorHandler = () => {
        if(this.props.isOperatorCreating) {
            showErrorToastr(i18n.t('operators.operatorCreatingErrorMessage'));
            return;
        }
        this.props.createOperator();
    }

    private _onChangeOperatorAvatarHandler = (
        imageFile: File, 
        operator: IOperator, 
        onAvatarChangeSuccess: (avatarId: string | null) => void
    ) => {
        this.props.changeOperatorAvatar(imageFile, operator, onAvatarChangeSuccess);
    }

    private _getTable = (): React.ReactElement<{}> => {
        return (
            <Table className='card-table'>
                {this._getTableHead()}
                {this._mapOperatorsToTableRows()}
            </Table>
        );
    }

    private _getTableHead = (): React.ReactElement<{}> => {
        return (
            <thead>
                <tr>
                    <th className="operators-list__avatar-cell" />
                    <th>
                        <span className="operators-list__name" onClick={() => this.props.toggleOperatorSorting()}>{i18n.t('operators.name')}</span>
                        <span>{this.props.isSortingByOperatorName ? "⯆" : "⯈"}</span></th>
                    <th className="operators-list__name">{i18n.t('operators.displayName')}</th>
                    <th>{i18n.t('common.type')}</th>
                    <th />
                    <th />
                    <th>{i18n.t('common.enabled')}</th>
                    <th className="operators-list__buttons-cell"/>
                </tr>
            </thead>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        isOperatorCreating: isOperatorCreatingSelector(state),
        isReceivingOperators: isReceivingOperatorsSelector(state),
        operators: allOperatorsSelector(state),
        isSortingByOperatorName: isSortingByOperatorNameSelector(state),
        operatorsStatusList: operatorsStatusListSelector(state),
        currentOperatorId: operatorIdSelector(state),
        pauseCategories: pauseCategoriesSelector(state)
    };
};

const mapDispatchToProps = (dispatch: any): IOperatorsListProps => {
    return {
        getAllOperators: () => dispatch(getAllOperators()),
        saveOperator: (
            oldOperator: IOperator, 
            newOperator: IOperator,
            scriptFile?: File
        ) => dispatch(saveOperator(oldOperator, newOperator, scriptFile)),
        deleteOperator: (operator: IOperator) => dispatch(deleteOperator(operator)),
        createOperator: () => dispatch(createOperator()),
        changeOperatorAvatar: (
            file: File, 
            operator: IOperator, 
            onImageChangeSuccess: (imageId: string | null) => void
        ) => dispatch(changeOperatorAvatar(file, operator, onImageChangeSuccess)),
        saveOperatorSetting: (
            operator: IOperator, 
            setting: string
        ) => dispatch(saveOperatorSetting(operator, setting)),
        toggleOperatorSorting: () => dispatch(toggleOperatorSorting()),
}
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorsList);
