const moduleName: string = "MAILING/";

export const START_MAILINGS_START: string = `${moduleName}START_MAILINGS_START`;
export const START_MAILINGS_SUCCESS: string = `${moduleName}START_MAILINGS_SUCCESS`;
export const START_MAILINGS_ERROR: string = `${moduleName}START_MAILINGS_ERROR`;



export const GET_MAILING_ITEM_INFO_SUCCESS: string = `${moduleName}GET_MAILING_ITEM_INFO_SUCCESS`;

export const GET_ENABLED_CHANNELS_SUCCESS: string = `${moduleName}GET_ENABLED_CHANNELS_SUCCESS`;
export const GET_ENABLED_CHANNELS_ERROR: string = `${moduleName}GET_ENABLED_CHANNELS_ERROR`;

export const CHANGE_SELECTED_CHANNELS_SUCCESS: string = `${moduleName}CHANGE_SELECTED_CHANNELS_SUCCESS`;

export const CHANGE_NAME_SUCCESS: string = `${moduleName}CHANGE_NAME_SUCCESS`;
export const CHANGE_OPERATOR_ID_SUCCESS: string = `${moduleName}CHANGE_OPERATOR_ID_SUCCESS`;

export const CHANGE_MESSAGE_SUCCESS: string = `${moduleName}CHANGE_MESSAGE_SUCCESS`;

export const SAVE_MAILING_SUCCESS: string = `${moduleName}SAVE_MAILING_SUCCESS`;

export const DELETE_RECIPIENTS_FROM_STATE_SUCCESS: string = `${moduleName}DELETE_RECIPIENTS_FROM_STATE_SUCCESS`;

export const GET_MAILINGS_RECIPIENTS_START: string = `${moduleName}GET_MAILINGS_RECIPIENTS_START`;
export const GET_MAILINGS_RECIPIENTS_SUCCESS: string = `${moduleName}GET_MAILINGS_RECIPIENTS_SUCCESS`;
export const GET_MAILINGS_RECIPIENTS_ERROR: string = `${moduleName}GET_MAILINGS_RECIPIENTS_ERROR`;

export const GET_MAILINGS_START: string = `${moduleName}GET_MAILINGS_START`;
export const GET_MAILINGS_SUCCESS: string = `${moduleName}GET_MAILINGS_SUCCESS`;
export const GET_MAILINGS_ERROR: string = `${moduleName}GET_MAILINGS_ERROR`;

export const REMOVE_ALL_MAILINGS_FROM_STATE_SUCCESS: string = `${moduleName}REMOVE_ALL_MAILINGS_FROM_STATE_SUCCESS`;

export const DELETE_MAILING_START: string = `${moduleName}DELETE_MAILING_START`;
export const DELETE_MAILING_SUCCESS: string = `${moduleName}DELETE_MAILING_SUCCESS`;
export const DELETE_MAILING_ERROR: string = `${moduleName}DELETE_MAILING_ERROR`;