import React, { createRef, useEffect, useState } from 'react';
import { IMessageEditorProps } from "./~types/MessageEditorProps";
import { Button } from 'reactstrap';
import { DELETED_MESSAGE_MESSAGE} from './message-editor-constants/MessageEditorConstants';
import Input from "@material-ui/core/Input";
import { showErrorToastr } from "src/app/shared/helpers/toastr-helper/ToastrHelper";

import './MessageEditor.css';
import { useTranslation } from 'react-i18next';
import { TopicPairContentType } from 'src/app/nlp/~store/models/enums/TopicPairContentType';

const MessageEditor = (props: IMessageEditorProps) => {
    const { t } = useTranslation();
    const [editedText, setEditedText] = useState(props.messageToEdit.text);
    const [isFocused, setIsFocued] = useState(false);
    const messageEditorRef = createRef<HTMLTextAreaElement>();

    useEffect(() => {
        if(messageEditorRef.current && !isFocused) {
            messageEditorRef.current.focus();
            setIsFocued(true);
        }
    });

    useEffect(() => {
        props.onChangeHeight();
    }, []);

    const onCloseHandler = () => {
        setIsFocued(false);
        props.onClose?.();
    }

    return (
        <div 
            className="message-editor__container"
            id="message-editor__container"
            onKeyDown={(event) => {
                if(!event.shiftKey && event.key === "Enter") {
                    var message = {...props.messageToEdit};
                    if (!message.text && (message.imageId || message.fileId)) {
                        showErrorToastr(t('chat.messageFileNoEdit'));
                    }
                    else {
                        props.onEdit({...props.messageToEdit, text: editedText});
                    }
                    onCloseHandler();
                }
            }}>
            <div className="message-to-edit">
                <div className="message-to-edit__header">
                    {t('chat.editMessage')}
                    <div 
                        className="message-to-edit__close-editor"
                        onClick={onCloseHandler}>
                        <i className="material-icons">
                            close
                        </i>
                    </div>
                </div>
                <textarea readOnly className="message-to-edit__text" id="message-to-edit__text">
                    {props.messageToEdit.text}
                </textarea>
                <div className="message-to-edit__footer"></div>
            </div>
            <div className="message-editor">
                <Input
                    type="text"
                    className="message-editor__input"
                    id="message-editor__input"
                    value={editedText}
                    inputRef={messageEditorRef}
                    multiline={true}
                    maxRows={props.rowsMax}
                    onFocus={event => {
                        const text = event.currentTarget.value;
                        event.currentTarget.setSelectionRange(text.length, text.length);
                        event.currentTarget.scrollTop = event.currentTarget.scrollHeight;
                    }}
                    onChange={event => setEditedText(event.target.value)}/>
                {(props.messageToEdit.imageId === null && props.messageToEdit.fileId === null) && (
                <Button
                    onClick={
                        () => {
                            var message = {...props.messageToEdit};
                            if (!message.text && (message.imageId || message.fileId)) {
                                showErrorToastr(t('chat.messageFileNoEdit'));
                            }
                            else {
                                props.onEdit({ ...props.messageToEdit, text: editedText, imageId: props.messageToEdit.imageId, fileId: props.messageToEdit.fileId });
                            }
                            onCloseHandler();
                    }}>
                    <i className="material-icons">
                        done
                    </i>
                </Button>)}
                <Button
                    className="message-editor__remove-message"
                    onClick={
                        () => {
                            props.onEdit({ ...props.messageToEdit, text: DELETED_MESSAGE_MESSAGE, imageId: "", fileId: "", contentType: TopicPairContentType.Text });
                            onCloseHandler();
                        }
                    }>
                    <i className="material-icons">
                        delete_forever
                    </i>
                </Button>
            </div>
        </div>
    )
}

export default MessageEditor;