import React from 'react';
import { ITagsListProps } from './~types/TagsListProps';
import { ISearchingTag, TagName, TagType } from '../~types/models/SearchingTag';
import { DEFAULT_OPERATOR_AVATAR_ID } from 'src/app/operator/~store/models/ModelsConstants';
import { GET_AVATAR_BY_ID_API_ROUTE } from 'src/app/operator/~api/apiRoutes';
import { GET_AVATAR_BY_CONTACT_ID_API_ROUTE } from 'src/app/contact/~api/apiRoutes';
import { Avatar } from '@mui/material';
import ContactIcon from '@mui/icons-material/AccountCircle';
import OperatorIcon from '@mui/icons-material/SupportAgent'

import './TagsList.css';
import { IContact } from 'src/app/contact/~store/models/Contact';
import { ContactTagValue } from '../../../helpers/contact-tag-value/ContactTagValue';

const TagsList = (props: ITagsListProps) => {

    const _createTag = (tag: ISearchingTag, key: number) => {
        const renderSwitch = (tagType: TagType) => {
            switch(tagType) {
                case TagType.OpeningTag:
                case TagType.OperationTag:
                    return tag.value as string;

                case TagType.ClosingTag: {
                    switch(tag.tagName) {
                        case TagName.Status:
                        case TagName.ChatTag:
                        case TagName.Channel:
                        case TagName.Date:
                        case TagName.Setting:
                        case TagName.Subject:
                        case TagName.Source:
                        case TagName.ChatNumber:
                        case TagName.Custom: 
                            return tag.value as string;

                        case TagName.ChannelName:
                            tag.externalValue = `${props.channels.find(el => {return el.name === tag.value})?.channelId}`;
                            return tag.value as string;

                        case TagName.Operator:
                            tag.externalValue = `${props.operators.find(el => { return el.name === tag.value })?.operatorId}`;
                            return _createOperatorTag(Number.parseInt(tag.externalValue));
                        
                        case TagName.Contact:
                            tag.externalValue = `${props.contacts.find(el => { return ContactTagValue(el) === tag.value })?.contactId}`;
                            return _createContactTag(Number.parseInt(tag.externalValue));
                            
                        case TagName.Phrase:
                            return tag.value as string;
                    }
                }
            }
        }
        return (
            <div
                className="tags-list__tag"
                key={key}>
                {renderSwitch(tag.tagType)}
                {tag.tagType === TagType.ClosingTag && 
                <div
                    className="tags-list__tag--close"
                    onClick={() => props.onCloseTag(tag, key)}>
                    <i className="material-icons">close</i>
                </div>}
            </div>
        )
    }

    const _createOperatorTag = (operatorId: number) => {
        const operator = props.operators.find(operator => operator.operatorId === operatorId);
        const userInStorage = sessionStorage.getItem("user");
        const customerId = userInStorage === null ? "" : JSON.parse(userInStorage.toString()).customerId;

        if(operator) {
            const imageUrl = 
                operator.avatarId !== DEFAULT_OPERATOR_AVATAR_ID ?
                    GET_AVATAR_BY_ID_API_ROUTE + operator.avatarId + "&customerId=" + customerId + "&cahceBypass=" + Math.random() : 
                "";
            return (
                <div className="operator-tag__container">
                    <Avatar
                        className="operator-tag__avatar"
                        src={imageUrl}
                    >
                        <OperatorIcon style={{ color: "#26a69a" }} />
                    </Avatar>
                    {operator.name}
                </div>
            )
        } else {
            return <></>
        }
    }

    const _createContactTag = (contactId: number) => {
        const contact = props.contacts.find(contact => contact.contactId === contactId);
        const userInStorage = sessionStorage.getItem("user");
        const customerId = userInStorage === null ? "" : JSON.parse(userInStorage.toString()).customerId;

        const imageUrl = (contact === undefined || contact.avatarId === null) ?
            "" :
            GET_AVATAR_BY_CONTACT_ID_API_ROUTE + contact.avatarId + "&customerId=" + customerId + "&cahceBypass=" + Math.random();
        return (
            <div className="operator-tag__container">
                <Avatar
                    className="operator-tag__avatar"
                    src={imageUrl}
                >
                    <ContactIcon style={{ color: "#26a69a" }} />
                </Avatar>
                {(contact !== undefined ? ContactTagValue(contact) : contactId)}
            </div>
        )
    }

    return (
        <div className="tags-list__container">
            {props.tags.map((tag, i) => {
                return _createTag(tag, i);
            })}
        </div>)
}

export default TagsList;