export interface IAnswer {
    text: string;
    alternativeText1: string;
    alternativeText2: string;
    answerId: number;
    buttons: INlpButton[];
    parametersJson: object;
}

export interface INlpButton {
    type: NlpButtonType;
    name: string;
    value: string;
}

export enum NlpButtonType {
    Text = 1,
    Link,
    Trigger,
    CustomEvent,
    Back
}

export const createNlpButton = (): INlpButton => {
    return {
        type: NlpButtonType.Text,
        name: "",
        value: ""
    }
}