const moduleName: string = 'PERMISSIONS/';


export const GET_ALL_PERMISSIONS_SUCCESS: string = `${moduleName}GET_ALL_PERMISSIONS_SUCCESS`;

export const GET_ROLES_PERMISSIONS_SUCCESS: string = `${moduleName}GET_ROLES_PERMISSIONS_SUCCESS`;

export const SAVE_ROLE_PERMISSIONS_SUCCESS: string = `${moduleName}SAVE_ROLE_PERMISSIONS_SUCCESS`;

export const GET_ALL_USERS_SUCCESS: string = `${moduleName}GET_ALL_USERS_SUCCESS`;

export const GET_USERS_PERMISSIONS_SUCCESS: string = `${moduleName}GET_USER_PERMISSIONS_SUCCESS`;

export const SAVE_USER_PERMISSIONS_SUCCESS: string = `${moduleName}SAVE_USER_PERMISSIONS_SUCCESS`;