import React, { useEffect } from 'react';
import LoginForm from './login-form/LoginForm';
import { ILoginProps } from './~types/LoginProps';
import { connect } from 'react-redux';
import { loginUser } from '../~store/actions/actions';
import './Login.css';
import { createMatchSelector } from 'connected-react-router';
import { IApplicationState } from 'src/~store/models/ApplicationState';
import { ILoginRouteParams } from './~types/LoginRouteParams';
import { showInfoToastr } from 'src/app/shared/helpers/toastr-helper/ToastrHelper';

type ReduxType = ReturnType<typeof mapStateToProps> & ILoginProps;

const Login = (props: ReduxType) => {
    const redirectedFrom = props.location?.state?.redirectedFrom;
    useEffect(() => {
        if(props.matchParams && props.matchParams.message !== "")
            showInfoToastr(props.matchParams.message);
    }, [props.matchParams?.message]);
    
    return(
        <section className="login-container">
            <LoginForm 
                onLoginUser={
                    (login, password) => props.loginUser(login, password, redirectedFrom)}/>
            <span className="login-build-version">build version: {process.env.REACT_APP_BUILD_VERSION}</span>
        </section>
    );
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        matchParams: createMatchSelector("/login/:message")(state)?.params as ILoginRouteParams,
    }
}

const mapDispatchToProps = (dispatch: any): ILoginProps => {
    return {
        loginUser: (login: string, password: string, redirectedFrom?: string) => dispatch(loginUser(login, password, redirectedFrom))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);