import { HttpTransportType, HubConnection, HubConnectionBuilder, IRetryPolicy, LogLevel, JsonHubProtocol, IHubProtocol } from "@microsoft/signalr";
import { historyWithForceUpdate } from "src/~store/history";
import { UNAUTHORIZED_ERROR } from './SignalRConstants';
import i18n from "src/app/shared/localization/i18n";
import { INegotiateResponse, signalRTransportProtocol } from "src/~store/models/NegotiateResponse";
import { MessagePackHubProtocol, MessagePackOptions } from "@microsoft/signalr-protocol-msgpack";

declare global {
    interface Window {
        signalRHubConnection: HubConnection;
        signalRTransportProtocol: HttpTransportType;
        messagePack: boolean;
    }
}

const trySendNegotiate = (hostUrl: string) => {
    try {
        console.log('Sending negotiate to server..');
        const userInStorage = sessionStorage.getItem('user');
        const customerId = userInStorage === null ? "" : JSON.parse(userInStorage).customerId;
        if(customerId === null || customerId === undefined || customerId === "") {
            console.warn('CustomerId is empty. Impossible to send negotiate');
            return;
        }

        const response = sendNegotiateRequest(hostUrl, customerId);
        window.signalRTransportProtocol = 
            response.signalRTransportProtocol === signalRTransportProtocol.serverSentEvents ? 
            HttpTransportType.ServerSentEvents : 
            HttpTransportType.WebSockets; 

        window.messagePack = response.messagePack;
        console.log(`Succeed to negotiate with server, signalRTransportProtocol: ${HttpTransportType[window.signalRTransportProtocol]}, messagePack: ${window.messagePack}`);
    }
    catch (err) {
        console.error(err.message);
        setTimeout(trySendNegotiate.bind(null, hostUrl), 2000);
    }
    
}
    
const sendNegotiateRequest = (hostUrl: string, customerId: string): INegotiateResponse => {    
    const url = `${hostUrl}/negotiate?customerId=${customerId}`;
    const request = new XMLHttpRequest();
    request.open('GET', url, false);
    request.send(null);
    if (request.status === 200) {
        return JSON.parse(request.responseText);
    }

    throw 'Failed to negotiate with server';  
}

export const createSignalRConnection = (hostUrl:string) => {
    const opt = {
        ingnoreUndefine: true,
        forceFloat32: true
    } as MessagePackOptions;
    
    trySendNegotiate(hostUrl);
    console.log('Creating SignalR connection..');
    const userInStorage = sessionStorage.getItem('user');
    const token = userInStorage === null ? "" : JSON.parse(userInStorage.toString()).tokenInfo.token;
    const hubConnection = new HubConnectionBuilder()
        .withUrl(hostUrl + "/chat", { accessTokenFactory: () => token, transport:window.signalRTransportProtocol})
        .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: (retryContext) => {
                if(retryContext.retryReason.message.includes(UNAUTHORIZED_ERROR)) {
                    logout(i18n.t('error.invalidTokenErrorMessage'));
                }
                if (retryContext.previousRetryCount < 5) {
                    return retryContext.previousRetryCount * 2000;
                } else {
                    return 10000;
                }
            }
        } as IRetryPolicy)
        .withHubProtocol(window.messagePack ? new MessagePackHubProtocol(opt) as IHubProtocol : new JsonHubProtocol())
        .configureLogging(LogLevel.Error).build(); 

    hubConnection.on("Ping", (pingTime) => pong(pingTime));

    hubConnection.onclose((err) => {
        const error = err === undefined ? '' : 'Error: ' + err;
        console.log('Connection closed! ' + error);
        if(error.includes(UNAUTHORIZED_ERROR)) {
            logout(i18n.t('error.invalidTokenErrorMessage'));
        }
    });

    window.signalRHubConnection = hubConnection;
    return hubConnection;
}

export const startSignalRConnection = async (hubConnection: HubConnection) => {
    hubConnection.serverTimeoutInMilliseconds = 10000;
    hubConnection.keepAliveIntervalInMilliseconds = 60000;
    console.log('Connecting..');
    try {
        await hubConnection.start();
        console.log('Connection started!');
        await hubConnection.invoke("CheckOperatorStatus");
    } catch (err) {
        console.log('Error while establishing connection: ' + err);
        if(err.message.includes(UNAUTHORIZED_ERROR)) {
            logout(i18n.t('error.invalidTokenErrorMessage'));
        }
        setTimeout(() => {startSignalRConnection(hubConnection)}, 2000);
    }
};

export const logout = (logoutMessage: string | undefined) => {
    sessionStorage.removeItem('user');
    historyWithForceUpdate.push(`/login${(logoutMessage !== undefined ? `/${logoutMessage}` : "")}`);
}

const pong = (pingTime: Date) => {
    window.signalRHubConnection.send("Pong", pingTime, new Date());
    //console.log(`[${new Date().toISOString()}] Sent pong ${pingTime} for ${window.signalRHubConnection.connectionId}`);
};