import * as React from "react";
import {IChannelOperatorListProps} from "./~types/ChannelOperatorListProps";
import IChannelOperatorListState from "./~types/ChannelOperatorListState";
import {IOperatorShort} from "src/app/channel/~store/models/OperatorShort";
import {GET_AVATAR_BY_ID_API_ROUTE} from "src/app/operator/~api/apiRoutes";
import { Avatar, AvatarGroup } from '@mui/material';
import OperatorIcon from '@mui/icons-material/SupportAgent'

import './ChannelOperatorList.css';
import ChannelOperatorListConfiguration from "./channel-operator-list-configuration/ChannelOperatorListConfiguration";
import i18n from "src/app/shared/localization/i18n";

export default class ChannelOperatorList extends React.Component<IChannelOperatorListProps, IChannelOperatorListState> {
    public readonly state: IChannelOperatorListState = {
        channelOperators: this.props.channelOperators,
        isChannelOperatorsConfigOpen: false,
    };

    public render() {
        const {allOperatorsShort} = this.props;
        const {
            isChannelOperatorsConfigOpen,
            channelOperators
        } = this.state
        return (
            <div className="channel-operator-list">
                <div className="channel-operator-list__title">{i18n.t('common.operators') + ":"}</div>
                {this._getChannelOperatorList()}
                <ChannelOperatorListConfiguration 
                    isChannelOperatorsConfigOpen={isChannelOperatorsConfigOpen}
                    channelOperators={channelOperators}
                    allOperatorsShort={allOperatorsShort}
                    channelId={this.props.channelId}
                    onCloseChannelOperatorsConfig={this._toggleChannelOperatorsConfig}
                    onSaveChannelOperators={this.props.onSaveChannelOperators} />
            </div>
        );
    }

    private _getChannelOperatorList = (): React.ReactElement<{}> => {
        const {allOperatorsShort} = this.props;
        const {channelOperators} = this.state;
        return (
            <div className="channel-operator-list__operators">
                <AvatarGroup max={10}>
                {channelOperators.map((channelOperator, index) => {
                    const operator: IOperatorShort = allOperatorsShort.find(
                        op => op.operatorId === channelOperator.operatorId
                    ) as IOperatorShort;
                    return this._mapOperatorShortToElement(operator, index);
                })}
                </AvatarGroup>
                <button 
                    className="channel-operator-list__btn-add-operator" 
                    onClick={this._toggleChannelOperatorsConfig}>
                    <i className="material-icons">
                        add
                    </i>
                </button>
            </div>
        );
    };

    private _mapOperatorShortToElement = (operator: IOperatorShort, index: number): React.ReactElement<{}> => {
        const userInStorage = sessionStorage.getItem("user");
        const customerId = userInStorage === null ? "" : JSON.parse(userInStorage.toString()).customerId;
        const imageUrl: string =
            (operator?.avatarId === null || operator?.avatarId === undefined)
                ? ''
                : GET_AVATAR_BY_ID_API_ROUTE + operator.avatarId + "&customerId=" + customerId + "&cahceBypass=" + Math.random();

        return (
            <div 
                key={index} 
                className="channel-operator-list__operator"
                onClick={this._toggleChannelOperatorsConfig}>
                <Avatar className="channel-operator-list__operator-avatar" src={imageUrl}>
                    <OperatorIcon style={{ color: '#26a69a'}} />
                </Avatar>
                <div className="channel-operator-list__operator-name">{operator.name}</div>
            </div>
        );
    };

    private _toggleChannelOperatorsConfig = (): void => {
        const isChannelOperatorsConfigOpen = !this.state.isChannelOperatorsConfigOpen;
        this.setState({isChannelOperatorsConfigOpen});
    }
}
