import React, { useState, useEffect } from 'react';
import { ITelephoneInputProps } from './TelephoneInputProps';
import './TelephoneInput.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { isPhoneValid } from '../../helpers/phone-helper/PhoneHelper';

const TelephoneInput = (props: ITelephoneInputProps) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        setPhoneNumber(props.phoneNumber);
    }, [props.phoneNumber]);
    document.querySelector('div.flag-dropdown')?.setAttribute('tabindex', '-1');

    const getBrowserLocales = () => {
        if (navigator.languages != undefined)
            return navigator.languages;
        return [navigator.language];
    }

    const getLanguage = () => {
        var phoneLocales = [];
        let browserLocales = getBrowserLocales();

        if (browserLocales.length > 0) {
            for (var i = 0; i < browserLocales.length; ++i) {
                switch (browserLocales[i].toLowerCase()) {
                    case 'en-us':
                    case 'en':
                        phoneLocales.push('us');
                        break;
                    case 'ru':
                        phoneLocales.push('ru');
                        break;
                    case 'uk':
                        phoneLocales.push('ua');
                        break;
                    case 'pl':
                        phoneLocales.push('pl');
                        break;
                    case 'by':
                    case 'be':
                        phoneLocales.push('by');
                        break;
                    case 'kk':
                        phoneLocales.push('kz');
                        break;
                    case 'fr-be':
                        phoneLocales.push('be');
                        break;

                }
            }
            if (phoneLocales.length == 0) {
                phoneLocales.push('us');
            }
            return phoneLocales;
        }
    }

    var langs = getLanguage() ?? ['us'];
    return (
        <div className={props.containerClass}>
            <PhoneInput
                containerClass={"phone-input__container " + props.phoneInputcontainerClass}
                preferredCountries={langs}
                inputClass={isValid ? "phone-input__input " : "phone-input__input" + " is-invalid "}
                country={langs[0]}
                placeholder="+XXX XX XXXXXXX"
                value={phoneNumber}
                onChange={(value: string, data: any) => {
                    setIsValid(isPhoneValid(value, data.dialCode));
                    setPhoneNumber(value); 
                    props.onChange(value, data)}
                }/>
        </div>
    )
}

export default TelephoneInput;