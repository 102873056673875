const CHAT_API_ROUTE = `${process.env.REACT_APP_CHAT_API_ROUTE}`;
const WRITER_API_ROUTE = `${process.env.REACT_APP_WRITER_API_ROUTE}`;

const OPERATOR_API_ROUTE = `${CHAT_API_ROUTE}/operator`;

const PICTURE_API_ROUTE = `${CHAT_API_ROUTE}/picture`;

export const GET_OPERATORS_API_ROUTE = `${OPERATOR_API_ROUTE}/get-all-operators`;
export const SAVE_OPERATOR_API_ROUTE = `${OPERATOR_API_ROUTE}/save-operator`;
export const DELETE_OPERATOR_API_ROUTE = `${OPERATOR_API_ROUTE}/delete-operator`;
export const CHANGE_OPERATOR_AVATAR_API_ROUTE = `${OPERATOR_API_ROUTE}/save-avatar`;
export const GET_AVATAR_BY_ID_API_ROUTE = `${PICTURE_API_ROUTE}/get-picture-by-id?pictureId=`;
export const GET_SETTING_BY_OPERATOR_ID_API_ROUTE = `${OPERATOR_API_ROUTE}/get-setting-by-operator-id?operatorId=`;
export const GET_SETTINGS_BY_OPERATORS_ID_API_ROUTE = `${OPERATOR_API_ROUTE}/get-settings-by-operators-id`;
export const SAVE_OPERATOR_SETTING_API_ROUTE = `${OPERATOR_API_ROUTE}/save-operator-setting`;
export const DELETE_OPERATOR_SETTING_API_ROUTE = `${OPERATOR_API_ROUTE}/delete-setting-by-operator-id?operatorId=`;
export const GET_OPERATOR_BY_ID_API_ROUTE = `${OPERATOR_API_ROUTE}/get-operator-by-id`;
export const GET_OPERATOR_PERMISSIONS_API_ROUTE = `${OPERATOR_API_ROUTE}/get-operator-permissions`;

const ACCOUNT_API_ROUTE = `${WRITER_API_ROUTE}/account`
export const REGISTER_OPERATOR_API_ROUTE = `${ACCOUNT_API_ROUTE}/register`;
export const DELETE_IDENTITY_API_ROUTE = `${ACCOUNT_API_ROUTE}/delete-operator`;
export const UPDATE_IDENTITY_API_ROUTE = `${ACCOUNT_API_ROUTE}/save-operator`;
export const CHECK_IF_USER_EXISTS_API_ROUTE = `${ACCOUNT_API_ROUTE}/check-user-exists`;
