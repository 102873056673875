import { IApiActionResult } from "src/~api/ApiActionResult";
import { fetchGet, fetchPost, getApiActionResult } from "src/~api/ApiHelper";
import { IRole } from "../~store/models/IRole";
import IWriterUser from "../~store/models/IWriterUser";
import IPermissionViewModel from "../~store/models/PermissionViewModel";
import { IRolePermissions } from "../~store/models/RolePermissions";
import { IUserPermissions } from "../~store/models/UserPermissions";
import { GET_ALL_PERMISSIONS_API_ROUTE, GET_ALL_USERS_API_ROUTE, GET_ROLES_PERMISSIONS_API_ROUTE, GET_USERS_PERMISSIONS_API_ROUTE, SAVE_ROLE_PERMISSIONS_API_ROUTE, SAVE_USER_PERMISSIONS_API_ROUTE } from "./apiRoutes";

export const getAllPermissionsAsync = async (): Promise<IApiActionResult<IPermissionViewModel[]>> => {
    const fetchPromise = fetchGet(GET_ALL_PERMISSIONS_API_ROUTE);

    return await getApiActionResult(fetchPromise, true);
}


export const getRolesPermissionsAsync = async (roles: IRole[]): Promise<IApiActionResult<IRolePermissions[]>> => {
    const fetchPromise = fetchPost(GET_ROLES_PERMISSIONS_API_ROUTE, {
        body: JSON.stringify({
            roles
        })
    });

    return await getApiActionResult(fetchPromise, true);
}

export const saveRolePermissionsAsync = async (rolePermissions: IRolePermissions): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(SAVE_ROLE_PERMISSIONS_API_ROUTE, {
        body: JSON.stringify({
            rolePermissions
        })
    });

    return await getApiActionResult(fetchPromise, false);
}

export const getAllUsersAsync = async (): Promise<IApiActionResult<IWriterUser[]>> => {
    const fetchPromise = fetchGet(GET_ALL_USERS_API_ROUTE);

    return await getApiActionResult(fetchPromise, true);
}

export const getUsersPermissionsAsync = async (): Promise<IApiActionResult<IUserPermissions[]>> => {
    const fetchPromise = fetchGet(GET_USERS_PERMISSIONS_API_ROUTE);

    return await getApiActionResult(fetchPromise, true);
}

export const saveUserPermissionsAsync = async (userPermissions: IUserPermissions): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(SAVE_USER_PERMISSIONS_API_ROUTE, {
        body: JSON.stringify({
            userPermissions
        })
    });

    return await getApiActionResult(fetchPromise, false);
}