export function getDateTime(dateTime: Date) {
    return new Date(dateTime).toLocaleString(navigator.language);
} 

export function getDateTimeFromNumber(dateTime: number | Date) {
    return new Date(dateTime).toLocaleString(navigator.language);
} 

export function getTime(dateTime: Date) {
    return dateTime.toLocaleTimeString(navigator.language);
}



