import { createSelector } from "reselect";
import { IApplicationState } from "src/~store/models/ApplicationState";

const permissionSelector = (state: IApplicationState) => state.permissionState;

export const allPermissionsSelector = createSelector(
    permissionSelector, 
    permissionState => permissionState.allPermissions
);

export const rolesPermissionsSelector = createSelector(
    permissionSelector, 
    permissionState => permissionState.rolesPermissions
);

export const allUsersSelector = createSelector(
    permissionSelector, 
    permissionState => permissionState.allUsers
);

export const usersPermissionsSelector = createSelector(
    permissionSelector, 
    permissionState => permissionState.usersPermissions
);