import React, { useState } from 'react';
import { IAuthorNameProps } from './~types/AuthorNameProps';

import './AuthorName.css';
import { Button, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ChatPanelRuleHelper } from 'src/app/shared/helpers/rule-helper/ChatPanelRuleHelper';

const AuthorName = (props: IAuthorNameProps) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const toggleDropdownVisibility = () => {
        setIsDropdownVisible(!isDropdownVisible);
    }
    
    return (
        <>
            {!ChatPanelRuleHelper.canRemoveOperatorFromChat(
                props.messageType,
                props.operatorId,
                props.messageOperatorId,
                props.chatOwnerId,
                props.isSupervisor
            ) && 
                <div className={"author-name " + props.messageTypeClass}>
                    {props.authorName}
                </div>}
            {ChatPanelRuleHelper.canRemoveOperatorFromChat(
                props.messageType,
                props.operatorId,
                props.messageOperatorId,
                props.chatOwnerId,
                props.isSupervisor
            ) && <Dropdown 
                className="chat-footer__transfer-chat"
                isOpen={isDropdownVisible}
                toggle={() => { toggleDropdownVisibility() }}>
                <DropdownToggle
                    className={"author-name dropdown-toggle " + props.messageTypeClass}
                    onClick={() => {
                        toggleDropdownVisibility();
                    }}>
                        {props.authorName}
                </DropdownToggle>
                <DropdownMenu
                    className={"author-name__dropdown-actions " + (!isDropdownVisible ? "dropdown-actions__closed" : '')}>
                        <Button
                            className={'author-name__action'}
                            onClick={() => {
                                props.onRemoveOperator();
                                toggleDropdownVisibility();
                            }}>
                            Remove Operator
                        </Button>
                </DropdownMenu>
            </Dropdown>}
        </>
    )
}

export default AuthorName;