import React from "react";
import { TopicPairContentType } from "src/app/nlp/~store/models/enums/TopicPairContentType";
import { botTextToComponentWithLinks, clientMessageTextToComponentWithLinks } from "../../helpers/weblinks-helper/WeblinksHelper";

export const ConvertTextToContent = (contentType: TopicPairContentType, text: string, fromBot: boolean) => {
    switch (contentType) {
        case TopicPairContentType.Markup:
            return botTextToComponentWithLinks(text);
        case TopicPairContentType.Text:
            return clientMessageTextToComponentWithLinks(text);
        case TopicPairContentType.Video:
            return (
                    <video
                        className="message-item__media"
                        src={text}
                        autoPlay={false}
                        loop={false}
                        preload="none"
                        controls={true}
                    />
            );
        case TopicPairContentType.Audio:
            return (
                <audio
                    src={text}
                    controls
                />
            );
        case TopicPairContentType.TextToSpeech:
            return (
                <>
                    {botTextToComponentWithLinks(text)}
                    <i className="material-icons" id="content-type__label">
                        graphic_eq
                    </i>
                </>
            );
        case TopicPairContentType.Navigation:
            return (
                <>
                    {botTextToComponentWithLinks(text)}
                    <i className="material-icons" id="content-type__label">
                        arrow_forward
                    </i>
                </>
            )                
        default:
            return fromBot ? botTextToComponentWithLinks(text) : clientMessageTextToComponentWithLinks(text);
    }
}