const moduleName: string = "CHATS/";

// get all chats
export const GET_ALL_CHATS_START: string = `${moduleName}GET_ALL_CHATS_START`;
export const GET_ALL_CHATS_SUCCESS: string = `${moduleName}GET_ALL_CHATS_SUCCESS`;
export const UPDATE_CHATS_CHANNELS: string = `${moduleName}UPDATE_CHATS_CHANNELS`;
export const UPDATE_CHATS_OPERATORS: string = `${moduleName}UPDATE_CHATS_OPERATORS`;
export const GET_ALL_CHATS_ERROR: string = `${moduleName}GET_ALL_CHATS_ERROR`;

// select contact
export const SELECT_CONTACT: string = `${moduleName}SELECT_CONTACT`;

// get contact chats
export const GET_CONTACT_CHATS_START: string = `${moduleName}GET_CONTACT_CHATS_START`;
export const GET_CONTACT_CHATS_SUCCESS: string = `${moduleName}GET_CONTACT_CHATS_SUCCESS`;
export const GET_CONTACT_CHATS_ERROR: string = `${moduleName}GET_CONTACT_CHATS_ERROR`;

// get contact chats info
export const GET_CONTACT_CHATS_INFO_START: string = `${moduleName}GET_CONTACT_CHATS_INFO_START`;
export const GET_CONTACT_CHATS_INFO_SUCCESS: string = `${moduleName}GET_CONTACT_CHATS_INFO_SUCCESS`;
export const GET_CONTACT_CHATS_INFO_ERROR: string = `${moduleName}GET_CONTACT_CHATS_INFO_ERROR`;

// get more
export const GET_MORE_CHATS_AFTER: string = `${moduleName}GET_MORE_CHATS_AFTER`;
export const GET_MORE_CHATS_BEFORE: string = `${moduleName}GET_MORE_CHATS_BEFORE`;

// get contact chats lazy
export const GET_CONTACT_CHATS_LAZY_START: string = `${moduleName}GET_CONTACT_CHATS_LAZY_START`;
export const GET_CONTACT_CHATS_LAZY_SUCCESS: string = `${moduleName}GET_CONTACT_CHATS_LAZY_SUCCESS`;
export const GET_CONTACT_CHATS_LAZY_ERROR: string = `${moduleName}GET_CONTACT_CHATS_LAZY_ERROR`;

// get chat by id
export const GET_CHAT_BY_ID_START: string = `${moduleName}GET_CHAT_BY_ID_START`;
export const GET_CHAT_BY_ID_SUCCESS: string = `${moduleName}GET_CHAT_BY_ID_SUCCESS`;
export const GET_CHAT_BY_ID_ERROR: string = `${moduleName}GET_CHAT_BY_ID_ERROR`;

// set search string
export const SET_SEARCH_STRING_TO_CHATS_LIST_SUCCESS: string = `${moduleName}SET_SEARCH_STRING_TO_CHATS_LIST_SUCCESS`;
export const SET_SEARCH_STRING_TO_CHATS_INFO_SUCCESS: string = `${moduleName}SET_SEARCH_STRING_TO_CHATS_INFO_SUCCESS`;

// open chat by id
export const OPEN_CHAT_BY_ID_START: string = `${moduleName}OPEN_CHAT_BY_ID_START`;
export const OPEN_CHAT_BY_ID_SUCCESS: string = `${moduleName}OPEN_CHAT_BY_ID_SUCCESS`;
export const OPEN_CHAT_BY_ID_ERROR: string = `${moduleName}OPEN_CHAT_BY_ID_ERROR`;

// set scroll info
export const SET_FIRST_ELEMENT_ID: string = `${moduleName}SET_SCROLL_INFO`;

// set selected message
export const SET_SELECTED_MESSAGE: string = `${moduleName}SET_SELECTED_MESSAGE`;

// toggle message editing
export const TOGGLE_MESSAGE_EDITING: string = `${moduleName}TOGGLE_MESSAGE_EDITING`;

// edit message
export const EDIT_MESSAGE_START: string = `${moduleName}EDIT_MESSAGE_START`;
export const EDIT_MESSAGE_SUCCESS: string = `${moduleName}EDIT_MESSAGE_SUCCESS`;
export const EDIT_MESSAGE_ERROR: string = `${moduleName}EDIT_MESSAGE_ERROR`;

//udate chats
export const UPDATE_CHATS: string = `${moduleName}UPDATE_CHATS`;


//add new chat
export const ADD_NEW_CHAT_TO_CHATPANEL_STATE_SUCCESS: string = `${moduleName}ADD_NEW_CHAT_TO_CHATPANEL_STATE_SUCCESS`;

//change getChatsMode
export const GET_CHATS_MODE_CHANGE_SUCCESS: string = `${moduleName}GET_CHATS_MODE_CHANGE_SUCCESS`;
export const GET_CHATS_MODE_CHANGE_START: string = `${moduleName}GET_CHATS_MODE_CHANGE_START`;

// save contact
export const CHANGE_CHAT_CONTACT_CHATNAME_SUCCESS: string = `${moduleName}SAVE_CONTACT_SUCCESS`;

// search chats
export const SEARCH_CHATS: string = `${moduleName}SEARCH_CHATS`;

// get chat tags
export const GET_TAG_NAMES: string = `${moduleName}GET_TAG_NAMES`;
export const GET_TAG_VALUES: string = `${moduleName}GET_TAG_VALUES`;
export const TOGGLE_AUTO_UPDATE_CHATS: string = `${moduleName}TOGGLE_AUTO_UPDATE_CHATS`;
export const GET_SL_COLOR_SECONDS_SETTINGS_SUCCESS = `${moduleName}GET_SL_COLOR_SECONDS_SETTINGS_SUCCESS`;