import { authenticationReducer } from "src/app/authentication/~store/reducer";
import { contactReducer } from "src/app/contact/~store/reducer";
import { operatorsReducer } from "src/app/operator/~store/reducer";
import { channelReducer } from "src/app/channel/~store/reducer";
import { chatItemsReducer } from "src/app/chats-panel/~store/reducer";
import { chatsReducer } from "src/app/chats/~store/reducer";
import { knowledgeBaseReducer } from "src/app/nlp/~store/reducer";
import { mailingReducer } from "src/app/mailing/~store/reducer";
import { userContextReducer } from  "src/app/shared/components/user-context/reducer";
import { settingReducer } from "src/app/setting/~store/reducer";
import { analyticsReducer } from "src/app/analytics/~store/reducer";
import { permissionReducer } from "src/app/permission/~store/reducer";

export const reducers = {
    authenticationState: authenticationReducer,
    contactState: contactReducer,
    operatorState: operatorsReducer,
    channelState: channelReducer,
    chatsState: chatsReducer,
    chatItemsState: chatItemsReducer,
    knowledgeBaseState: knowledgeBaseReducer,
    mailingState: mailingReducer,
    settingState: settingReducer,
    userContextState: userContextReducer,
    analyticsState: analyticsReducer,
    permissionState: permissionReducer
}