const translate = {
    common: {
        login: "Login",
        password: "Password",
        maskedPassword: "**********",
        date: "Date",
        today: "Today",
        yesterday: "Yesterday",
        currentWeek: "Current week",
        currentMonth: "Current month",
        currentYear: "Current year",
        period: "Period",
        tier: "Tier",
        save: "Save",
        operators: "Operators",
        search: "Search",
        online: "online",
        pause: "pause",
        offline: "offline",
        chats: "Chats",
        chat: "Chat",
        channels: "Channels",
        type: "Type",
        token: "Token",
        enabled: "Enabled",
        name: "Name",
        settings: "Settings",
        permissions: "Permissions",
        contact: "Contact",
        channel: "Channel",
        accounts: "Accounts",
        cancel: "Cancel",
        delete: "Delete",
        restore: "Restore",
        merge: "Merge",
        detach: "Detach",
        contacts: "Contacts",
        messengers: "Messengers",
        created: "Created",
        status: "Status",
        mailing: "Mailing",
        start: "Start",
        message: "Message",
        analytics: "Analytics",
        synonyms: "Synonyms",
        export: "Export",
        import: "Import",
        publish: "Publish",
        answer: "Answer",
        active: "Active",
        inactive: "Inactive",
        pending: "Pending",
        apply: "Apply",
        setting: "Setting",
        editAsJson: "Edit as json",
        editAsTable: "Edit as table",
        image: "Image",
        file: "File",
        send: "Send",
        close: "Close",
        reject: "Reject",
        confirm: "Confirm",
        setOn: "Set on",
        logout: "Logout",
        wrongPasswordOrLoginOrLicenseMessage: "Authorisation Error. Check your login and password.",
        id: "Id",
        channelType: "Channel Type",
        channelName: "Channel Name",
        chatTag: "Chat tag",
        telegram: "Telegram",
        phrase: "Phrase",
        parameters: "Parameters",
        fileSendLikeImageError: "This image type can only be sent as a file"
    },
    analytics: {
        customerJourney: "Customer journey",
        statistics: "Statistics",
        monitoring: "Monitoring",
        reports: "Reports",
        exportReport: "Export",
        comeFrom: "Come from",
        dialog: "Dialog",
        wentTo: "Went to",
        getSettingForChartsMessage: "Cannot load setting for charts.",
        reportOutOfRangeException: "The report cannot be generated due to file format limitations.",
        reportException: "An error occurred while uploading the report.",
    },
    channels: {
        operatorsForChannel: "Operators for channel",
        operator: "Operator",
        feedback: "Feedback",
        vip: "VIP",
        spam: "Spam",
        emptyNameTooltipErrorMessage: "Name cannot be empty",
        invalidFieldsMessage: "Some fields invalid",
        channelCreatingErrorMessage: "Please, save or delete new channel",
        createChannel: "Create Channel",
        sureDeleteChannel: "Are you sure you want to delete the channel?",
        saveChannelSuccessMessage: "Save channel success",
        saveNewChannelSuccessMessage: "Create channel success",
        saveChannelErrorMessage: "Save channel error",
        saveNewChannelErrorMessage: "Cannot save new channel",
        channelAlreadyExists: "A channel with this token already exists",
        uniformWidgetStartSignalRError: "An error occurred while connecting to the connector {{url}}",
        deleteChannelSuccessMessage: "Delete channel success",
        deleteNewChannelSuccessMessage: "Channel was not saved",
        deleteChannelErrorMessage: "Delete channel error",
        deleteNewChannelErrorMessage: "�hannel with ID {0} cannot be deleted due to it has opened chats: ",
        copyScriptHeader: "Script copied",
        copyScriptBody: "Script has been copied to the clipboard. Use Ctrl+V to paste it to your site.",
        telegram: "Telegram",
        viber: "Viber",
        vk: "Vk",
        widget: "Widget",
        facebook: "Facebook",
        weChat: "WeChat",
        instagram: "Instagram",
        uniformWidget: "Uniform Widget",
        ednaWhatsApp: "WhatsApp (Edna)",
        whatsApp: "WhatsApp",
        voiceChannel: "Voice Channel",
        restartChannelSuccessMesage: "Channel restarted successfully",
        restartChannelQuestion: "Are you sure you want to restart the channel?",
        restartOnlyEnabledChannel: "Channel must be enabled"
    },
    chat: {
        transfer: "Transfer",
        transferToOperator: "Transfer to operator",
        transferToBot: "Transfer to bot",
        transferToGroup: "Transfer to group",
        invite: "Invite",
        editMessage: "Edit message",
        doLeaveChat: "Do you want to leave the chat?",
        doExportChat: "Do you want to export chat?",
        exportSuccess: "Chat exported successfully",
        exportError: "Export to external CRM is not configured",
        externalCrmOptionsAreNotConfidured: "Options of external crm are not configured",
        export: "Export",
        pause: "Pause",
        unpause: "Unpause",
        setPauseHeader: "Choose pause category",
        removePauseHeader: "Remove pause",
        removePause: "Remove pause",
        setPause: "Pause",
        pauseWithoutNotifacation: "Pause without client notification",
        pauseWithNotifacation: "Pause with client notification",
        pauseBotChatError: "Cannot pause the chat with bot",
        leave: "Leave",
        join: "Join",
        close: "Close",
        closeChat: "Close chat",
        save: "Save",
        specifyCategoryAndSubject: "Specify the category and subject of the chat",
        specifyCategory: "Specify the category of the chat",
        category: 'category',
        subject: 'subject',
        categoryAndSubjectSavedError: 'To close the chat, fill in all the fields',
        categoryAndSubjectSavedSuccessfully: 'Category and subject saved successfully',
        messageDeleted: "Message deleted",
        messageFileNoEdit: "Message with file/image cannot be edited.",
        messageFileNoDelete: "Message with file/image cannot be deleted.",
        cannotEditMessageWrongChannelType: "Cannot edit message. Wrong channel type.",
        sendFileMessageError: "An error occurred while sending the file",
        continue: "Continue",
        tagSaveError: "To save tags fill both fields",
        tagSaveSuccess: "The tags saved successfully",
        markAsSpam: "Mark as spam",
        contactHasOpenedChatException: "There is an open chat with this contact. ChatId - {0} Operator - {1}",
        enabledChannelNotFoundException: "Cannot start a new chat due to there is no enabled channel"
    },
    chats: {
        my: "My",
        withMe: "With Me",
        all: "All",
        archive: "Archive",
        autoUpdateChat: "Auto-update",
        info: "Info",
        statistics: "Statistics",
        closed: "Closed",
        pause: "PAUSE",
        active: "Active",
        expired: "Expired",
        finished: "Finished",
        missed: "Missed",
        chatNumber: "Chat Number",
        chatTag: "Chat tag",
        askYouWannaCloseChat: "Do you want to close the chat?",
        askYouWannaJoinChat: "Do you want to join the chat?",
        askYouWannaReopenChat: "Do you want to reopen the chat?",
        chatOpenedSuccessMessage: "Chat opened successfully.",
        chatNotOpenedMessageForSomeReason: "Chat was not opened. Maybe there are already open chats with this contact.",
        chatNotOpenedMessage: "There is an open chat with this contact. ChatId - {0} Operator - {1}",
        operatorNotAssignedYetMessage: "Not assigned yet",
        openChatErrorMessage: "Something went wrong during chat opening.",
        continueChatErrorMessage: "Cannot start the chat. Please make sure the channel exists and started.",
        editMessageSuccessMessage: "Message was successfully edited",
        deleteMessageSuccessMessage: "Message was successfully deleted",
        editMessageErrorMessage: "Can't edit message",
        chatAlreadyClosing: "Chat closing is already in processing",
        chatClosedSuccessfully: "Chat closed successfully",
        chatClosedErrorMessage: "An error occurred while closing the chat",
        enterChatName: "enter chat name...",
    },
    contact: {
        sureDeleteAccount: "Are you sure you want to delete your account?",
        createNewAccounts: "Create new accounts",
        noRecords: "No records",
        invalidFieldsErrorMessage: "There are some invalid fields",
        phoneNumber: "Phone Number",
        firstName: "First Name",
        lastName: "Last Name",
        middleName: "Middle Name",
        chatName: "Chat Name",
        comment: "Comment",
        chatsCount: "Chats count",
        locale: "Locale",
        contactCreatingErrorMessage: "Please, save or delete unsaved contact",
        searchingInputPlaceholder: "search by name, phone or chat name",
        noContacts: "No contacts",
        createContact: "Create contact",
        saveContactSuccessMessage: "Save contact success",
        saveNewContactSuccessMessage: "Create contact success",
        saveContactErrorMessage: "Save contact error",
        saveNewContactErrorMessage: "Cannot save new contact",
        deleteContactSuccessMessage: "Delete contact success",
        deleteNewContactSuccessMessage: "Delete new contact success",
        deleteContactErrorMessage: "Delete contact error",
        contactFoundMessage: "Contact found",
        contactNotFoundMessage: "Contact not found",
        errorSavingAccountWithoutData: "Can't save account with empty fields",
        errorSavingAccountOnlyWithSpaces: "Can't save account where fields consist of spaces",
        errorSavingAccountCharactersEqualOrMore50: "Cannot save an account where the number of characters in a field is greater than 50 characters",
        errorSavingAccountIdWithSpaces: "Can't save an account where the id field contains spaces",
    },
    license: {
        confirmPasswordNotMatchMessage: "Passwords do not match!",
        unknownErrorMessage: "An error occurred while activating access, contact your administrator!",
        licenseErrorMessage: "License error, contact your administrator!",
        updateLicenseSuccessMessage: "License updated successfully!",
        updateLicenseErrorMessage: "License update error!",
        licenseExpiresPart: 'You have {{days}} days of license left, contact your administrator!',
        license: "License",
        licenseDetails: "License Details",
        title: "Access activation"
    },
    mailing: {
        createMailing: "Create mailing",
        pending: "Pending..",
        inProgress: "In Progress",
        sent: "Sent",
        canceled: "Canceled",
        allContacts: "All contacts",
        balanceBelowZero: "Balance below zero",
        waitingForConnection: "Waiting for connection",
        sentVia: "Sent via",
        notEnoughSymbolsInMessageError: "Message is too short.",
        notSelectedChannelError: "You need to choose a channel.",
        saveMailingError: "An error occurred while saving.",
        getMailingByIdError: "Failed getting mailing from the server.",
        startMailingStartMessage: "Mailing started.",
        startMailingSuccessMessage: "Mailing successfully done.",
        startMailingErrorMessage: "An error occurred while sending the mailing.",
        deleteMailingSuccessMessage: "Mailing successfully deleted.",
        deleteMailingErrorMessage: "An error occurred while deleting the mailing.",
        chooseOperator: "Send by operator",
        needChooseOperator: "You should choose an operator"
    },
    nlp: {
        question: "Question",
        answer: 'Answer',
        getScript: "Get script",
        createNewTopic: "Create New Topic",
        createNewPair: "Create New Pair",
        topicRemoving: "Topic removing",
        doWantDeleteTopic: "Do you want to delete topic?",
        selectTarget: "Select target",
        transferQuestion: "Transfer question",
        move: "Move",
        to: "to",
        askYourQuestion: "Ask your question",
        unsupportedMessageType: "Unsupported message type",
        answerNotFound: "Answer not found",
        actualSearchString: "Actual search string:",
        sureRemoveButton: "Are you sure you want to remove the button ",
        buttonType: "Button type",
        text: "Text",
        value: "Value",
        noFoundTopicPair: "No topic pair found.",
        buttonTextExists: "A button with this text already exists.",
        newButton: "New button",
        buttonEditing: "Button editing",
        saveTopicSuccessMessage: "Save topic success",
        saveTopicErrorMessage: "Save topic error",
        deleteTopicSuccessMessage: "Delete topic success",
        deleteTopicErrorMessage: "Delete topic error",
        exportPairsErrorMessage: "Export pairs error",
        importPairsErrorMessage: "Import pairs error",
        importPairsSuccessMessage: "Import pairs success",
        publishQuestionsSuccessMessage: "Publish questions success",
        publishQuestionsErrorMessage: "Publish questions error",
        saveTopicPairSuccessMessage: "Save topic pair success",
        saveTopicPairErrorMessage: "Save topic pair error",
        saveSynonymsSuccessMessage: "Synonyms saved",
        synonyms: "Synonyms",
        synonyms_placeholder: "hi : howdy|hello|welcome\nwork : job|effort\nclear : plain|uncomplicated|understandable",
        synonyms_fill: 'Fill in the synonyms. Each word on a new line, followed by a colon and its synonyms separated by "|"',
        synonyms_needColon: "Missing colon between word and synonyms in string \n",
        synonyms_fewColons: "There are more than one separated colons in string ",
        synonyms_wrongCharacter: "Invalid character in string \n",
        synonym_stopWord: "Error. \"{{sw}}\" is a stop word",
        deleteTopicPairSuccessMessage: "Delete topic pair success",
        deleteTopicPairErrorMessage: "Delete topic pair error",
        changeTopicPairStatusSuccessMessage: "Change topic pair status success",
        changeTopicPairStatusErrorMessage: "Change topic pair status error",
        transferQuestionToTopicPairSuccessMessage: "Transfer question to topic pair success",
        transferQuestionToTopicPairErrorMessage: "Transfer question to topic pair error",
        messageBoxGetScriptMessage: "Script has been copied to the clipboard. Use Ctrl+V to paste it to your site.",
        messageBoxGetScriptTitle: "Script",
        messageBoxDeleteTopicMessage: "Do you want to remove topic?",
        messageBoxDeleteTopicTitle: "Topic removing",
        theme: "Theme",
        triggerName: "Trigger name",
        trigger: "Trigger",
        subject: "Subject",
        source: "Source",
        topicPairContentType_text: "Text",
        topicPairContentType_markup: "Markup",
        topicPairContentType_video: "Video",
        topicPairContentType_audio: "Audio",
        topicPairContentType_navigation: "Navigation",
        topicPairContentType_textToSpeech: "Text-to-speech",
        commonParameters: "Common parameters",
        errorSendNLPSearchWhenOperatorOffline: "Go online or pause to continue chat",
        errorSendNLPSearchWhenChatNotAssigned: "The selected chat is not associated with this operator",
        messageDontHaveAccessToTopic: "Sorry, you don't have access to this topic",
        questionWithOnlyStopWordsError: "Question <{{question}}> contains only stop words"
    },
    operators: {
        name: "Name",
        displayName: "Display name",
        createOperator: "Create operator",
        sureRemoveOperator: "Are you sure you want to remove the operator?",
        incorrectLoginMessage: "Login contains invalid characters",
        emptyNameTooltipMessage: "Name cannot be empty",
        emptyLoginTooltipMessage: "Login cannot be empty",
        emptyPasswordTooltipMessage: "Password cannot be empty",
        minLengthPasswordMessage: "Password should be longer then ",
        maxLengthPasswordMessage: "Password should be shorter then ",
        minLengthLoginMessage: "Login should be longer then ",
        maxLengthLoginMessage: "Login should be shorter then ",
        changeAvatarNullIdErrorMessage: "Cannot change avatar of non created operator",
        saveOperatorSettingsNullIdMessage: "Cannot save settings of non created operator",
        spaceInLoginErrorMessage: "Login cannot contain spaces",
        operatorCreatingErrorMessage: "Cannot create few operators at a time!",
        uploadScript: "Upload",
        saveOperatorNoChangesMessage: "Operator has not been changed",
        saveOperatorSuccessMessage: "Save operator success",
        saveOperatorErrorMessage: "Save operator error",
        duplicatedOperatorNameErrorMessage: "Operator with the same name already exists",
        duplicatedOperatorLoginErrorMessage: "Operator with the same login already exists",
        deleteOperatorSuccessMessage: "Delete operator success",
        cannotDeleteYourself: "You cannot delete the operator you logged in",
        deleteOperatorErrorMessage: "Delete operator error",
        cannotCheckOperatorExists: "Unexpected error! Cannot check if user already exists"
    },
    settings: {
        createSettings: "Create settings",
        settingIdCannotBeEmpty: "Cannot save setting with empty id.",
        confirmDeleteSetting: "Delete setting",
        confirmDeleteParameter: "Delete parameter",
        settingIdAlreadyExists: "Setting with Id {id} already exists.",
        settingId: "Setting Id",
        key: "Key",
        value: "Value",
        system: "System",
        integration: "Integration",
    },
    chatsPanel: {
        youAreLoggedInAs: "You are loggged in as ",
        toChangeTheUserPress: "To change the user press ",
        haveBeenAssignedChannels: "You have been assigned to the channels: ",
        selectChatMessage: "Please, select chat.",
        transferChatErrorMessage: "Cannot transfer chat.",
        transferChatSuccessMessage: "Chat transfered success.",
        errorReopenChatDueOfflineStatus: "Go online to continue chat",
        cannotOpenChatInWidgetChannel: "Cannot open chat in widget channel.",
        editMessageWrongChannelTypeMessage: "Cannot edit message. Wrong channel type.",
        sendMessageError: "An error occurred while sending messages",
        sendFileMessageError: "An error occurred while sending the file",
    },
    permission: {
        editPermissionsFor: "Edit permissions for ",
        tabRole: "Roles",
        tabUsers: "Users",
        selectUser: "Select user"
    },
    error: {
        disableWithOpenChats: "Update channel with ID {{channelId, number}} failed: Can not disable channel if it has opened chats: {{openedChats, number}}",
        changeTokenWhenOpenChats: "Update channel with ID {{channelId, number}} failed: Cannot change type or token if the channel has chats",
        connectionError: "Connection error. Try again later.",
        invalidDateMessage: "Invalid date format",
        leftDateLessMessage: "The left date is less than the right date",
        error: "Error!",
        invalidTokenErrorMessage: "Please login to continue",
        channelNotInitialized: "Channel not initialized",
        fileEmpty: "The file is empty and can't be sent.",
        notSendMessageNotChatParticipant: "Cannot send message due to operator is not a chat participant. ChatId: {{chatId, number}} OperatorId: {{operatorId, number}}",
        botBlockedByUser: "Channel blocked by user. Channel id: {{channelId, number}}, Chat id: {{chatId, number}}",
        sendMessageFailed: "Send message failed. ChatId: {{chatId, number}} OperatorId: {{operatorId, number}}",
        FailedLicenseValidation: "License error, contact your administrator!",
        LicenseExpired: "The license has expired, contact your administrator!",
        LicenseAlreadyActivated: "This license is already activated.",
        UserAlreadyExist: "A user with this login already exists."
    },
    notification: {
        locationText: "Location",
        documentText: "Document",
        photoText: "Photo",
        newMessageInChat: "New message in chat ",
        newChatRecieved: "New chat received!",
        chatId: "ChatId-",
        connectionError: "Connection error!",
        connectionErrorText: "Failed to restore connection, you will be transferred to Offline status",
        connectionAbortText: "The connection was broken. Refresh the page."
    }
};

export default translate;
