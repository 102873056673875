import { connect } from "react-redux";
import React, { useEffect } from "react";
import "./SpamChatButton.css"
import { isChatSpam, markChatAsSpam } from "src/app/chats-panel/~store/actions/actions";
import { ISpamChatButtonProps } from "./~types/SpamChatButtonProps";
import { selectedChatIdSelector, selectedChatSelector } from "src/app/chats-panel/~store/selectors";
import { IApplicationState } from "src/~store/models/ApplicationState";
import { useTranslation } from "react-i18next";

type ReduxType = ReturnType<typeof mapStateToProps> & ISpamChatButtonProps;

const SpamChatButton = (props: ReduxType) => {
    const { t } = useTranslation();
    useEffect(() => {
        if(props.selectedChatId) {
            props.isChatSpam(props.selectedChatId);
        }
    },[props.selectedChatId])
    return (
        <React.Fragment >
            <div className="spam-chat-button" 
                onClick={() => props.markChatAsSpam(props.selectedChatId)}
                title={t('chat.markAsSpam')}
            >
                <img src={props.selectedChat?.isSpam !== false ? require("./../../images/spam_on.svg") : require("./../../images/spam_off.svg")} /> 
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: IApplicationState, externalProps: any) => {
    return {
      selectedChatId: selectedChatIdSelector(state),
      selectedChat: selectedChatSelector(state),
    };
  };
  
const mapDispatchToProps = (dispatch: any): ISpamChatButtonProps => {
return {
        markChatAsSpam: (chatId: number) => dispatch(markChatAsSpam(chatId)),
        isChatSpam: (chatId: number) => dispatch(isChatSpam(chatId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpamChatButton);