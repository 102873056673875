import * as React from "react";
import { useState } from "react";
import { AnalyticTab } from "../../../~store/models/enums/AnalyticTab";
import { IApplicationState } from "src/~store/models/ApplicationState";
import { IAnalyticsSubtabsProps } from "../AnalyticsTabsProps";
import { changeAnalyticSubtab, changeAnalyticTab } from "../../../~store/actions/actions";
import { selectedCurrentSettingsSelector, selectedSubtabSelector, selectedTabKeysSelector, selectedTabSelector } from "../../../~store/selector";
import { connect } from "react-redux";
import Charts from "./charts/Charts";
import { ISearchingTag, TagName, TagType } from "src/app/shared/components/tag-searching-input/~types/models/SearchingTag";
import { IChartsExternalProps, IChartsProps } from "./charts/ChartsProps";

type ReduxType = ReturnType<typeof mapStateToProps> & IAnalyticsSubtabsProps;

interface IChartsTabExternalProps {
    tags: ISearchingTag[]
}

const ChartsTab = (props: ReduxType) => {
    return (
        <div>
            <div className="analytics-header analytics-subtab-header">
                {props.allKeys.length > 1 && <div className="analytics-tabs-wrapper">
                    {props.allKeys.map((element, index) => {
                        return (<span 
                        key = {index}
                        className={props.selectedSubtab === element ? "active" : ""}
                        onClick={() => props.changeAnalyticSubtab(props.selectedTab, element, props.currentSettings, props.tags)}>
                        {element}
                    </span>)
                    })}
                </div>}
                {props.selectedTab === AnalyticTab.Statistic && <Charts tags={props.tags} settingId="StatisticsCharts"/>}
                {props.selectedTab === AnalyticTab.Monitoring && <Charts tags={props.tags} settingId="MonitoringCharts"/>}
            </div>
        </div>
    );
}

const mapStateToProps = (state: IApplicationState, externalProps: IChartsTabExternalProps) => {
    return {
        tags: externalProps.tags,
        selectedTab: selectedTabSelector(state),
        selectedSubtab: selectedSubtabSelector(state),
        allKeys: selectedTabKeysSelector(state),
        currentSettings: selectedCurrentSettingsSelector(state)
    };
}

const mapDispatchToProps = (dispatch: any): IAnalyticsSubtabsProps => {
    return {
        changeAnalyticSubtab: (tab: AnalyticTab, subtab: string, currentSettings: string, tags: ISearchingTag[]) => dispatch(changeAnalyticSubtab(tab, subtab, currentSettings, tags))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartsTab);

