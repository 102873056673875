const moduleName: string = "CONTACT/";

export const GET_ALL_CONTACTS_START: string = `${moduleName}GET_ALL_CONTACTS_START`;
export const GET_ALL_CONTACTS_SUCCESS: string = `${moduleName}GET_ALL_CONTACTS_SUCCESS`;
export const GET_ALL_CONTACTS_ERROR: string = `${moduleName}GET_ALL_CONTACTS_ERROR`;

export const CREATE_CONTACT: string = `${moduleName}CREATE_CONTACT`;
export const CLEAR_CONTACT: string = `${moduleName}CLEAR_CONTACT`;

export const SAVE_CONTACT_START: string = `${moduleName}SAVE_CONTACT_START`;
export const SAVE_CONTACT_SUCCESS: string = `${moduleName}SAVE_CONTACT_SUCCESS`;
export const SAVE_NEW_CONTACT_SUCCESS: string = `${moduleName}SAVE_NEW_CONTACT_SUCCESS`;
export const SAVE_NEW_CONTACT_ERROR: string = `${moduleName}SAVE_NEW_CONTACT_ERROR`;
export const SAVE_CONTACT_ERROR: string = `${moduleName}SAVE_CONTACT_ERROR`;

export const DELETE_CONTACT_START: string = `${moduleName}DELETE_CONTACT_START`;
export const DELETE_CONTACT_SUCCESS: string = `${moduleName}DELETE_CONTACT_SUCCESS`;
export const DELETE_NEW_CONTACT_SUCCESS: string = `${moduleName}DELETE_NEW_CONTACT_SUCCESS`;
export const DELETE_CONTACT_ERROR: string = `${moduleName}DELETE_CONTACT_ERROR`;

export const GET_CONTACT_BY_ID_START: string = `${moduleName}GET_CONTACT_BY_ID_START`;
export const GET_CONTACT_BY_ID_SUCCESS: string = `${moduleName}GET_CONTACT_BY_ID_SUCCESS`;
export const GET_CONTACT_BY_ID_ERROR: string = `${moduleName}GET_CONTACT_BY_ID_ERROR_ACTION`;

export const CHANGE_SELECTED_CONTACT: string = `${moduleName}CHANGE_SELECTED_CONTACT`;

export const FIND_CONTACT_BY_PHONE_START: string = `${moduleName}FIND_CONTACT_BY_PHONE_START`;
export const FIND_CONTACT_BY_PHONE_SUCCESS: string = `${moduleName}FIND_CONTACT_BY_PHONE_SUCCESS`;
export const FIND_CONTACT_BY_PHONE_NOT_FOUND: string = `${moduleName}FIND_CONTACT_BY_PHONE_NOT_FOUND`;
export const FIND_CONTACT_BY_PHONE_ERROR: string = `${moduleName}FIND_CONTACT_BY_PHONE_ERROR`;

export const CHANGE_CONTACT_AVATAR_START: string = `${moduleName}CHANGE_CONTACT_AVATAR_START`;
export const CHANGE_CONTACT_AVATAR_SUCCESS: string = `${moduleName}CHANGE_CONTACT_AVATAR_SUCCESS`;
export const CHANGE_CONTACT_AVATAR_ERROR: string = `${moduleName}CHANGE_CONTACT_AVATAR_ERROR`;

export const SET_SEARCH_STRING: string = `${moduleName}SET_SEARCH_STRING`;

export const SAVE_ACCOUNT_SUCCESS: string = `${moduleName}SAVE_ACCOUNT_SUCCESS`

export const DELETE_ACCOUNT_SUCCESS: string = `${moduleName}DELETE_ACCOUNT_SUCCESS`

export const MERGE_CONTACT: string = `${moduleName}MERGE_CONTACT`;

export const RESTORE_CONTACT_START: string = `${moduleName}RESTORE_CONTACT_START`;
export const RESTORE_CONTACT_SUCCESS: string = `${moduleName}RESTORE_CONTACT_SUCCESS`;
export const RESTORE_CONTACT_ERROR: string = `${moduleName}RESTORE_CONTACT_ERROR`;

export const CLEAR_CONTACT_FOUND_BY_PHONE: string = `${moduleName}CLEAR_CONTACT_FOUND_BY_PHONE`;

export const TOGGLE_CONTACT_SORTING: string = `${moduleName}TOGGLE_CONTACT_SORTING`;

export const DETACH_MESSENGER_START: string = `${moduleName}DETACH_MESSENGER_START`;
export const DETACH_MESSENGER_SUCCESS: string = `${moduleName}DETACH_MESSENGER_SUCCESS`;
export const DETACH_MESSENGER_ERROR: string = `${moduleName}DETACH_MESSENGER_ERROR`;