import { IChatsState } from './ChatsState';
import { IChat } from '../models/Chat';
import { IMessage } from '../models/Message';
import { GetChatsMode } from '../models/enums/GetChatsMode';
import { ISLColorSecondsSettigs } from '../models/SLColorSecondsSettings';

export const initState: IChatsState = {
    chatsList: {
        chats: [],
        autoUpdateChats: true,
        isReceivingChats: false,
        isAllChatsLoaded: false,
        isChatCreating: false,
        countOfChats: 5,
        searchString: '',
        chatsItemInfo: {
            selectedChat: {} as IChat,
            isReceivingChat: false,
        },
        getChatsMode: GetChatsMode.my,
        isModeChanging: false,
        tags: [],
        chatTags: [],
        chatTagValues: [],
        SLColorSecondsSettings: {} as ISLColorSecondsSettigs,
    },
    contactChatsInfo: {
        isReceivingInfo: false,
        isMessageEditing: false,
        searchString: '',
        contactChats: [],
        beforeChatsCount: 0,
        afterChatsCount: 0,
        areChatsBefore: true,
        selectedChatId: 0,
        firstElementId: '',
        selectedMessage: undefined,
    }
}