import * as React from "react";
import {connect} from "react-redux";

import {IApplicationState} from "src/~store/models/ApplicationState";
import {IKnowledgeBaseMainProps} from "./~types/KnowledgeBaseMainProps";

import {getAllTopics} from "../~store/actions/actions";
import {isOperationInProgressSelector} from "../~store/selectors";

import Loader from "src/app/shared/components/loader/Loader";
import TopicList from "../topic-list/TopicList";
import KnowledgeBaseHeader from "../knowledge-base-header/KnowledgeBaseHeader";

import "./KnowledgeBaseMain.css";

type ReduxType = ReturnType<typeof mapStateToProps> & IKnowledgeBaseMainProps;

class KnowledgeBaseMain extends React.Component<ReduxType> {
    public componentDidMount() {
        this.props.getAllTopics();
    }

    public render() {
        return (
            <div className="knowledge-base__main">
                <KnowledgeBaseHeader />
                <TopicList />
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        isOperationInProgress: isOperationInProgressSelector(state)
    };
};

const mapDispatchToProps = (dispatch: any): IKnowledgeBaseMainProps => {
    return {
        getAllTopics: () => dispatch(getAllTopics),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeBaseMain);
