import { useCallback, useLayoutEffect, useRef, useState } from "react";

const useSaveScroll = ({ trigger }: { trigger: boolean }) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const resetScroll = useCallback(() => {
    setScrollPosition(0);
    elementRef.current?.scrollTo({ top: 0 });
  }, [elementRef.current]);

  const saveScrollPosition = useCallback(() => {
    setScrollPosition(elementRef?.current?.scrollTop || 0);
  }, [elementRef.current]);

  useLayoutEffect(() => {
    let effectAdded = false;
    if (elementRef.current && !effectAdded) {
      effectAdded = true;
      elementRef?.current?.addEventListener("scroll", saveScrollPosition);
      return () => {
        effectAdded = false;
        elementRef?.current?.removeEventListener("scroll", saveScrollPosition);
      };
    }
  }, [trigger, elementRef, saveScrollPosition]);

  useLayoutEffect(() => {
    if (trigger && elementRef.current) {
      elementRef.current?.scrollTo({ top: scrollPosition });
    }
  }, [trigger]);

  return {
    elementRef,
    resetScroll,
  };
};

export default useSaveScroll;
