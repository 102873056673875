import * as React from "react";
import {IMessageInputProps} from "./~types/MessageInputProps";
import {IMessageInputState} from "./~types/MessageInputState";
import {FormGroup, Button, Input} from "reactstrap";
import {LINE_BREAK, MIN_MESSAGE_LENGTH, ENTER_KEY} from "./MessageInputConstants";

import "./MessageInput.css";
import ScalableTextInput from "src/app/shared/components/scalable-text-input/ScalableTextInput";

export default class MessageInput extends React.Component<IMessageInputProps, IMessageInputState> {
    public readonly state: Readonly<IMessageInputState> = {
        messageText: "",
    };

    public render() {
        return (
            <FormGroup>
                <FormGroup className="message-form">
                    <ScalableTextInput
                        className="message-form__input"
                        value={this.state.messageText}
                        onChange={this._onMessageChangeHandler}
                        onKeyDown={event => {
                            if (event.key === ENTER_KEY && !event.shiftKey) {
                                this._onSendMessageHandler();
                            }
                        }}
                    />
                    <Button className="message-form__send" onClick={this._onSendMessageHandler}>
                        <i className="material-icons">send</i>
                    </Button>
                </FormGroup>
            </FormGroup>
        );
    }

    private _onMessageChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.currentTarget.value[event.currentTarget.value.length - 1] === LINE_BREAK 
            && this.state.messageText.length < MIN_MESSAGE_LENGTH) {
            return;
        }
        const messageText = event.currentTarget.value;
        this.setState({messageText});
    };

    private _onSendMessageHandler = (): void => {
        if (this.state.messageText.length < MIN_MESSAGE_LENGTH) {
            return;
        }
        this.props.onSendMessage(this.state.messageText);
        this.setState({messageText: ""});
    };
}
