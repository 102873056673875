const moduleName: string = 'OPERATORS/';

// operators list
export const GET_ALL_OPERATORS_START: string = `${moduleName}GET_ALL_OPERATORS_START`;
export const GET_ALL_OPERATORS_SUCCESS: string = `${moduleName}GET_ALL_OPERATORS_SUCCESS`;
export const GET_ALL_OPERATORS_ERROR: string = `${moduleName}GET_ALL_OPERATORS_ERROR`;

export const SAVE_OPERATOR_START: string = `${moduleName}SAVE_OPERATOR_START`;
export const SAVE_OPERATOR_SUCCESS: string = `${moduleName}SAVE_OPERATOR_SUCCESS`;
export const SAVE_NEW_OPERATOR_SUCCESS: string = `${moduleName}SAVE_NEW_OPERATOR_SUCCESS`;
export const SAVE_OPERATOR_ERROR: string = `${moduleName}SAVE_OPERATOR_SUCCESS`;

export const DELETE_OPERATOR_START: string = `${moduleName}DELETE_OPERATOR_START`;
export const DELETE_OPERATOR_SUCCESS: string = `${moduleName}DELETE_OPERATOR_SUCCESS`;
export const DELETE_OPERATOR_ERROR: string = `${moduleName}DELETE_OPERATOR_ERROR`;
export const DELETE_NEW_OPERATOR_SUCCESS: string = `${moduleName}DELETE_NEW_OPERATOR_SUCCESS`;

export const CREATE_OPERATOR: string = `${moduleName}CREATE_OPERATOR`;

export const CHANGE_OPERATOR_AVATAR_START: string = `${moduleName}CHANGE_OPERATOR_AVATAR_START`;
export const CHANGE_OPERATOR_AVATAR_SUCCESS: string = `${moduleName}CHANGE_OPERATOR_AVATAR_SUCCESS`;
export const CHANGE_OPERATOR_AVATAR_ERROR: string = `${moduleName}CHANGE_OPERATOR_AVATAR_ERROR`;

export const SAVE_OPERATOR_SETTING: string = `${moduleName}SAVE_OPERATOR_SETTING`; 

export const TOGGLE_OPERATOR_SORTING: string = `${moduleName}TOGGLE_OPERATOR_SORTING`;