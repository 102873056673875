import IUser from '../app/authentication/~store/models/User';
import { IApiActionResult } from './ApiActionResult';
import { getUserExternalIdFromUrl } from "src/app/shared/helpers/user-auth-helper/UserAuthHelper";
import { logout } from 'src/~signalR/SignalRHub';
import i18n from "src/app/shared/localization/i18n";

export const fetchGet = 
    async (requestUrl: string, requestOptions?: RequestInit): Promise<Response> => {
        const authHeader = getAuthHeader();

        let headers: Headers = new Headers();
        headers.set('Content-Type', 'application/json');
        if(authHeader !== null){
            headers.set('Authorization', authHeader.Authorization);
        }
        return await fetch(requestUrl, {
            ...requestOptions,
            method: 'GET',
            headers: headers
        });
}

export const fetchPost =
    async (requestUrl: string, requestOptions?: RequestInit): Promise<Response> => {
        const authHeader = getAuthHeader();

        let headers: Headers = new Headers();
        let body = requestOptions?.body;
        if(!(body instanceof FormData)){
            headers.set('Content-Type', 'application/json');
        }
        if(authHeader !== null){
            headers.set('Authorization', authHeader.Authorization);
        }

        return await fetch(requestUrl, {
            ...requestOptions,
            method: 'POST',
            headers: headers
        })
}

export const getApiActionResult = 
    async <T>(
        fetchPromise: Promise<Response>, 
        hasReturnValue: boolean = false
    ): Promise<IApiActionResult<T>> => {
        let statusCode: number = 0;
        try {
            const response: Response = await fetchPromise;
            statusCode = response.status;
            if(response.ok) {
                let value: any = ""; 
                if(response.status !== 204) {
                    value = hasReturnValue ? await response.json() : null;
                }
                return { isSuccess: true, value, statusCode };
            } else {
                let errorText: string | undefined = undefined;
                try {
                    errorText = await response.text();
                } catch {
                    errorText = undefined;
                }
                if(response.status === 401){
                    logout(i18n.t(errorText ?? 'error.invalidTokenErrorMessage'));
                    return { isSuccess: false, statusCode }
                }
                return { isSuccess: false, statusCode, errorText }
            }
        } catch(ex) {
            return { isSuccess: false, statusCode };
        }
}

export const fetchGetFile = async (
    requestUrl: string, 
    filename: string, 
    requestOptions?: RequestInit
): Promise<any> => {
    const authUserHeaders = getAuthHeader();
    const method = requestOptions?.body ? "POST" : "GET";
    return await fetch(requestUrl, {
        ...requestOptions,
        method: method,
        headers: {
            "Content-Type": "application/json",
            ...authUserHeaders
        }
    })
        .then(async response => {
            if (response.status === 200) 
                await response.blob()
                    .then(blob => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = filename;
                        a.click();
                    });
            return response;
        });
};

const getAuthHeader = (): Record<'Authorization', string> | null => {
    try {
        const user: IUser = JSON.parse(sessionStorage.getItem('user') ?? "");
        if(user.isAuth &&
           user.tokenInfo.token.length > 0) {
            return {'Authorization': 'Bearer ' + user.tokenInfo.token };    
        } else {
            return null;
        }
    } catch(ex) {
        return null;
    }
}

const getAuthUserHeader = (): any => {
    const userExternalId = getUserExternalIdFromUrl();
    return {"X-User-External-Id": userExternalId};
};