const moduleName: string = "ANALYTICS/";

export const GET_CHAT_STEPS_START: string = `${moduleName}GET_CHAT_STEPS_START`;
export const GET_CHAT_STEPS_SUCCESS: string = `${moduleName}GET_CHAT_STEPS_SUCCESS`;
export const GET_CHAT_STEPS_ERROR: string = `${moduleName}GET_CHAT_STEPS_ERROR`;

export const GET_BOTS_SUCCESS: string = `${moduleName}GET_BOTS_SUCCESS`;

export const CHANGE_SELECTED_BOT_SUCCESS: string = `${moduleName}CHANGE_SELECTED_BOT_SUCCESS`;

//get setting by id
export const GET_SETTING_BY_ID_SUCCESS: string = `${moduleName}GET_SETTING_BY_ID_SUCCESS`;
export const GET_SETTING_BY_ID_ERROR: string = `${moduleName}GET_SETTING_BY_ID_ERROR`;

export const GET_REPORTS_SETTING_BY_ID_SUCCESS: string = `${moduleName}GET_REPORTS_SETTING_BY_ID_SUCCESS`;

export const CHANGE_REPORT_SUBTAB_SUCCESS: string = `${moduleName}CHANGE_REPORT_SUBTAB_SUCCESS`;

export const GET_REPORT_BY_NAME_SUCCESS: string = `${moduleName}GET_REPORT_BY_NAME_SUCCESS`;

//update charts
export const UPDATE_CHARTS_BY_FILTER: string = `${moduleName}UPDATE_CHARTS_BY_FILTER`;

//change analytic tab
export const CHANGE_ANALYTIC_TAB: string = `${moduleName}CHANGE_ANALYTIC_TAB`;

//change analytic tab
export const CHANGE_ANALYTIC_SUBTAB: string = `${moduleName}CHANGE_ANALYTIC_SUBTAB`;

export const SET_ANALYTICS_TAGS_SUCCESS: string = `${moduleName}SET_ANALYTICS_TAGS_SUCCESS`;

//save report

export const SAVE_REPORT_BY_NAME_START: string = `${moduleName}SAVE_REPORT_BY_NAME_START`;
export const SAVE_REPORT_BY_NAME_SUCCESS: string = `${moduleName}SAVE_REPORT_BY_NAME_SUCCESS`;
export const SAVE_REPORT_BY_NAME_ERROR: string = `${moduleName}SAVE_REPORT_BY_NAME_ERROR`;