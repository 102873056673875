import { AnalyticTab } from "../models/enums/AnalyticTab";
import { IAnalyticsState } from "./AnalyticsState";
import { ISearchingTag } from "src/app/shared/components/tag-searching-input/~types/models/SearchingTag";

export const initAnalysticsState: IAnalyticsState =  {
    analytics: {
        selectedTab: AnalyticTab.Statistic,
        customerJourney: {
            chatSteps: [],
            bots: [],
            selectedBotId: undefined
        },
        charts: {
            allCharts: []
        },
        reports: {
            allKeys: [],
            selectedSubtab: "",
            currentSettings: "",
            report: "",
            isLoad: false
        },
        allKeys: ["Default"],
        currentSettings: "",
        selectedSubtab: "Default", 
        tags: []
    }
}