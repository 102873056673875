const IMAGE_FORMATS = [
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
];

export const getIsFileImage = (format: string): boolean => {
    return IMAGE_FORMATS.includes(format);
}