import * as React from "react";
import { GET_AVATAR_BY_CONTACT_ID_API_ROUTE } from "src/app/contact/~api/apiRoutes";
import { IContactItemProps } from "./~types/ContactItemProps";
import ContactMessengers from "./contact-messengers/ContactMessengers";
import { IContactItemState } from "./~types/ContactItemState";
import { Avatar } from '@mui/material';
import ContactIcon from '@mui/icons-material/AccountCircle';

import "./ContactItem.css";
import { getDateTime } from "src/app/shared/helpers/date-helper/DateFormats";
import history from "src/~store/history";

export class ContactItem extends React.Component<
  IContactItemProps,
  IContactItemState
> {
  public readonly state: IContactItemState = {
    isContactItemInfoOpen: false,
  };

  public render() {
    const { contact } = this.props;

    const userInStorage = sessionStorage.getItem("user");
    const customerId = userInStorage === null ? "" : JSON.parse(userInStorage.toString()).customerId;
    const avatarLink =
    contact.avatarId === null
      ? ''
      : GET_AVATAR_BY_CONTACT_ID_API_ROUTE +
        contact.avatarId + 
        "&customerId=" + 
        customerId +
        "&cahceBypass=" +
        Math.random();
    return (
      <React.Fragment>
        <tr
          onClick={() => {
            this.props.onSelectContact();
            history.push("/contacts/edit-contact/" + contact.contactId);
          }}
          className="contact-item__table-row"
        >
          <td className="contact-item__contact-info-cell">
            <div className="contact-item__contact-info-container">
              <Avatar
                className="contact-item__contact-avatar"
                src={avatarLink}
              >
                <ContactIcon style={{ color: "#26a69a" }} />
              </Avatar>
              <div className="contact-item__contact-info">
                <div className="contact-item__contact-name">
                  {contact.lastName + " " + contact.firstName}
                </div>
                <div className="contact-item__contact-phone">
                  {contact.phone !== null && contact.phone.length > 0
                    ? "+" + contact.phone
                    : ""}
                </div>
              </div>
            </div>
          </td>
          <td className="contact-item__messengers">
            <ContactMessengers
              messengers={contact.messengers}
              isNeedDetach={false}
            />
          </td>
          <td className="contact-item__creation-date">{getDateTime(contact.createdOn)}</td>
          <td className="contact-item__chats-count">{contact.chatsCount}</td>
        </tr>
      </React.Fragment>
    );
  }
}
