import * as React from 'react';
import MailingList from './mailing-list/MailingList'

import './MailingMain.css';
import { useTranslation } from 'react-i18next';

const MailingMain = () => {
    const { t } = useTranslation();
    return(
        <div className="mailing-main">
            <div className="mailing-main__header">{t('common.mailing')}</div>
            <MailingList />
        </div>
    )
}

export default MailingMain;